import { observer } from 'mobx-react-lite';
import LeaseSection from '.';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import LeaseModel from '../../models/offers/lease/LeaseModel';

interface Props {
  group: LeaseModel;
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
}

/**
 * Tabs selection for master lease and examples
 * @returns
 */
const LeaseGroup = ({ group, seriesProfile, rgnlAlt }: Props) => {
  if (group.selectedLease) {
    return <LeaseSection lease={group} leaseForm={group.selectedLease} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />;
  }

  return null;
};

export default observer(LeaseGroup);
