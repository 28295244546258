import { makeAutoObservable } from 'mobx';
import { DropdownItem } from 'oat-common-ui';
import { Report } from '../gql/generated';
import { getFiscalYearFromDate } from '../utils/getFiscalYearFromDate';

class ReportsStore {
  fiscalYear = getFiscalYearFromDate(new Date()).toString();
  selectedOfferingData: DropdownItem = { value: '', label: '' };
  availableReports: Report[] = [];
  generatingReport = false;
  selectedReport = { value: '', label: '' };
  defaultReport = { value: '', label: '' };

  constructor() {
    makeAutoObservable(this);
  }

  updateFiscalYear = (year: string) => {
    this.fiscalYear = year;
  };

  setAvailableReports = (reports: Report[]) => {
    this.availableReports = reports;
  };

  setGeneratingReport = (generatingReport: boolean) => {
    this.generatingReport = generatingReport;
  };

  setSelectedOfferingData = (offeringName: DropdownItem) => {
    this.selectedOfferingData = offeringName;
  };

  addReport = (report: Report) => {
    // remove on UI older version of same report
    this.availableReports = this.availableReports.filter(availReport => availReport.fileName !== report.fileName);
    this.availableReports.push(report);
  };

  setSelectedReport = (report: { label: string; value: string }) => {
    this.selectedReport = report;
  };
}

export default ReportsStore;
