import { makeAutoObservable } from 'mobx';

class TableSort {
  sortField = '';

  sortAscending = false;

  constructor(sortField: string, sortAscending: boolean) {
    makeAutoObservable(this);
    this.sortField = sortField;
    this.sortAscending = sortAscending;
  }

  setSortField = (sortField: string) => {
    if (sortField !== this.sortField) {
      this.sortAscending = false;
    }

    this.sortAscending = !this.sortAscending;
    this.sortField = sortField;
  };
}

export default TableSort;
