import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../../styles.module.scss';

interface Props {
  children: ReactNode;
}

const GridItemWrapper = ({ children }: Props) => {
  return (
    <div className={clsx(styles.fySeriesBox, styles.fySeriesBoxShadowRight)}>
      <ul className={styles.fySeriesBoxList}>{children}</ul>
    </div>
  );
};

export default GridItemWrapper;
