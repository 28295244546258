import AccordionItemModel from '../models/AccordionItemModel';

/**
 *
 * @param items Root items
 * @param uid
 * @param parent
 */
const recursiveExpand = (items: AccordionItemModel[], uid: string) => {
  items.forEach(item => {
    if (item.uid !== uid && item.items.length > 0) {
      recursiveExpand(item.items, uid);
    } else if (item.uid === uid) {
      item.toggleExpand();
    }
  });
};

export default recursiveExpand;
