import { observer } from 'mobx-react-lite';
import { assignStringValue, BlockUi, dateFormatISOString, OATIcon, Tooltip, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NavLink } from 'react-router-dom';
import { FEATURE_UNPUBLISH, OfferingStatus, PUBLISHED_ATC_DATE_FORMAT } from '../../constants/global';
import { Offering, useCreateAtcMutation, useDeleteOfferingMutation, useUnpublishOfferingMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import WarningModal from '../FYRollup/components/header/Navbar/components/WarningModal';
import CopyProgramModal from './CopyProgramModal';
import DefaultNationalModal from './DefaultNationalModal';

import styles from './styles.module.scss';

interface DashboardRowProps {
  item: Offering;
}

const DashboardRow = ({ item }: DashboardRowProps) => {
  const {
    dashboardStore: { addCopiedOffering, hasUnsavedChanges, setUnsavedChanges, getOfferingDisplayName, deleteOfferingById, updateByOffering },
    globalStore: { setShowProgressBar },
  } = useStores();
  const { error } = useToast();

  const [showIcons, setShowIcons] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);

  const [fetchingCreateATC, setFetchingCreateATC] = useState(false);
  const [blockingUI, setBlockingUI] = useState(false);

  const formatedAtcPublishedDate = item.atcPublishedDate && dateFormatISOString(new Date(assignStringValue(item.atcPublishedDate)), PUBLISHED_ATC_DATE_FORMAT);
  const [createATC] = useCreateAtcMutation();
  const [deleteOffering] = useDeleteOfferingMutation();
  const [unpublishOffering] = useUnpublishOfferingMutation();

  const handleCloseCopyModal = () => {
    if (hasUnsavedChanges) {
      setShowWarningModal(true);
    } else {
      setShowCopyModal(false);
    }
  };

  const handleCreateATC = async () => {
    try {
      setShowProgressBar(true);
      setFetchingCreateATC(true);
      const res = await trackPromise(createATC({ variables: { input: { offeringId: item.id } } }));

      if (res.data?.createATC.success) {
        addCopiedOffering(res.data.createATC.ATCOffering, true);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowProgressBar(false);
      setFetchingCreateATC(false);
    }
  };

  const renderStatusIcon = () => {
    switch (item.status) {
      case OfferingStatus.PUBLISHED:
        return <OATIcon icon="check-mark" colorTheme="green" />;
      case OfferingStatus.DRAFT:
        return <OATIcon icon="notes" colorTheme="orange" />;
      default:
        return <></>;
    }
  };

  const handleDeleteOffering = async (offering: Offering) => {
    try {
      setBlockingUI(true);
      setShowProgressBar(true);
      const res = await trackPromise(deleteOffering({ variables: { input: { offeringId: offering.id, rev: offering.rev } } }));

      if (res.data?.deleteOffering.success) {
        deleteOfferingById(offering.id);
      }
      setShowProgressBar(false);
    } catch (e) {
      setShowProgressBar(false);
      error((e as Error).message);
    } finally {
      setBlockingUI(false);
      setShowDeleteModal(false);
    }
  };

  const handleUnpublishOffering = async (offering: Offering) => {
    const { id, rev } = offering;

    try {
      const res = await trackPromise(unpublishOffering({ variables: { input: { offeringId: id, rev } } }));

      if (res.data?.unpublishOffering.success) {
        updateByOffering(res.data.unpublishOffering.updatedOffering as Offering);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowUnpublishModal(false);
    }
  };

  return (
    <>
      <BlockUi blocking={blockingUI || fetchingCreateATC} title={fetchingCreateATC ? 'Creating an ATC Draft …' : 'Deleting Incentive Period ...'} />
      <tr className={styles.offeringRow} onMouseOver={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
        <td className={styles.ipName}>
          <NavLink to={`/nationalPrograms/${item.id}`}>{getOfferingDisplayName(item)}</NavLink>
        </td>
        <td className={styles.status}>
          <>
            {renderStatusIcon()}
            <span className={styles.statusTxt}>{item.status}</span>
          </>
        </td>
        <td className={styles.date}>{item.startDate}</td>
        <td className={styles.date}>{item.endDate}</td>
        <td className={styles.date}>
          {formatedAtcPublishedDate && <span className={styles.atcPublishedText}>{`Mid-month enhancement published on ${formatedAtcPublishedDate}`}</span>}
        </td>
        <td className={styles.actionItems} data-testid="action-items">
          {showIcons && item.status === OfferingStatus.DRAFT && (
            <>
              {!item.isAtc && (
                <Tooltip id="copy" message="Copy" grayTheme place="bottom">
                  <OATIcon
                    icon="copy"
                    onClick={() => {
                      setShowCopyModal(true);
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip id="delete" message="Delete" grayTheme place="bottom">
                <OATIcon
                  icon="trash"
                  className={styles.icon}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                />
              </Tooltip>
              <Tooltip id="edit" message="Edit Incentive Period Settings" grayTheme place="bottom">
                <NavLink to={`/ipSettings/general/${item.id}`}>
                  <OATIcon icon="gear" className={styles.icon} />
                </NavLink>
              </Tooltip>
            </>
          )}
          {showIcons && item.status === OfferingStatus.PUBLISHED && (
            <>
              <Tooltip id="copy" message="Copy" grayTheme place="bottom">
                <OATIcon icon="copy" onClick={() => setShowCopyModal(true)} />
              </Tooltip>
              <Tooltip id="create-atc" message="Create ATC" grayTheme place="bottom">
                <OATIcon icon="edit" className={styles.icon} onClick={handleCreateATC} />
              </Tooltip>
              <Tooltip id="edit" message="View Incentive Period Settings" grayTheme place="bottom">
                <NavLink to={`/ipSettings/general/${item.id}`}>
                  <OATIcon icon="gear" className={styles.icon} />
                </NavLink>
              </Tooltip>
              {FEATURE_UNPUBLISH && (
                <Tooltip id="unpublish-offering" message="Unpublish Offering" grayTheme place="bottom">
                  <OATIcon icon="reset" className={styles.icon} onClick={() => setShowUnpublishModal(true)} />
                </Tooltip>
              )}
            </>
          )}
        </td>

        {showCopyModal && <CopyProgramModal showCopyModal={showCopyModal} setShowCopyModal={setShowCopyModal} offering={item} handleCloseCopyModal={handleCloseCopyModal} />}
        {showDeleteModal && (
          <DefaultNationalModal
            id="delete-offering"
            message={`Are you sure you want to delete ${item.name}? This action cannot be undone.`}
            onClose={() => setShowDeleteModal(false)}
            onSubmit={() => handleDeleteOffering(item)}
            submitBtnText="Delete"
            title="Delete Offering"
          />
        )}
        {showWarningModal && (
          <WarningModal
            id="copy-program-id"
            onClose={() => {
              setShowWarningModal(false);
            }}
            onSubmit={() => {
              setShowWarningModal(false);
              setShowCopyModal(false);
              setUnsavedChanges(false);
            }}
          />
        )}
        {showUnpublishModal && (
          <DefaultNationalModal
            id={`unpublish-offering-${item.id}`}
            message={`Are you sure you want to unpublish ${item.name}? This action cannot be undone.`}
            onClose={() => setShowUnpublishModal(false)}
            onSubmit={() => handleUnpublishOffering(item)}
            title="Unpublish Offering"
            submitBtnText="Unpublish"
          />
        )}
      </tr>
    </>
  );
};

export default observer(DashboardRow);
