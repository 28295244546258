import { observer } from 'mobx-react-lite';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Button from '../../../../../components/Button';
import useGenerateReport from '../../../useGenerateReport';

import styles from '../styles.module.scss';

interface Props {
  report: { value: string; label: string };
  isOpen: boolean;
  onClose: () => void;
}

const RegenerateReportModal = ({ report, isOpen, onClose }: Props) => {
  const { generateReport } = useGenerateReport(report);

  return (
    <Modal size="md" className="regenerate-report-modal" isOpen={isOpen} onClose={onClose}>
      <ModalHeader className={styles.header} onClose={onClose}>
        Regenerate Report
      </ModalHeader>
      <ModalBody>
        <div>
          <span>Are you sure you want to remove and regenerate the </span>
          <span className={styles.bold}>{report.label}</span>
          <span> report? Once report is ready, the report will be available for download in your Generated Reports.</span>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button className={styles.footerActions} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={styles.footerActions}
          onClick={() => {
            generateReport();
            onClose();
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(RegenerateReportModal);
