import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import { IAssumptionMonth } from '../../../../../models/Assumptions';

import styles from '../../styles.module.scss';

type Props = {
  assumption: IAssumptionMonth;
  field: 'standardRate' | 'residualValue';
  id: string;
  onChange: (field: 'standardRate' | 'residualValue', month: number, value?: string) => void;
  className?: string;
};

const AssumptionInput = ({ assumption, onChange, field, id, className }: Props) => {
  const handleChange = (value: string, month: number) => {
    onChange(field, month, value === '' ? undefined : value.replaceAll(',', ''));
  };

  const isStdRate = field === 'standardRate';

  return (
    <div id={`${field}-${assumption.month}`} className={clsx(styles.ngcRowDetail)}>
      <div className={styles.ngcInputpercent}>
        <NumberInput
          isNational
          negativeNumber
          decimalScale={isStdRate ? 2 : 0}
          min={isStdRate ? -100 : -99}
          max={isStdRate ? 100 : 99}
          stdRate={isStdRate}
          id={id}
          textAlign="right"
          placeholder="--"
          error={!assumption[field].isValid}
          className={clsx([className, styles.assumptionInput])}
          value={assumption[field].value}
          onChange={e => {
            handleChange(e.target.value, assumption.month);
          }}
        />
      </div>
    </div>
  );
};

export default observer(AssumptionInput);
