import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.scss';

type Variant =
  | 'black'
  | 'blue'
  | 'blue-gray'
  | 'blue-green'
  | 'disabled'
  | 'gray'
  | 'green-blue'
  | 'override'
  | 'text'
  | 'transparent-black'
  | 'transparent-blue'
  | 'unlocked'
  | 'white';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  fixedWidth?: boolean;
  extraPadding?: boolean;
  icon?: ReactNode;
}

const Button = ({ className, variant, fixedWidth, extraPadding, children, icon, ...props }: Props) => {
  const theme = variant ? styles[variant] : undefined;
  const fixedWidthStyle = fixedWidth ? styles.fixedWidth : undefined;
  const extraPaddingStyle = extraPadding ? styles.extraPadding : undefined;

  return (
    <button className={clsx(styles.button, theme, fixedWidthStyle, extraPaddingStyle, className)} {...props}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {children}
    </button>
  );
};

export default Button;
