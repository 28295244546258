import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { OfferingStandardRatesInput, useSaveOfferingStandardRatesMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import FooterCtas from '../../components/FooterCtas';
import { createDifferentialPayload, createRatesPayload, formatRates } from '../utils';

const RatesTabFooter = () => {
  const {
    ratesTabRVRateStore: { rvDifferential, setRvHasUnsavedChanges, rev, updateRev },
    ratesTabAPRRatesStore: { aprRates, aprDifferential, setAprHasUnsavedChanges },
    ratesTabLeaseRCFStore: { leaseRates, leaseDifferential, setLeaseHasUnsavedChanges },
    ratesTabTFSLeaseRCFStore: { tfsLeaseRates, tfsLeaseDifferential, setTfsHasUnsavedChanges },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();
  const [saveOfferingStandardRates] = useSaveOfferingStandardRatesMutation();
  const { offeringId } = useUrlParams();
  const { error } = useToast();

  const handleUnsavedChangesReset = () => {
    setRvHasUnsavedChanges(false);
    setAprHasUnsavedChanges(false);
    setLeaseHasUnsavedChanges(false);
    setTfsHasUnsavedChanges(false);
  };

  const handleSave = async () => {
    const input: OfferingStandardRatesInput = {
      rev,
      offeringId,
      rvDifferential,
      aprRates: createRatesPayload(formatRates(aprRates, true)),
      aprDifferential: createDifferentialPayload(aprDifferential),
      leaseRates: createRatesPayload(formatRates(leaseRates, false)),
      leaseDifferential: createDifferentialPayload(leaseDifferential),
      tfsLeaseRates: createRatesPayload(formatRates(tfsLeaseRates, false)),
      tfsLeaseDifferential: createDifferentialPayload(tfsLeaseDifferential),
    };

    try {
      const res = await trackPromise(saveOfferingStandardRates({ variables: { input } }));
      updateRev(res.data?.saveOfferingStandardRates.offeringStandardRates.rev || '');
      handleUnsavedChangesReset();
    } catch (e) {
      error((e as Error).message);
    }
  };

  return <FooterCtas onSave={handleSave} disabled={isPublished} />;
};

export default observer(RatesTabFooter);
