import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const GridRowsParent = ({ children, className }: Props) => {
  return <div className={clsx(styles.msSettings, className)}>{children}</div>;
};

export default GridRowsParent;
