import { makeAutoObservable } from 'mobx';

class GlobalStore {
  showProgressBar = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowProgressBar = (show: boolean) => {
    this.showProgressBar = show;
  };
}

export default GlobalStore;
