import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const GridRow = ({ children, className }: Props) => {
  return (
    <div className={clsx(styles.gridRow, className)}>
      <div className={styles.gridWrapper}>{children}</div>
    </div>
  );
};

export default GridRow;
