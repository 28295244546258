import { Offering } from '../gql/generated';

const defaultOffering = (): Offering => {
  return {
    brand: '',
    created: '',
    createdBy: '',
    endDate: '',
    id: '',
    isPublished: false,
    name: '',
    note: '',
    regionCode: '',
    rev: '',
    startDate: '',
    status: '',
    updated: '',
    updatedBy: '',
  };
};

export default defaultOffering;
