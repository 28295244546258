import { makeAutoObservable } from 'mobx';
import { AdminConstants, FinalPayCalcFns } from 'oat-admin-common';
import { assignNumberValue, assignStringValue, DropdownItem, formatNumber, uuidv4, validator } from 'oat-common-ui';
import { FEATURE_OR_5454_PHASE_2 } from '../../../../constants/global';
import { Offer, PriorOffer } from '../../../../gql/generated';
import { OfferCostFields, OfferFields, OfferFieldType } from './OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

export interface FinalPayCostFields extends OfferCostFields {
  finalPayAmount: number;
  finalPayAmountPercent: number;
  isManualOverride: boolean;
  isPercentSelected: boolean;
  priorNationalRyoOfferId?: string;
  tfsCap: number;
  tfsCostShare: number;
}

class FinalPayModel {
  uid = uuidv4();
  priorNationalRyoOffers: PriorOffer[] = [];
  priorOffersOptions: DropdownItem[] = [];
  selectedPriorNationalRyoOffer: PriorOffer | undefined = undefined;
  offerFields: OfferFields = {
    id: '',
    isForRegions: true,
    rev: '',
    rgnlAltId: '',
    optionType: (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE) as string,
    name: (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE) as string,
    penetrationRate: '',
  };
  offerCostFields: FinalPayCostFields = {
    amount: '',
    finalPayAmount: 0,
    finalPayAmountPercent: 80,
    isManualOverride: false,
    isPercentSelected: true,
    offerEnhancedTfsPnvs: 0,
    offerPnvs: '',
    offerTfsPnvs: 0,
    priorNationalRyoOfferId: '--',
    tfsCap: 0,
    tfsCostShare: 0,
  };

  constructor(data: { offer?: Offer; optionType?: string; rgnlAltId: string }) {
    makeAutoObservable(this);

    this.offerFields.rgnlAltId = data.rgnlAltId;
    this.offerFields.optionType = assignStringValue(data.optionType);
    this.offerFields.name = assignStringValue(data.optionType);

    if (data.offer) {
      this.setData(data.offer);
    }
  }

  setData = (offer: Offer) => {
    const offerFields: Pick<Offer, OfferFieldType> = { ...offer };
    this.offerFields = { ...offerFields };

    if (offer.finalPayDetails) {
      this.offerCostFields.amount = assignStringValue(offer.finalPayDetails.amount);
      this.offerCostFields.offerEnhancedTfsPnvs = offer.finalPayDetails.offerEnhancedTfsPnvs;
      this.offerCostFields.offerPnvs = assignStringValue(offer.finalPayDetails.offerPnvs);
      this.offerCostFields.offerTfsPnvs = offer.finalPayDetails.offerTfsPnvs;
      this.offerCostFields.tfsCap = assignNumberValue(offer.finalPayDetails.tfsCap);
      this.offerCostFields.tfsCostShare = offer.finalPayDetails.tfsCostShare;

      this.offerCostFields.finalPayAmount = assignNumberValue(offer.finalPayDetails.finalPayAmount);
      this.offerCostFields.finalPayAmountPercent = assignNumberValue(offer.finalPayDetails.finalPayAmountPercent);
      this.offerCostFields.isManualOverride = offer.finalPayDetails.isManualOverride;
      this.offerCostFields.isPercentSelected = offer.finalPayDetails.isPercentSelected;
      this.offerCostFields.priorNationalRyoOfferId = assignStringValue(offer.finalPayDetails.priorNationalRyoOfferId);
      this.recalculate();
    }
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;

    if (field === 'penetrationRate') {
      this.recalculate();
    }
  };

  updateOfferCostFields = <T extends keyof FinalPayCostFields, V extends FinalPayCostFields[T]>(field: T, value: V) => {
    this.offerCostFields[field] = value;
    this.recalculate();
  };

  postSave = (offer: Offer | undefined) => {
    if (offer) {
      this.offerFields.id = offer.id;
      this.offerFields.rev = offer.rev;
    }
  };

  setPriorOffers = (priorOffers: PriorOffer[]) => {
    const getPriorOfferLabel = (priorOffer: PriorOffer) => {
      return `RA-${priorOffer.raNumber} National RYO $${formatNumber(priorOffer.offer.nationalRyoDetails?.amount, true)} ${priorOffer.isAtc ? '- ATC' : ''}`;
    };

    this.priorNationalRyoOffers = priorOffers;
    this.priorOffersOptions = priorOffers.map(priorOffer => {
      return {
        label: getPriorOfferLabel(priorOffer),
        value: priorOffer.offer.id,
      };
    });

    if (this.offerCostFields.priorNationalRyoOfferId) {
      this.selectPriorOffer(this.offerCostFields.priorNationalRyoOfferId);
    }
  };

  selectPriorOffer = (id: string) => {
    const foundPriorOffer = this.priorNationalRyoOffers.find(priorOffer => priorOffer.offer.id === id && priorOffer.offer.id !== this.selectedPriorNationalRyoOffer?.offer.id);

    if (foundPriorOffer) {
      this.offerCostFields.priorNationalRyoOfferId = foundPriorOffer?.offer.id;
      this.selectedPriorNationalRyoOffer = foundPriorOffer;
      this.offerCostFields.finalPayAmountPercent = 80;
      this.offerCostFields.isPercentSelected = true;
      this.recalculate();
    }
  };

  amountFieldsCalculation = () => {
    if (this.selectedPriorNationalRyoOffer) {
      const priorOfferAmount = Number(this.selectedPriorNationalRyoOffer.offer.nationalRyoDetails?.amount);

      // calculate only if manual override is false, no calculation required if Manual Override is true
      if (!this.offerCostFields.isManualOverride) {
        if (this.offerCostFields.isPercentSelected) {
          const { amount, finalPayAmount } = FinalPayCalcFns.calculateAmountByPercentage(priorOfferAmount, this.offerCostFields.finalPayAmountPercent);
          this.offerCostFields.amount = amount.toString();
          this.offerCostFields.finalPayAmount = finalPayAmount;
        } else {
          const { amount, finalPayPercent } = FinalPayCalcFns.calculateAmountByDifference(priorOfferAmount, this.offerCostFields.finalPayAmount);
          this.offerCostFields.amount = amount.toString();
          this.offerCostFields.finalPayAmountPercent = finalPayPercent;
        }
      }
    }
  };

  recalculate = () => {
    this.amountFieldsCalculation();

    if (this.offerFields.penetrationRate) {
      this.offerCostFields.offerPnvs = assignStringValue(
        FinalPayCalcFns.calculatePnvs(assignNumberValue(this.offerCostFields.amount), this.offerCostFields.tfsCostShare / 100.0, this.offerCostFields.tfsCap),
      );

      this.offerCostFields.offerTfsPnvs = FinalPayCalcFns.calculateTfsPnvs(
        assignNumberValue(this.offerCostFields.amount),
        this.offerCostFields.tfsCostShare / 100.0,
        this.offerCostFields.tfsCap,
      );

      this.offerCostFields.offerEnhancedTfsPnvs = this.offerCostFields.offerTfsPnvs;
    }
  };

  hasError = () => {
    return this.nameError || this.amountError || this.penetrationRateError || this.costShareError;
  };

  get nameError() {
    return !!validator(this.offerFields.name, { required: true });
  }

  get amountError() {
    return !!validator(this.offerCostFields.amount, { required: true });
  }

  get penetrationRateError() {
    return !!validator(this.offerFields.penetrationRate, { required: true });
  }

  get costShareError() {
    return !!validator(this.offerCostFields.tfsCostShare, { required: true, min: 0, max: 100 });
  }

  // for cost share feature flag
  get isEnhancedTfs() {
    return Number(this.offerCostFields.tfsCostShare) > 0;
  }

  get isEnhancedCap() {
    return Number(this.offerCostFields.tfsCap) > 0;
  }
}

export default FinalPayModel;
