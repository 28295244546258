import { observer } from 'mobx-react-lite';
import useStores from '../../../stores/useStores';
import IpSettingsContentWrapper from '../components/IpSettingsContentWrapper';
import IpSettingsHeader from '../components/IpSettingsHeader';
import IpSettingsNav from '../components/IpSettingsNav';
import Footer from './components/Footer';
import ModelCodeMappings from './components/ModelCodeMappings/ModelCodeMappings';
import MultiSeries from './components/MultiSeries/multiSeries';
import SeriesSelections from './components/SeriesSelections/SeriesSelections';

const SeriesAndModelsTabComponent = () => {
  const {
    ipSettingsStore: { offeringDisplayName },
  } = useStores();
  const title = `Edit Incentive Period - ${offeringDisplayName}`;

  return (
    <IpSettingsContentWrapper>
      <IpSettingsHeader title={title} />
      <IpSettingsNav />
      <SeriesSelections />
      <ModelCodeMappings />
      <MultiSeries />
      <Footer />
    </IpSettingsContentWrapper>
  );
};

export default observer(SeriesAndModelsTabComponent);
