import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import { MULTIPLE_RATES_APPLIED } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import styles from '../../components/styles.module.scss';

const ChangeInRateInput = () => {
  const {
    ratesTabAPRRatesStore: { aprDifferential, updateAprDifferential, updateAprRates, validateAprRates },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    updateAprDifferential(newValue);
    updateAprRates(newValue);
  };

  const multipleRates = validateAprRates() ? '' : MULTIPLE_RATES_APPLIED;
  const labelText = `Change in Rate-${multipleRates || aprDifferential === 0 ? 'N/A' : 'All'}`;

  return (
    <BlockInputLabel label={labelText}>
      <NumberInput
        isNational
        placeholder={multipleRates}
        id="change-in-rate-input"
        className={styles.changeInInput}
        rate
        min={-100}
        max={100}
        value={!multipleRates ? aprDifferential : ''}
        onChange={onChangeHandler}
        error={!multipleRates && (aprDifferential > 100 || aprDifferential < -100)}
        disabled={isPublished}
      />
    </BlockInputLabel>
  );
};

export default observer(ChangeInRateInput);
