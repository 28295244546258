import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import Button from '../../../components/Button';
import useStores from '../../../stores/useStores';
import RgnlAltSection from '../RgnlAltSection';
import SeriesProfileModel from '../models/SeriesProfileModel';
import CompareScenarios from './CompareScenarios';
import GetRgnlAltData from './GetRgnlAltData';
import ScenarioBudgetInfo from './ScenarioInfo/ScenarioBudgetInfo';
import MultiSeriesScenarioInfo from './ScenarioInfo/ScenarioBudgetInfo/MultiSeriesScenarioInfo';
import ScenarioSetupInfo from './ScenarioInfo/ScenarioSetupInfo';
import ScenarioTabs from './ScenarioTabs';
import SeriesProfileHeader from './SeriesProfileHeader';
import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
  zIndex: number;
}

const SeriesProfileSection = ({ seriesProfile, zIndex }: Props) => {
  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();

  const {
    selectedScenario,
    showCompare,
    data: { multiSeriesOfferId },
    isSetupView,
    getPublishedScenario,
  } = seriesProfile;

  const handleAddRa = () => {
    if (selectedScenario) {
      selectedScenario.addRgnlAlt();
      seriesProfile.clearErrors();
    }
  };

  const scenarioForView = isSetupView && isOfferingPublished ? getPublishedScenario() ?? selectedScenario : selectedScenario;

  return (
    <div className={styles.seriesProfileSection} style={{ zIndex }}>
      {/* Title */}
      <SeriesProfileHeader seriesProfile={seriesProfile} />

      {/* Scenarios */}
      <ScenarioTabs seriesProfile={seriesProfile} />

      {/* Budget/Costs section */}
      {!isSetupView && scenarioForView && !showCompare && (
        <>
          {multiSeriesOfferId ? (
            <MultiSeriesScenarioInfo scenario={scenarioForView} seriesProfile={seriesProfile} />
          ) : (
            <ScenarioBudgetInfo scenario={scenarioForView} seriesProfile={seriesProfile} />
          )}
        </>
      )}

      {/* RgnlAlts */}
      {!showCompare && scenarioForView && scenarioForView.activated && (
        <GetRgnlAltData seriesProfile={seriesProfile} scenario={scenarioForView} isSetupView={isSetupView}>
          {scenarioForView.expanded && (
            <>
              {isSetupView && <ScenarioSetupInfo seriesProfile={seriesProfile} />}
              {/* RA Section */}
              <div className={styles.innerSection}>
                <div className={styles.innerContent}>
                  {scenarioForView.rgnlAlts.map((ra, index) => {
                    return isSetupView ? (
                      ra.setupOffers.length > 0 && <RgnlAltSection key={index} seriesProfile={seriesProfile} scenario={scenarioForView} rgnlAlt={ra} />
                    ) : (
                      <RgnlAltSection key={index} seriesProfile={seriesProfile} scenario={scenarioForView} rgnlAlt={ra} />
                    );
                  })}
                  {!isSetupView && (
                    <div className={styles.addRaCtaSection}>
                      <Button
                        id={`add-ra-cta-${seriesProfile.id}`}
                        disabled={seriesProfile.data.isBudgetConfirmed}
                        variant="blue"
                        className={styles.addRaCta}
                        onClick={handleAddRa}
                        icon={<OATIcon icon="plus" />}
                      >
                        Add RA
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </GetRgnlAltData>
      )}

      {/* Compare Scenarios */}
      {showCompare && (
        <div className={styles.innerSection}>
          <div className={styles.innerContent}>
            <CompareScenarios seriesProfile={seriesProfile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SeriesProfileSection);
