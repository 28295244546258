import { observer } from 'mobx-react-lite';
import { assignNumberValue, OATIcon, useInputDelay, useToast } from 'oat-common-ui';
import { useState } from 'react';
import Button from '../../../components/Button';
import useStores from '../../../stores/useStores';
import RgnlAltModel from '../models/RgnlAltModel';
import ScenarioModel from '../models/ScenarioModel';
import SeriesProfileModel from '../models/SeriesProfileModel';
import AddOfferModal from './AddOfferModal';
import OfferBudget from './OfferBudget';
import OfferSetup from './OfferSetup';
import RgnlAltBudgetFields from './RgnlAltBudgetFields';
import RgnlAltSetupFields from './RgnlAltSetupFields';
import TitleSummary from './TitleSummary';

import { Offer, OfferingCosts, RgnlAltRegion, useCreateRegionalAlternativeMutation, useUpdateRegionalAlternativeMutation } from '../../../gql/generated';

import { trackPromise } from 'react-promise-tracker';
import styles from './styles.module.scss';
import TitleSummarySetup from './TitleSummary/TitleSummarySetup';

interface Props {
  seriesProfile: SeriesProfileModel;
  scenario: ScenarioModel;
  rgnlAlt: RgnlAltModel;
}

const RgnlAltSection = ({ seriesProfile, scenario, rgnlAlt }: Props) => {
  const {
    nationalProgramsStore: { standardRates },
    nationalCostShareStore: { costSharesBySeriesProfile },
    offeringCostsStore: { updateOfferingCostsFromResponse },
    userInfoStore: { isLexus },
  } = useStores();

  const { offers, expanded, data, hasError, getRegionsPayload, setupOffers } = rgnlAlt;
  const [showAddOffer, setShowAddOffer] = useState(false);

  const { setDelay, cancelDelay } = useInputDelay();
  const [createRgnlAlt] = useCreateRegionalAlternativeMutation();
  const [updateRgnlAlt] = useUpdateRegionalAlternativeMutation();
  const { error } = useToast();

  const handleAddOffer = (offers: string[]) => {
    rgnlAlt.addOffers(offers, seriesProfile, standardRates, isLexus(), costSharesBySeriesProfile.get(seriesProfile.id));
    setShowAddOffer(false);
  };

  const handleSaveRa = () => {
    if (hasError()) {
      cancelDelay();
    } else {
      setDelay(async () => {
        if (!rgnlAlt.data.id) {
          try {
            const res = await trackPromise(
              createRgnlAlt({
                variables: {
                  input: {
                    scenarioId: scenario.id,
                    name: data.name ?? '',
                    number: assignNumberValue(data.number),
                    note: data.note,
                    penetrationRate: assignNumberValue(data.penetrationRate),
                  },
                },
              }),
            );

            if (res.data?.createRegionalAlternative.success) {
              const ra = res.data.createRegionalAlternative.regionalAlternative;
              rgnlAlt.updateRev(ra.id, ra.rev, ra.created);
              scenario.sortRgnlAlts();
              updateOfferingCostsFromResponse(res.data.createRegionalAlternative.offeringCosts as OfferingCosts);
            }
          } catch (e) {
            error((e as Error).message);
          }
        } else {
          try {
            const res = await trackPromise(
              updateRgnlAlt({
                variables: {
                  input: {
                    id: rgnlAlt.data.id,
                    rev: rgnlAlt.data.rev,
                    name: data.name ?? '',
                    number: assignNumberValue(data.number),
                    note: data.note,
                    penetrationRate: assignNumberValue(data.penetrationRate),
                    regions: getRegionsPayload(),
                  },
                },
              }),
            );

            if (res.data?.updateRegionalAlternative.success) {
              const ra = res.data.updateRegionalAlternative.regionalAlternative;
              rgnlAlt.updateRev(ra.id, ra.rev);
              rgnlAlt.setInitialRegions(ra.regions as RgnlAltRegion[]);
              scenario.sortRgnlAlts();

              if (res.data.updateRegionalAlternative.updatedOffers) {
                rgnlAlt.updateOfferRegions(res.data.updateRegionalAlternative.updatedOffers as Offer[]);
              }

              // offeringCosts will only return if pen rate is edited
              if (res.data.updateRegionalAlternative.offeringCosts) {
                updateOfferingCostsFromResponse(res.data.updateRegionalAlternative.offeringCosts as OfferingCosts);
              }
            }
          } catch (e) {
            error((e as Error).message);
          }
        }
      });
    }
  };

  return (
    <div className={styles.rgnlAltSection}>
      <div className={styles.content}>
        {/* Title/Summary */}
        {seriesProfile.isSetupView ? (
          <TitleSummarySetup seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} scenario={scenario} />
        ) : (
          <TitleSummary seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} scenario={scenario} />
        )}

        {/* Offers */}
        {expanded && (
          <>
            {seriesProfile.isSetupView ? (
              <RgnlAltSetupFields seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} onSave={handleSaveRa} />
            ) : (
              <RgnlAltBudgetFields seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} onSave={handleSaveRa} />
            )}
            <div className={seriesProfile.isSetupView ? styles.offersSetupSection : styles.offersBudgetSection}>
              {seriesProfile.isSetupView
                ? setupOffers.map(item => <OfferSetup item={item} key={item.uid} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />)
                : offers.map(item => <OfferBudget item={item} key={item.uid} rgnlAlt={rgnlAlt} seriesProfile={seriesProfile} scenario={scenario} />)}

              {!seriesProfile.isSetupView && (
                <div className={styles.raFooter}>
                  <Button
                    disabled={seriesProfile.data.isBudgetConfirmed}
                    id={`add-offer-cta-${rgnlAlt.uid}`}
                    className={styles.addCta}
                    variant="blue"
                    fixedWidth
                    icon={<OATIcon icon="plus" />}
                    onClick={() => setShowAddOffer(true)}
                  >
                    Add Offer
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showAddOffer && <AddOfferModal onClose={() => setShowAddOffer(false)} onAddOffer={handleAddOffer} />}
    </div>
  );
};

export default observer(RgnlAltSection);
