import { observer } from 'mobx-react-lite';
import { BlockInputLabel, NumberInput, formatRcf } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import useStores from '../../../../../stores/useStores';
import styles from '../../TFSStandardRcf/components/styles.module.scss';
import LeaseRateModel from '../../models/LeaseRateModel';

interface Props {
  model: LeaseRateModel;
}

const StandardRcfTableInput = ({ model }: Props) => {
  const {
    ratesTabLeaseRCFStore: { updateLeaseRate },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const { uid, tier, term, rate } = model;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    updateLeaseRate(uid, tier, term, Number(e.target.value));
  };

  return (
    <div className={styles.tierBlock}>
      <div className={styles.labelText}>Tier {tier}</div>
      <BlockInputLabel bottomContent={rate > 0 && <div className={styles.rcfBottomVal}>{formatRcf(rate)}</div>}>
        <NumberInput
          isNational
          id={`${uid}-${tier}`}
          rcf
          max={0.99999}
          value={model.differential}
          onChange={onChangeHandler}
          error={(tier !== '7+' && rate < 0) || isNaN(rate)}
          disabled={isPublished}
        />
      </BlockInputLabel>
    </div>
  );
};

export default observer(StandardRcfTableInput);
