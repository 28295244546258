import { OATLoadingBar } from 'oat-common-ui';
import GetNationalTfsCostShares from '../../../components/GetNationalTfsCostShares';
import { FEATURE_OR_4154 } from '../../../constants/global';
import { SeriesProfile, useFetchSeriesProfilesByIdQuery, useGetOfferingByIdQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import CostShareTabComponent from './CostShareTabComponent';

const CostShareTab = () => {
  const { offeringId } = useUrlParams();
  const {
    costShareTabStore: { setSeriesProfiles },
    ipSettingsStore: { offering, setOffering },
    nationalCostShareStore: { setSeries },
  } = useStores();

  const { data, loading, error } = useFetchSeriesProfilesByIdQuery({
    variables: {
      offeringId,
    },
  });

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useGetOfferingByIdQuery({
    variables: {
      id: offeringId,
    },
    skip: !!offering.id,
  });

  if (loading || offeringLoading) {
    return <OATLoadingBar />;
  }

  if (error || offeringError) {
    return <Error />;
  }

  if (data) {
    if (FEATURE_OR_4154) {
      setSeries(data.fetchSeriesProfiles as SeriesProfile[]);
    } else {
      setSeriesProfiles(data.fetchSeriesProfiles as SeriesProfile[]);
    }
  }

  if (offeringData) {
    setOffering(offeringData.getOffering);
  }

  return (
    <GetNationalTfsCostShares>
      <CostShareTabComponent />
    </GetNationalTfsCostShares>
  );
};

export default CostShareTab;
