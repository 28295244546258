import { makeAutoObservable } from 'mobx';
import { uuidv4, validator } from 'oat-common-ui';
import { AprTierTerm } from '../../../../../gql/generated';

class TierTermModel {
  uid = uuidv4();
  tier = '';
  term = 0;
  rate: number | string = '';
  standardRate = 0;
  initialStandardRate = 0;
  tfsShare: number | string = '';
  penetrationRate: number | string = '';
  averageAmountFinanced: number | string = '';
  monthlyPayment: number | string = '';
  pnvs = 0;
  fullPnvs = 0;
  tfsPnvs = 0;
  fullTfsPnvs = 0;
  enhancedTfsPnvs = 0;
  ncsTfsPnvs = 0;
  ncsEnhancedTfsPnvs = 0;
  checkEqual = false;

  constructor(data?: AprTierTerm) {
    makeAutoObservable(this);

    if (data) {
      this.tier = data.tier;
      this.term = data.term;
      this.rate = data.rate;
      this.standardRate = data.standardRate;
      this.initialStandardRate = data.standardRate;
      this.tfsShare = data.tfsShare;
      this.penetrationRate = data.penetrationRate;
      this.averageAmountFinanced = data.averageAmountFinanced;
      this.monthlyPayment = data.monthlyPayment;
      this.pnvs = data.pnvs;
      this.fullPnvs = data.fullPnvs;
      this.tfsPnvs = data.tfsPnvs;
      this.fullTfsPnvs = data.fullTfsPnvs;
      this.enhancedTfsPnvs = data.enhancedTfsPnvs;
      this.ncsTfsPnvs = data.ncsTfsPnvs;
      this.ncsEnhancedTfsPnvs = data.ncsEnhancedTfsPnvs;
    }
  }

  get rateError() {
    return !!validator(this.rate, { required: true, min: 0, max: 100 }) || this.checkEqual ? Number(this.rate) >= this.standardRate : Number(this.rate) > this.standardRate;
  }

  get tfsError() {
    return !!validator(this.tfsShare, { required: true, min: 0, max: 100 });
  }

  get avgAmtFinancedError() {
    return !!validator(this.averageAmountFinanced ?? '', { required: true, min: 1 });
  }

  set setCheckEqual(checkEqual: boolean) {
    this.checkEqual = checkEqual;
  }
}

export default TierTermModel;
