import clsx from 'clsx';
import React from 'react';
import styles from '../../../styles.module.scss';

interface Props {
  children: any;
}

const FYSeriesDetailsWrapper = ({ children }: Props) => {
  return (
    <div className={clsx(styles.fySeriesBox, styles.fySeriesBoxShadowRight)}>
      <ul className={styles.fySeriesBoxList}>{children}</ul>
    </div>
  );
};

export default FYSeriesDetailsWrapper;
