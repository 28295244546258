import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { AprTierPenRate } from '../../../../../gql/generated';

class TierPenRateModel {
  uid = uuidv4();
  tier = '';
  penetrationRate: number | string = '';

  constructor(data?: AprTierPenRate) {
    makeAutoObservable(this);

    if (data) {
      this.tier = data.tier;
      this.penetrationRate = data.penetrationRate;
    }
  }
}

export default TierPenRateModel;
