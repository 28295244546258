import { LocalTfsContract } from '../../models/offers/OfferFields';

export const getTiersPerTerm = (terms: number[], tiers: string[]) => {
  const termTier: { term: number; tiers: string[] }[] = [];

  terms.forEach(term => {
    termTier.push({ term, tiers });
  });

  return termTier;
};

export const tfsUtils = (localTfs: LocalTfsContract[], activeTab: LocalTfsContract, setLocalTfs: (val: LocalTfsContract[]) => void) => {
  const handleSelectAll = () => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    newVal[indexOfActive].termsList.forEach(term => {
      newVal[indexOfActive].tiersList.forEach(tier => {
        newVal[indexOfActive].tierTerms.push({ tier, term });
      });
    });

    setLocalTfs(newVal);
  };

  const updateSingleLocalTierTerm = (tier: string, term: number, isChecked: boolean) => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    if (isChecked) {
      const test = newVal[indexOfActive].tierTerms.filter(tierTerm => tierTerm.term !== term || tierTerm.tier !== tier);
      newVal[indexOfActive].tierTerms = test;
    } else {
      newVal[indexOfActive].tierTerms.push({ tier, term });
    }

    setLocalTfs(newVal);
  };

  const addTierOrTerm = (field: 'tier' | 'term', value?: number) => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    if (field === 'term') {
      newVal[indexOfActive].termsList.push(value as number);
    } else {
      const newTier = Number(activeTab.tiersList.slice(-1)[0]) + 1;
      newVal[indexOfActive].tiersList.push(newTier.toString());
    }

    setLocalTfs(newVal);
  };

  const uncheckRow = (value: string | number) => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    newVal[indexOfActive].tierTerms = newVal[indexOfActive].tierTerms.filter(tierTem => tierTem.term !== value && tierTem.tier !== value.toString());

    setLocalTfs(newVal);
  };

  const handleRightArrowClick = (currentTerm: number) => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    const { tierTerms, tiersList } = newVal[indexOfActive];
    const foundTierTerm = tierTerms.find(tierTerm => tierTerm.term === currentTerm && tierTerm.tier === '1+');

    if (foundTierTerm) {
      for (const tier of tiersList.filter(t => t !== '1+')) {
        tierTerms.push({ tier, term: currentTerm });
      }
    } else {
      newVal[indexOfActive].tierTerms = tierTerms.filter(t => t.term !== currentTerm);
    }

    setLocalTfs(newVal);
  };

  const handleDownArrowClick = (currentTier: string) => {
    const newVal = localTfs?.slice();
    const indexOfActive = localTfs?.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    const { tierTerms, termsList } = newVal[indexOfActive];
    const foundTierTerm = tierTerms.find(tierTerm => tierTerm.tier === currentTier && tierTerm.term === 24);

    if (foundTierTerm) {
      for (const term of termsList.filter(t => t > 24)) {
        tierTerms.push({ tier: currentTier, term });
      }
    } else {
      newVal[indexOfActive].tierTerms = tierTerms.filter(t => t.tier !== currentTier);
    }

    setLocalTfs(newVal);
  };

  return {
    handleSelectAll,
    updateSingleLocalTierTerm,
    addTierOrTerm,
    uncheckRow,
    handleRightArrowClick,
    handleDownArrowClick,
  };
};
