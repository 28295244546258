import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OATIcon } from 'oat-common-ui';
import styles from './styles.module.css';

interface ShowErrorProps {
  errorMessage: string;
  useDefaultIcon?: boolean;
}

const ShowError = ({ errorMessage, useDefaultIcon = true }: ShowErrorProps) => {
  return (
    <>
      {useDefaultIcon ? <FontAwesomeIcon icon={faInfoCircle} className={styles.errorIcon} /> : <OATIcon icon="warning" className={styles.errorIcon} />}
      <span className={styles.errorMessage}>{errorMessage}</span>
    </>
  );
};

export default ShowError;
