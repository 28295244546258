import { observer } from 'mobx-react-lite';
import { IMultiItem, OATIcon, Tooltip } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  listItem: IMultiItem;
  onEdit: (item: IMultiItem) => void;
}
const Series = ({ listItem, onEdit }: Props) => {
  const {
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <li
      key={`ex-${listItem.id}`}
      id={`list-item-model-ex-${listItem.id}`}
      className={styles.listItem}
      onMouseOver={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      <span className={styles.seriesName}>{listItem.data}</span>
      {showEdit && (
        <Tooltip id={`${listItem.id}-edit-a`} message={!isPublished ? 'Edit' : 'View'} grayTheme place="bottom">
          <OATIcon icon={!isPublished ? 'edit' : 'eye'} onClick={() => onEdit(listItem)} />
        </Tooltip>
      )}
    </li>
  );
};

export default observer(Series);
