import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import AnchorNav from '../../../../components/MainSideNav/AnchorNav';
import { FEATURE_OR_4154 } from '../../../../constants/global';
import useUrlParams from '../../../../hooks/useUrlParams';
import styles from './styles.module.scss';

interface TabLinkProps {
  to: string;
  children?: ReactNode;
  pathname?: string;
}

const TabLink = ({ to, children, pathname }: TabLinkProps) => {
  return (
    <AnchorNav to={to} customTheme={styles.anchor} className={pathname?.includes(to) ? styles.active : ''}>
      {children}
    </AnchorNav>
  );
};

const IpSettingsNav = () => {
  const { offeringId } = useUrlParams();
  const {
    location: { pathname },
  } = useHistory();

  return (
    <div className={styles.ipSettingsNav}>
      <ul>
        <li>
          <TabLink to={`/ipSettings/general/${offeringId}`} pathname={pathname}>
            General
          </TabLink>
        </li>
        <li>
          <TabLink to={`/ipSettings/rates/${offeringId}`} pathname={pathname}>
            Rates
          </TabLink>
        </li>
        <li>
          <TabLink to={`/ipSettings/uploads/${offeringId}`} pathname={pathname}>
            Uploads
          </TabLink>
        </li>
        <li>
          <TabLink to={`/ipSettings/costShare/${offeringId}`} pathname={pathname}>
            Cost Share
          </TabLink>
        </li>
        <li>
          <TabLink to={`/ipSettings/seriesModels/${offeringId}`} pathname={pathname}>
            Series and Models
          </TabLink>
        </li>
        {FEATURE_OR_4154 && <li className={styles.whiteCover} role="navigation" />}
      </ul>
    </div>
  );
};

export default IpSettingsNav;
