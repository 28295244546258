import { AdminConstants } from 'oat-admin-common';

import { CompareOffer } from '../useCompareData';
import { addSign, getOferTypeCompareLabel } from '../utils';

import OfferModel from '../../../../models/offers/OfferModel';

import { assignStringValue, formatRcf } from 'oat-common-ui';
import styles from '../../styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;
type Props = {
  offer: CompareOffer;
};

const getValuePerType = (_offerType: string, offer?: OfferModel) => {
  if (!offer) {
    return null;
  }

  switch (offer.getOptionType()) {
    case OPTION_TYPE_NAMES.NATIONAL_RYO:
      return <>{addSign(true, false, assignStringValue(offer.nationalRyoDetails?.offerCostFields.amount))}</>;

    case OPTION_TYPE_NAMES.CUSTOMER_CASH:
      return <>{addSign(true, false, assignStringValue(offer.cashDetails?.offerCostFields.amount))}</>;

    case OPTION_TYPE_NAMES.APR:
      const aprDetails = offer?.aprDetails;
      return (
        <>
          {aprDetails?.aprRatesFor1Plus.map((item, i) => (
            <span key={item.uid}>
              {item.rate}
              {i < aprDetails?.aprRatesFor1Plus.length - 1 && <span className={styles.sep}>/</span>}
            </span>
          ))}
        </>
      );

    case OPTION_TYPE_NAMES.LEASE:
      return <>{formatRcf(offer.leaseDetails?.selectedLease?.leaseFields.rcf)}</>;

    case OPTION_TYPE_NAMES.FINAL_PAY:
      return <>{addSign(true, false, assignStringValue(offer.finalPayDetails?.offerCostFields.amount))}</>;

    default:
      return <>{addSign(true, false, assignStringValue(offer.miscDetails?.offerCostFields.amount))}</>;
  }
};

const OfferCompareRow = ({ offer }: Props) => {
  const offerType = getOferTypeCompareLabel(offer);

  return (
    <>
      <td className={styles.subLabel}>{offerType}</td>
      <td className={styles.value}>{getValuePerType(offerType, offer.originalOffer)}</td>
      <td className={styles.value}>{getValuePerType(offerType, offer.compareOffer)}</td>
    </>
  );
};

export default OfferCompareRow;
