import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import useStores from '../../../../../stores/useStores';
import styles from '../../components/styles.module.scss';

const ResidualValueRateInput = () => {
  const {
    ratesTabRVRateStore: { setRvDifferential, rvDifferential },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRvDifferential(Number(e.target.value));
  };

  return (
    <BlockInputLabel label="Change in Pre-NGL RV Rates">
      <NumberInput
        isNational
        id="residual-value-input"
        className={styles.changeInInput}
        negativeNumber
        value={rvDifferential}
        onChange={onChangeHandler}
        min={-100}
        max={100}
        disabled={isPublished}
      />
    </BlockInputLabel>
  );
};

export default observer(ResidualValueRateInput);
