import { makeAutoObservable } from 'mobx';
import { OfferingStandardRates } from '../gql/generated';

class RatesTabRVRateStore {
  id = '';
  rev = '';
  rvDifferential = 0;
  rvHasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateRev = (newRev: string) => {
    this.rev = newRev;
  };

  setData = (data: OfferingStandardRates) => {
    this.id = data.id;
    this.rev = data.rev;
    this.rvDifferential = data.rvDifferential;
  };

  setRvDifferential = (val: number) => {
    this.rvDifferential = val;
    this.rvHasUnsavedChanges = true;
  };

  setRvHasUnsavedChanges = (val: boolean) => {
    this.rvHasUnsavedChanges = val;
  };
}

export default RatesTabRVRateStore;
