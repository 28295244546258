import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Checkbox, Tooltip, useToast } from 'oat-common-ui';
import { useMemo, useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { OfferingCosts, useSelectScenarioMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import ScenarioModel from '../../models/ScenarioModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import { getFormattedLabel } from '../../utils/getFormattedLabel';

import Button from '../../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
}

const ScenarioTabs = ({ seriesProfile }: Props) => {
  const {
    showCompare,
    selectedScenario,
    selectScenario,
    updateSelectScenarioRevs,
    compareSelected,
    isSetupView,
    isCompareSelectedValid,
    data: { multiSeriesOfferId, isBudgetConfirmed },
  } = seriesProfile;

  const [selectScenarioMutation] = useSelectScenarioMutation();
  const { error } = useToast();

  const {
    seriesSettingsStore: { findByBosuSeries },
    offeringCostsStore: { updateOfferingCostsFromResponse },
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();

  const ref = useRef<HTMLUListElement>(null);

  const handleSelectScenario = async (newScenario: ScenarioModel) => {
    // if same scenario or it's preview, just expand
    if (newScenario.id === selectedScenario?.id || isOfferingPublished) {
      selectScenario(newScenario.id);
      return;
    }

    try {
      const res = await trackPromise(
        selectScenarioMutation({
          variables: {
            input: {
              oldScenarioId: selectedScenario?.id ?? '',
              oldScenarioRev: selectedScenario?.rev ?? '',
              newScenarioId: newScenario.id,
              newScenarioRev: newScenario.rev,
            },
          },
        }),
      );

      if (res.data?.selectScenario.success) {
        selectScenario(newScenario.id);

        const { newScenarioId, newScenarioRev, oldScenarioId, oldScenarioRev } = res.data.selectScenario;
        updateSelectScenarioRevs(newScenarioId, newScenarioRev, oldScenarioId, oldScenarioRev);

        updateOfferingCostsFromResponse(res.data.selectScenario.offeringCosts as OfferingCosts);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSelectToCompare = (scenario: ScenarioModel) => {
    scenario.toggleCompareSelected();
  };

  const scenariosToShow = useMemo(() => {
    let retVal = seriesProfile.scenarios;

    // For budgetView if IP is published we are showing all scenarios but all fields are disabled
    // if IP is not published when user confirm budget we are showing only confirmed/selected scenario
    // with possibility to click edit budget and select other scenario
    if (isBudgetConfirmed && !isOfferingPublished) {
      retVal = seriesProfile.scenarios.filter(s => s.isSelected);
    }

    // for setupView we are always showing one (confirmed or published) scenario
    if (isSetupView && isOfferingPublished) {
      retVal = retVal.filter(s => s.isPublished);
    }

    return retVal;
  }, [isBudgetConfirmed, isOfferingPublished, isSetupView, seriesProfile.scenarios]);

  const nameToShow = (scene: ScenarioModel, index: number, multiSeriesOfferId: boolean) => {
    return getFormattedLabel(index === 0 && !multiSeriesOfferId ? findByBosuSeries(scene.name) : scene.name, 20).formattedName;
  };

  return (
    <div className={styles.tabs}>
      <ul ref={ref}>
        {scenariosToShow.map((scene, i) => {
          return (
            <li
              key={scene?.id}
              className={clsx(
                scene.isSelected && styles.selected,
                showCompare && isCompareSelectedValid && compareSelected.length === 2 && scene?.id === compareSelected[0]?.id && styles.firstSelected,
                showCompare && isCompareSelectedValid && compareSelected.length === 2 && scene?.id === compareSelected[1]?.id && styles.secondSelected,
                !isCompareSelectedValid || (isCompareSelectedValid && compareSelected.length !== 2 && styles.default),
              )}
            >
              {/* Regular Tabs */}
              {!showCompare && (
                <Tooltip id={`tab-tooltip-${scene.id}`} message={scene.name} place="bottom" className={styles.tabTooltip}>
                  <Button
                    id={`tab-${scene.id}`}
                    className={clsx(styles.sceneName, (scene.isConfirmed || scene.isPublished) && styles.confirmed)}
                    onClick={() => handleSelectScenario(scene)}
                  >
                    {nameToShow(scene, i, !!multiSeriesOfferId)}
                  </Button>
                </Tooltip>
              )}

              {/* Compare chboxes */}
              {showCompare && (
                <Tooltip message={scene.name} place="bottom" className={styles.tabTooltip} id={`tab-tooltip-${scene.id}`}>
                  <Checkbox
                    nationalCheckmarkClass={styles.checkmark}
                    className={styles.scenarioCheckbox}
                    id={`tab-tooltip-${scene.id}`}
                    isChecked={scene.compareSelected}
                    isNational
                    label={<span className={styles.sceneName}>{getFormattedLabel(scene.name, 25).formattedName}</span>}
                    onChange={() => handleSelectToCompare(scene)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default observer(ScenarioTabs);
