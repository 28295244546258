import { assignNumberValue, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useCreateCashOfferMutation, useUpdateCashOfferBudgetMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import CashModel from '../../models/offers/CashModel';

const useSaveCash = (seriesProfile: SeriesProfileModel, rgnlAlt: RgnlAltModel, cashOffer: CashModel) => {
  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();
  const { setDelay } = useInputDelay();
  const [createCashOffer] = useCreateCashOfferMutation();
  const [updateCashOfferBudget] = useUpdateCashOfferBudgetMutation();
  const { error } = useToast();

  const { hasPenetrationError, hasAmountError, setRevAndId, uid, offerCostFields, offerFields } = cashOffer;

  const saveCashOffer = () => {
    if (!hasPenetrationError() && !hasAmountError()) {
      setDelay(async () => {
        try {
          if (!offerFields.rev.length) {
            const res = await trackPromise(
              createCashOffer({
                variables: {
                  input: {
                    amount: assignNumberValue(offerCostFields.amount),
                    offerEnhancedTfsPnvs: offerCostFields.offerEnhancedTfsPnvs,
                    isForRegions: offerFields.isForRegions,
                    name: offerFields.name,
                    optionType: offerFields.optionType,
                    penetrationRate: assignNumberValue(offerFields.penetrationRate),
                    offerPnvs: assignNumberValue(offerCostFields.offerPnvs),
                    rgnlAltId: offerFields.rgnlAltId,
                    offerTfsPnvs: assignNumberValue(offerCostFields.offerTfsPnvs),
                    sortOrder: rgnlAlt.getSortOrderByOfferUid(uid),
                  },
                },
              }),
            );

            const resData = res.data?.createCashOffer;
            if (resData) {
              setRevAndId(resData.offer as Offer);
              updateOfferingCostsFromResponse(resData.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(resData.ryoOffers as Offer[]);
              const newOfferSortOrder = { offerId: resData.offer.id || '', sortOrder: rgnlAlt.getSortOrderByOfferUid(uid) };
              rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
            }

            if (resData && resData.rgnlAltId && resData.rgnlAltRev) {
              rgnlAlt.updateRev(resData.rgnlAltId, resData.rgnlAltRev);
            }
          } else {
            const res = await trackPromise(
              updateCashOfferBudget({
                variables: {
                  input: {
                    amount: assignNumberValue(offerCostFields.amount),
                    id: offerFields.id,
                    isForRegions: offerFields.isForRegions,
                    name: offerFields.name,
                    offerEnhancedTfsPnvs: offerCostFields.offerEnhancedTfsPnvs,
                    offerPnvs: assignNumberValue(offerCostFields.offerPnvs),
                    offerTfsPnvs: offerCostFields.offerTfsPnvs,
                    penetrationRate: assignNumberValue(offerFields.penetrationRate),
                    rev: offerFields.rev,
                  },
                },
              }),
            );

            const resData = res.data?.updateCashOfferBudget;
            if (resData) {
              setRevAndId(resData.offer as Offer);
              updateOfferingCostsFromResponse(resData.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(resData.ryoOffers as Offer[]);
            }
          }
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return {
    saveCashOffer,
  };
};

export default useSaveCash;
