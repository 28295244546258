import { ScenarioCosts } from '../../../../../gql/generated';

import { useMemo } from 'react';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel, appendTmsLabel } from '../../../../../utils/appendTfsLabel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from '../styles.module.scss';
import { processCost } from './utils';

export type SummaryCompareData = { name: string; costs: ScenarioCosts };

interface Props {
  seriesProfile: SeriesProfileModel;
}

const SummaryComparison = ({ seriesProfile }: Props) => {
  const {
    offeringCostsStore: { scenarioCosts },
    userInfoStore: { isLexus },
  } = useStores();

  const compareData: SummaryCompareData[] = useMemo(() => {
    const { previousMonthScenarioData } = seriesProfile;

    const compareScenariosData = seriesProfile.compareScenarios.map(compareScenario => {
      return {
        name: compareScenario.name,
        costs: scenarioCosts[compareScenario.id],
      };
    });

    if (previousMonthScenarioData?.scenario) {
      compareScenariosData.unshift({ name: 'Previous Month', costs: previousMonthScenarioData.scenarioCost });
    }

    return compareScenariosData;
  }, [scenarioCosts, seriesProfile]);

  return (
    <div className={styles.summaryComparison}>
      <table>
        <thead>
          <tr>
            <td colSpan={2} className={styles.noBorder}>
              Summary Comparison
            </td>
            <td className={styles.scenarioName}>{compareData[0]?.name}</td>
            <td className={styles.compareScenarioName}>{compareData[1]?.name}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.label}>PNVS</td>
            <td className={styles.subLabel}>Budgeted</td>
            <td className={styles.value}>${compareData[0].costs.budgetedPnvs}</td>
            <td className={styles.value}>${compareData[1]?.costs.budgetedPnvs}</td>
          </tr>
          <tr>
            <td className={styles.noBorder}></td>
            <td className={styles.subLabel}>Proposed</td>
            <td className={styles.value}>${compareData[0].costs.pnvs}</td>
            <td className={styles.value}>${compareData[1]?.costs.pnvs}</td>
          </tr>
          <tr>
            <td className={styles.noBorder}></td>
            <td className={styles.subLabel}>Difference</td>
            <td className={styles.value}>${compareData[0].costs.pnvsDifference}</td>
            <td className={styles.value}>${compareData[1]?.costs.pnvsDifference}</td>
          </tr>
          <tr>
            <td colSpan={2}>{appendTmsLabel(isLexus(), 'Cost')}</td>
            <td className={styles.value}>${processCost(compareData[0].costs.cost)}</td>
            <td className={styles.value}>${processCost(compareData[1]?.costs.cost)}</td>
          </tr>
          <tr>
            <td colSpan={2}>{appendTfsLabel(isLexus(), 'Cost')}</td>
            <td className={styles.value}>${processCost(compareData[0].costs.tfsCost)}</td>
            <td className={styles.value}>${processCost(compareData[1]?.costs.tfsCost)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SummaryComparison;
