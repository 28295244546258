import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignBooleanValue, assignNumberValue, assignStringValue, validator } from 'oat-common-ui';
import { defaultTfsContracts, hasTargetedAudience, hasTargetedAudienceRequired } from '../../../../constants/global';
import { CompetitorDetails, Offer } from '../../../../gql/generated';
import { getTargetedAudienceDefaultValue, getTfsContractsDefaultValue, getTransferableDefaultValue } from '../../utils/getMiscOfferSetupDefaults';
import { OfferMiscSetupFields, VehicleMake } from '../offers/OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

class MiscSetupModel {
  uid = '';
  id = '';
  rev = '';
  amount = 0;
  offerPnvs = 0;
  optionType = '';

  fields: OfferMiscSetupFields = {
    tfsContracts: defaultTfsContracts,
    isTransferable: false,
    targetedAudience: '',
    competitorDetails: [{ make: '', model: '', years: '' }],
    giftDeliveryOption: '',
    maxDaysNo: 0,
    isTfsContractRequired: false,
  };

  constructor(offer: Offer, offerUid: string) {
    makeAutoObservable(this);

    this.uid = offerUid;
    this.id = offer.id;
    this.amount = assignNumberValue(offer.miscDetails?.amount);
    this.offerPnvs = assignNumberValue(offer.miscDetails?.offerPnvs);
    this.optionType = offer.optionType;

    this.fields.isTransferable = getTransferableDefaultValue(offer);
    this.fields.tfsContracts = getTfsContractsDefaultValue(offer);
    this.fields.targetedAudience = getTargetedAudienceDefaultValue(offer);
    this.fields.maxDaysNo = assignNumberValue(offer.miscDetails?.maxDaysNo);
    this.fields.giftDeliveryOption = assignStringValue(offer.miscDetails?.giftDeliveryOption);
    this.fields.isTfsContractRequired = assignBooleanValue(offer.miscDetails?.isTfsContractRequired);
    this.fields.competitorDetails = (offer.miscDetails?.competitorDetails as CompetitorDetails[]) || [{ make: '', model: '', years: '' }];
  }

  updateField = <T extends keyof OfferMiscSetupFields, V extends OfferMiscSetupFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateVehicleMakeArray = (action?: string, index?: number, value?: VehicleMake) => {
    if (action === 'add') {
      this.fields.competitorDetails?.push({ make: '', model: '', years: '' });
    } else if (action === 'remove') {
      this.fields.competitorDetails?.splice(Number(index), 1);
    } else {
      if (value) {
        this.fields.competitorDetails?.splice(Number(index), 1, value);
      }
    }
  };

  hasErrors = () => {
    return this.tfsContractsError || this.competitorDetailsError || this.targetAudienceError;
  };

  get hasTargetedAndTransferable() {
    return hasTargetedAudience.includes(this.optionType);
  }

  get showCompetitorDetails() {
    return [OPTION_TYPE_NAMES.CONQUEST.valueOf(), OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE.valueOf()].includes(this.optionType);
  }

  get isTargetAudienceRequired() {
    return hasTargetedAudienceRequired.includes(this.optionType);
  }

  get targetAudienceError() {
    return Boolean(validator(this.fields.targetedAudience, { required: this.isTargetAudienceRequired }));
  }

  get tfsContractsError() {
    if (this.fields.isTfsContractRequired) {
      return this.fields.tfsContracts.every(contract => {
        return !contract.tierTerms.length;
      });
    }

    return false;
  }

  get competitorDetailsError() {
    return (
      this.showCompetitorDetails &&
      this.fields.competitorDetails &&
      this.fields.competitorDetails.length &&
      this.fields.competitorDetails.some(item => {
        return (
          Boolean(validator(item.make, { required: true })) || //
          Boolean(validator(item.model, { required: true })) ||
          Boolean(validator(item.years, { required: true }))
        );
      })
    );
  }
}

export default MiscSetupModel;
