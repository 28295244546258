import { OATLoadingBar } from 'oat-common-ui';
import { SeriesSortItem, useGetSeriesSortingQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import SeriesSortingSettingsComponent from './SeriesSortingSettingsComponent';

const SeriesSortingSettings = () => {
  const {
    seriesSettingsStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { data, loading, error } = useGetSeriesSortingQuery({
    variables: { brand },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data && data.seriesSorting.sortList) {
    seriesSettingsStore.setSeriesSortingList(data.seriesSorting.sortList as SeriesSortItem[], data.seriesSorting.rev || '');
  }

  return <SeriesSortingSettingsComponent />;
};

export default SeriesSortingSettings;
