import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_2947_PHASE_2 } from '../../../../constants/global';

const { OPTION_TYPE_NAMES, MISC_ENH_COSTSHARE_OFFERTYPES } = AdminConstants;

export const buildRegionsCheckboxes = () => {
  const ryoAndCashOfferTypes: string[] = [
    OPTION_TYPE_NAMES.NATIONAL_RYO,
    OPTION_TYPE_NAMES.ADDITIONAL_RYO,
    OPTION_TYPE_NAMES.RYO_EARNINGS,
    OPTION_TYPE_NAMES.LUMPSUM_RYO,
    OPTION_TYPE_NAMES.CUSTOMER_CASH,
  ];

  const miscWithoutCostShareOfferTypes: string[] = [
    OPTION_TYPE_NAMES.BONUS,
    OPTION_TYPE_NAMES.NON_CASH_CERTIFICATE,
    OPTION_TYPE_NAMES.VSPP,
    OPTION_TYPE_NAMES.DEALER_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.DEALER_WHOLESALE_PROGRAM,
    OPTION_TYPE_NAMES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH,
    FEATURE_OR_2947_PHASE_2 ? OPTION_TYPE_NAMES.DEALER_TFS_FINANCE_SUBVENTION_CASH : OPTION_TYPE_NAMES.DEALER_TFS_APR_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_LEASE_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.FINAL_PAY,
    OPTION_TYPE_NAMES.SALES_PERSON_FLAT_CASH,
    OPTION_TYPE_NAMES.SALES_MANAGER_FLAT_CASH,
  ];

  const miscWithCostShareOfferTypes: string[] = MISC_ENH_COSTSHARE_OFFERTYPES;

  return {
    ryoAndCashOfferTypes,
    miscWithoutCostShareOfferTypes,
    miscWithCostShareOfferTypes,
  };
};
