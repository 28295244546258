import { OATLoadingBar } from 'oat-common-ui';
import { GetOfferingsResponse, useGetOfferingsQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import ErrorPage from '../../ErrorPage';
import FetchReportsComponent from './FetchReportsComponent';

const NationalPlanning = () => {
  const {
    userInfoStore: {
      userInfo: { regionCodes },
    },
    reportsStore: { setSelectedOfferingData },
    dashboardStore,
  } = useStores();

  const { data, loading, error } = useGetOfferingsQuery({
    variables: {
      regionCode: regionCodes,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (data) {
    dashboardStore.processQuery(data.getOfferings as GetOfferingsResponse);
    const currentOffering = dashboardStore.active.length ? dashboardStore.active[0] : dashboardStore.inactive.length ? dashboardStore.inactive[0] : undefined;
    if (currentOffering) {
      setSelectedOfferingData({ label: currentOffering.name, value: currentOffering.id });
    }
  }

  return <FetchReportsComponent />;
};

export default NationalPlanning;
