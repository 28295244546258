import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  label: string;
  bottomMargin?: boolean;
  children?: ReactNode;
  className?: string;
}

const BlockLabel = ({ children, className, bottomMargin, label }: Props) => {
  return (
    <div className={clsx(styles.blockLabel, bottomMargin && styles.bottomMargin, className)}>
      {children}
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default BlockLabel;
