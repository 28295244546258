import { observer } from 'mobx-react-lite';
import AprModel from '../../../../models/offers/apr/AprModel';
import getSortedTiers from '../../../../utils/getSortedTiers';
import styles from '../styles.module.scss';
import TermRateTableCol from './TermRateTableCol';

interface Props {
  apr: AprModel;
  onSave: () => void;
}

const TermRateTable = ({ apr, onSave }: Props) => {
  const tierTermsToShow = getSortedTiers(apr.tierTerms);

  return (
    <div className={styles.termRateTable} data-testid="term-rate-table">
      {tierTermsToShow.map((tierTerm, i) => {
        return <TermRateTableCol key={tierTerm.uid} apr={apr} tierTerm={tierTerm} index={i} onSave={onSave} />;
      })}
    </div>
  );
};

export default observer(TermRateTable);
