import AccordionItemModel from '../models/AccordionItemModel';

export const countTotalSelected = (accordion: AccordionItemModel[]) => {
  let numSelected = 0;
  accordion.forEach(detail => {
    const splitLabel = detail.label.split(' ')[0];

    if (detail.items.length > 0) {
      detail.items.forEach(item => {
        item.items.forEach(checked => {
          if (checked.checked) {
            numSelected++;
          }
        });
      });
    }
    detail.label = `${splitLabel}  ${numSelected} model codes`;
  });

  return accordion;
};
