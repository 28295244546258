import { observer } from 'mobx-react-lite';
import { assignNumberValue } from 'oat-common-ui';
import { useState } from 'react';
import NumberStack from '../../../../components/NumberStack';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import styles from './styles.module.scss';

interface Props {
  lease: LeaseFormModel;
}

const LeasePnvs = ({ lease }: Props) => {
  const { leaseFields } = lease;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={styles.container}>
        {expanded && (
          <>
            <NumberStack
              label="RCF"
              value={assignNumberValue(leaseFields.rcfPnvs)}
              miniFormat
              textAlign="right"
              classNameContainer={styles.otherCosts}
              classNameValue={styles.otherCostsValue}
              classNameLabel={styles.otherCostsLabel}
            />
            <div className={styles.symbol}>+</div>
            <NumberStack
              label="Sub Cash"
              value={assignNumberValue(leaseFields.subCashPnvs)}
              miniFormat
              textAlign="right"
              classNameContainer={styles.otherCosts}
              classNameValue={styles.otherCostsValue}
              classNameLabel={styles.otherCostsLabel}
            />
            <div className={styles.symbol}>+</div>
            <NumberStack
              label="RV Sup"
              value={assignNumberValue(leaseFields.rvSupportPnvs)}
              miniFormat
              textAlign="right"
              classNameContainer={styles.otherCosts}
              classNameValue={styles.otherCostsValue}
              classNameLabel={styles.otherCostsLabel}
            />
            <div className={styles.symbol}>=</div>
          </>
        )}
        <button id={`pnvs-toggle-${lease.uid}`} onClick={handleToggle}>
          <NumberStack label="PNVS" value={assignNumberValue(leaseFields.offerPnvs)} textAlign="right" leasePnvsTheme active={expanded} />
        </button>
      </div>
    </>
  );
};

export default observer(LeasePnvs);
