import { useEffect, useState } from 'react';

import ScenarioModel from '../../../models/ScenarioModel';
import OfferModel from '../../../models/offers/OfferModel';
import { compareOfferTypesSortOrder, getRaCompareData, groupOfferByType } from './utils';
import SeriesProfileModel, { PreviousMonthScenarioData } from '../../../models/SeriesProfileModel';
import useStores from '../../../../../stores/useStores';
import { AdminConstants } from 'oat-admin-common';

const { OPTION_TYPE_NAMES } = AdminConstants;

type RACompareData = {
  originalScenarioName: string;
  compareScenarioName: string;
  offers: Array<CompareOffer>;
  leaseExOffers?: Array<CompareOffer>;
  raNumber: number;
};

export type CompareOffer = {
  originalOffer?: OfferModel;
  compareOffer?: OfferModel;
};

const useCompareData = (compareScenarios: ScenarioModel[], previousMonthScenarioData?: PreviousMonthScenarioData) => {
  const [compareData, setcompareData] = useState<RACompareData[]>([]);
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    seriesSettingsStore: { seriesMapping },
  } = useStores();

  useEffect(() => {
    const raCompareData: RACompareData[] = [];

    let originalScenario = compareScenarios[0];
    let compareScenario = compareScenarios[1];

    if (previousMonthScenarioData) {
      const sp = new SeriesProfileModel();
      sp.setData(previousMonthScenarioData.seriesProfile, brand, seriesMapping);

      originalScenario = new ScenarioModel(brand, previousMonthScenarioData.scenario);
      originalScenario.setRgnlAlts(previousMonthScenarioData.rgnlAlts, sp);
      originalScenario.updateName('Previous Month');
      compareScenario = compareScenarios[0];
    }

    const originalScenarioName = originalScenario.name;
    const compareScenarioName = compareScenario.name;

    const compareRAsData = getRaCompareData(originalScenario.rgnlAlts, compareScenario.rgnlAlts);

    compareRAsData?.forEach(data => {
      const originalOffers = groupOfferByType(data.originalRA?.offers);
      const compareOffers = groupOfferByType(data.compareRA?.offers);

      const offers: CompareOffer[] = [];
      const leaseExOffers: CompareOffer[] = [];

      compareOfferTypesSortOrder?.forEach(optionType => {
        const offersPerTypeOriginal: OfferModel[] = originalOffers?.get(optionType);
        const offersPerTypeCompare: OfferModel[] = compareOffers?.get(optionType);

        offersPerTypeOriginal?.forEach(offer => {
          const compareOffer = offersPerTypeCompare?.shift();

          offers.push({
            originalOffer: offer,
            compareOffer,
          });
          if (offer.getOptionType() === OPTION_TYPE_NAMES.LEASE) {
            leaseExOffers.push({
              originalOffer: offer,
              compareOffer,
            });
          }
        });

        if (offersPerTypeCompare?.length) {
          offersPerTypeCompare?.forEach(offer => {
            offers.push({
              originalOffer: undefined,
              compareOffer: offer,
            });
            if (offer.getOptionType() === OPTION_TYPE_NAMES.LEASE) {
              leaseExOffers.push({
                originalOffer: undefined,
                compareOffer: offer,
              });
            }
          });
        }
      });

      raCompareData.push({
        raNumber: data.raNumber,
        originalScenarioName,
        compareScenarioName,
        offers,
        leaseExOffers,
      });
    });

    setcompareData(raCompareData);
  }, [brand, compareScenarios, previousMonthScenarioData, seriesMapping]);

  return {
    compareData,
  };
};

export default useCompareData;
