import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import APRRateTableInput from './APRRateTableInput';
import styles from './styles.module.scss';

const APRRatesTable = () => {
  const {
    ratesTabAPRRatesStore: { aprRates },
  } = useStores();

  const ratesToUse = aprRates.filter(rate => Number(rate.tier) < 4 || rate.tier === '1+');
  const tierStyle = clsx(styles.gridColumn, styles.gridItem, styles.labelText);
  const termStyle = clsx(styles.term, styles.gridItem, styles.labelText);

  return (
    <div className={styles.aprRatesTable}>
      {ratesToUse.map((rateModel, i) => {
        return (
          <div className={styles.gridColumns} key={rateModel.uid}>
            {i % 5 === 0 && <div className={tierStyle}>{`Tier ${rateModel.tier}`}</div>}
            {rateModel.tier === '1+' && <div className={termStyle}>{rateModel.term}</div>}
            <APRRateTableInput model={rateModel} />
          </div>
        );
      })}
    </div>
  );
};

export default observer(APRRatesTable);
