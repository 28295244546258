import { makeAutoObservable } from 'mobx';
import { SubventionMethods } from '../../../../constants/global';
import { Offer } from '../../../../gql/generated';
import { LeaseSetupFields, LeaseTier } from '../offers/OfferFields';
import { AdminConstants } from 'oat-admin-common';
import { defaultStringToUndefined } from 'oat-common-ui';

const { SYSTEM_TO_PAY } = AdminConstants;

class LeaseSetupModel {
  uid = '';
  tierOptions = ['1+', '1', '2', '3', '4', '5', '6', '7', '7+'];
  fields: LeaseSetupFields = {
    rcf: 0,
    mileage: 0,
    term: 0,
    highTerm: 36,
    lowTerm: 24,
    standardRcf: 0,
    subventionCash: 0,
    subventionCashSystemToPay: SYSTEM_TO_PAY.IPLUS,
    subventionMethod: SubventionMethods.BLENDED,
    tier: '1+',
    tiers: [],
    offerPnvs: 0,
    costShareId: undefined,
  };

  constructor(offer: Offer, offerUid: string) {
    makeAutoObservable(this);

    this.uid = offerUid;

    if (!offer.leaseDetails) {
      return;
    }

    this.fields.highTerm = offer.leaseDetails.highTerm ?? this.fields.highTerm;
    this.fields.lowTerm = offer.leaseDetails.lowTerm ?? this.fields.lowTerm;
    this.fields.mileage = offer.leaseDetails.mileage;
    this.fields.rcf = offer.leaseDetails.rcf;
    this.fields.standardRcf = offer.leaseDetails.standardRcf;
    this.fields.subventionCash = offer.leaseDetails.subCashAmount;
    this.fields.tier = offer.leaseDetails.tier;
    this.fields.tiers = offer.leaseDetails.tiers ? this.formatTiersTableData(offer.leaseDetails.tiers) : [];
    this.fields.term = offer.leaseDetails.term;
    this.fields.offerPnvs = offer.leaseDetails.offerPnvs;
    this.fields.subventionCashSystemToPay = offer.leaseDetails.subventionCashSystemToPay ?? this.fields.subventionCashSystemToPay;
    this.fields.costShareId = defaultStringToUndefined(offer.leaseDetails.costShareId);
  }

  get termError() {
    return this.fields.lowTerm > this.fields.highTerm;
  }

  updateField = <T extends keyof LeaseSetupFields, V extends LeaseSetupFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateTier = (selectedTier: string) => {
    this.fields.tiers = this.fields.tiers.map(tier => ({
      ...tier,
      isSelected: this.tierOptions.indexOf(tier.tier) <= this.tierOptions.indexOf(selectedTier),
    }));
  };

  formatTiersTableData(tiers: LeaseTier[]): LeaseTier[] {
    return tiers.map(tier => ({
      ...tier,
      differential: (tier.standardRcf - tier.subventedRcf).toFixed(5),
    }));
  }
}

export default LeaseSetupModel;
