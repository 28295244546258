import { DocumentNode } from '@apollo/client';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ButtonGroup, dateFormatISOString, Input, OATIcon, uploadWithPresignedUrl, useToast } from 'oat-common-ui';
import { RefObject, useCallback, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import apolloClient from '../../../apolloClient';
import Button from '../../../components/Button';
import { FEATURE_OR_2816, OfferingUploadOptions, UPLOAD_TYPE } from '../../../constants/global';
import {
  OfferingUploadItem,
  PresignedUrlResponse,
  UploadEnhancedStandardRcfDocument,
  UploadModelCodesDocument,
  UploadOfferingBosuContentDocument,
  UploadPostNglResidualValuesDocument,
  UploadPreNglResidualValuesDocument,
  UploadRegionalAprRatesDocument,
} from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import { BOSU_FILE_EXTENSION, BOSU_INVALID_FILE_EXTENSION_MESSAGE, validateBosuFileExtension } from '../../../utils/bosu';
import getPresignedUploadUrl from '../../../utils/getPresignedUploadUrl';
import IpSettingsHeader from '../components/IpSettingsHeader';
import IpSettingsNav from '../components/IpSettingsNav';
import SettingsTabWrapper from '../SettingsTabWrapper';
import styles from './styles.module.scss';

interface OfferingUploadConfig {
  mutation: DocumentNode;
  responseKey: string;
  updateFn: (value: OfferingUploadItem) => void;
}

interface UploadOption {
  uploadType: OfferingUploadOptions;
  refObject: RefObject<HTMLInputElement>;
  uploadDataKey?: 'bosu' | 'modelCodes' | 'postNglResidual' | 'preNglResidual' | 'regionalAprRates' | 'enhancedStandardRcf';
  config?: OfferingUploadConfig;
  allowUpload?: Boolean;
  accept?: string;
  handleFileChange?: (files: FileList | undefined | null) => void;
  validateHandleUpload?: (file: string) => boolean;
}

const UploadsTabComponent = () => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    uploadsTabStore: { setUploadsHasUnsavedChanges },
    nationalProgramsStore: { setEnhancedRcfChanged },
    ipSettingsStore: {
      offeringDisplayName,
      updateUploadObject,
      offeringUploads,
      offering: { isPublished },
    },
  } = useStores();
  const { offeringId } = useUrlParams();
  const { error, success } = useToast();

  const hiddenFileInput1 = useRef<HTMLInputElement>(null);
  const hiddenFileInput2 = useRef<HTMLInputElement>(null);
  const hiddenFileInput3 = useRef<HTMLInputElement>(null);
  const hiddenFileInput4 = useRef<HTMLInputElement>(null);
  const hiddenFileInput5 = useRef<HTMLInputElement>(null);
  const hiddenFileInput6 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string[]>(['', '', '', '', '']);

  const uploadOptions: UploadOption[] = [
    {
      uploadType: OfferingUploadOptions.MODEL_CODES,
      refObject: hiddenFileInput1,
      uploadDataKey: 'modelCodes',
      config: { mutation: UploadModelCodesDocument, responseKey: 'uploadModelCodes', updateFn: updateUploadObject('modelCodes') },
      allowUpload: true,
    },
    {
      uploadType: OfferingUploadOptions.PRE_NGL_RESIDUAL_VALUE,
      refObject: hiddenFileInput2,
      uploadDataKey: 'preNglResidual',
      config: { mutation: UploadPreNglResidualValuesDocument, responseKey: 'uploadPreNglResidualValues', updateFn: updateUploadObject('preNglResidual') },
      allowUpload: true,
    },
    {
      uploadType: OfferingUploadOptions.POST_NGL_RESIDUAL_VALUE,
      refObject: hiddenFileInput3,
      uploadDataKey: 'postNglResidual',
      config: { mutation: UploadPostNglResidualValuesDocument, responseKey: 'uploadPostNglResidualValues', updateFn: updateUploadObject('postNglResidual') },
      allowUpload: true,
    },
    {
      uploadType: OfferingUploadOptions.REGIONAL_APR_RATES,
      refObject: hiddenFileInput4,
      uploadDataKey: 'regionalAprRates',
      config: { mutation: UploadRegionalAprRatesDocument, responseKey: 'uploadRegionalAprRates', updateFn: updateUploadObject('regionalAprRates') },
      allowUpload: true,
    },
    {
      uploadType: OfferingUploadOptions.BOSU_FILE,
      refObject: hiddenFileInput5,
      uploadDataKey: 'bosu',
      config: { mutation: UploadOfferingBosuContentDocument, responseKey: 'uploadOfferingBosuContent', updateFn: updateUploadObject('bosu') },
      allowUpload: true,
      accept: BOSU_FILE_EXTENSION,
      handleFileChange: (files: FileList | undefined | null) => {
        if (files?.length && !isValidFileExtensionForBosuFile(files[0].name)) {
          notifyInvalidFileExtensionForBosuFile();
        }
      },
      validateHandleUpload: (file: string) => {
        if (!isValidFileExtensionForBosuFile(file)) {
          notifyInvalidFileExtensionForBosuFile();
          return false;
        }
        return true;
      },
    },
    {
      uploadType: OfferingUploadOptions.ENHANCED_STANDARD_RCFS,
      refObject: hiddenFileInput6,
      uploadDataKey: 'enhancedStandardRcf',
      config: { mutation: UploadEnhancedStandardRcfDocument, responseKey: 'uploadEnhancedStandardRcf', updateFn: updateUploadObject('enhancedStandardRcf') },
      allowUpload: FEATURE_OR_2816,
    },
  ];

  const handleClick = (option: UploadOption) => option.refObject.current?.click();

  const handleFileChange = (option: UploadOption, index: number) => {
    setFileName(prevState => {
      const newArray = [...prevState];
      newArray[index] = (option.refObject.current?.files as FileList)[0].name;
      return newArray;
    });
    setUploadsHasUnsavedChanges(!!option.refObject.current?.files);

    if (option.handleFileChange) {
      option.handleFileChange(option.refObject.current?.files);
    }
  };

  const handleUpload = async (option: UploadOption, index: number) => {
    const currentRef = option.refObject;

    if (currentRef.current?.files && option.config) {
      try {
        const file = currentRef.current?.files[0];

        const isValid = option.validateHandleUpload ? option.validateHandleUpload(file.name) : true;
        if (isValid) {
          const presignedUrlResponse: PresignedUrlResponse = await trackPromise(getPresignedUploadUrl(UPLOAD_TYPE.OFRNG_UPLOAD, fileName[index], brand, offeringId));

          await trackPromise(uploadWithPresignedUrl(presignedUrlResponse.presignedUrl, file));

          const { mutation, responseKey, updateFn } = option.config;
          const variables = {
            input: { offeringId, s3Key: presignedUrlResponse.s3Key, fileName: presignedUrlResponse.fileName },
          };
          const response = await trackPromise(apolloClient.mutate({ mutation, variables }));

          if (response.data[responseKey].success) {
            updateFn(response.data[responseKey].uploadedLog);
          }
          if (option.uploadType === OfferingUploadOptions.ENHANCED_STANDARD_RCFS) {
            setEnhancedRcfChanged();
          }
          setUploadsHasUnsavedChanges(false);
          success(`Uploaded successfully`);
        }
      } catch (e) {
        error(`${(e as Error).message}`);
      }
    }
  };

  const notifyInvalidFileExtensionForBosuFile = useCallback(() => error(BOSU_INVALID_FILE_EXTENSION_MESSAGE), [error]);

  const isValidFileExtensionForBosuFile = useCallback(validateBosuFileExtension, []);

  return (
    <>
      <IpSettingsHeader title={`Edit Incentive Period - ${offeringDisplayName}`} />
      <IpSettingsNav />
      <SettingsTabWrapper>
        {uploadOptions.map((uploadOption, index) => {
          const uploadObject = uploadOption.uploadDataKey ? offeringUploads[uploadOption.uploadDataKey] : undefined;
          const date = uploadObject?.updated ? new Date(uploadObject.updated) : '';
          const dateToDisplay = dateFormatISOString(date, 'MM/dd/yyyy');
          const timetoDisplay = date.toLocaleString([], { timeStyle: 'short' });

          return (
            <div className={styles.uploadContainer} key={index}>
              <div className={styles.contentContainer}>
                <Button
                  variant="text"
                  icon={<OATIcon icon="export" />}
                  className={clsx(styles.uploadBtn, !uploadOption.allowUpload && styles.disableUpload)}
                  onClick={() => handleClick(uploadOption)}
                  disabled={isPublished}
                >
                  {uploadOption.uploadType}
                </Button>
                <Input
                  type="file"
                  data-testid="file-input"
                  name="file"
                  accept={uploadOption.accept ?? '.xlsx,.txt'}
                  style={{ display: 'none' }}
                  ref={uploadOption.refObject}
                  onChange={() => handleFileChange(uploadOption, index)}
                />
                <p className={styles.fileName}>{fileName[index] || uploadObject?.fileName}</p>
                {date && <p className={styles.fileDate}>{`Uploaded ${dateToDisplay} ${timetoDisplay}`}</p>}
              </div>
              <ButtonGroup>
                <Button id={`save-btn-${uploadOption}`} variant="blue" extraPadding onClick={() => handleUpload(uploadOption, index)} disabled={isPublished}>
                  Save
                </Button>
              </ButtonGroup>
            </div>
          );
        })}
      </SettingsTabWrapper>
    </>
  );
};

export default observer(UploadsTabComponent);
