import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../../styles.module.scss';

interface Props {
  children: ReactNode;
}

const GridWrapper = ({ children }: Props) => {
  return <div className={clsx(styles.fyGridWrapper, styles.fyGridWrapperHeaderTotals)}>{children}</div>;
};

export default GridWrapper;
