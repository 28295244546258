import styles from '../../../styles.module.scss';
import { processTotalCost } from '../../../utils';
import OtherItemWrapper from './OtherItemWrapper';

interface Props {
  multiSeriesTotal: number;
}

const OtherItemRowTotalDetail = ({ multiSeriesTotal }: Props) => {
  return (
    <OtherItemWrapper>
      <span className={styles.totalTextColor}>{multiSeriesTotal > 0 ? processTotalCost(multiSeriesTotal) : '--'}</span>
    </OtherItemWrapper>
  );
};

export default OtherItemRowTotalDetail;
