import { makeAutoObservable } from 'mobx';
import { StateAprCapsLog } from '../gql/generated';

class StateAprCapsStore {
  lastUploadedFile = '';
  lastUploadedDate = '';

  constructor() {
    makeAutoObservable(this);
  }

  setLastUploadedFile = (lastAprCaps?: StateAprCapsLog) => {
    if (lastAprCaps) {
      this.lastUploadedFile = lastAprCaps.fileName;

      const lastUploadedDate = new Date(lastAprCaps.updated);
      const date = lastUploadedDate.toISOString().split('T')[0];
      const time = lastUploadedDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });

      this.lastUploadedDate = `${date} ${time}`;
    }
  };
}

export default StateAprCapsStore;
