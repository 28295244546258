import { observer } from 'mobx-react-lite';
import React from 'react';
import RgnlAltModel from '../../models/RgnlAltModel';
import ScenarioModel from '../../models/ScenarioModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import RgnlAltTitle from './RgnlAltTitle';
import AprRow from './Rows/AprRow';
import CashRow from './Rows/CashRow';
import FinalPayRow from './Rows/FinalPayRow';
import LeaseRow from './Rows/LeaseRow';
import MiscRow from './Rows/MiscRow';
import NationalRyoRow from './Rows/NationalRyoRow';

import styles from './styles.module.scss';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel, appendTmsLabel } from '../../../../utils/appendTfsLabel';
import { FEATURE_OR_4154 } from '../../../../constants/global';

interface Props {
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
  scenario: ScenarioModel;
}

const TitleSummary = ({ seriesProfile, rgnlAlt, scenario }: Props) => {
  const {
    offeringCostsStore: { showTfsEnhanced },
    userInfoStore: { isLexus },
  } = useStores();
  // persistedOffers included items with id
  // in setup view, only show ones that are for regions
  const persistedOffers = rgnlAlt.offers.filter(item => !!item.getOfferId());
  const tfsLabel = `${showTfsEnhanced ? 'Enh. ' : ''}${appendTfsLabel(isLexus(), 'Cost')}`;

  return (
    <div className={styles.summarySection}>
      <RgnlAltTitle seriesProfile={seriesProfile} scenario={scenario} rgnlAlt={rgnlAlt} isDisabled={seriesProfile.data.isBudgetConfirmed} showCtas={true} />
      <fieldset disabled={seriesProfile.data.isBudgetConfirmed}>
        {persistedOffers.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className={styles.offerTypeCol}>Offer Type</th>
                <th className={styles.offerCol}>Offer</th>
                <th className={styles.offerTypeCol}>Offer Cost</th>
                <th className={styles.penRateCol}>Pen Rate</th>
                {FEATURE_OR_4154 && (
                  <>
                    <th className={styles.penRateCol}>{appendTmsLabel(isLexus(), 'Cost')}</th>
                    <th className={styles.penRateCol}>{tfsLabel}</th>
                  </>
                )}
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {persistedOffers.map(offer => (
                <React.Fragment key={offer.uid}>
                  {offer.cashDetails && <CashRow seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer.cashDetails} />}
                  {offer.aprDetails && <AprRow seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer.aprDetails} />}
                  {offer.leaseDetails && <LeaseRow seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer.leaseDetails} />}
                  {offer.miscDetails && <MiscRow seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer.miscDetails} />}
                  {offer.finalPayDetails && <FinalPayRow seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer.finalPayDetails} />}
                  {offer.nationalRyoDetails && <NationalRyoRow offer={offer.nationalRyoDetails} rgnlAlt={rgnlAlt} seriesProfile={seriesProfile} />}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </fieldset>
    </div>
  );
};

export default observer(TitleSummary);
