import { makeAutoObservable } from 'mobx';
import { SeriesMapping, SeriesSortItem } from '../gql/generated';

// Used for both Series Sorting & Series Mapping
class SeriesSettingsStore {
  // Series Sorting
  seriesSortingRev = '';
  seriesSortingList: SeriesSortItem[] = [];

  // Series Mapping
  seriesMappingMapData = new Map<string, SeriesMapping[]>();
  seriesMapping: SeriesMapping[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Series Sorting
  setSeriesSortingList = (seriesList: SeriesSortItem[], rev?: string) => {
    this.seriesSortingList = seriesList;

    if (rev && !this.seriesSortingRev) {
      this.seriesSortingRev = rev;
    }
  };

  setSeriesSortingRev = (rev: string) => {
    this.seriesSortingRev = rev;
  };

  // Series Mapping
  setSeriesMapping = (seriesMapping: SeriesMapping[]) => {
    this.seriesMapping = seriesMapping;
  };

  isSeriesMappingLoaded = (brand: string) => {
    return !!this.seriesMappingMapData.has(`${brand}`);
  };

  updateSeriesName = (seriesMapping: SeriesMapping) => {
    this.seriesMapping = this.seriesMapping.map(item => {
      if (item.id === seriesMapping.id) {
        return {
          ...item,
          ...seriesMapping,
        };
      }
      return item;
    });
    this.seriesSortingList = this.seriesSortingList.map(item => {
      if (item.id === seriesMapping.id) {
        return {
          ...item,
          displayName: seriesMapping.displayName,
        };
      }
      return item;
    });
  };

  getSeriesMapByBosuSeries = () => {
    const seriesMap: Map<string, SeriesSortItem> = new Map<string, SeriesSortItem>();
    this.seriesSortingList.forEach(item => seriesMap.set(item.bosuSeries, item));
    return seriesMap;
  };

  getOatSeriesBySeriesAndYear = (bosuSeries: string, year: number) => {
    for (const mapping of this.seriesMapping) {
      if (mapping.bosuSeries === bosuSeries) {
        for (const oat of mapping.oatSeries) {
          if (oat && oat.year === year) {
            return oat;
          }
        }
      }
    }
  };

  // find by bosu series function
  findByBosuSeries = (name: string) => {
    return this.seriesMapping.find(sm => sm.bosuSeries === name)?.displayName || name;
  };
}

export default SeriesSettingsStore;
