import { ModelCodeMappingResponse, ModelCodesResponse, Vehicle } from '../../../../gql/generated';

const getIncludedModelCodes = (modelCodeMappings: ModelCodeMappingResponse[], vehicles: Vehicle[]) => {
  const includedModelCodes: ModelCodesResponse[] = [];

  modelCodeMappings.forEach(modelCode => {
    modelCode.models.forEach(model => {
      vehicles.forEach(vehicle => {
        const vehicleIndex = includedModelCodes.findIndex(m => m.seriesNameFromFile === modelCode.bosuSeries && m.year === modelCode.year);
        if (vehicle?.id === model.id && vehicleIndex < 0) {
          includedModelCodes.push({ offeringId: modelCode.offeringId, seriesNameFromFile: modelCode.bosuSeries, year: modelCode.year, models: [model] });
        } else if (vehicle?.id === model.id) {
          includedModelCodes[vehicleIndex].models.push(model);
        }
      });
    });
  });

  return includedModelCodes;
};
export default getIncludedModelCodes;
