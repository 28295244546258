import { sortFieldsHelper } from 'oat-common-ui';
import { Maybe, Offer, OfferSortOrderResult } from '../../../gql/generated';

const sortOffers = (offers: Maybe<Offer>[], offerSortOrder: OfferSortOrderResult[]) => {
  // take out examples and sort by created date. we will insert them in proper position below their master lease
  const offersExamples = offers.filter(offer => offer && offer.isExample).sort(sortFieldsHelper('created', true));

  // get regular offers and sort them by the offerSortOrder list
  const offersNoExamples = offers
    .filter(offer => offer && !offer.isExample)
    .sort((a, b) => {
      if (offerSortOrder.length) {
        const aIndex = offerSortOrder.find(item => item.offerId === a?.id)?.sortOrder ?? 0;
        const bIndex = offerSortOrder.find(item => item.offerId === b?.id)?.sortOrder ?? 0;
        return aIndex - bIndex;
      }
      return 0;
    });

  // for each master lease, grab examples and insert after the master
  let curs = offersNoExamples.length;
  while (curs--) {
    const curOffer = offersNoExamples[curs];
    if (curOffer && curOffer.leaseDetails) {
      const examples = offersExamples.filter(ex => ex && ex.masterOfferId === curOffer.id);

      // insert examples after lease
      if (examples.length) {
        offersNoExamples.splice(curs + 1, 0, ...examples);
      }
    }
  }

  return offersNoExamples;
};

export default sortOffers;
