import { observer } from 'mobx-react-lite';
import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import AccordionModel from './models/AccordionModel';

interface Props {
  accordion: AccordionModel;
  isDisabled?: boolean;
}

const AccordionComponent = ({ accordion, isDisabled }: Props) => {
  return (
    <Accordion>
      {accordion.items.map(item => {
        return item.hide ? null : <AccordionItem key={item.uid} item={item} toggleItem={accordion.toggleItem} toggleExpand={accordion.toggleExpand} isDisabled={isDisabled} />;
      })}
    </Accordion>
  );
};

export default observer(AccordionComponent);
