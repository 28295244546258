import { formatDollars } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  offerPnvs: string | number;
}

const BasicPNVS = ({ offerPnvs }: Props) => {
  return (
    <div className={styles.offerPnvsWrapper}>
      <sup>$</sup>
      <span className={styles.value}>{formatDollars(offerPnvs || 0)}</span>
      <div className={styles.label}>PNVS</div>
    </div>
  );
};

export default BasicPNVS;
