import BlockInputLabel from '../../../../components/BlockInputLabel';
import styles from './styles.module.scss';

interface Props {
  label: string;
}

const SectionLabel = ({ label }: Props) => {
  return (
    <div className={styles.label}>
      <BlockInputLabel label={label} />
    </div>
  );
};

export default SectionLabel;
