import { formatRcf } from 'oat-common-ui';
import BlockInputLabel from '../../../../components/BlockInputLabel';
import styles from './styles.module.scss';

interface Props {
  label: string;
  rcf: number;
}

const StandardRcfValue = ({ label, rcf }: Props) => {
  return (
    <div className={styles.ntlRcfVal}>
      <BlockInputLabel label={label}>
        <span className={styles.labelText}>{formatRcf(rcf)}</span>
      </BlockInputLabel>
    </div>
  );
};

export default StandardRcfValue;
