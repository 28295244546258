import { BlockInputLabel, Button, InlineInputLabel, Input } from 'oat-common-ui';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  btnText?: string;
  fileName: string;
  hasDate: boolean;
  handleBrowse: () => void | undefined;
  handleFileChange: () => void;
  handleUpload: () => void;
  lastUploadedDate?: string;
  lastUploadedFile: string;
  setFileName: (val: string) => void;
  title: string;
  accept?: string;
}

const UploadFile = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <div className={styles.uploadFileContainer}>
    <p className={styles.fileText}>Last Uploaded File: {props.lastUploadedFile}</p>
    {props.hasDate && <p className={styles.fileText}>Last Uploaded Date: {props.lastUploadedDate}</p>}

    <BlockInputLabel labelComponent={<span className={styles.fileText}>{props.title}:</span>}>
      <InlineInputLabel
        className={styles.paddingRight}
        inputWrapperClass={styles.browseContent}
        labelComponent={<Input className={styles.fileInput} value={props.fileName} readOnly disabled />}
      >
        <Button variant="primary" onClick={props.handleBrowse} id="browse-btn" className={styles.browseBtn}>
          Browse
        </Button>
      </InlineInputLabel>
      <Input type="file" data-testid="file-input" name="file" style={{ display: 'none' }} ref={ref} accept={props.accept} onChange={props.handleFileChange} />
    </BlockInputLabel>

    <Button variant="primary" id="upload-btn" data-testid="upload-btn" className={styles.uploadBtn} onClick={props.handleUpload} disabled={props.fileName.length < 1}>
      {props.btnText ? props.btnText : 'Upload'}
    </Button>
  </div>
));

export default UploadFile;
