import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../../../components/Button';
import { Offer, OfferingCosts, useCreateLeaseExampleMutation, useDeleteOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import CommonFields from '../../components/CommonFields';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import LeaseFormModel from '../../models/offers/lease/LeaseFormModel';
import LeaseModel from '../../models/offers/lease/LeaseModel';
import FirstColumn from './columns/FirstColumn';
import LastColumn from './columns/LastColumn';
import MiddleColumn from './columns/MiddleColumn';
import AddExampleModal from './components/AddExampleModal';
import LeasePnvs from './components/LeasePnvs';
import LeaseTitleExampleTabs from './components/LeaseTitleExampleTabs';
import RemoveExampleModal from './components/RemoveExampleModal';
import styles from './styles.module.scss';
import useSaveLease from './useSaveLease';

interface Props {
  lease: LeaseModel;
  leaseForm: LeaseFormModel;
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
}

const LeaseSection = ({ lease, leaseForm, seriesProfile, rgnlAlt }: Props) => {
  const { getMaster, addExample, updateName } = lease;
  const { offerFields, isExample, nameError, leaseExampleNameError, penRateError, updateOfferField, isTfsCostShareEnhanced, isTfsSubCashCostShareEnhanced } = leaseForm;
  const [addExampleModal, setAddExampleModal] = useState(false);
  const [removeExampleModal, setRemoveExampleModal] = useState(false);

  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();

  const [createExample] = useCreateLeaseExampleMutation();
  const [deleteOffer] = useDeleteOfferMutation();
  const { saveLease } = useSaveLease(seriesProfile, rgnlAlt, lease, leaseForm);
  const { error } = useToast();

  const master = getMaster();

  const saveAndClearErrors = () => {
    seriesProfile.clearErrors();
    saveLease();
  };

  const handleRemoveExample = async () => {
    if (isExample) {
      try {
        if (offerFields.id) {
          const res = await trackPromise(deleteOffer({ variables: { input: { offerId: offerFields.id, offerRev: offerFields.rev } } }));
          seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.deleteOffer.ryoOffers as Offer[]);
        }
        lease.removeExample(leaseForm.uid);
        seriesProfile.clearErrors();
      } catch (e) {
        error((e as Error).message);
      } finally {
        setRemoveExampleModal(false);
      }
    }
  };

  const handleAddExample = async (name: string) => {
    try {
      const res = await trackPromise(createExample({ variables: { input: { masterOfferId: master.offerFields.id, rev: master.offerFields.rev, name } } }));
      addExample(res.data?.createLeaseExample.offer as Offer);
      updateOfferingCostsFromResponse(res.data?.createLeaseExample.offeringCosts as OfferingCosts);
      seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.createLeaseExample.ryoOffers as Offer[]);
      seriesProfile.clearErrors();
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <div>
        <OfferHeader>
          <LeaseTitleExampleTabs lease={leaseForm} leaseGroup={lease} />
          <LeasePnvs lease={leaseForm} />
        </OfferHeader>
        <fieldset disabled={seriesProfile.data.isBudgetConfirmed}>
          <div className={styles.addLeaseExampleBtn}>
            {!isExample && (
              <Button id={`add-example-cta-${leaseForm.uid}`} variant="text" onClick={() => setAddExampleModal(true)} disabled={lease.disabledAddExamples}>
                + Add Lease Example
              </Button>
            )}
            {isExample && (
              <Button id={`remove-example-cta-${leaseForm.uid}`} variant="text" onClick={() => setRemoveExampleModal(true)}>
                - Remove Lease Example
              </Button>
            )}
          </div>
          <CommonFields
            id={leaseForm.uid}
            name={leaseForm.offerFields.name}
            nameError={nameError || leaseExampleNameError}
            type={offerFields.optionType}
            penetrationRate={offerFields.penetrationRate ?? 0}
            penetrationRateError={penRateError}
            penetrationRateDisable={leaseForm.isExample && leaseForm.leaseFields.term === master.leaseFields.term}
            onNameChange={val => {
              updateName(leaseForm, val);
              saveAndClearErrors();
            }}
            onTotalPenChange={val => {
              updateOfferField('penetrationRate', val);
              saveAndClearErrors();
            }}
          />
        </fieldset>
        <div className={styles.fieldsContainer}>
          <FirstColumn leaseForm={leaseForm} seriesProfile={seriesProfile} onSave={saveAndClearErrors} useSrpLabelLogic={false} />

          <fieldset disabled={seriesProfile.data.isBudgetConfirmed} className={styles.columnsFieldset}>
            <MiddleColumn lease={lease} leaseForm={leaseForm} seriesProfile={seriesProfile} onSave={saveAndClearErrors} />
            <LastColumn leaseForm={leaseForm} onSave={saveAndClearErrors} />
          </fieldset>
        </div>

        <OfferFooter
          uid={lease.uid}
          seriesProfile={seriesProfile}
          offerFields={master.offerFields}
          rgnlAlt={rgnlAlt}
          isEnhTfsCostShareForRegions={offerFields.isEnhTfsCostShareForRegions}
          isEnhSubCashTfsCostShareForRegions={offerFields.isEnhSubCashTfsCostShareForRegions}
          isEnhSubCashTfsCostShareForRegionsDisabled={!isTfsSubCashCostShareEnhanced}
          isEnhTfsCostShareForRegionsDisabled={!isTfsCostShareEnhanced}
          isForRegions={offerFields.isForRegions}
          isForRegionsDisabled={false}
          updateOfferField={(field, val) => {
            updateOfferField(field, !val);
            saveAndClearErrors();
          }}
        />
      </div>
      {addExampleModal && <AddExampleModal onClose={() => setAddExampleModal(false)} onSave={handleAddExample} lease={lease} />}
      {removeExampleModal && <RemoveExampleModal exampleName={leaseForm.offerFields.name} onClose={() => setRemoveExampleModal(false)} onSave={handleRemoveExample} />}
    </>
  );
};

export default observer(LeaseSection);
