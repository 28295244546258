import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, Dropdown, NumberInput, OATIcon } from 'oat-common-ui';
import { FEATURE_OR_4154 } from '../../../constants/global';
import useStores from '../../../stores/useStores';
import { appendTfsLabel } from '../../../utils/appendTfsLabel';
import IpSettingsContentWrapper from '../components/IpSettingsContentWrapper';
import IpSettingsHeader from '../components/IpSettingsHeader';
import IpSettingsNav from '../components/IpSettingsNav';
import EnhancedCostShareDates from './components/EnhancedCostShareDates';
import Footer from './components/Footer';
import styles from './styles.module.scss';

const CostShareTabComponent = () => {
  const {
    ipSettingsStore: {
      offeringDisplayName,
      offering: { isPublished },
    },
    costShareTabStore: { seriesOptions, costShares, setHasUnsavedChanges, addNewCostShare, removeCostShare, selectSeries, isCostSharesSaved },
    userInfoStore: { isLexus },
  } = useStores();

  const title = `Edit Incentive Period - ${offeringDisplayName}`;
  const percentageThreshold = {
    max: 0.999999,
    min: 0,
  };

  const isDisabled = isPublished;

  return (
    <IpSettingsContentWrapper>
      <div className={FEATURE_OR_4154 ? styles.fixedHeader : ''}>
        <IpSettingsHeader title={title} />
        <IpSettingsNav />
      </div>

      {FEATURE_OR_4154 ? (
        <EnhancedCostShareDates />
      ) : (
        <>
          <div className={styles.labelContainer}>
            <BlockInputLabel label={appendTfsLabel(isLexus(), 'Enhanced Cost Share')} className={styles.costShareLabel} />
            <BlockInputLabel label="APR" className={clsx(styles.costShareLabel, styles.costShareLabels)} />
            <BlockInputLabel label="LEASE" className={clsx(styles.costShareLabel, styles.costShareLabels)} />
          </div>

          <div className={styles.costShareContainer}>
            {costShares.map(costShare => (
              <div key={costShare.uid} className={styles.seriesContainer}>
                <Button onClick={() => removeCostShare(costShare.uid)} id={`${costShare.uid}-remove-cost-share-btn`} className={styles.removeBtn} disabled={isDisabled}>
                  <OATIcon icon="close" className={styles.closeIcon} />
                </Button>
                <div className={styles.seriesDropdown}>
                  <Dropdown
                    id={`cost-share-series-${costShare.uid}`}
                    isNational
                    disabled={isCostSharesSaved(costShare)}
                    value={costShare.label}
                    onChange={item => selectSeries(item, costShare)}
                    options={JSON.parse(JSON.stringify(seriesOptions))}
                  />
                </div>
                <NumberInput
                  isNational
                  id={`${costShare.uid}-ncsRate`}
                  rate
                  value={costShare.ncsRate}
                  onValueChange={value => {
                    costShare.updateNcsRate(value.value);
                    setHasUnsavedChanges(true);
                  }}
                  error={costShare.hasRateError}
                  disabled={isDisabled}
                />
                <NumberInput
                  isNational
                  id={`${costShare.uid}-ncsRcf`}
                  rcf
                  value={costShare.ncsRcf}
                  onValueChange={value => {
                    costShare.updateNcsRcf(value.value);
                    setHasUnsavedChanges(true);
                  }}
                  error={costShare.hasRcfError}
                  {...percentageThreshold}
                  disabled={isDisabled}
                />
              </div>
            ))}
          </div>

          <div className={clsx(styles.addSeriesContainer, isPublished && styles.unsetCursor)}>
            <Button variant="text" id="add-series-btn" onClick={addNewCostShare} className={clsx(styles.addSeriesBtn, isPublished && styles.addIsDisabled)} disabled={isDisabled}>
              <OATIcon icon="plus" className={styles.plus} />
              <BlockInputLabel label="Add Series" className={styles.addSeries} />
            </Button>
          </div>
        </>
      )}

      <Footer />
    </IpSettingsContentWrapper>
  );
};

export default observer(CostShareTabComponent);
