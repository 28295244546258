import { observer } from 'mobx-react-lite';
import { OATLoadingBar, useToast } from 'oat-common-ui';
import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import NGCHeader from '../../../components/NGCHeader';
import { useGetAllAssumptionsLazyQuery, useSaveAssumptionsMutation } from '../../../gql/generated';
import { AssumptionsModel } from '../../../models/Assumptions';
import useStores from '../../../stores/useStores';
import Header from '../components/header/Header';
import AssumptionsNav from './Header/AssumptionsNav';
import AssumptionsRows from './Rows/AssumptionsRows';

const Assumptions = () => {
  const {
    assumptionsStore: { fiscalYear, setAssumptions, loading, assumptions, rev, setIsLoading, updateRev, setIsFetched, isFetched },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { error } = useToast();

  const [getAssumptionsForYear] = useGetAllAssumptionsLazyQuery();
  const [saveAssumptions] = useSaveAssumptionsMutation();

  useEffect(() => {
    const setAssumptionsFromResponse = async () => {
      try {
        setIsLoading(true);
        setIsFetched(true);

        const response = await getAssumptionsForYear({ variables: { brand, fiscalYear } });
        setAssumptions(response.data?.queryAssumptions as AssumptionsModel);
      } catch (e) {
        error((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isFetched) {
      setAssumptionsFromResponse();
    }
  }, [brand, error, fiscalYear, getAssumptionsForYear, setAssumptions, setIsLoading, isFetched, setIsFetched]);

  const saveAssumptionsHandler = async () => {
    const payload: AssumptionsModel = {
      rev,
      brand,
      fiscalYear,
      months: assumptions.map(assumption => ({
        month: assumption.month,
        residualValue: assumption.residualValue.isValid ? Number(assumption.residualValue.value) : undefined,
        standardRate: assumption.standardRate.isValid ? Number(assumption.standardRate.value) : undefined,
        standardRcf: assumption.standardRcf.isValid ? Number(assumption.standardRcf.value) : undefined,
        year: assumption.year,
      })),
    };

    try {
      setIsLoading(true);
      const res = await trackPromise(saveAssumptions({ variables: { input: { ...payload } } }));

      if (res.data?.saveAssumptions.assumptions) {
        setAssumptions(res.data?.saveAssumptions.assumptions as AssumptionsModel);
        updateRev(res.data?.saveAssumptions.assumptions.rev);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {loading && <OATLoadingBar />}
      <Header>
        <NGCHeader title="Assumptions" />
      </Header>
      <AssumptionsNav fetchAssumptions={getAssumptionsForYear} />
      <AssumptionsRows onSave={saveAssumptionsHandler} />
    </>
  );
};

export default observer(Assumptions);
