import { OATLoadingBar } from 'oat-common-ui';
import GetNationalTfsCostShares from '../../components/GetNationalTfsCostShares';
import {
  ModelCodeMappingResponse,
  Offering,
  OfferingCosts,
  OfferingStandardRates,
  PreNglResidualValues,
  SeriesMapping,
  SeriesProfile,
  useGetNationalProgramsDataQuery,
} from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../ErrorPage';
import GetAtcOfferingChanges from './GetAtcOfferingChanges';
import NationalProgramsComponent from './NationalProgramsComponent';

const NationalPrograms = () => {
  const {
    nationalProgramsStore: { setData, isBudgetAndSetupConfirmed },
    offeringCostsStore: { updateOfferingCostsFromResponse, resetCosts },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { offeringId } = useUrlParams();

  const { data, loading, error } = useGetNationalProgramsDataQuery({ variables: { offeringId, brand } });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    resetCosts();
    setData(
      data.offering as Offering,
      data.seriesProfiles as SeriesProfile[],
      data.standardRates as OfferingStandardRates,
      data.prenglResidualValues as PreNglResidualValues,
      data.modelCodeMappings as ModelCodeMappingResponse[],
      data.seriesMappings.seriesMappings as SeriesMapping[],
    );

    if (data.offering.offeringCosts) {
      updateOfferingCostsFromResponse(data.offering.offeringCosts as OfferingCosts);
    }
  }

  return (
    <GetAtcOfferingChanges offering={data?.offering as Offering} isConfirmed={isBudgetAndSetupConfirmed()}>
      <GetNationalTfsCostShares>
        <NationalProgramsComponent />
      </GetNationalTfsCostShares>
    </GetAtcOfferingChanges>
  );
};

export default NationalPrograms;
