import clsx from 'clsx';
import { Checkbox } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import AccordionHeader from './AccordionHeader';
import AccordionSection from './AccordionSection';
import AccordionItemModel from './models/AccordionItemModel';
import styles from './styles.module.scss';

type Props = {
  item: AccordionItemModel;
  toggleItem: (uid: string, checked: boolean) => void;
  toggleExpand?: (uid: string) => void;
  isDisabled?: boolean;
};

const AccordionItem = ({ item, toggleItem, toggleExpand, isDisabled }: Props) => {
  const handleOnToggle = () => {
    if (toggleExpand) {
      toggleExpand(item.uid);
    }
  };

  const handleOnCheck = () => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      toggleItem(item.uid, e.currentTarget.checked);
    };
  };

  return (
    <div className={clsx(styles.accordionItem, item.level === 1 && styles.zebra, { [styles.shadow]: !item.expand }, isDisabled && styles.chboxDisabled)}>
      <AccordionHeader expand={item.expand} onToggle={handleOnToggle} item={item} level={item.level} hasChildren={item.items.length > 0}>
        <Checkbox
          id={item.uid}
          onChange={handleOnCheck()}
          label={item.label}
          isChecked={item.checked}
          isDisabled={item.disabled || isDisabled}
          checkmarkClass={clsx(item.isPartial && styles.isPartialSelected)}
        />
      </AccordionHeader>
      <AccordionSection expand={item.expand}>
        {item.items.map(i => {
          return i.hide ? null : <AccordionItem key={i.uid} item={i} toggleItem={toggleItem} toggleExpand={toggleExpand} isDisabled={isDisabled} />;
        })}
      </AccordionSection>
    </div>
  );
};

export default AccordionItem;
