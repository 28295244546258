import { makeAutoObservable } from 'mobx';
import { AdminModels } from 'oat-admin-common';
import SeriesMonthModel from './SeriesMonthModel';

class ModelYearDetailModel implements AdminModels.SeriesMYItem {
  modelYear = 0;
  stock = 0;
  sales = 0;
  pnvs = 0;
  totalCost = 0;
  seriesMonthModel;
  isDisplayOnly = false;

  constructor(data: AdminModels.SeriesMYItem, seriesMonthModel: SeriesMonthModel) {
    makeAutoObservable(this);

    const { modelYear, stock, sales, pnvs, totalCost } = data;

    this.modelYear = modelYear;
    this.stock = stock;
    this.sales = sales;
    this.pnvs = pnvs;
    this.totalCost = totalCost;
    this.seriesMonthModel = seriesMonthModel;
  }

  updatePnvsValue = (value: number) => {
    this.pnvs = value;
    this.totalCost = this.sales * this.pnvs;
  };

  updateDealerStockValue = (value: number) => {
    this.stock = value;
  };

  updateSalesVolumeValue = (value: number) => {
    this.sales = value;
    this.totalCost = this.sales * this.pnvs;
  };

  updateParentValues = () => {
    this.seriesMonthModel.updateTotals();
  };
}

export default ModelYearDetailModel;
