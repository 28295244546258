import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import MiscModel from '../../../models/offers/MiscModel';
import useSaveMisc from '../../../offers/MiscSection/useSaveMisc';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import OfferName from './OfferName';
import RowProps from './RowProps';
import { useSummaryCosts } from '../useSummaryCosts';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

const MiscRow = ({ seriesProfile, rgnlAlt, offer }: RowProps<MiscModel>) => {
  const { uid, updateOfferCostFields, updateOfferField } = offer;
  const { saveMiscOffer } = useSaveMisc(seriesProfile, rgnlAlt, offer);

  const { tfsCost, tmsCost } = useSummaryCosts(rgnlAlt, offer);

  const handlePenRate = (val: string) => {
    updateOfferField('penetrationRate', val);
    saveMiscOffer();
  };

  const handleAmount = (val: string) => {
    seriesProfile.clearErrors();
    updateOfferCostFields('amount', val);
    saveMiscOffer();
  };

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{offer.offerFields.name}</OfferName>
      </td>
      <td>
        <RgnlAltInput
          id={`misc-summary-amount-${uid}`}
          isNational
          wholeNumber
          dollarSign
          value={offer.offerCostFields.amount}
          onChange={e => handleAmount(e.currentTarget.value)}
          error={offer.amountError}
          units
        />
      </td>
      <td>${formatDollars(offer.offerCostFields.offerPnvs)}</td>
      <td>
        <RgnlAltInput
          id={`misc-summary-penrate-${uid}`}
          isNational
          wholeNumber
          percentageSign
          min={0}
          max={100}
          value={offer.offerFields.penetrationRate}
          onValueChange={val => handlePenRate(val.value)}
          error={offer.penetrationRateError}
          disabled={offer.isFinalPay}
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost}</td>
          <td>${tfsCost}</td>
        </>
      )}
    </tr>
  );
};

export default observer(MiscRow);
