import { makeAutoObservable } from 'mobx';
import { AdminConstants, MiscCalcFns } from 'oat-admin-common';
import { assignNumberValue, assignStringValue, uuidv4, validator } from 'oat-common-ui';
import { FEATURE_OR_5454_PHASE_2 } from '../../../../constants/global';
import { Offer } from '../../../../gql/generated';
import { OfferCostFields, OfferFields, OfferFieldType } from './OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

export interface MiscCostFields extends OfferCostFields {
  tfsCapAmount: number;
  tfsCostShare: number;
}

class MiscModel {
  uid = uuidv4();
  offerFields: OfferFields = {
    id: '',
    rev: '',
    rgnlAltId: '',
    optionType: (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE) as string,
    name: (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE) as string,
    penetrationRate: '',
    isForRegions: true,
    isEnhTfsCostShareForRegions: true,
  };
  offerCostFields: MiscCostFields = {
    amount: '',
    offerEnhancedTfsPnvs: 0,
    offerPnvs: '',
    offerTfsPnvs: 0,
    tfsCapAmount: 0,
    tfsCostShare: 0,
  };

  constructor(data: { offer?: Offer; optionType?: string; rgnlAltId: string }) {
    makeAutoObservable(this);

    this.offerFields.rgnlAltId = data.rgnlAltId;
    this.offerFields.optionType = assignStringValue(data.optionType);
    this.offerFields.name = assignStringValue(data.optionType);

    if (data.offer) {
      this.setData(data.offer);
    }
  }

  setData = (offer: Offer) => {
    const offerFields: Pick<Offer, OfferFieldType> = { ...offer };
    this.offerFields = { ...offerFields };

    if (offer.miscDetails) {
      this.offerFields.isEnhTfsCostShareForRegions = !!offer.miscDetails.isEnhTfsCostShareForRegions;

      this.offerCostFields.amount = assignStringValue(offer.miscDetails.amount);
      this.offerCostFields.offerEnhancedTfsPnvs = offer.miscDetails.offerEnhancedTfsPnvs;
      this.offerCostFields.offerPnvs = assignStringValue(offer.miscDetails.offerPnvs);
      this.offerCostFields.offerTfsPnvs = offer.miscDetails.offerTfsPnvs;
      this.offerCostFields.tfsCapAmount = assignNumberValue(offer.miscDetails.tfsCapAmount);
      this.offerCostFields.tfsCostShare = offer.miscDetails.tfsCostShare;
      this.recalculate();
    }
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;
  };

  updateOfferCostFields = <T extends keyof MiscCostFields, V extends MiscCostFields[T]>(field: T, value: V) => {
    this.offerCostFields[field] = value;
    this.recalculate();
  };

  postSave = (offer: Offer | undefined) => {
    if (offer) {
      this.offerFields.id = offer.id;
      this.offerFields.rev = offer.rev;
    }
  };

  recalculate = () => {
    this.offerCostFields.offerPnvs = assignStringValue(
      MiscCalcFns.calculatePnvs(assignNumberValue(this.offerCostFields.amount), this.offerCostFields.tfsCostShare / 100.0, this.offerCostFields.tfsCapAmount),
    );
    this.offerCostFields.offerTfsPnvs = MiscCalcFns.calculateTfsPnvs(
      assignNumberValue(this.offerCostFields.amount),
      this.offerCostFields.tfsCostShare / 100.0,
      this.offerCostFields.tfsCapAmount,
    );
    this.offerCostFields.offerEnhancedTfsPnvs = this.offerCostFields.offerTfsPnvs;
  };

  hasError = () => {
    return this.nameError || this.amountError || this.penetrationRateError;
  };

  get isFinalPay() {
    return this.offerFields.optionType === OPTION_TYPE_NAMES.FINAL_PAY;
  }

  get nameError() {
    return !!validator(this.offerFields.name, { required: true });
  }

  get amountError() {
    return !!validator(this.offerCostFields.amount, { required: true });
  }

  get penetrationRateError() {
    return !!validator(this.offerFields.penetrationRate, { required: true, min: 0, max: 100 });
  }

  get costShareError() {
    return !!validator(this.offerCostFields.tfsCostShare, { required: true, min: 0, max: 100 });
  }

  // for cost share feature flag
  get isEnhancedTfs() {
    return Number(this.offerCostFields.tfsCostShare) > 0;
  }

  get isEnhancedCap() {
    return Number(this.offerCostFields.tfsCapAmount) > 0;
  }

  get enhCostShareCheckboxIsDisabled() {
    return this.offerCostFields.tfsCostShare === 0 || !this.offerFields.isForRegions;
  }
}

export default MiscModel;
