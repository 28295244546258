import { brands } from 'oat-common-ui';
import { Brand, LdaCode } from '../constants/global';

export type PortStates = {
  [code: string]: {
    [port: string]: string[];
  };
};

class RegionsStore {
  isLoaded = false;
  statesOptions: string[] = [];
  westernLexusRegions = brands[Brand.LEXUS].ports.laPortLWAstates.concat(brands[Brand.LEXUS].ports.ptPortLWAstates).sort();
  southernLexusRegions = brands[Brand.LEXUS].ports.laPortLSAstates.concat(brands[Brand.LEXUS].ports.jxPortLSAstates).sort();
  easternLexusRegions = brands[Brand.LEXUS].ports.easternPortStates.sort();
  centralLexusRegions = brands[Brand.LEXUS].ports.centralPortStates.sort();
  portsWithStates: PortStates = {
    LSA: {
      CM: [],
      GT: [],
      JX: ['AL', 'FL', 'GA', 'NC', 'SC', 'TN'],
      LA: ['AR', 'LA', 'MS', 'OK', 'TX'],
      NY: [],
      PT: [],
      SJ: [],
    },
    LWA: {
      CM: [],
      GT: [],
      JX: [],
      LA: ['AZ', 'CA', 'NM', 'NV'],
      NY: [],
      PT: ['AK', 'CO', 'ID', 'OR', 'UT', 'WA', 'MT', 'WY'],
      SJ: [],
    },
  };

  setStatesOptions = (ldaCode: string) => {
    switch (ldaCode) {
      case LdaCode.LWA:
        this.statesOptions = this.westernLexusRegions;
        break;
      case LdaCode.LSA:
        this.statesOptions = this.southernLexusRegions;
        break;
      case LdaCode.LEA:
        this.statesOptions = this.easternLexusRegions;
        break;
      case LdaCode.LCA:
        this.statesOptions = this.centralLexusRegions;
        break;
      default:
        this.statesOptions = [...this.westernLexusRegions, ...this.southernLexusRegions, ...this.easternLexusRegions, ...this.centralLexusRegions].sort();
        break;
    }
  };
}

export default RegionsStore;
