import AccordionItemModel from '../models/AccordionItemModel';

const recursiveApplyHideFalse = (items: AccordionItemModel[]) => {
  items.forEach(item => {
    item.hide = false;
    if (item.items.length > 0) {
      recursiveApplyHideFalse(item.items);
    }
  });
};

const shouldHide = (query: string, value: string) => {
  const queryTrimmed = query.trim().toLowerCase();
  return Boolean(queryTrimmed && !value.toLowerCase().includes(queryTrimmed));
};

/**
 *
 * @param items
 * @param query
 */
const recursiveQuery = (items: AccordionItemModel[], query: string) => {
  items.forEach(item => {
    if (item.items.length > 0) {
      // step 1, check if query applies here, if it does, apply hide = false to all children
      item.hide = shouldHide(query, item.label);
      if (!item.hide) {
        recursiveApplyHideFalse(item.items);
      } else {
        // step 2, if query fails check in this level, recursively check children
        recursiveQuery(item.items, query);
        item.hide = item.items.every(i => i.hide);
      }
    } else {
      item.hide = shouldHide(query, item.label);
    }
  });
};

export default recursiveQuery;
