import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Dropdown, DropdownItem, OATIcon, dateStringToDate, formatRcf } from 'oat-common-ui';
import { useCallback, useMemo } from 'react';
import { Maybe } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import NationalCostShareModel from '../../../IpSettings/CostShareTab/models/NationalCostShareModel';
import LeaseFormModel from '../../models/offers/lease/LeaseFormModel';
import styles from './styles.module.scss';

type Props = {
  id: string;
  seriesProfileId: string;
  offerType: 'Lease' | 'APR';
  onSelect: (id: Maybe<string>, value: number) => void;
  leaseForm?: LeaseFormModel;
  selectedTfsCost?: Maybe<string>;
  isUpdated?: boolean;
};

const { OPTION_TYPE_NAMES } = AdminConstants;

const TfsCostSharesSelector = ({ id, seriesProfileId, offerType, onSelect, leaseForm, selectedTfsCost, isUpdated }: Props) => {
  const {
    nationalCostShareStore: { getCostSharesByType, getCostShareById },
    userInfoStore: { isLexus },
  } = useStores();

  const costShares = useMemo(() => {
    const costSharesByType = getCostSharesByType(seriesProfileId, offerType);
    const singleTfsIsApplied = costSharesByType?.costShares.length === 1;

    // If Single Enhanced Cost Share span the entire incentive period we are not showing the dropdown
    if (singleTfsIsApplied) {
      if (offerType === OPTION_TYPE_NAMES.APR && costSharesByType?.fullMonthAprCostShareId) {
        return [];
      } else if (offerType === OPTION_TYPE_NAMES.LEASE && costSharesByType?.fullMonthLeaseCostShareId) {
        leaseForm?.updateSelectedCostShare(costSharesByType?.costShares[0].id || '');
        return [];
      }
    }

    const retVal: DropdownItem[] =
      costSharesByType?.costShares.map((cs: NationalCostShareModel) => {
        const startDate = dateStringToDate(cs.fields.startDate);
        const endDate = dateStringToDate(cs.fields.endDate);

        return {
          value: cs.id,
          label: cs.id,
          jsxLabel: (
            <label className={styles.tfsCostLabel}>
              <span className={offerType === OPTION_TYPE_NAMES.LEASE ? styles.valuesLease : styles.valuesApr}>
                <span className={styles.adjustmentText}>Adjustment:</span>
                <span>{offerType === OPTION_TYPE_NAMES.LEASE ? formatRcf(cs.fields.value) : cs.fields.value}</span>
              </span>
              <span className={styles.separatorVertical} />
              <span className={styles.dates}>
                <OATIcon icon="calendar" size={20} className={styles.calendarIcon} />
                <span>{`${startDate.toLocaleDateString(undefined, { weekday: 'short' })}, ${startDate.toLocaleDateString(undefined, { day: '2-digit', month: 'short' })}`}</span>
                <span className={styles.separatorHorizontal} />
                <span>{`${endDate.toLocaleDateString(undefined, { weekday: 'short' })}, ${endDate.toLocaleDateString(undefined, { day: '2-digit', month: 'short' })}`}</span>
              </span>
            </label>
          ),
        };
      }) || [];

    if (costSharesByType?.costShares.length) {
      const noCostShareLabel = appendTfsLabel(isLexus(), 'Cost Share', 'No Enhanced');

      retVal.push({ label: noCostShareLabel, jsxLabel: <label>{noCostShareLabel}</label>, value: 'none' });
    }

    return retVal;
  }, [getCostSharesByType, isLexus, offerType, seriesProfileId, leaseForm]);

  const getSelectedValue = useCallback(() => {
    const foundCostShare = costShares.find(cs => cs.value === selectedTfsCost);

    // If user select "No Enhanced TFS Cost Shares" we set costShareId to null. By default is undefined (placeholder text).
    if (selectedTfsCost === null || !foundCostShare) {
      leaseForm?.updateSelectedCostShare('');

      return costShares[costShares.length - 1];
    }

    if (foundCostShare && selectedTfsCost) {
      leaseForm?.updateSelectedCostShare(selectedTfsCost);
    }

    return foundCostShare;
  }, [costShares, selectedTfsCost, leaseForm]);

  const handleOnChange = (item: DropdownItem) => {
    if (leaseForm?.isExample) {
      return;
    }

    const id = item.value === 'none' ? null : item.value;
    const value = Number(getCostShareById(seriesProfileId, id)?.fields.value || 0);

    onSelect(id, value);
  };

  const isDisabled = !costShares?.length;

  return (
    <>
      {!!costShares.length && (
        <BlockInputLabel label={appendTfsLabel(isLexus(), 'Cost Share', 'Enhanced')} className={styles.costShareSelectorWrapper}>
          <div className={isUpdated ? styles.updatedCostShareSelector : undefined}>
            <Dropdown
              disabled={isDisabled}
              id={`cost-shares-${id}`}
              isJsxLabel
              isNational
              minWidth={500}
              onChange={item => handleOnChange(item)}
              options={costShares}
              placeholder="Select"
              value={getSelectedValue()}
            />
          </div>
        </BlockInputLabel>
      )}
    </>
  );
};

export default observer(TfsCostSharesSelector);
