import { useEffect } from 'react';
import { PriorOffer, useGetPriorNationalRyoOffersQuery } from '../gql/generated';
import SeriesProfileModel from '../pages/NationalPrograms/models/SeriesProfileModel';
import FinalPayModel from '../pages/NationalPrograms/models/offers/FinalPayModel';

const useGetPriorOffers = (seriesProfile: SeriesProfileModel, finalPay: FinalPayModel) => {
  const { data } = useGetPriorNationalRyoOffersQuery({
    variables: { modelYear: seriesProfile.data.modelYear, series: seriesProfile.data.series, offeringId: seriesProfile.data.offeringId },
  });

  useEffect(() => {
    if (data) {
      finalPay.setPriorOffers(data.getPriorNationalRyoOffers as PriorOffer[]);
    }
  }, [data, finalPay, finalPay.setPriorOffers]);
};

export default useGetPriorOffers;
