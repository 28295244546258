import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, IMultiItem, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import { FEATURE_OR_3325 } from '../../../../constants/global';
import {
  ModelCodeMappingInput,
  ModelCodeMappingResponse,
  ModelCodeResponse,
  ModelCodesResponse,
  useGetAllModelCodesQuery,
  useGetModelCodeMappingsQuery,
  useSaveModelCodeMappingMutation,
} from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import ModelCodeMappingModel from '../../SeriesModelsTab/models/ModelCodeMappingModel';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  selectedItem?: IMultiItem;
}

const ModelCodeMappingModal = ({ onClose, selectedItem }: Props) => {
  const {
    seriesAndModelsStore: { allModelCodes, seriesSelections, seriesMapping, updateModelCodesRev, setAllModelCodes },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const [saveModelCodeMapping] = useSaveModelCodeMappingMutation();
  const { offeringId } = useUrlParams();
  const { error } = useToast();
  const state = useLocalObservable(() => new ModelCodeMappingModel());

  const { data: modelCodes, loading: allModelCodesLoading, error: allModelCodesError } = useGetAllModelCodesQuery({ variables: { offeringId } });
  const { data: modelCodeMappingData, loading: modelCodeMappingLoading, error: modelCodeMappingError } = useGetModelCodeMappingsQuery({ variables: { offeringId } });

  const isLoading = allModelCodesLoading || modelCodeMappingLoading;
  const hasError = allModelCodesError || modelCodeMappingError;

  const handleSearchInputChange = (value: string) => {
    state.setResetInput(value ? true : false);
    state.setModalSearchInput(value);
  };

  const handleCancelSearch = () => {
    state.setResetInput(false);
    state.setModalSearchInput('');
  };

  const handleSubmit = async () => {
    try {
      const models: ModelCodeMappingInput[] = [];
      const filtered = allModelCodes.filter(mc => mc.year === state.selectedYear);
      filtered.forEach(all => {
        all.models.forEach(mc => {
          state.accordion.items.forEach(item => {
            item.items.forEach(acItem => {
              acItem.items.forEach(ac => {
                if (ac.checked && ac.value === mc.modelCode && !ac.disabled) {
                  models.push({
                    id: mc.id,
                    rev: mc.rev,
                  });
                }
              });
            });
          });
        });
      });

      const input = {
        offeringId,
        bosuSeries: state.selectedBosuSeries,
        year: state.selectedYear,
        models,
      };

      const resp = await trackPromise(saveModelCodeMapping({ variables: { input: input } }));
      updateModelCodesRev(resp.data?.saveModelCodeMapping.updatedModels as ModelCodeResponse[]);
      onClose();
    } catch (e) {
      error((e as Error).message);
    }
  };

  // init data
  if (!state.loaded && modelCodes && modelCodeMappingData && selectedItem) {
    setAllModelCodes(modelCodes.getAllModelCodes as ModelCodesResponse[]);
    state.setSelectedBosuSeries(selectedItem, seriesMapping);
    state.setMappings(
      modelCodeMappingData.modelCodeMappingData as ModelCodeMappingResponse[],
      modelCodes.getAllModelCodes as ModelCodesResponse[],
      seriesSelections.excluded.concat(seriesSelections.included),
    );
    state.setAccordion();
  }

  return (
    <Modal isOpen onClose={onClose} className={styles.modalWrapper}>
      <ModalHeader onClose={onClose}>Model Codes</ModalHeader>
      <ModalBody className={styles.body}>
        {isLoading && 'Loading...'}
        {!isLoading && modelCodes && modelCodeMappingData && !hasError && (
          <div>
            <div className={styles.searchSeriesProfileBox}>
              <Input
                id="model-code-mapping-modal-search"
                className={styles.seriesProfileNameInput}
                placeholder="Search by trim name"
                value={state.modalSearchInput}
                onChange={e => handleSearchInputChange(e.currentTarget.value)}
              />
              <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
              {FEATURE_OR_3325 && state.resetInput && (
                <Button onClick={handleCancelSearch} className={styles.resetInput}>
                  <OATIcon icon="cancel-circle" colorTheme="blue" />
                </Button>
              )}
            </div>
            <div className={styles.accordionWrapper}>
              <AccordionComponent accordion={state.accordion} isDisabled={isPublished} />
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="edit-series-profile-close" className={styles.closeBtn} onClick={onClose}>
            Close
          </Button>
          <Button id="edit-series-profile-save" variant="primary" onClick={handleSubmit} disabled={isPublished}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ModelCodeMappingModal);
