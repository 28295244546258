import { observer } from 'mobx-react-lite';
import { DropdownItem } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import SelectSeriesModal from '../SelectSeriesModal';
import AddSeriesButton from './AddSeriesButton';
import SeriesNameButton from './SeriesNameButton';
import styles from './styles.module.scss';

const LeftSide = () => {
  const {
    nationalCostShareStore: { addNewSeries, costSharesBySeriesProfile, addedSeriesOptions, processSeriesProfileOptions },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const [showAddSeriesModal, setShowAddSeriesModal] = useState(false);

  const handleAddSeries = () => {
    setShowAddSeriesModal(true);
  };

  const handleSubmit = (seriesOption: DropdownItem) => {
    addNewSeries(seriesOption);
    setShowAddSeriesModal(false);
  };

  const isDisabled = isPublished || !processSeriesProfileOptions().length;

  return (
    <div className={styles.leftSide}>
      {showAddSeriesModal && <SelectSeriesModal seriesOptions={processSeriesProfileOptions()} onClose={() => setShowAddSeriesModal(false)} onSubmit={handleSubmit} />}

      <AddSeriesButton handleAddSeries={handleAddSeries} isDisabled={isDisabled} />

      {addedSeriesOptions.map((option, i) => {
        const costShareSeriesProfile = costSharesBySeriesProfile.get(option.value);

        if (costShareSeriesProfile && costShareSeriesProfile.costShares.length > 0) {
          return <SeriesNameButton option={option} costShares={costShareSeriesProfile.costShares} key={`${option.value}-${i}`} />;
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default observer(LeftSide);
