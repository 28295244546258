import { ModelCodeMappingResponse, ModelCodesResponse, SeriesMapping, Vehicle } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const getDisplayName = (seriesNameFromFile: string, seriesMappingList?: SeriesMapping[]) => {
  return seriesMappingList?.find(series => series.bosuSeries === seriesNameFromFile)?.displayName ?? seriesNameFromFile;
};

const createAccordionFromModelCodes = (
  vehicles: ModelCodesResponse[],
  mappings?: ModelCodeMappingResponse[],
  selectedSeries?: string,
  seriesProfileVehicles?: Vehicle[],
  seriesMappingList?: SeriesMapping[],
  expandedByDefault?: boolean,
  disabledVehicleIds?: string[],
) => {
  const years: AccordionItemModel[] = [];

  vehicles?.forEach(vehicle => {
    let yearItem = years.find(year => year.value === vehicle.year);
    if (!yearItem) {
      yearItem = new AccordionItemModel(vehicle.year, vehicle.year);
      years.push(yearItem);
    }

    let seriesItem = yearItem.items.find(series => series.value === vehicle.seriesNameFromFile);
    if (!seriesItem) {
      seriesItem = new AccordionItemModel(getDisplayName(vehicle.seriesNameFromFile, seriesMappingList), vehicle.seriesNameFromFile);
      seriesItem.level = 2;
      yearItem.items.push(seriesItem);
    }

    if (mappings) {
      yearItem.expand = true;
    }

    vehicle.models.forEach(model => {
      if (model) {
        let modelItem = seriesItem?.items.find(t => t.value === model.modelCode);

        if (!modelItem) {
          modelItem = new AccordionItemModel(`(${model.modelCode}) ${model.vehicleDescription}`, model.modelCode);
          modelItem.id = model.id;
          modelItem.level = 3;
          modelItem.checked = false;
          modelItem.description = model.vehicleDescription;
          modelItem.disabled = false;
          seriesItem?.items.push(modelItem);
        }

        if (mappings) {
          mappings
            .filter(mppg => mppg.year === vehicle.year)
            .forEach(item => {
              item.models.forEach(mdl => {
                if (modelItem) {
                  if (model.mappedBosuSeries && mdl.mappedBosuSeries === model.mappedBosuSeries && mdl.modelCode === model.modelCode) {
                    modelItem.setChecked(true);
                  }
                  if (model.mappedBosuSeries && mdl.mappedBosuSeries !== selectedSeries && mdl.modelCode === model.modelCode) {
                    modelItem.setChecked(false);
                    modelItem.disabled = true;
                  }
                }
              });
            });
        }
        if (seriesProfileVehicles) {
          seriesProfileVehicles.filter(item => model.id === item.id).forEach(spVehicle => modelItem?.setChecked(spVehicle.isInclusion));
        }

        if (disabledVehicleIds) {
          modelItem.disabled = disabledVehicleIds.includes(model.id);
        }
      }
    });

    seriesItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    seriesItem.checked = seriesItem.items.some(item => item.checked);
    seriesItem.disabled = seriesItem.items.every(item => item.disabled);
    seriesItem.expand = !!expandedByDefault;

    yearItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    yearItem.checked = yearItem.items.some(item => item.checked);
    yearItem.expand = !!expandedByDefault;
  });

  return years.sort((itemA, itemB) => (itemA.label > itemB.label ? -1 : 1));
};

export default createAccordionFromModelCodes;
