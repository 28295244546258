import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { assignNumberValue, BlockInputLabel, Checkbox, Dropdown, DropdownItem, Input, NumberInput } from 'oat-common-ui';
import BasicPNVS from '../../../../components/BasicPNVS';
import { FEATURE_OR_2477 } from '../../../../constants/global';
import useGetPriorOffers from '../../../../hooks/useGetPriorOffers';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import OfferTitle from '../../components/OfferTitle';
import FinalPayModel, { FinalPayCostFields } from '../../models/offers/FinalPayModel';
import { OfferFields } from '../../models/offers/OfferFields';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import styles from './styles.module.scss';
import useSaveFinalPay from './useSaveFinalPay';

interface Props {
  finalPay: FinalPayModel;
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;
}

const FinalPaySection = ({ finalPay, rgnlAlt, seriesProfile }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const {
    amountError,
    costShareError,
    nameError,
    offerCostFields,
    offerFields,
    penetrationRateError,
    priorOffersOptions,
    selectPriorOffer,
    selectedPriorNationalRyoOffer,
    updateOfferCostFields,
    updateOfferField,
    isEnhancedTfs,
    isEnhancedCap,
  } = finalPay;

  const { saveFinalPayOffer } = useSaveFinalPay(seriesProfile, rgnlAlt, finalPay);
  useGetPriorOffers(seriesProfile, finalPay);

  const handleUpdateOfferCostField = (fieldName: keyof FinalPayCostFields, value: string | number | boolean) => {
    seriesProfile.clearErrors();
    updateOfferCostFields(fieldName, value);
    saveFinalPayOffer();
  };

  const handleUpdateOfferField = (fieldName: keyof OfferFields, value: string | number | boolean) => {
    seriesProfile.clearErrors();
    updateOfferField(fieldName, value);
    saveFinalPayOffer();
  };

  const handleUpdatePriorRyoDropdown = (item: DropdownItem) => {
    seriesProfile.clearErrors();
    selectPriorOffer(item.value);
    saveFinalPayOffer();
  };

  return (
    <div>
      <OfferHeader>
        <OfferTitle offerType="finalPay" title={offerFields.optionType} />
        <BasicPNVS offerPnvs={offerCostFields.offerPnvs} />
      </OfferHeader>
      <fieldset className={styles.finalPayForm} disabled={seriesProfile?.data.isBudgetConfirmed}>
        <div className={styles.finalPayFields}>
          <BlockInputLabel label="Name" className={styles.name}>
            <Input
              id={`final-pay-offer-name-${finalPay.uid}`}
              isNational
              value={offerFields.name}
              onChange={val => handleUpdateOfferField('name', val.currentTarget.value)}
              error={nameError}
            />
          </BlockInputLabel>

          <BlockInputLabel label="Select Prior National RYO">
            <Dropdown
              id={`prior-ryo-${finalPay.uid}`}
              isNational
              minWidth={250}
              options={priorOffersOptions || []}
              value={priorOffersOptions.find(priorOffer => priorOffer.value === selectedPriorNationalRyoOffer?.offer.id) || '--'}
              onChange={item => handleUpdatePriorRyoDropdown(item)}
              disabled={offerCostFields.isManualOverride || seriesProfile.data.isBudgetConfirmed}
            />
          </BlockInputLabel>
          <BlockInputLabel label="Calculate Final Pay Amount">
            <div className={styles.finalPayment}>
              <span
                onClick={() => {
                  selectedPriorNationalRyoOffer && !offerCostFields.isManualOverride && handleUpdateOfferCostField('isPercentSelected', true);
                }}
                className={!offerCostFields.isPercentSelected || offerCostFields.isManualOverride || !selectedPriorNationalRyoOffer ? styles.disabled : undefined}
              >
                <NumberInput
                  id={`final-pay-amt-percent-input-${finalPay.uid}`}
                  className={styles.percentage}
                  disabled={offerCostFields.isManualOverride || !selectedPriorNationalRyoOffer}
                  onChange={val => handleUpdateOfferCostField('finalPayAmountPercent', Number(val.currentTarget.value))}
                  percentageSign
                  value={offerCostFields.finalPayAmountPercent}
                  wholeNumber
                />
              </span>
              <span
                onClick={() => {
                  selectedPriorNationalRyoOffer && !offerCostFields.isManualOverride && handleUpdateOfferCostField('isPercentSelected', false);
                }}
                className={clsx(
                  { [styles.disabled]: offerCostFields.isPercentSelected || offerCostFields.isManualOverride || !selectedPriorNationalRyoOffer },
                  styles.amountWrapper,
                )}
              >
                <NumberInput
                  id={`final-pay-amt-${finalPay.uid}`}
                  className={styles.amount}
                  disabled={offerCostFields.isManualOverride || !selectedPriorNationalRyoOffer}
                  dollarSign
                  negativeNumber
                  onValueChange={val => handleUpdateOfferCostField('finalPayAmount', Number(val.value))}
                  value={offerCostFields.finalPayAmount}
                  units
                />
              </span>
            </div>
          </BlockInputLabel>
          <BlockInputLabel label="Amount">
            <div className={styles.amountWrapper}>
              <NumberInput
                id={`amount-${finalPay.uid}`}
                disabled={!offerCostFields.isManualOverride}
                error={offerCostFields.isManualOverride && amountError}
                dollarSign
                min={1}
                isNational
                onValueChange={value => {
                  handleUpdateOfferCostField('amount', Number(value.value));
                }}
                value={offerCostFields.amount}
                wholeNumber
                units
              />
              <Checkbox
                className={styles.manualOverrideCheckbox}
                id={`manual-override-checkbox-${finalPay.uid}`}
                isChecked={offerCostFields.isManualOverride}
                isDisabled={seriesProfile.isConfirmed}
                isNational
                label="Manual Override"
                onChange={e => {
                  handleUpdateOfferCostField('isManualOverride', e.target.checked);
                }}
              />
            </div>
          </BlockInputLabel>
        </div>

        <div className={styles.commonFields}>
          <BlockInputLabel label="Total Cash Pen %">
            <NumberInput
              id={`final-pay-pen-rate-input-${finalPay.uid}`}
              error={penetrationRateError}
              isNational
              max={100}
              min={0}
              onChange={val => handleUpdateOfferField('penetrationRate', val.currentTarget.value)}
              percentageSign
              required
              value={offerFields.penetrationRate}
              wholeNumber
            />
          </BlockInputLabel>
          <BlockInputLabel label={appendTfsLabel(isLexus(), 'Cost Share %')}>
            <NumberInput
              id={`${appendTfsLabel(isLexus(), '-cost-share-input')}-${finalPay.uid}`}
              error={costShareError}
              isNational
              max={100}
              min={0}
              onChange={val => handleUpdateOfferCostField('tfsCostShare', assignNumberValue(val.currentTarget.value))}
              percentageSign
              required
              value={offerCostFields.tfsCostShare}
              wholeNumber
              className={FEATURE_OR_2477 && isEnhancedTfs ? styles.costShareEnh : ''}
            />
          </BlockInputLabel>
          <BlockInputLabel label={appendTfsLabel(isLexus(), 'Cap')}>
            <NumberInput
              id={`${appendTfsLabel(isLexus(), '-cap-input')}-${finalPay.uid}`}
              isNational
              min={0}
              onValueChange={val => handleUpdateOfferCostField('tfsCap', Number(val.value))}
              value={offerCostFields.tfsCap}
              wholeNumber
              dollarSign
              className={FEATURE_OR_2477 && isEnhancedCap ? styles.costShareEnh : ''}
              units
            />
          </BlockInputLabel>
        </div>
      </fieldset>
      <OfferFooter
        uid={finalPay.uid}
        seriesProfile={seriesProfile}
        offerFields={offerFields}
        rgnlAlt={rgnlAlt}
        isForRegions={offerFields.isForRegions}
        updateOfferField={(field, val) => {
          updateOfferField(field, !val);
        }}
      />
    </div>
  );
};

export default observer(FinalPaySection);
