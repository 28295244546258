import { observer } from 'mobx-react-lite';
import { BlockInputLabel, CustomDatePicker, dateFormatISOString, dateStringToDate, Input } from 'oat-common-ui';
import ShowError from '../../../../../components/ShowError';
import useStores from '../../../../../stores/useStores';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import OfferSetupTitle from '../OfferSetupTitle';
import styles from './styles.module.scss';
import { useMemo } from 'react';
import { AdminConstants } from 'oat-admin-common';

type Props = {
  offer: OfferSetupModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
};

const OfferSetupHeader = ({ offer, seriesProfile, onSave }: Props) => {
  const { updateField } = offer;
  const {
    nationalProgramsStore: { offering },
    nationalCostShareStore: { getCostShareById },
  } = useStores();

  const costShare = useMemo(() => {
    if (offer.fields.optionType === AdminConstants.OPTION_TYPE_NAMES.LEASE) {
      return offer.leaseSetupDetails?.fields.costShareId && getCostShareById(seriesProfile.id, offer.leaseSetupDetails?.fields.costShareId);
    } else if (offer.fields.optionType === AdminConstants.OPTION_TYPE_NAMES.APR) {
      return offer.aprSetupDetails?.costShareId && getCostShareById(seriesProfile.id, offer.aprSetupDetails?.costShareId);
    }
  }, [getCostShareById, offer.aprSetupDetails?.costShareId, offer.fields.optionType, offer.leaseSetupDetails?.fields.costShareId, seriesProfile.id]);

  return (
    <>
      <OfferSetupTitle offerSetup={offer} />
      <BlockInputLabel label="Name" className={styles.inlineInput} labelClass={styles.inlineInputLabel}>
        <Input
          isNational
          value={offer.fields.offerName}
          disabled={seriesProfile.isConfirmed}
          required
          error={offer.nameError}
          onChange={e => {
            updateField('offerName', e.target.value);
            onSave();
          }}
        />
      </BlockInputLabel>
      <BlockInputLabel label="Start Date" className={styles.inlineInput} labelClass={styles.inlineInputLabel}>
        <CustomDatePicker
          minDate={dateStringToDate(costShare ? costShare.fields.startDate : offering.startDate)}
          maxDate={dateStringToDate(costShare ? costShare.fields.endDate : offering.endDate)}
          isNational
          id={`${offer.uid}-start-date`}
          selected={offer.fields.startDate ? dateStringToDate(offer.fields.startDate) : undefined}
          onChange={date => {
            updateField('startDate', dateFormatISOString(date));
            onSave();
          }}
          disabled={seriesProfile.isConfirmed}
          error={offer.startDateError || offer.datesOrderError}
        />
      </BlockInputLabel>
      <BlockInputLabel label="End Date" className={styles.inlineInput} labelClass={styles.inlineInputLabel}>
        <CustomDatePicker
          minDate={dateStringToDate(costShare ? costShare.fields.startDate : offering.startDate)}
          maxDate={dateStringToDate(costShare ? costShare.fields.endDate : offering.endDate)}
          isNational
          id={`${offer.uid}-end-date`}
          selected={offer.fields.endDate ? dateStringToDate(offer.fields.endDate) : undefined}
          onChange={date => {
            updateField('endDate', dateFormatISOString(date));
            onSave();
          }}
          disabled={seriesProfile.isConfirmed}
          error={offer.endDateError || offer.datesOrderError}
        />
      </BlockInputLabel>
      {offer.datesOrderError && <ShowError errorMessage="End Date is before the Start Date. Please modify the dates." useDefaultIcon={false} />}
    </>
  );
};

export default observer(OfferSetupHeader);
