import { makeAutoObservable, runInAction } from 'mobx';
import { IMultiItem, assignStringValue, uuidv4 } from 'oat-common-ui';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromModelCodes from '../../../../components/Accordion/utils/createAccordionFromModelCodes';
import { ModelCodeMappingResponse, ModelCodesResponse, SeriesMapping, SeriesProfile, Vehicle } from '../../../../gql/generated';

class MultiSeriesProfileModel {
  uid = uuidv4();
  id = '';
  rev = '';
  offeringId = '';
  name = '';
  multiSeriesOfferId = '';
  modelCodes: ModelCodesResponse[] = [];
  allModelCodes: ModelCodesResponse[] = [];
  modelCodeMapping: ModelCodeMappingResponse[] = [];
  loaded = false;
  accordion: AccordionModel = new AccordionModel();
  selectedYear = '';
  selectedBosuSeries = '';
  vehicles: Vehicle[] = [];
  resetInput = false;
  modalSearchInput = '';

  constructor(data?: SeriesProfile, modelCodeMappings?: ModelCodeMappingResponse[], updatedVehicles?: Vehicle[]) {
    makeAutoObservable(this);
    if (data) {
      const vehicles = data.vehicles.map(vehicle => ({ id: assignStringValue(vehicle?.id), isInclusion: vehicle?.isInclusion || false }));
      this.id = assignStringValue(data.id);
      this.modelCodeMapping = modelCodeMappings || [];
      this.rev = assignStringValue(data.rev);
      this.offeringId = assignStringValue(data.offeringId);
      this.name = assignStringValue(data.multiSeriesOfferName);
      this.multiSeriesOfferId = assignStringValue(data.multiSeriesOfferId);
      this.vehicles = updatedVehicles || vehicles;
    }
  }

  setLoaded = (v: boolean) => runInAction(() => (this.loaded = v));

  setSelectedBosuSeries = (item: IMultiItem, seriesMappingList: SeriesMapping[]) => {
    const series = seriesMappingList.filter(srs => srs.id === item.id.split('_')[0])[0];
    this.selectedYear = item.data.split(' ')[0];
    this.selectedBosuSeries = series.bosuSeries;
  };

  setAccordion = (mappings: ModelCodeMappingResponse[], seriesMappingList: SeriesMapping[]) => {
    this.modelCodeMapping = mappings;
    this.modelCodes = mappings.map(({ bosuSeries, ...rest }) => ({ seriesNameFromFile: bosuSeries, ...rest })) as ModelCodesResponse[];
    const accordionModel = new AccordionModel(createAccordionFromModelCodes(this.modelCodes, undefined, undefined, this.vehicles, seriesMappingList));
    this.accordion = accordionModel;
    this.setLoaded(true);
  };

  setSearchInput = (value: string, seriesMappingList: SeriesMapping[]) => {
    this.modalSearchInput = value;
    this.accordion.setQuery(value);
    this.accordion.toggleExpandAll(!!value);
  };

  get hasErrors() {
    return this.vehiclesError;
  }

  get vehiclesError() {
    return this.accordion.getChecked(this.accordion.items).length === 0;
  }

  setResetInput = (v: boolean) => runInAction(() => (this.resetInput = v));
}

export default MultiSeriesProfileModel;
