import { ModelCodesResponse, Vehicle, VehicleInput } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

export const mapAccordionToVehicleInputs = (accItems: AccordionItemModel[], vehicles: Vehicle[]) => {
  const itemsList: Record<string, boolean> = {};

  // flatten accordion items/values
  accItems.forEach(yearItem => {
    yearItem.items.forEach(seriesItem => {
      seriesItem.items.forEach(({id, checked}) => {
        itemsList[id] = checked;
      });
    });
  });

  // maps vehicles to input
  return vehicles.map(({id}) => ({
    id: id,
    isInclusion: itemsList[id] ?? false
  }));
};

const mapSeriesProfileVehicleModelCodeFromAccordion = (accItems: AccordionItemModel[], vehicleInputs: ModelCodesResponse[]): VehicleInput[] => {
  const vehicles: VehicleInput[] = [];
  vehicleInputs.forEach(v => {
    const yearItem = accItems.find(i => i.value === v.year);
    if (yearItem) {
      const seriesItem = yearItem.items.find(si => si.value === v.seriesNameFromFile);
      if (seriesItem) {
        v.models.forEach(vModel => {
          const modelItem = seriesItem.items.find(mi => mi.value === vModel.modelCode);
          if (modelItem) {
            vehicles.push({
              isInclusion: modelItem.checked,
              id: modelItem.id,
            });
          }
        });
      }
    }
  });

  return vehicles;
};

export default mapSeriesProfileVehicleModelCodeFromAccordion;
