import clsx from 'clsx';
import { Button, OATIcon } from 'oat-common-ui';
import IconNames from 'oat-common-ui/dist/src/components/OATIcon/IconNames';
import styles from '../styles.module.scss';

interface Props {
  id: string;
  iconId: IconNames;
  buttonText: string;
  onClickHandler: () => void;
  parentClass?: string;
  className?: string;
  textClass?: string;
  disabled?: boolean;
}

const NavButton = ({ id, iconId, parentClass, className, buttonText, onClickHandler, textClass, disabled }: Props) => {
  const handleButtonClick = () => {
    onClickHandler();
  };

  return (
    <Button
      disabled={disabled}
      className={clsx(styles.navButton, parentClass ? parentClass : styles.syncButton, className)}
      id={id}
      variant="text"
      onClick={() => handleButtonClick()}
    >
      <OATIcon icon={iconId} size={14} />
      <span className={textClass ? textClass : styles.syncText}>{buttonText}</span>
    </Button>
  );
};

export default NavButton;
