import { getResidualRateAdjustment } from 'oat-admin-common';
import { PreNglResidualSeries } from '../gql/generated';

export interface PreNglResidualItem {
  modelCode: number;
  modelYear: number;
  description: string;
  rv: number;
  adjustedRv: number;
  mileage: number;
}
const getPreNglResidualItem = (residualSeries: PreNglResidualSeries[], modelCode: number, modelYear: number, term: number, mileage: number, rvDifferential: number) => {
  let residualItem: PreNglResidualItem | undefined;
  const rvDiffPercent = rvDifferential / 100.0;

  residualSeries.forEach(item => {
    if (item.modelCode === modelCode && item.modelYear === modelYear) {
      item.terms.forEach(termItem => {
        if (termItem.term === term) {
          residualItem = {
            modelCode: item.modelCode,
            modelYear: item.modelYear,
            description: item.description,
            rv: termItem.value,
            adjustedRv: +(termItem.value + getResidualRateAdjustment(mileage) + rvDiffPercent).toFixed(2),
            mileage,
          };
        }
      });
    }
  });

  return residualItem;
};

export default getPreNglResidualItem;
