import { assignStringValue, getDateParts } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import useStores from '../../../../../../stores/useStores';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import buildSeriesString from './buildSeriesString';
import styles from './styles.module.scss';

interface Props {
  leaseForm: LeaseFormModel;
}

const VehicleTooltip = ({ leaseForm }: Props) => {
  const { leaseFields } = leaseForm;
  const {
    efcStore,
    userInfoStore: { isLexus },
  } = useStores();
  const [state, setState] = useState({
    vin: '',
    seriesDisplay: '',
    invoiceDate: '',
    dealerCode: '',
    ogDealerCode: '',
    options: [] as string[],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const vinDetails = await efcStore.getEfcVinDetails(leaseFields.vin ?? '', 'Toyota');
        if (vinDetails) {
          let invoiceDate = vinDetails.dealerPricing.invoicedate ?? vinDetails.dealerPricing.invoiceDate;

          // Convert date to MM/DD/YY per OR-2324
          if (invoiceDate) {
            const parts = getDateParts(invoiceDate);
            invoiceDate = `${parts.month}/${parts.day}/${parts.year.substring(2, 4)}`;
          }

          setState({
            vin: vinDetails.vin,
            seriesDisplay: buildSeriesString(isLexus(), vinDetails),
            invoiceDate,
            dealerCode: assignStringValue(vinDetails.currentDealerCode),
            ogDealerCode: vinDetails.wholesaleDealerCodes ? vinDetails.wholesaleDealerCodes[0] : '',
            options: vinDetails.accessories.map(item => `${item.title} (${item.code})`),
          });
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [efcStore, leaseFields, isLexus]);

  return (
    <>
      <div className={styles.section}> {state.seriesDisplay}</div>
      <div className={styles.section}>{leaseFields.vin}</div>

      {loading && <p>Loading...</p>}
      {error && <p>Error loading vehicle details</p>}
      {!loading && !error && (
        <>
          <div className={styles.section}>
            <ul>
              <li>
                <div>Invoice Date</div> <div>{state.invoiceDate}</div>
              </li>
              <li>
                <div>Dealer Code</div> <div>{state.dealerCode}</div>
              </li>
              <li>
                <div>Original Dealer Code</div> <div>{state.ogDealerCode}</div>
              </li>
            </ul>
          </div>
          <div>Options:</div>
          <div className={styles.section}>
            <ul>
              {state.options.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default VehicleTooltip;
