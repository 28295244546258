import { observer } from 'mobx-react-lite';
import { Dropdown, OATIcon, useToast } from 'oat-common-ui';
import useStores from '../../../../stores/useStores';
import useFetchReports from '../useFetchReports';
import styles from './styles.module.scss';

interface Props {
  fiscalYear: string;
  reports: { value: string; label: string }[];
  setIsUpToDate: (val: boolean) => void;
}

const FiscalYear = ({ fiscalYear, reports, setIsUpToDate }: Props) => {
  const {
    reportsStore: { selectedOfferingData, updateFiscalYear, setSelectedReport },
  } = useStores();
  const { error } = useToast();
  const { fetchReports } = useFetchReports();

  const getFYDropdownOptions = () => {
    const currentYear = new Date().getFullYear();
    const options: string[] = [];

    // dropdown list with currentYear + 2 till currentYear -3
    [2, 1, 0, -1, -2, -3].forEach(value => {
      const optionYear = (currentYear + value).toString();
      options.push(optionYear);
    });

    return options;
  };

  const handleYearChange = async (year: string) => {
    updateFiscalYear(year);
    try {
      await fetchReports(+year, selectedOfferingData.value);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleReportClick = (report: { value: string; label: string }) => {
    if (report.value === 'working') {
      setIsUpToDate(true);
    } else {
      setSelectedReport(report);
    }
  };

  return (
    <>
      <Dropdown id="fy-dd" value={fiscalYear} options={getFYDropdownOptions()} onChange={e => handleYearChange(e.value)} darkTheme />
      <ul className={styles.reportsList}>
        {reports.map(report => {
          return (
            <li className={styles.singleReport} key={report.label}>
              <button id={`report-${report}`} onClick={() => handleReportClick(report)}>
                <OATIcon icon="notes" className={styles.reportIcon} />
                <span>{report.label}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default observer(FiscalYear);
