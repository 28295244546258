import { useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useCreateLeaseOfferMutation, useUpdateLeaseOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import LeaseFormModel from '../../models/offers/lease/LeaseFormModel';
import LeaseModel from '../../models/offers/lease/LeaseModel';
import { getCreateLeasePayload, getUpdateLeasePayload } from './payloadUtils';

const useSaveLease = (seriesProfile: SeriesProfileModel, rgnlAlt: RgnlAltModel, lease: LeaseModel, leaseForm: LeaseFormModel) => {
  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
    userInfoStore: { isLexus },
  } = useStores();
  const { offerFields, getError, setRevAndId } = leaseForm;
  const { setDelay } = useInputDelay();
  const [createLease] = useCreateLeaseOfferMutation();
  const [updateLease] = useUpdateLeaseOfferMutation();
  const { error } = useToast();

  const saveLease = (skipDelay = false) => {
    setDelay(
      async () => {
        try {
          if (!getError()) {
            if (!offerFields.id) {
              // Create Lease
              const res = await trackPromise(createLease({ variables: { input: getCreateLeasePayload(leaseForm, rgnlAlt.getSortOrderByOfferUid(lease.uid), isLexus()) } }));
              const resData = res.data?.createLeaseOffer;
              if (resData) {
                const { offer, rgnlAltId, rgnlAltRev } = resData;
                setRevAndId(offer as Offer);

                // due to sort, update rgnlAlt id/rev
                if (rgnlAltId && rgnlAltRev) {
                  rgnlAlt.updateRev(rgnlAltId, rgnlAltRev);
                }
              }
              updateOfferingCostsFromResponse(res.data?.createLeaseOffer.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.createLeaseOffer.ryoOffers as Offer[]);
              if (res.data?.createLeaseOffer.rgnlAltId && res.data?.createLeaseOffer.rgnlAltRev) {
                rgnlAlt.updateRev(res.data?.createLeaseOffer.rgnlAltId, res.data?.createLeaseOffer.rgnlAltRev);
              }

              const newOfferSortOrder = { offerId: res.data?.createLeaseOffer.offer.id || '', sortOrder: rgnlAlt.getSortOrderByOfferUid(lease.uid) };
              rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
            } else {
              // Update Lease
              lease.syncExamples();
              const res = await trackPromise(updateLease({ variables: { input: getUpdateLeasePayload(lease, isLexus()) } }));
              lease.updateRevs(res.data?.updateLeaseOfferBudget.offers as Offer[]);
              updateOfferingCostsFromResponse(res.data?.updateLeaseOfferBudget.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.updateLeaseOfferBudget.ryoOffers as Offer[]);
            }
          }
        } catch (e) {
          error((e as Error).message);
        }
      },
      skipDelay ? 0 : undefined,
    );
  };

  return {
    saveLease,
  };
};

export default useSaveLease;
