import { observer } from 'mobx-react-lite';
import { OATIcon, Tooltip } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import Header from '../../../../FYRollup/components/header/Header';
import MultiSeriesProfileModal from '../../../components/MultiSeriesProfileModal';
import MultiSeriesProfileModel from '../../models/MultiSeriesProfileModel';
import styles from './styles.module.scss';

const MultiSeries = () => {
  const {
    seriesAndModelsStore: { multiSeriesProfiles },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<MultiSeriesProfileModel>();

  return (
    <>
      <Header className={styles.header}>Multi-Series Offers</Header>
      <div className={styles.multiSeriesTable}>
        <ul>
          {multiSeriesProfiles.map(profile => (
            <li key={profile.uid} className={styles.offerRow}>
              <div className={styles.offerName}>{profile.name}</div>
              <div className={styles.icon}>
                <Tooltip id="edit-a" message={!isPublished ? 'Edit' : 'View'} grayTheme place="bottom">
                  <OATIcon
                    icon={!isPublished ? 'edit' : 'eye'}
                    onClick={() => {
                      setSelectedProfile(profile);
                      setIsModalOpen(true);
                    }}
                  />
                </Tooltip>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {isModalOpen && selectedProfile && <MultiSeriesProfileModal onClose={() => setIsModalOpen(false)} selectedProfile={selectedProfile} />}
    </>
  );
};

export default observer(MultiSeries);
