import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  boxShadow?: boolean;
  isNationalPrograms?: boolean;
  name?: string;
  rightContent?: ReactNode;
  title: string;
}

const NGCHeader = ({ title, name, rightContent, isNationalPrograms, boxShadow }: Props) => {
  const headerText = (
    <div className={styles.ngcHeader}>
      <div className={clsx(rightContent && styles.leftContent)}>
        <span className={styles.boldText}>{title}</span>
        <span className={styles.name}>{name}</span>
      </div>
      {rightContent && <div>{rightContent}</div>}
    </div>
  );

  return <div className={clsx(styles.ngcTitle, isNationalPrograms && styles.nationalProgramsHeader, boxShadow && styles.boxShadow)}>{headerText}</div>;
};

export default NGCHeader;
