import SeriesListModel from '../../models/SeriesListModel';
import styles from '../../styles.module.scss';

interface Props {
  seriesItem: SeriesListModel;
}

const GridItemSeriesName = ({ seriesItem }: Props) => {
  return (
    <div className={styles.fySeriesBoxHeader} onClick={() => seriesItem.toggleShowDetails()}>
      {seriesItem.displayName}
    </div>
  );
};

export default GridItemSeriesName;
