import clsx from 'clsx';
import { useState } from 'react';
import { LeaseTier } from '../../../../models/offers/OfferFields';
import CreditTiersTable from '../CreditTiersTable';
import styles from '../styles.module.scss';

interface Props {
  tier: string;
  disabled?: boolean;
  tiers: LeaseTier[];
  updateTier: (T: string) => void;
}

const tierOptions = ['1+', '1', '2', '3', '4', '5', '6', '7', '7+'];

const CreditTiers = ({ tier, tiers, updateTier, disabled = false }: Props) => {
  const initTierIndex = tierOptions.indexOf(tier);
  const [selectedTier, setSelectedTier] = useState(initTierIndex);

  const handleTierChange = (tier: number) => {
    setSelectedTier(tier);
    const tierValue = tierOptions[tier];
    tierValue && updateTier(tierValue);
  };

  return (
    <div>
      <div className={styles.tierList}>
        <label className={styles.tierListTitle}>Credit Tiers</label>
        {tierOptions.map((tier, index) => (
          <span
            className={clsx(styles.tierSelection, index <= selectedTier && styles.tierSelectionActive)}
            key={tier}
            onClick={() => {
              !disabled && handleTierChange(index);
            }}
          >
            {tier}
          </span>
        ))}
      </div>
      <CreditTiersTable tier={selectedTier} tiers={tiers} />
    </div>
  );
};

export default CreditTiers;
