import { DropdownItem } from 'oat-common-ui';

export enum SystemToPay {
  TFS = 'TFS',
  NISS = 'NISS',
  FISS = 'FISS',
  IPLUS = 'iPlus',
  MANUAL = 'Manual',
  ICAT = 'ICAT',
  NA = 'NA',
}

export const getSystemToPayDropdownItems = () => {
  const newItems: DropdownItem[] = [];
  Object.values(SystemToPay).forEach(value => newItems.push({ label: value.toString(), value: value.toString() }));

  return newItems;
};
