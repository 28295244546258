import { OATLoadingBar } from 'oat-common-ui';
import { useGetOfferingByIdQuery, useGetOfferingStandardRatesQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import RatesTabComponent from './RatesTabComponent';

const RatesTab = () => {
  const {
    ratesTabAPRRatesStore,
    ratesTabLeaseRCFStore,
    ratesTabTFSLeaseRCFStore,
    ratesTabRVRateStore,
    userInfoStore: { isLexus },
    ipSettingsStore: { offering, setOffering },
  } = useStores();
  const { offeringId } = useUrlParams();

  const { data, loading, error } = useGetOfferingStandardRatesQuery({
    variables: {
      offeringId,
    },
  });

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useGetOfferingByIdQuery({
    variables: {
      id: offeringId,
    },
    skip: !!offering.id,
  });

  if (loading || offeringLoading) {
    return <OATLoadingBar />;
  }

  if (error || offeringError) {
    return <Error />;
  }

  if (data) {
    ratesTabRVRateStore.setData(data.getOfferingStandardRates);
    ratesTabAPRRatesStore.setData(data.getOfferingStandardRates);
    ratesTabLeaseRCFStore.setData(data.getOfferingStandardRates);
    ratesTabTFSLeaseRCFStore.setData(data.getOfferingStandardRates, isLexus());
  }

  if (offeringData) {
    setOffering(offeringData.getOffering);
  }

  return <RatesTabComponent />;
};

export default RatesTab;
