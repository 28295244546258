import { makeAutoObservable } from 'mobx';
import { AdminConstants, getMileages, LeaseCalcFns, LeaseLocks } from 'oat-admin-common';
import { assignNumberValue, uuidv4, validator } from 'oat-common-ui';
import { LeaseDetails, Maybe, Offer, OfferingRate, PreNglResidualSeries } from '../../../../../gql/generated';
import getPreNglResidualItem from '../../../../../utils/getPreNglResidualItem';
import { VinValues } from '../../../offers/LeaseSection/components/NewVinModal';
import toDDList from '../../../offers/LeaseSection/toDDList';
import getDefaultTfsShareLease from '../../../utils/getDefaultTfsShareLease';
import { OfferFields, OfferFieldType } from '../OfferFields';
import LeaseFields from './LeaseFields';
import LeaseModel from './LeaseModel';

const { BRAND_LEXUS, LEXUS_AC_FEE, TOYOTA_AC_FEE, OPTION_TYPE_NAMES } = AdminConstants;

class LeaseFormModel {
  uid = uuidv4();
  lock = LeaseLocks.TARGET_PAYMENT;
  isExample = false;
  isSupra = false;
  brand = '';
  optionType = OPTION_TYPE_NAMES.LEASE;
  dealerGrossForZeroDueAtSigning = 0;
  ncsRcf = 0;
  leaseExampleNames: string[] = [];
  selectedCostShare = '';
  offerFields: OfferFields = {
    id: '',
    masterOfferId: '',
    isExample: false,
    isForRegions: true,
    isEnhTfsCostShareForRegions: true,
    isEnhSubCashTfsCostShareForRegions: true,
    isIncludedInCosts: true, // note: examples will be false
    rev: '',
    rgnlAltId: '',
    penetrationRate: '',
    created: '',
    optionType: OPTION_TYPE_NAMES.LEASE as string,
    name: OPTION_TYPE_NAMES.LEASE as string,
  };
  leaseFields: LeaseFields = {
    acquisitionFee: 650,
    adjustmentFactor: 1.1,
    baseMsrp: 0,
    dealerCostPerInvoice: 0,
    downPayment: 2000,
    dueAtSigning: '',
    financialReserve: 0,
    grossCapCost: 0,
    holdback: 0,
    isNcsApplied: false,
    mileage: 12000,
    ncsTfsPnvs: 0,
    ncsEnhancedTfsPnvs: 0,
    netCapCost: 0,
    nglResidualAmount: 0,
    nglResidualRate: 0,
    offerEnhancedTfsPnvs: 0,
    offerPnvs: 0,
    offerTfsPnvs: 0,
    dealerGross: 0,
    tfsShare: 0,
    rcf: '',
    rcfPnvs: 0,
    rcfTfsPnvs: 0,
    rcfEnhancedTfsPnvs: 0,
    residualAmount: 0,
    residualRate: '',
    rvSupport: 0,
    rvSupportPenRate: 0,
    rvSupportCostShare: 0,
    rvSupportPnvs: 0,
    rvSupportTfsPnvs: 0,
    rvSupportAdjustmentFactor: 1,
    standardRcf: 0.00001,
    initalStandardRcf: 0.00001,
    subCashAmount: '',
    subCashEnhancedTfsPnvs: 0,
    subCashPnvs: 0,
    subCashTfsPnvs: 0,
    series: '',
    seriesYear: 0,
    subCashCostShare: 0,
    subCashCostShareCap: 0,
    targetPayment: '',
    term: 36,
    tier: '1+',
    totalDealerGross: 0,
    totalMsrp: 0,
    costShareId: undefined,
    isCostShareUpdated: false,
  };

  constructor(brand: string, isSupra: boolean, ncsRcf: number) {
    makeAutoObservable(this);
    this.brand = brand;
    this.isSupra = isSupra;
    this.ncsRcf = ncsRcf;

    this.leaseFields.acquisitionFee = this.brand === BRAND_LEXUS ? LEXUS_AC_FEE : TOYOTA_AC_FEE;
    this.leaseFields.mileage = this.brand === BRAND_LEXUS ? 10000 : 12000;
  }

  setExampleNames = (leaseExamples: LeaseFormModel[]) => {
    this.leaseExampleNames = leaseExamples.map(leaseItem => {
      return leaseItem.offerFields.name;
    });
  };

  setData = (offer: Pick<Offer, OfferFieldType>, leaseData?: LeaseDetails) => {
    this.offerFields = {
      ...offer,
      isEnhSubCashTfsCostShareForRegions: leaseData?.isEnhSubCashTfsCostShareForRegions,
      isEnhTfsCostShareForRegions: leaseData?.isEnhTfsCostShareForRegions,
    };

    if (leaseData) {
      this.leaseFields = {
        ...leaseData,
        initalStandardRcf: leaseData.standardRcf,
      };
      this.isExample = Boolean(offer.isExample);
    }
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;
  };

  setRevAndId = (offer?: Offer) => {
    if (offer) {
      this.offerFields.id = offer.id;
      this.offerFields.rev = offer.rev;
      this.offerFields.created = offer.created;
    }
  };

  updateNewVin = (vinVals: VinValues) => {
    this.leaseFields.vin = vinVals.vin;
    this.leaseFields.modelCode = vinVals.modelCode;
    this.leaseFields.seriesYear = vinVals.seriesYear;
    this.leaseFields.dealerCostPerInvoice = vinVals.dealerCostPerInvoice;
    this.leaseFields.totalMsrp = vinVals.totalMsrp;
    this.leaseFields.baseMsrp = vinVals.baseMsrp;
    this.leaseFields.financialReserve = vinVals.financeReserve;
    this.leaseFields.holdback = vinVals.holdback;
    this.leaseFields.residualRate = vinVals.residualRate;
    this.leaseFields.configuration = vinVals.configuration;
    this.leaseFields.vehicleDescription = vinVals.vehicleDescription;
    this.lock = LeaseLocks.TARGET_PAYMENT;
    this.recalculate();
  };

  setLock = (lock: LeaseLocks) => {
    this.lock = lock;
  };

  updateCostShareId = (id: Maybe<string>, value: number, standardRates: OfferingRate[]) => {
    const standardRate = standardRates.find(std => std.tier === this.leaseFields.tier)?.rate || 0;
    const rcf = standardRate - value;

    this.leaseFields.costShareId = id;
    this.leaseFields.isCostShareUpdated = false;
    this.leaseFields.standardRcf = rcf;

    this.recalculate();
  };

  updateLeaseFields = <T extends keyof LeaseFields, V extends LeaseFields[T]>(field: T, value: V, lock?: LeaseLocks) => {
    this.leaseFields[field] = value;

    if (lock) {
      this.lock = lock;
    }

    this.recalculate();
  };

  updateMileage = (mileage: number, residualValues: PreNglResidualSeries[], rvDifferential: number) => {
    this.lock = LeaseLocks.TARGET_PAYMENT;
    this.leaseFields.mileage = mileage;

    this.updateResidualFromList(residualValues, rvDifferential);
    this.recalculate();
  };

  updateTerm = (lease: LeaseModel, term: number, residualValues: PreNglResidualSeries[], rvDifferential: number) => {
    this.lock = LeaseLocks.TARGET_PAYMENT;
    this.leaseFields.term = term;

    // Lexus share tfs logic of toyota according to OR-2345
    this.leaseFields.tfsShare = getDefaultTfsShareLease(term);

    const master = lease.getMaster();

    // determine isIncludedInCosts flag
    // if changing example, check against master
    // if changing master, loop through examples set the flag
    if (this.isExample) {
      this.offerFields.isIncludedInCosts = master.leaseFields.term.toString() !== this.leaseFields.term.toString();
    } else {
      lease.forms.forEach(form => {
        if (form.isExample) {
          form.offerFields.isIncludedInCosts = master.leaseFields.term.toString() !== form.leaseFields.term.toString();
        }
      });
    }

    // get new set of mileages
    // if current mileage is not included in set,
    // use first mileage in set
    const mileages = getMileages(this.brand, assignNumberValue(this.leaseFields.term), '', this.isSupra);
    if (!mileages.includes(this.leaseFields.mileage)) {
      this.leaseFields.mileage = Number(toDDList(mileages)[0].value);
    }
    this.updateResidualFromList(residualValues, rvDifferential);
    this.recalculate();
  };

  updateResidualFromList = (residualValues: PreNglResidualSeries[], rvDifferential: number) => {
    const residualItem = getPreNglResidualItem(
      residualValues,
      assignNumberValue(this.leaseFields.modelCode),
      assignNumberValue(this.leaseFields.seriesYear),
      +this.leaseFields.term,
      this.leaseFields.mileage,
      rvDifferential,
    );
    if (residualItem) {
      this.leaseFields.residualRate = Math.round(residualItem.adjustedRv * 100);
    }
  };

  applyStandardRcf = () => {
    this.leaseFields.rcf = this.leaseFields.standardRcf;
    this.recalculate();
  };

  /**
   *
   * @param skipSetFields is true when initialzing lease card to get initial flags (isAcfeeCapitalized, etc...)
   */
  recalculate = (skipSetFields = false) => {
    // prepare values for calculation
    const params = LeaseCalcFns.getNationalBaseValues(
      this.leaseFields.acquisitionFee,
      assignNumberValue(this.leaseFields.adjustmentFactor),
      Math.round(LeaseCalcFns.calculateDAP(this.leaseFields.baseMsrp)),
      assignNumberValue(this.leaseFields.dealerCostPerInvoice),
      assignNumberValue(this.leaseFields.dealerGross),
      this.dealerGrossForZeroDueAtSigning,
      // Lexus share tfs logic of toyota according to OR-2345
      getDefaultTfsShareLease(Number(this.leaseFields.term)) / 100.0,
      assignNumberValue(this.leaseFields.dueAtSigning),
      assignNumberValue(this.leaseFields.financialReserve),
      assignNumberValue(this.leaseFields.holdback),
      assignNumberValue(this.leaseFields.standardRcf),
      assignNumberValue(this.leaseFields.rcf),
      this.ncsRcf,
      assignNumberValue(this.leaseFields.residualRate),
      assignNumberValue(this.leaseFields.rvSupport),
      assignNumberValue(this.leaseFields.rvSupportCostShare),
      assignNumberValue(this.leaseFields.rvSupportAdjustmentFactor),
      assignNumberValue(this.leaseFields.subCashAmount),
      assignNumberValue(this.leaseFields.subCashCostShareCap),
      assignNumberValue(this.leaseFields.subCashCostShare),
      assignNumberValue(this.leaseFields.targetPayment),
      assignNumberValue(this.leaseFields.term),
      assignNumberValue(this.leaseFields.tfsShare) / 100.0,
      assignNumberValue(this.leaseFields.totalMsrp),
    );

    // get calculated values
    const { dealerGrossForZeroDueAtSigning, ...newValues } = LeaseCalcFns.calculateNationalLease(params, this.lock);

    // set values and flags
    this.dealerGrossForZeroDueAtSigning = dealerGrossForZeroDueAtSigning;

    if (!skipSetFields) {
      // persist locked values form. If user left input empty, it should remain empty
      const lockedValues = {
        rcf: this.lock === LeaseLocks.RCF ? newValues.rcf : this.leaseFields.rcf,
        targetPayment: this.lock === LeaseLocks.TARGET_PAYMENT ? newValues.targetPayment : this.leaseFields.targetPayment,
        dueAtSigning: this.lock === LeaseLocks.DUE_AT_SIGNING ? newValues.dueAtSigning : this.leaseFields.dueAtSigning,
        subCashAmount: this.lock === LeaseLocks.SUBVENTION_CASH ? newValues.subCashAmount : this.leaseFields.subCashAmount,
        dealerGross: this.lock === LeaseLocks.DEALER_GROSS ? newValues.dealerGross : this.leaseFields.dealerGross,
      };

      // totalDealerGross
      // keep user input value if no vin is selected
      if (!this.leaseFields.vin) {
        newValues.totalDealerGross = assignNumberValue(this.leaseFields.totalDealerGross);
      }

      // map calculated values back into the fields
      this.leaseFields = {
        ...this.leaseFields,
        ...newValues,
        ...lockedValues,
      };
    }
  };

  updateSelectedCostShare = (costShare: string) => {
    this.selectedCostShare = costShare;
  };

  getError = () => {
    return (
      this.nameError ||
      this.penRateError ||
      this.tpError ||
      this.rcfError ||
      this.residualRateError ||
      this.dasError ||
      this.dgError ||
      this.subCashError ||
      this.adjFactorError ||
      this.tfsShareError ||
      this.subCashCostShareError ||
      this.subCashCostShareCapError ||
      this.rvSupportError ||
      this.rvSupportPenRateError ||
      this.rvSupportCostShareError ||
      this.rvSupportAdjFactorError ||
      this.leaseExampleNameError
    );
  };

  get isTfsCostShareEnhanced() {
    return getDefaultTfsShareLease(Number(this.leaseFields.term)) < Number(this.leaseFields.tfsShare);
  }

  get isTfsSubCashCostShareEnhanced() {
    return Number(this.leaseFields.subCashCostShare) > 0;
  }

  get nameError() {
    return !!validator(this.offerFields.name, { required: true });
  }

  get penRateError() {
    return !!validator(this.offerFields.penetrationRate, { required: true, min: 0, max: 100 });
  }

  get mileages() {
    return toDDList(getMileages(this.brand, assignNumberValue(this.leaseFields.term), '', this.isSupra));
  }

  get tpError() {
    return !!validator(this.leaseFields.targetPayment, { required: true, min: 0 });
  }

  get rcfError() {
    return !!validator(this.leaseFields.rcf, { required: true, min: 0.00001 }) || (this.offerFields.isForRegions && !(this.brand === BRAND_LEXUS))
      ? Number(this.leaseFields.rcf) >= this.leaseFields.standardRcf
      : Number(this.leaseFields.rcf) > this.leaseFields.standardRcf;
  }

  get residualRateError() {
    return !!validator(this.leaseFields.residualRate, { required: true, min: 0 });
  }

  get dasError() {
    return !!validator(this.leaseFields.dueAtSigning, { required: true, min: 0 });
  }

  get dgError() {
    return !!validator(this.leaseFields.dealerGross, { required: true, min: 0 });
  }

  get subCashError() {
    return !!validator(this.leaseFields.subCashAmount, { required: true, min: 0 });
  }

  get adjFactorError() {
    return !!validator(this.leaseFields.adjustmentFactor, { required: true, min: 0 });
  }

  get tfsShareError() {
    return !!validator(this.leaseFields.tfsShare, { required: true, min: 0, max: 100 });
  }

  get subCashCostShareError() {
    return !!validator(this.leaseFields.subCashCostShare, { required: true, min: 0, max: 100 });
  }

  get subCashCostShareCapError() {
    return !!validator(this.leaseFields.subCashCostShareCap, { required: true, min: 0 });
  }

  get rvSupportError() {
    return !!validator(this.leaseFields.rvSupport, { required: true, min: 0 });
  }

  get rvSupportPenRateError() {
    return !!validator(this.leaseFields.rvSupportPenRate, { required: true, min: 0 });
  }

  get rvSupportCostShareError() {
    return !!validator(this.leaseFields.rvSupportCostShare, { required: true, min: 0, max: 100 });
  }

  get rvSupportAdjFactorError() {
    return !!validator(this.leaseFields.rvSupportAdjustmentFactor, { required: true, min: 0.001 });
  }

  get isNgl() {
    return Number(this.leaseFields.rvSupport) > 0;
  }

  get leaseExampleNameError() {
    return this.leaseExampleNames.includes(this.offerFields.name);
  }
}

export default LeaseFormModel;
