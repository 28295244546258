import { AdminModels } from 'oat-admin-common';
import styles from '../../styles.module.scss';
import GridItemTotalDetail from './GridItemTotalDetail';
import GridItemTotalName from './GridItemTotalName';
import TotalDetails from './TotalDetails';

interface Props {
  fiscalYear: number;
  fiscalMonths: AdminModels.FiscalMonth[];
  fyMonthTotals: { [month: number]: AdminModels.FyMonthTotal };
  fyTotals: AdminModels.Total;
}

const CalculatedTotal = ({ fiscalYear, fiscalMonths, fyMonthTotals, fyTotals }: Props) => {
  return (
    <div className={styles.fyTotals}>
      <div className={styles.fyGridWrapper}>
        <GridItemTotalName />
        <GridItemTotalDetail fiscalMonths={fiscalMonths} fyMonthTotals={fyMonthTotals} />
        <div className={styles.fySeriesBox}>
          <div className={styles.paddingTop}>
            <TotalDetails year={fiscalYear} sales={fyTotals.sales} pnvs={fyTotals.pnvs} totalCost={fyTotals.totalCost} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatedTotal;
