import { ExportScenariosInput } from '../../../../../../../gql/generated';
import ScenarioModel from '../../../../../models/ScenarioModel';
import SeriesProfileModel from '../../../../../models/SeriesProfileModel';

export const createExportScenarioInput = (offeringId: string, scenarios: ScenarioModel[], seriesProfiles: SeriesProfileModel[]): ExportScenariosInput => {
  const scenariosId = scenarios.filter(s => s.exportSelected).map(s => ({ id: s.id, rev: s.rev }));
  const seriesProfilesId = seriesProfiles.filter(s => s.exportTo).map(s => ({ id: s.id, rev: s.data.rev }));

  return {
    offeringId,
    scenariosId,
    seriesProfilesId,
  };
};
