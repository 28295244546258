import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/ErrorPage';
import Approved from './pages/FYRollup/Approved';
import Assumptions from './pages/FYRollup/Assumptions';
import Bosu from './pages/FYRollup/Bosu';
import Forecasted from './pages/FYRollup/Forecasted';
import MultiSeriesOffers from './pages/FYRollup/MultiSeriesOffers';
import Working from './pages/FYRollup/Working';
import InitialRoute from './pages/InitialRoute';
import CostShareTab from './pages/IpSettings/CostShareTab';
import GeneralTab from './pages/IpSettings/GeneralTab';
import RatesTab from './pages/IpSettings/RatesTab';
import SeriesModelsTab from './pages/IpSettings/SeriesModelsTab';
import UploadsTab from './pages/IpSettings/UploadsTab';
import NationalPrograms from './pages/NationalPrograms';
import NationalPlanning from './pages/Reports/NationalPlanning';
import IncentiveCalendar from './pages/Settings/IncentiveCalendar';
import PersonalSettings from './pages/Settings/PersonalSettings';
import PNVSThresholdSettings from './pages/Settings/PNVSThresholdSettings';
import SeriesMapping from './pages/Settings/SeriesMapping';
import SeriesSortingSettings from './pages/Settings/SeriesSortingSettings';
import StateAprCapsSettings from './pages/Settings/StateAprCapsSettings';

const Routes = () => {
  return (
    <Switch>
      {/* Added duplicate Dashboard to load all data for redirect to work */}
      <PrivateRoute exact path="/">
        <InitialRoute />
      </PrivateRoute>
      <PrivateRoute exact path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/nationalPrograms/:offeringId">
        <NationalPrograms />
      </PrivateRoute>
      <PrivateRoute exact path="/ipSettings/general/:offeringId">
        <GeneralTab />
      </PrivateRoute>
      <PrivateRoute exact path="/ipSettings/rates/:offeringId">
        <RatesTab />
      </PrivateRoute>
      <PrivateRoute exact path="/ipSettings/uploads/:offeringId">
        <UploadsTab />
      </PrivateRoute>
      <PrivateRoute exact path="/ipSettings/costShare/:offeringId">
        <CostShareTab />
      </PrivateRoute>
      <PrivateRoute exact path="/ipSettings/seriesModels/:offeringId">
        <SeriesModelsTab />
      </PrivateRoute>
      <PrivateRoute exact path="/reports">
        <NationalPlanning />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/personal">
        <PersonalSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/pnvsThreshold">
        <PNVSThresholdSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/seriesSorting">
        <SeriesSortingSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/multiSeriesOffers">
        <MultiSeriesOffers />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/stateAprCaps">
        <StateAprCapsSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/incentiveCalendar">
        <IncentiveCalendar />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/approved">
        <Approved />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/forecasted">
        <Forecasted />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/working">
        <Working />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/bosu">
        <Bosu />
      </PrivateRoute>
      <PrivateRoute exact path="/ngc/assumptions">
        <Assumptions />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/seriesMapping">
        <SeriesMapping />
      </PrivateRoute>
      <Route exact path="/error">
        <ErrorPage />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
