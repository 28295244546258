import { observer } from 'mobx-react-lite';
import InlineCheckBox from '../../../../../../../components/InlineCheckBox';
import ScenarioModel from '../../../../../models/ScenarioModel';
import styles from './styles.module.scss';

interface Props {
  scenarios: ScenarioModel[];
}

const SelectSection = ({ scenarios }: Props) => {
  return (
    <div className={styles.select}>
      <span className={styles.header}>Select Scenario(s)</span>
      <div className={styles.heightDivider} />
      <ul>
        {scenarios.map(s => {
          return (
            <li key={s.id}>
              <InlineCheckBox text={s.name} checked={s.exportSelected} handleChange={() => s.toggleExportSelected()} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default observer(SelectSection);
