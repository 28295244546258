import { observer } from 'mobx-react-lite';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Button from '../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  seriesName: string;
  displayName: string;
  submitText: string;
  onSubmit: () => void;
  onClose: () => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const SeriesNameModal = ({ seriesName, displayName, submitText, onClose, onSubmit, onBlur }: Props) => {
  const handleUpdate = () => {
    onSubmit();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader className={styles.header} onClose={onClose}>
        {'Series Name'}
      </ModalHeader>
      <ModalBody>
        <div className={styles.inputWrapper}>
          <div>
            <span className={styles.label}>Series Name:</span>
            <Input className={styles.input} value={seriesName} readOnly></Input>
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div>
            <span className={styles.label}>Display name:</span>
            <Input className={styles.input} displayValue={displayName} onBlur={onBlur}></Input>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button id={`default-modal-submit-${seriesName}`} variant="blue" onClick={handleUpdate}>
          {submitText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(SeriesNameModal);
