import { AdminConstants } from 'oat-admin-common';
import { defaultProperties, lexusProperties, toyotaProperties } from '../constants/brandProperties';
import { Brand } from '../constants/global';
import { User } from '../gql/generated';

const { TdaCodes } = AdminConstants;

class UserInfoStore {
  isLoaded = false;
  properties = defaultProperties;
  userInfo: User = {
    azureGroups: '',
    brand: '',
    email: '',
    name: '',
    objectId: '',
    regionCodes: '',
    roles: '',
    tdaCodes: '',
    userTypes: '',
  };

  isLexus = () => {
    return this.userInfo.brand === Brand.LEXUS;
  };

  setUserInfo = (userInfo: User, isLoaded: boolean) => {
    this.userInfo = userInfo;
    this.isLoaded = isLoaded;

    if (userInfo.brand === Brand.TOYOTA) {
      this.properties = toyotaProperties;
    }
    if (userInfo.brand === Brand.LEXUS) {
      this.properties = lexusProperties;
    }
  };

  isSETUser = (userInfo: User) => {
    return userInfo.userTypes.includes('TDA') && userInfo.userTypes.includes('Regional') && userInfo.tdaCodes.includes(TdaCodes.SET) && userInfo.regionCodes.includes('500');
  };

  isGst = (userInfo: User) => {
    return userInfo.userTypes.includes('TDA') && userInfo.tdaCodes.includes(TdaCodes.GST);
  };

  isSSNY = (tdaCode: string): boolean => {
    const ssnyCodes = [TdaCodes.PTL, TdaCodes.INW, TdaCodes.DVR, TdaCodes.CON, TdaCodes.GNY, TdaCodes.TRE, TdaCodes.UST, TdaCodes.CHI, TdaCodes.KAC, TdaCodes.TRI] as string[];
    return ssnyCodes.includes(tdaCode);
  };
}
export default UserInfoStore;
