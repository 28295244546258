import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../../../../../components/Button';
import { defaultTiersTerms } from '../../../../../../constants/global';
import { useUpdateAprOfferSetupMutation } from '../../../../../../gql/generated';
import AprSetupModel from '../../../../models/offersSetup/AprSetupModel';
import getSortedTiers from '../../../../utils/getSortedTiers';
import DeleteRow from './components/DeleteRow';
import TermsRow from './components/TermsRow';
import TiersRow from './components/TiersRow';
import { createAprSetupInput } from './utils/createAprSetupInput';
import { getTermRowData } from './utils/getTermRowData';

import styles from './styles.module.scss';

interface Props {
  aprSetupDetails: AprSetupModel;
  isDisabled?: boolean;
}

const RatesTable = ({ aprSetupDetails, isDisabled = false }: Props) => {
  const { terms } = defaultTiersTerms;
  const { id, rev, tierTerms, selectedTierTerms, subCashAmount, subventionCashSystemToPay, deleteRatesByTerm, deleteRatesByTier, updateRev } = aprSetupDetails;

  const tierTermsToShow = getSortedTiers(tierTerms);

  const { error } = useToast();
  const [updateAprOfferSetup] = useUpdateAprOfferSetupMutation();

  const handleSaveRates = async () => {
    const input = createAprSetupInput(id, rev, selectedTierTerms, subCashAmount > 0 ? subventionCashSystemToPay : '');

    try {
      const res = await trackPromise(updateAprOfferSetup({ variables: { input } }));
      updateRev(res.data?.updateAprOfferSetup?.offer?.rev || '');
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <div className={styles.ratesTable}>
      <table className={styles.table}>
        <tbody>
          <TiersRow />
          {terms.map(term => {
            const termRowData = getTermRowData(term, tierTermsToShow);
            return (
              <TermsRow
                disabled={isDisabled} //
                handleDelete={() => deleteRatesByTerm(term)}
                key={`${term}-${termRowData[0].uid}`}
                term={`${term} mo`}
                tierTerms={termRowData}
                selectedTierTerms={selectedTierTerms}
              />
            );
          })}
          <DeleteRow handleDeleteByTier={deleteRatesByTier} disabled={isDisabled} />
        </tbody>
      </table>

      <Button disabled={isDisabled} variant="blue" id={aprSetupDetails.id} onClick={handleSaveRates} className={styles.saveRatesBtn}>
        Save Rates
      </Button>
    </div>
  );
};

export default observer(RatesTable);
