import { observer } from 'mobx-react-lite';
import { DefaultTable, OfferingsLayout, sortFieldsHelper } from 'oat-common-ui';
import { useState } from 'react';
import Button from '../../components/Button';
import MainFooter from '../../components/MainFooter';
import NGCHeader from '../../components/NGCHeader';
import { Offering } from '../../gql/generated';
import useStores from '../../stores/useStores';
import WarningModal from '../FYRollup/components/header/Navbar/components/WarningModal';
import CreateProgramModal from './CreateProgramModal';
import DashboardRow from './DashboardRow';
import styles from './styles.module.scss';

const DashboardComponent = () => {
  const { dashboardStore } = useStores();

  const [sortAscActive, setSortAscActive] = useState(true);
  const [sortAscInactive, setSortAscInactive] = useState(false);
  const [sortFieldActive, setSortFieldActive] = useState('endDate');
  const [sortFieldInactive, setSortFieldInactive] = useState('endDate');

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const columns = [
    {
      header: '',
      sortable: false,
      field: 'name',
    },
    {
      header: 'Status',
      field: 'status',
    },
    {
      header: 'Start Date',
      sortable: true,
      field: 'startDate',
      descendingFirst: true,
    },
    {
      header: 'End Date',
      sortable: true,
      field: 'endDate',
      descendingFirst: true,
    },
    {
      header: '',
      field: 'atcPublishedDate',
      width: 350,
    },
    {
      header: '',
      field: 'actions',
      width: 100,
    },
  ];

  const renderRow = (offerings: Offering[]) => {
    return (
      <>
        {offerings.map(item => {
          return <DashboardRow key={item.id} item={item} />;
        })}
      </>
    );
  };

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };

  const onSortInactive = (field: string, asc: boolean) => {
    setSortFieldInactive(field);
    setSortAscInactive(asc);
  };

  const handleCloseCreateModal = () => {
    if (dashboardStore.hasUnsavedChanges) {
      setShowWarningModal(true);
    } else {
      setShowCreateModal(false);
    }
  };

  // ATC Offering has id as id-atc so sort(sortFieldsHelper('id', true)) will ensure that ATC is always after his parent Offering
  const activeOfferings = dashboardStore.active.slice().sort(sortFieldsHelper('id', true)).sort(sortFieldsHelper(sortFieldActive, sortAscActive));
  const inactiveOfferings = dashboardStore.inactive.slice().sort(sortFieldsHelper(sortFieldInactive, sortAscInactive));

  return (
    <>
      <NGCHeader title="National Programs" />
      <OfferingsLayout
        renderHeader={null}
        renderActiveTable={
          <div className={styles.activeTableWrapper}>
            <DefaultTable
              id="activeTbl"
              title="Active National Programs"
              columns={columns}
              renderRows={renderRow(activeOfferings)}
              onSort={onSortActive}
              defaultSortField={sortFieldActive}
              defaultSortAscending={sortAscActive}
              className={styles.nationalTable}
              isNational
            />
            <Button id="create-national-program" className={styles.createProgramCta} variant="blue" onClick={() => setShowCreateModal(true)}>
              Create National Program
            </Button>
          </div>
        }
        renderArchiveTable={
          <DefaultTable
            id="inActiveTbl"
            title="Previous National Programs"
            collapsable
            columns={columns}
            renderRows={renderRow(inactiveOfferings)}
            onSort={onSortInactive}
            defaultSortField={sortFieldInactive}
            defaultSortAscending={sortAscInactive}
            className={styles.nationalTable}
            isNational
          />
        }
      >
        {showCreateModal && <CreateProgramModal onClose={handleCloseCreateModal} />}
        {showWarningModal && (
          <WarningModal
            id="create-program-id"
            onClose={() => {
              setShowWarningModal(false);
            }}
            onSubmit={() => {
              setShowWarningModal(false);
              setShowCreateModal(false);

              dashboardStore.setUnsavedChanges(false);
            }}
          />
        )}
        <MainFooter />
      </OfferingsLayout>
    </>
  );
};

export default observer(DashboardComponent);
