import { observer } from 'mobx-react-lite';
import { assignStringValue, Checkbox, CheckListItem, Tooltip } from 'oat-common-ui';
import useStores from '../../../../../../stores/useStores';
import NationalRyoModel, { OfferUidSelection } from '../../../../models/offers/NationalRyoModel';
import RgnlAltModel from '../../../../models/RgnlAltModel';
import { getFormattedLabel } from '../../../../utils/getFormattedLabel';
import { buildOfferSelectionsChecklist } from '../../utils';

import SeriesProfileModel from '../../../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  nationalRyo: NationalRyoModel;
  onSave: () => void;
  rgnlAlts: RgnlAltModel[];
  seriesProfile: SeriesProfileModel;
}

const IncludedSection = ({ nationalRyo, onSave, rgnlAlts, seriesProfile }: Props) => {
  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();

  // list of available checkboxes
  const list = buildOfferSelectionsChecklist(rgnlAlts);

  // selected checkboxes. selected is based if the UIDs in this list will match the list above.
  // ex:
  // list [ uid: 1, uid: 2]
  // offerSelections [uid: 2]
  // offer with uid 2 will be selected
  const offerSelections = nationalRyo.offerCostFields.offerSelections;

  const handleSave = (selections: OfferUidSelection[]) => {
    nationalRyo.updateOfferCostFields('offerSelections', selections);
    onSave();
  };

  const handleRaOnCheck = (selected: CheckListItem) => {
    let selections: OfferUidSelection[] = [...offerSelections];

    for (const ra of list) {
      if (ra.id === selected.id) {
        const foundRa = rgnlAlts.find(rgnlAlt => rgnlAlt.data.id === ra.id);
        const hasSelections = selections.filter(selection => ra.id === selection.rgnlAltId);

        if (hasSelections.length > 0) {
          selections = selections.filter(os => os.rgnlAltId !== ra.id && !os.isNgl);
        } else {
          for (const offer of ra.items) {
            if (offer.id && offer.name !== 'NGL') {
              selections.push({
                isNgl: false,
                offerId: offer.id,
                rgnlAltId: assignStringValue(foundRa?.data.id),
                uid: offer.uid,
              });
            }
          }
        }
      }
    }

    handleSave(selections);
  };

  const handleOfferOnCheck = (rgnlAltId: string, selected: CheckListItem) => {
    let selections: OfferUidSelection[] = [...offerSelections];
    const foundRa = rgnlAlts.find(ra => ra.data.id === rgnlAltId);

    switch (true) {
      // NGL chbox of specific offer is unchecked, check it
      case selected.name === 'NGL' && !selections.find(os => os.uid === selected.uid && os.isNgl):
        selections.push({
          isNgl: true,
          offerId: selected.id,
          rgnlAltId: assignStringValue(foundRa?.data.id),
          uid: selected.uid,
        });
        break;

      // NGL chbox of specific offer is checked, uncheck it
      case !!(selected.name === 'NGL' && selections.find(os => os.uid === selected.uid && os.isNgl)):
        selections = selections.filter(os => !os.isNgl);
        break;

      // non-NGL is checked, uncheck it
      case !!selections.find(os => os.uid === selected.uid && !os.isNgl):
        selections = selections.filter(os => os.uid !== selected.uid);
        break;
      // else, check it
      case selections.some(os => os.offerId !== selected.id):
      case !selections.length:
      default:
        selections.push({
          isNgl: false,
          offerId: selected.id,
          rgnlAltId: assignStringValue(foundRa?.data.id),
          uid: selected.uid,
        });
        break;
    }

    handleSave(selections);
  };

  return (
    <div>
      <label htmlFor="included" className={styles.boldLabel}>
        Included
      </label>

      {list.map(rgnlAlt => {
        return (
          <div key={rgnlAlt.id} className={styles.raContainer}>
            <div className={styles.raWrapper}>
              <div className={styles.raName}>
                <Tooltip id={`incl-tooltip-${rgnlAlt.id}`} message={getFormattedLabel(rgnlAlt.name).fullName}>
                  <Checkbox
                    id={`incl-checkbox-${rgnlAlt.id}`}
                    onChange={() => handleRaOnCheck(rgnlAlt)}
                    isChecked={!!offerSelections?.find(os => os.rgnlAltId === rgnlAlt.id)}
                    isNational
                    isDisabled={isOfferingPublished || seriesProfile.data.isBudgetConfirmed}
                    label={getFormattedLabel(rgnlAlt.name, 20).formattedName}
                    className={styles.raCheckBox}
                  />
                </Tooltip>
              </div>
              <div className={styles.raOffersContainer}>
                <ul className={styles.raList}>
                  {rgnlAlt.items &&
                    rgnlAlt.items.map((offer, ind) => {
                      return (
                        <li key={`incl-offer-li-${offer.uid}-${offer.name}`} className={styles.raOffer}>
                          <Tooltip id={`incl-offer-tooltip-${offer.uid}-${offer.name}`} message={getFormattedLabel(offer.name).fullName}>
                            <Checkbox
                              id={`incl-offer-checkbox-${offer.uid}-${offer.name}`}
                              onChange={() => handleOfferOnCheck(rgnlAlt.id, offer)}
                              isChecked={
                                offer.name === 'NGL' ? !!offerSelections?.find(o => o.uid === offer.uid && o.isNgl) : !!offerSelections?.find(o => o.uid === offer.uid && !o.isNgl)
                              }
                              isDisabled={isOfferingPublished || seriesProfile.data.isBudgetConfirmed}
                              isNational
                              label={getFormattedLabel(offer.name).formattedName}
                              className={styles.raCheckBox}
                            />
                          </Tooltip>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(IncludedSection);
