import { observer, useLocalObservable } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { OATIcon, Textarea } from 'oat-common-ui';
import { useState } from 'react';
import AccordionItemModel from '../../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../../components/Accordion/models/AccordionModel';
import createAccordionFromCompatibility from '../../../../../components/Accordion/utils/createAccordionFromCompatibility';
import { mapAccordionToVehicleInputs } from '../../../../../components/Accordion/utils/mapSeriesProfileVehicleModelCodeFromAccordion';
import mapExcludedVehicleIds from '../../../../../components/Accordion/utils/mapExcludedVehicleIds';
import Button from '../../../../../components/Button';
import { FEATURE_OR_4767, hideManageCompatibility } from '../../../../../constants/global';
import { Vehicle } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import CompatibilityModal from '../../../components/CompatibilityModal';
import ExclusionsModal from '../../../components/ExclusionsModal';
import StateRestrictionsModal from '../../../components/StateRestrictionsModal';
import RgnlAltModel from '../../../models/RgnlAltModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';
import SpecialEdition from '../OfferSetupHeader/SpecialEdition';
import styles from './styles.module.scss';

type Props = {
  item: OfferSetupModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
  rgnlAlt: RgnlAltModel;
};

const OfferSetupFooter = ({ item, seriesProfile, onSave, rgnlAlt }: Props) => {
  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();

  const { items, toggleItem, toggleAll } = useLocalObservable(
    () => new AccordionModel(createAccordionFromCompatibility(item.fields.compatibilityList, item.fields.optionType as AdminConstants.OPTION_TYPE_NAMES)),
  );

  const { updateField, fields } = item;
  const [showNoteInput, setShowNoteInput] = useState(Boolean(fields.notes));
  const [isCompatibilityModalOpen, setIsCompatibilityModalOpen] = useState(false);
  const [isStateRestrictionsModalOpen, setIsStateRestrictionsModalOpen] = useState(false);
  const [isExclusionModalOpen, setIsExclusionModalOpen] = useState(false);
  const disabledExclusionsVehicleIds = mapExcludedVehicleIds(seriesProfile.data.vehicles as Vehicle[])
  
  const handleSubmitExclusionModal = (items: AccordionItemModel[], vehicles: Vehicle[]) => {
    setIsExclusionModalOpen(false);
    updateField('vehicles', mapAccordionToVehicleInputs(items, vehicles));
    onSave();
  };

  const handleSubmitStateRestrictionsModal = (items: AccordionItemModel[]) => {
    const checkedRegions = items.filter(item => item.checked);
    const regionsPayload = checkedRegions.map(regionItem => {
      return {
        regionCode: regionItem.value,
        states: regionItem.items.filter(stateItem => stateItem.checked).map(stateItem => stateItem.value),
      };
    });

    updateField('regions', regionsPayload);

    setIsStateRestrictionsModalOpen(false);
    onSave();
  };

  return (
    <div className={styles.offerSetupFooter}>
      {item.leaseSetupDetails && <SpecialEdition item={item} seriesProfile={seriesProfile} onSave={onSave} />}
      <Button
        id={`${item.uid}-view-models-footer-btn`}
        className={styles.footerBtn}
        icon={<OATIcon icon="eye" className={styles.icon} />}
        onClick={() => setIsExclusionModalOpen(true)}
      >
        View Models
      </Button>
      {!item.nationalRyoSetupDetails && !hideManageCompatibility.includes(item.fields.optionType) && (
        <Button
          id={`${item.uid}-manage-compatibility-btn`}
          className={styles.footerBtn}
          icon={<OATIcon icon="plus" size="12" className={styles.icon} />}
          onClick={() => setIsCompatibilityModalOpen(true)}
        >
          <span className={styles.notesLabel}>Manage Compatibility</span>
        </Button>
      )}
      {FEATURE_OR_4767 && (
        <Button
          id={`${item.uid}-manage-state-restrictions-btn`}
          className={styles.footerBtn}
          icon={<OATIcon icon="plus" size="12" className={styles.icon} />}
          onClick={() => setIsStateRestrictionsModalOpen(true)}
        >
          <span className={styles.notesLabel}>Manage State Restrictions </span>
        </Button>
      )}
      <Button
        id={`${item.uid}-notes-btn`}
        className={styles.notes}
        disabled={seriesProfile.isConfirmed}
        icon={!showNoteInput && <OATIcon className={styles.icon} icon="plus" size="12" />}
        onClick={() => setShowNoteInput(true)}
        style={{ cursor: !showNoteInput ? 'pointer' : 'default' }}
      >
        <span className={styles.notesLabel}>Notes</span>
      </Button>
      {showNoteInput && (
        <Textarea
          id={`${item.uid}-notes-input`}
          disabled={seriesProfile.isConfirmed}
          isNational
          value={fields.notes}
          onChange={e => {
            updateField('notes', e.target.value);
            onSave();
          }}
          className={styles.notesInput}
        />
      )}

      {isCompatibilityModalOpen && (
        <CompatibilityModal
          id={`${item.uid}-offer-comp-modal`}
          items={items}
          compatibileOffers={item.fields.compatibilityList}
          onClose={() => {
            setIsCompatibilityModalOpen(false);
          }}
          onSubmit={value => {
            updateField('compatibilityList', value);
            onSave();
          }}
          toggleAll={toggleAll}
          toggleItem={toggleItem}
          disabled={seriesProfile.isConfirmed || isOfferingPublished}
        />
      )}
      {isExclusionModalOpen && (
        <ExclusionsModal
          vehicles={fields.vehicles}
          seriesProfile={seriesProfile}
          id={`${item.uid}-view-models-modal`}
          isExclusionsActive={false}
          labelExcluded="Exclusions"
          labelIncluded="Inclusions"
          hideCtas={seriesProfile.isConfirmed || isOfferingPublished}
          onClose={() => setIsExclusionModalOpen(false)}
          onSubmit={handleSubmitExclusionModal}
          disabledVehicleIds={disabledExclusionsVehicleIds}
        />
      )}
      {FEATURE_OR_4767 && isStateRestrictionsModalOpen && (
        <StateRestrictionsModal
          rgnAlt={rgnlAlt}
          id={`${item.uid}-state-restrictions-modal`}
          regions={item.fields.regions}
          hideCtas={seriesProfile.isConfirmed || isOfferingPublished}
          onClose={() => setIsStateRestrictionsModalOpen(false)}
          onSubmit={handleSubmitStateRestrictionsModal}
        />
      )}
    </div>
  );
};

export default observer(OfferSetupFooter);
