import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const InputsWrapper = ({ children }: Props) => {
  return (
    <div className={styles.inputsWrapper}>
      <div className={styles.ratesRibbon}>{children}</div>
    </div>
  );
};

export default InputsWrapper;
