const addToRate = (value: number | string, valueToAdd: number) => {
  return Number((Number(value) + valueToAdd).toFixed(2));
};

export const processRate = (value: number | string, tier: string) => {
  if (tier === '2') {
    if (value === '') {
      return '';
    }

    if (value === 0 || value === '0' || value === '.0') {
      return 2.9;
    }

    return addToRate(value, 2);
  }

  if (tier === '3') {
    if (value === '') {
      return '';
    }

    if (value === 0 || value === '0' || value === '.0') {
      return 5.9;
    }

    return addToRate(value, 5);
  }

  return '';
};
