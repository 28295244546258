import { makeAutoObservable } from 'mobx';
import { PnvsThreshold } from '../gql/generated';

class PNVSTresholdStore {
  pnvs: PnvsThreshold = {
    id: '',
    rev: '',
    brand: '',
    pnvsThreshold: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  updatePNVS = (newPnvs: number) => {
    this.pnvs.pnvsThreshold = newPnvs;
  };

  setPNVSTreshold = (pnvsTreshold: PnvsThreshold) => {
    this.pnvs = pnvsTreshold;
  };
}

export default PNVSTresholdStore;
