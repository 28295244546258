import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import {
  assignNumberValue,
  assignStringValue,
  BlockUi,
  CreateLeaseModels,
  CreateLeaseTabs,
  CreateLeaseUtils,
  EnterVinFlow,
  FinalSelectionFlow,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectTrimFlow,
} from 'oat-common-ui';
import { useState } from 'react';
import { FEATURE_OR_4641 } from '../../../../../../constants/global';
import useStores from '../../../../../../stores/useStores';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import SeriesProfileModel from '../../../../models/SeriesProfileModel';
import styles from './styles.module.scss';
import useNewVinModalService from './useNewVinModalService';
import getVinListData from './utils/getVinListData';
import portsMap from './utils/portsMap';

const { BRAND_TOYOTA } = AdminConstants;
const { getConfigListFromEFC, returnNoVehiclesError } = CreateLeaseUtils;

export interface VinValues {
  vin: string;
  modelCode: number;
  seriesYear: number;
  dealerCostPerInvoice: number;
  totalMsrp: number;
  baseMsrp: number;
  financeReserve: number;
  holdback: number;
  residualRate: number;
  configuration: string;
  vehicleDescription: string;
}

interface Props {
  leaseForm: LeaseFormModel;
  seriesProfile: SeriesProfileModel;
  onClose: () => void;
  onSave: (values: VinValues) => void;
}

const NewVinModal = ({ seriesProfile, leaseForm, onClose, onSave }: Props) => {
  const { efcStore, userInfoStore } = useStores();
  const brand = seriesProfile.data.brand ?? BRAND_TOYOTA;
  const { getInventoryApi, getEfcVinDetailsItem } = efcStore;
  const [blocking, setBlocking] = useState(false);

  const { addVinStore } = useNewVinModalService(seriesProfile, leaseForm);

  const { selectTrimFields, enterVinFields } = addVinStore;
  const flow = addVinStore.getFlow();

  const handleSelectTrim = async (trim: CreateLeaseModels.TrimItem) => {
    try {
      setBlocking(true);
      addVinStore.selectTrim(trim);
      const [inventoryData] = await Promise.all([getInventoryApi(trim.code, addVinStore.seriesYear, addVinStore.series, brand)]);

      if (!inventoryData.body.response.docs.length) {
        addVinStore.setError(returnNoVehiclesError('No docs found', trim.trimTitle || ''));
        return;
      }

      addVinStore.setConfigList(getConfigListFromEFC(inventoryData));
    } catch (e) {
      addVinStore.setError(returnNoVehiclesError('No docs found', flow.trim?.trimTitle || ''));
    } finally {
      setBlocking(false);
    }
  };

  const handleSelectConfig = async (config: CreateLeaseModels.ConfigItem) => {
    addVinStore.selectConfig(config);

    try {
      setBlocking(true);
      // get vin list data
      const { vinList } = await getVinListData(addVinStore.selectTrimFields.trim?.code || '', addVinStore.seriesYear, config.config, brand, false, efcStore, config);
      addVinStore.setVinList(vinList);

      // triggers stop sale calls and will update vin list
      if (!vinList.length) {
        addVinStore.setError(returnNoVehiclesError('No detail found', flow.trim?.trimTitle || ''));
      }
    } catch (e) {
      addVinStore.setError(returnNoVehiclesError('No detail found', flow.trim?.trimTitle || ''));
    } finally {
      setBlocking(false);
    }
  };

  const handleSelectVin = (vin: CreateLeaseModels.VinItem) => {
    addVinStore.selectVin(vin);
  };

  // Reverts back to first step of advertised
  const handleTrimClick = () => {
    if (addVinStore.tab === 1) {
      addVinStore.backToTrimSelectionStep();
    } else {
      addVinStore.setEnterVinStep(2);
    }
  };

  const handleConfigClick = () => {
    if (addVinStore.tab === 1) {
      addVinStore.backToConfigSelectionStep();
    } else {
      addVinStore.setEnterVinStep(2);
    }
  };

  const handleSubmit = () => {
    const vinItem = flow?.vin;
    const trimItem = flow?.trim;
    const configItem = flow?.config;

    if (vinItem && trimItem) {
      onSave({
        vin: vinItem.vin,
        modelCode: assignNumberValue(vinItem.modelCode),
        seriesYear: assignNumberValue(addVinStore.seriesYear),
        dealerCostPerInvoice: assignNumberValue(vinItem.dealerInvoice),
        totalMsrp: assignNumberValue(vinItem.msrp),
        baseMsrp: assignNumberValue(vinItem.baseMsrp),
        financeReserve: assignNumberValue(vinItem.financeReserve),
        holdback: assignNumberValue(vinItem.holdback),
        residualRate: Math.round(assignNumberValue(trimItem.residual) * 100),
        configuration: assignStringValue(configItem?.config),
        vehicleDescription: assignStringValue(trimItem.seriesTitle),
      });
      onClose();
    }
  };

  const handleVinSearch = async () => {
    try {
      setBlocking(true);
      // get vin details data
      const vinQuery = enterVinFields.vinQuery;
      const vinItem = await getEfcVinDetailsItem(vinQuery, brand);

      // get SSC data and inventory (if model code is different)
      const inventory = await getInventoryApi(vinItem.modelCode, addVinStore.seriesYear, addVinStore.series, brand);
      addVinStore.setVinList([vinItem]);

      // set config list from inventory call
      if (inventory.body.response.docs.length) {
        addVinStore.setConfigList(getConfigListFromEFC(inventory));
      }

      addVinStore.setEnterVinStep(2);
    } catch (e) {
      addVinStore.setError({ name: 'No vin found', message: 'No VIN found. Please try another VIN.' });
    } finally {
      setBlocking(false);
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Create Lease Offer" onClose={onClose} />
      <ModalBody>
        <BlockUi blocking={blocking || addVinStore.loading} title="Fetching..." />
        <div className={styles.container}>
          <CreateLeaseTabs tab={addVinStore.tab} onTabClick={addVinStore.setTab}>
            {addVinStore.showSelectTrimFlow() && (
              <SelectTrimFlow
                // top section values/handlers
                trimItem={selectTrimFields.trim}
                trimQuery={selectTrimFields.trimQuery}
                handleTrimQueryChange={addVinStore.setTrimQuery}
                configItem={selectTrimFields.config}
                configQuery={selectTrimFields.configQuery}
                handleConfigQueryChange={addVinStore.setConfigQuery}
                vinItem={selectTrimFields.vin}
                dealerCodeQuery={selectTrimFields.dealerCodeQuery}
                handleDealerCodeQueryChange={addVinStore.setDealerCodeQuery}
                handleTrimClick={handleTrimClick}
                handleSelectTrim={handleSelectTrim}
                handleConfigClick={handleConfigClick}
                handleSelectConfig={handleSelectConfig}
                handleSelectVin={handleSelectVin}
                showTrimTable={addVinStore.showTrimTable()}
                showConfigTable={addVinStore.showConfigTable()}
                showVinTable={addVinStore.showVinTable()}
                showNoVehicles={addVinStore.error?.message}
                trimList={addVinStore.trimList}
                configList={selectTrimFields.configList}
                vinList={selectTrimFields.vinList}
                gStatus={addVinStore.gStatusFiltered}
                setGStatus={addVinStore.setGstatus}
                isSETUser={false}
                isLexusUser={userInfoStore.isLexus()}
                useSrpLabelLogic={false}
                isNationalApp
                portsMap={portsMap}
                handleSelectDifferentTrim={addVinStore.backToTrimSelectionStep}
                sortCol={addVinStore.getTableSort().sortField}
                sortAsc={addVinStore.getTableSort().sortAscending}
                handleTrimTableSort={addVinStore.sortTrimList}
                handleConfigTableSort={addVinStore.sortConfigList}
                handleVinTableSort={addVinStore.sortVinList}
                showVinCount={FEATURE_OR_4641}
              />
            )}
            {addVinStore.showEnterVinFlow() && (
              <EnterVinFlow
                vinList={enterVinFields.vinList}
                vinQuery={enterVinFields.vinQuery}
                hasVinQueryError={addVinStore.hasVinQueryError}
                showVinList={addVinStore.showVinList()}
                handleSelectVin={addVinStore.selectVin}
                portsMap={portsMap}
                handleVinSearchReset={addVinStore.vinSearchReset}
                handleVinSearch={handleVinSearch}
                onVinQueryChange={addVinStore.setVinQuery}
                isSETUser={false}
                isLexusUser={userInfoStore.isLexus()}
                useSrpLabelLogic={false}
                isNationalApp
                showNoVehicles={addVinStore.error?.message}
                sortCol={addVinStore.vinTable.sortField}
                sortAsc={addVinStore.vinTable.sortAscending}
                handleSortColumns={addVinStore.sortVinList}
              />
            )}
            {addVinStore.showFinalSelectionFlow() && <FinalSelectionFlow vin={flow.vin?.vin ?? ''} onVinClick={addVinStore.vinReset} onSubmit={handleSubmit} />}
          </CreateLeaseTabs>
        </div>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

export default observer(NewVinModal);
