import { observer } from 'mobx-react-lite';
import { assignStringValue, BlockInputLabel, DefaultModal, Input, OATIcon, Textarea, useInputDelay, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import AccordionItemModel from '../../../../../components/Accordion/models/AccordionItemModel';
import { mapAccordionToVehicleInputs } from '../../../../../components/Accordion/utils/mapSeriesProfileVehicleModelCodeFromAccordion';
import Button from '../../../../../components/Button';
import {
  ModelCodesResponse,
  Scenario,
  useSaveScenarioNoteMutation,
  useSaveSeriesProfileVehiclesMutation,
  useUpdateMultiSeriesProfileNameMutation,
  Vehicle,
} from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import ExclusionsModal from '../../../components/ExclusionsModal';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
}

const ScenarioSetupInfo = ({ seriesProfile }: Props) => {
  const { data, selectedScenario, setRev, updateDataField, updateSetupOfferVehicles, isConfirmed } = seriesProfile;
  const [isOpenExclusionModal, setIsOpenExclusionModal] = useState(false);
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
  const [items, setItems] = useState<AccordionItemModel[]>([]);
  const [includedModelCodes, setIncludedModelCodes] = useState<ModelCodesResponse[]>([]);
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const {
    nationalProgramsStore: { isOfferingPublished },
    globalStore: { setShowProgressBar },
  } = useStores();

  const [saveScenarioNote] = useSaveScenarioNoteMutation();
  const [saveSPVehicles] = useSaveSeriesProfileVehiclesMutation();
  const [updateMultiSeriesProfileName] = useUpdateMultiSeriesProfileNameMutation();
  const isMultiSeries = Boolean(data.multiSeriesOfferId);
  const hasOffers = selectedScenario?.rgnlAlts.some(rgnlAlt => rgnlAlt.offers.length > 0);

  const handleNoteChange = (note: string) => {
    if (!selectedScenario) {
      return;
    }

    selectedScenario.updateNote(note);

    setDelay(async () => {
      try {
        const res = await trackPromise(
          saveScenarioNote({
            variables: {
              input: {
                note: selectedScenario.note,
                rev: selectedScenario.rev,
                scenarioId: selectedScenario.id,
              },
            },
          }),
        );

        if (res.data?.saveScenarioNote.data) {
          selectedScenario.setData(res.data.saveScenarioNote.data as Scenario);
        }
      } catch (e) {
        error((e as Error).message);
      }
    }, 1000);
  };

  const handleVehicleInclusionChange = async (items: AccordionItemModel[], vehicles: Vehicle[]) => {
    try {
      const res = await trackPromise(
        saveSPVehicles({
          variables: {
            input: {
              rev: data.rev,
              seriesProfileId: data.id,
              vehicles: mapAccordionToVehicleInputs(items, vehicles),
            },
          },
        }),
      );

      if (res.data?.saveSeriesProfileVehicles.success) {
        setRev(res.data.saveSeriesProfileVehicles.rev as string);
        updateDataField('hasModelCodeUpdates', res.data.saveSeriesProfileVehicles.hasModelCodeUpdates as boolean);
        updateSetupOfferVehicles(res.data.saveSeriesProfileVehicles);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsOpenAlertModal(false);
    }
  };

  const handleSubmitExclusionModal = (items: AccordionItemModel[], vehicles: Vehicle[]) => {
    setIsOpenExclusionModal(false);
    setItems(items);
    setIncludedModelCodes(includedModelCodes);
    hasOffers ? setIsOpenAlertModal(true) : handleVehicleInclusionChange(items, vehicles);
  };

  const handleUpdateMultiSeriesProfileName = (value: string) => {
    if (!isMultiSeries) {
      return;
    }

    updateDataField('multiSeriesProfileName', value);

    setDelay(async () => {
      setShowProgressBar(true);
      try {
        const res = await trackPromise(
          updateMultiSeriesProfileName({
            variables: {
              input: {
                rev: data.rev,
                seriesProfileId: data.id,
                multiSeriesOfferId: assignStringValue(data.multiSeriesOfferId),
                multiSeriesProfileName: value,
              },
            },
          }),
        );

        setRev(assignStringValue(res.data?.updateMultiSeriesProfileName.rev));
      } catch (e) {
        error((e as Error).message);
      } finally {
        setShowProgressBar(false);
      }
    });
  };

  return (
    <div className={styles.scenarioInfo}>
      <div className={styles.leftPanel}>
        <Input
          isNational
          value={!isMultiSeries ? `${data.modelYear} ${data.series}` : `${data.multiSeriesProfileName}`}
          onChange={val => handleUpdateMultiSeriesProfileName(val.currentTarget.value)}
          disabled={!isMultiSeries || isOfferingPublished || isConfirmed}
          inputWrapperClass={styles.inputWrapper}
          className={styles.nameInput}
        />
        <div className={styles.viewModels}>
          <Button className={styles.viewModelsText} onClick={() => setIsOpenExclusionModal(true)}>
            <OATIcon icon="eye" /> View Models
          </Button>
          {isOpenExclusionModal && (
            <ExclusionsModal
              vehicles={data.vehicles as Vehicle[]}
              seriesProfile={seriesProfile}
              id="view-models"
              isExclusionsActive={false}
              labelExcluded="Exclusions"
              labelIncluded="Inclusions"
              hideCtas={isConfirmed || isOfferingPublished}
              onClose={() => setIsOpenExclusionModal(false)}
              onSubmit={handleSubmitExclusionModal}
            />
          )}
          {data.hasModelCodeUpdates && (
            <p className={styles.warningText}>
              <OATIcon icon="warning" /> Model codes have been updated. Please resubmit model codes.
            </p>
          )}
        </div>
      </div>
      <div>
        <BlockInputLabel label="Notes" className={styles.notesWrapper}>
          <Textarea isNational id="scenario-notes-input" value={selectedScenario?.note} onChange={e => handleNoteChange(e.target.value)} disabled={isConfirmed} />
        </BlockInputLabel>
      </div>
      {isOpenAlertModal && (
        <DefaultModal
          message="The data will be overwritten if there are offers within the series profiles whose inclusions/exclusions did not match the series profile inclusions/exclusions."
          modalBodyClassName={styles.alertModal}
          onClose={() => {
            setIsOpenAlertModal(false);
          }}
          onSubmit={() => handleVehicleInclusionChange(items, seriesProfile.data.vehicles as Vehicle[])}
          open
          submitText="OK"
          title="Alert"
        />
      )}
    </div>
  );
};

export default observer(ScenarioSetupInfo);
