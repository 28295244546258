import { AdminConstants } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import NationalCostShareModel from '../models/NationalCostShareModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const sortCostSharesByType = (costShares: NationalCostShareModel[]) => {
  const filteredCostShares: { [key: string]: NationalCostShareModel[] } = { [OPTION_TYPE_NAMES.APR]: [], [OPTION_TYPE_NAMES.LEASE]: [] };

  for (const costShare of costShares) {
    costShare.fields.optionTypeName === OPTION_TYPE_NAMES.APR
      ? (filteredCostShares[OPTION_TYPE_NAMES.APR] = [...filteredCostShares[OPTION_TYPE_NAMES.APR], costShare]).sort(
          (a, b) => dateStringToDate(a.fields.endDate).getTime() - dateStringToDate(b.fields.endDate).getTime(),
        )
      : (filteredCostShares[OPTION_TYPE_NAMES.LEASE] = [...filteredCostShares[OPTION_TYPE_NAMES.LEASE], costShare].sort(
          (a, b) => dateStringToDate(a.fields.endDate).getTime() - dateStringToDate(b.fields.endDate).getTime(),
        ));
  }

  return [...filteredCostShares[OPTION_TYPE_NAMES.APR], ...filteredCostShares[OPTION_TYPE_NAMES.LEASE]];
};
