import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import React from 'react';
import AprModel from '../../../models/offers/apr/AprModel';
import useSaveApr from '../../../offers/AprSection/useSaveApr';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import OfferName from './OfferName';
import RowProps from './RowProps';
import { useSummaryCosts } from '../useSummaryCosts';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

const AprRow = ({ seriesProfile, rgnlAlt, offer }: RowProps<AprModel>) => {
  const { uid, updateOfferField } = offer;
  const { saveApr } = useSaveApr(seriesProfile, rgnlAlt, offer);

  const { tfsCost, tmsCost } = useSummaryCosts(rgnlAlt, offer);

  const handleRateChange = (term: number, value: string) => {
    offer.updateTermField('1+', term, 'rate', value);
    saveApr();
  };

  const handlePenRate = (val: string) => {
    seriesProfile.clearErrors();
    updateOfferField('penetrationRate', val);
    saveApr();
  };

  const rgnlInputWidth = (rate: number) => {
    const rateLength = rate.toString().split('').length;

    if (rateLength >= 2 && rateLength < 4) {
      return styles.smallWidth;
    } else if (rateLength === 4) {
      return styles.medWidth;
    } else if (rateLength > 4) {
      return styles.largeWidth;
    } else {
      return styles.xSmallWidth;
    }
  };

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{offer.offerFields.name}</OfferName>
      </td>
      <td>
        <div className={styles.aprInputs}>
          {offer.aprRatesFor1Plus.map((item, i) => {
            return (
              <React.Fragment key={item.uid}>
                <RgnlAltInput
                  className={rgnlInputWidth(Number(item.rate))}
                  id={`apr-summary-rate-${item.term}-${uid}`}
                  isNational
                  min={0}
                  max={100}
                  rate
                  value={item.rate}
                  onValueChange={val => handleRateChange(item.term, val.value)}
                  error={item.rateError}
                />
                {i < offer.aprRatesFor1Plus.length - 1 && <span className={styles.sep}>/</span>}
              </React.Fragment>
            );
          })}{' '}
          <span className={clsx(styles.labelsm, styles.marginLeft)}>Tier 1+</span>
        </div>
      </td>
      <td>${formatDollars(offer.aprFields.offerPnvs)}</td>
      <>
        <td>
          <RgnlAltInput
            id={`apr-summary-penrate-${uid}`}
            isNational
            wholeNumber
            percentageSign
            min={0}
            max={100}
            value={offer.offerFields.penetrationRate}
            onValueChange={val => handlePenRate(val.value)}
            error={offer.penetrationRateError}
            className={styles.penRateInput}
          />
        </td>
        {FEATURE_OR_4154 && (
          <>
            <td>${tmsCost} </td>
            <td>${tfsCost}</td>
          </>
        )}
        {+offer.aprFields.subCashAmount > 0 && (
          <td className={styles.paddingLeft}>
            ${formatDollars(offer.aprFields.subCashAmount)} <span className={styles.labelsm}>Sub Cash</span>
          </td>
        )}
      </>
    </tr>
  );
};

export default observer(AprRow);
