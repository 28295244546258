import styles from './styles.module.scss';

interface Props {
  isUpdated: boolean;
}

const DatesUpdatedNotification = ({ isUpdated }: Props) => {
  if (isUpdated) {
    return <span className={styles.datesUpdatedText}>Cost Share dates have been updated. Please verify.</span>;
  }

  return null;
};

export default DatesUpdatedNotification;
