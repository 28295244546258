import { observer } from 'mobx-react-lite';
import { DefaultModal, OATIcon, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../../../../components/Button';
import { OfferingCosts, RgnlAltSummary, useCopyRegionalAlternativeMutation, useDeleteRegionalAlternativeMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import RgnlAltModel from '../../../models/RgnlAltModel';
import ScenarioModel from '../../../models/ScenarioModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from '../styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
  scenario: ScenarioModel;
  rgnlAlt: RgnlAltModel;
  isDisabled: boolean;
  showCtas: boolean;
}

const RgnlAltTitle = ({ seriesProfile, scenario, rgnlAlt, isDisabled, showCtas }: Props) => {
  const [copyRegionalAlternative] = useCopyRegionalAlternativeMutation();
  const [deleteRegionalAlternative] = useDeleteRegionalAlternativeMutation();
  const [deleteModal, setDeleteModal] = useState(false);

  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();

  const { error } = useToast();
  const { data, expanded, toggleExpanded } = rgnlAlt;

  const handleCopyRa = async () => {
    try {
      const res = await trackPromise(copyRegionalAlternative({ variables: { input: { rgnlAltId: rgnlAlt.data.id } } }));
      if (res.data?.copyRegionalAlternative.success) {
        const newRgnlAlt = new RgnlAltModel(data.brand ?? '');
        newRgnlAlt.toggleExpanded();
        newRgnlAlt.setData(res.data?.copyRegionalAlternative.regionalAlternative as RgnlAltSummary, seriesProfile);
        scenario.rgnlAlts.push(newRgnlAlt);
        updateOfferingCostsFromResponse(res.data.copyRegionalAlternative.offeringCosts as OfferingCosts);
        seriesProfile.clearErrors();
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleDeleteRa = async () => {
    try {
      if (rgnlAlt.data.id) {
        const res = await trackPromise(deleteRegionalAlternative({ variables: { input: { rgnlAltId: rgnlAlt.data.id, rgnlAltRev: rgnlAlt.data.rev } } }));

        if (res.data) {
          const { offeringCosts, updatedRgnlAlts, updatedRyoOffers } = res.data.deleteRegionalAlternative;
          updateOfferingCostsFromResponse(offeringCosts as OfferingCosts);
          scenario.deleteRgnlAlt(rgnlAlt.uid, updatedRgnlAlts, updatedRyoOffers);
          seriesProfile.clearErrors();
        }
      } else {
        scenario.deleteRgnlAlt(rgnlAlt.uid);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setDeleteModal(false);
    }
  };

  const raNum = data.id ? `-${data.number}` : '';
  const penRate = data.penetrationRate && `${parseInt(data.penetrationRate.toString())}`;
  const raTitle = `RA${raNum} • ${data.name} ${!seriesProfile.isSetupView ? `• ${penRate}%` : ''}`;

  return (
    <div className={styles.title}>
      <h3>
        <Button id={data.id} onClick={toggleExpanded}>
          <OATIcon className={styles.angleIcon} icon={expanded ? 'angle-up' : 'angle-down'} colorTheme="blue" /> {raTitle}
        </Button>
      </h3>
      {showCtas && (
        <div className={styles.ctas}>
          <Button id={`copy-ra-cta-${rgnlAlt.uid}`} variant="gray" icon={<OATIcon icon="copy" />} onClick={handleCopyRa} disabled={isDisabled} />
          <Button id={`delete-ra-cta-${rgnlAlt.uid}`} variant="gray" icon={<OATIcon icon="trash" />} onClick={() => setDeleteModal(true)} disabled={isDisabled}>
            Delete RA
          </Button>
        </div>
      )}
      {deleteModal && (
        <DefaultModal
          open
          title={`Delete RA-${rgnlAlt.data.number}`}
          message={`Are you sure you want to delete ${raTitle}? This action cannot be undone.`}
          onClose={() => setDeleteModal(false)}
          onSubmit={handleDeleteRa}
        />
      )}
    </div>
  );
};

export default observer(RgnlAltTitle);
