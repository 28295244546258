import { IMultiItem, assignStringValue, sortFieldsHelper } from 'oat-common-ui';
import { ModelCodeResponse, ModelCodesResponse, OfferingSeriesItem, SeriesItemInput, SeriesMapping } from '../../../gql/generated';

export const xformDisplayName = (data: OfferingSeriesItem[], seriesMappingList: SeriesMapping[]) => {
  return data.map(series => {
    const srs = seriesMappingList.filter(srs => srs.bosuSeries === series.series)[0];
    return {
      seriesYear: series.modelYear,
      series: srs?.displayName,
      id: srs?.id,
    };
  });
};

export const xformSeriesSelections = (data: OfferingSeriesItem[], seriesMappingList: SeriesMapping[]) => {
  return xformDisplayName(data, seriesMappingList)
    .sort(sortFieldsHelper('seriesYear', true))
    .sort(sortFieldsHelper('series', true))
    .map((series, index) => {
      return {
        id: `${series.id}_${series.seriesYear}`,
        data: `${series.seriesYear} ${series.series}`,
        selected: false,
        index,
      };
    });
};

export const xformToBosuSeries = (data: SeriesItemInput[], seriesMappingList: SeriesMapping[]) => {
  
  const displayNameToBosuSeriesMap: Map<string, string> = new Map<string, string>();
  for (const seriesMapping of seriesMappingList) {
    for (const oatSeries of seriesMapping.oatSeries) {
      displayNameToBosuSeriesMap.set(`${oatSeries?.year}#${seriesMapping.displayName}`, seriesMapping.bosuSeries);
    }
  }

  return data.map(seriesInputItem => {
    return {
      modelYear: seriesInputItem.modelYear,
      series: assignStringValue(displayNameToBosuSeriesMap.get(`${seriesInputItem.modelYear}#${seriesInputItem.series}`)),
    };
  });
};

export const xformModelCodeMappings = (data: ModelCodesResponse[]) => {
  return data
    .sort(sortFieldsHelper('year', true))
    .sort(sortFieldsHelper('seriesNameFromFile', true))
    .map(series => {
      return {
        id: `${series.offeringId}-${series.year}-${series.seriesNameFromFile}`,
        data: `${series.year} ${series.seriesNameFromFile}`,
      };
    });
};

export const hasValidIndex = (models: ModelCodeResponse[], filter: string) => {
  return models.findIndex(model => model.modelCode.includes(filter) || model.vehicleDescription.toLowerCase().includes(filter.toLowerCase())) !== -1;
};

export const nameOrYearIsIncluded = (modelCodesItem: ModelCodesResponse, filter: string) => {
  return modelCodesItem.seriesNameFromFile.toLowerCase().includes(filter.toLowerCase()) || modelCodesItem.year.includes(filter);
};

export const splitSeriesAndModelYear = (availableSeries: IMultiItem[]) => {
  return availableSeries.map(series => ({
    ...series,
    series: series.data.slice(5, series.data.length + 4),
    modelYear: series.data.split(' ')[0],
  }));
};
