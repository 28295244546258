import { assignNumberValue, CreateLeaseModels, EFCVinListResponse } from 'oat-common-ui';
import EFCStore from '../../../../../../../stores/EFCStore';
import getRegionsForEfc from '../../../../../utils/getRegionsForEfc';

const getVinListData = async (
  trimCode: string,
  seriesYear: string,
  config: string,
  brand: string,
  isSET: boolean,
  efcStore: EFCStore,
  configItem?: CreateLeaseModels.ConfigItem,
) => {
  const { getVinListApi } = efcStore;
  const regions = getRegionsForEfc(brand);

  // get vin list data
  const vinList: CreateLeaseModels.VinItem[] = [];

  if (isSET && configItem) {
    configItem.vinItems.forEach(item => vinList.push(item));
  } else {
    const promises: Promise<EFCVinListResponse | undefined>[] = [];
    for (const region of regions) {
      promises.push(getVinListApi(region.toString(), trimCode, seriesYear, config, brand));
    }

    const res = await Promise.all(promises);
    res.forEach(data => {
      if (data && data.inventoryVehicles.length > 0) {
        for (const vin of data.inventoryVehicles) {
          // create vin item and add to vin list
          const vinItem = new CreateLeaseModels.VinItem();
          vinItem.setFromEFC(vin);

          if (configItem) {
            vinItem.setAccessoriesFrom365(configItem.config, configItem.config.split(','));
          }

          vinItem.dealerInvoice = Math.round(assignNumberValue(vinItem.dealerInvoice)).toString();
          vinList.push(vinItem);
        }
      }
    });
  }

  return {
    vinList,
  };
};

export default getVinListData;
