import AccordionItemModel from '../models/AccordionItemModel';

/**
 *
 * @param items Root items
 * @param uid
 * @param parent
 */

const recursiveAllSelected = (items: AccordionItemModel[], selectionValue: boolean): boolean => {
  return items.every(item => {
    if (item.items.length > 0 && item.checked === selectionValue) {
      return recursiveAllSelected(item.items, selectionValue);
    }
    return item.checked === selectionValue;
  });
};

export default recursiveAllSelected;
