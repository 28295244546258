import { makeAutoObservable, runInAction } from 'mobx';
import { IMultiItem, sortFieldsHelper } from 'oat-common-ui';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import { countTotalSelected } from '../../../../components/Accordion/utils/countTotalSelected';
import createAccordionFromModelCodes from '../../../../components/Accordion/utils/createAccordionFromModelCodes';
import { ModelCodeMappingResponse, ModelCodeResponse, ModelCodesResponse, SeriesMapping } from '../../../../gql/generated';
import { splitSeriesAndModelYear } from '../utils';

class ModelCodeMappingModel {
  series: IMultiItem[] = [];
  filteredSeries: IMultiItem[] = [];
  modelCodes: ModelCodesResponse[] = [];
  mappings: ModelCodeMappingResponse[] = [];
  loaded = false;
  searchInput = '';
  resetInput = false;
  modalSearchInput = '';
  accordion = new AccordionModel();
  selectedBosuSeries = '';
  selectedYear = '';

  constructor() {
    makeAutoObservable(this);
  }

  setSearchInput = (value: string) => {
    this.searchInput = value;
    this.filteredSeries = this.series.filter(item => item.data.toLowerCase().includes(value.toLowerCase()));
  };

  setModalSearchInput = (value: string) => {
    this.modalSearchInput = value;
    this.accordion.setQuery(value);
    this.accordion.toggleExpandAll(Boolean(value));
  };

  setSelectedBosuSeries = (item: IMultiItem, seriesMappingList: SeriesMapping[]) => {
    const series = seriesMappingList.filter(srs => srs.id === item.id.split('_')[0])[0];
    this.selectedYear = item.data.split(' ')[0];
    this.selectedBosuSeries = series.bosuSeries;
  };

  setMappings = (mappings: ModelCodeMappingResponse[], modelCodes: ModelCodesResponse[], availableSeries: IMultiItem[]) => {
    this.series = availableSeries.sort(sortFieldsHelper('year', true)).sort(sortFieldsHelper('seriesNameFromFile', true));
    this.filteredSeries = splitSeriesAndModelYear(availableSeries).sort((a, b) => a.series.localeCompare(b.series) || +a.modelYear - +b.modelYear);
    this.modelCodes = modelCodes;
    this.mappings = mappings;
    this.searchInput = '';
  };

  setAccordion = () => {
    const accordionModel = new AccordionModel(
      createAccordionFromModelCodes(
        this.modelCodes.filter(mc => mc.year === this.selectedYear),
        this.mappings,
        this.selectedBosuSeries,
      ),
    );
    accordionModel.setShowTotal();
    countTotalSelected(accordionModel.items);
    this.accordion = accordionModel;

    this.setLoaded(true);
  };

  updateMappings = (updatedModelCodes: ModelCodeResponse[]) => {
    this.mappings.forEach(item => {
      if (item.bosuSeries === updatedModelCodes[0].mappedBosuSeries || item.bosuSeries === this.selectedBosuSeries) {
        updatedModelCodes.forEach(update => {
          item.models.push(update);
        });
      }
    });
  };

  setLoaded = (v: boolean) => runInAction(() => (this.loaded = v));

  setResetInput = (v: boolean) => runInAction(() => (this.resetInput = v));
}

export default ModelCodeMappingModel;
