import { assignNumberValue } from 'oat-common-ui';
import { CreateFinalPayOfferInput, UpdateFinalPayOfferInput } from '../../../../gql/generated';
import FinalPayModel from '../../models/offers/FinalPayModel';

export const getCreateFinalPayOfferPayload = (brand: string, offeringId: string, finalPay: FinalPayModel, sortOrder: number): CreateFinalPayOfferInput => {
  return {
    ...finalPay.offerCostFields,
    amount: assignNumberValue(finalPay.offerCostFields.amount),
    isForRegions: finalPay.offerFields.isForRegions,
    name: finalPay.offerFields.name,
    offerPnvs: assignNumberValue(finalPay.offerCostFields.offerPnvs),
    priorNationalRyoOfferId: finalPay.offerCostFields.priorNationalRyoOfferId === '--' ? undefined : finalPay.offerCostFields.priorNationalRyoOfferId,
    penetrationRate: assignNumberValue(finalPay.offerFields.penetrationRate),
    rgnlAltId: finalPay.offerFields.rgnlAltId,
    finalPayAmount: Math.round(finalPay.offerCostFields.finalPayAmount),
    finalPayAmountPercent: Math.round(finalPay.offerCostFields.finalPayAmountPercent),

    sortOrder,
  };
};

export const getUpdateFinalPayOfferPayload = (finalPay: FinalPayModel): UpdateFinalPayOfferInput => {
  return {
    id: finalPay.offerFields.id,
    rev: finalPay.offerFields.rev,
    isForRegions: finalPay.offerFields.isForRegions,
    penetrationRate: assignNumberValue(finalPay.offerFields.penetrationRate),
    name: finalPay.offerFields.name,
    ...finalPay.offerCostFields,
    finalPayAmount: Math.round(finalPay.offerCostFields.finalPayAmount),
    finalPayAmountPercent: Math.round(finalPay.offerCostFields.finalPayAmountPercent),
    priorNationalRyoOfferId: finalPay.offerCostFields.priorNationalRyoOfferId === '--' ? undefined : finalPay.offerCostFields.priorNationalRyoOfferId,
    amount: assignNumberValue(finalPay.offerCostFields.amount),
    offerPnvs: assignNumberValue(finalPay.offerCostFields.offerPnvs),
  };
};
