import { observer } from 'mobx-react-lite';
import NationalCostShareModel from '../../models/NationalCostShareModel';
import CostShareRow from './CostShareRow';
import SeriesTitle from './SeriesTitle';
import styles from './styles.module.scss';

interface Props {
  nationalCostShares: NationalCostShareModel[];
  seriesProfileId: string;
}

const SeriesCostShare = ({ nationalCostShares, seriesProfileId }: Props) => {
  return (
    <div id={seriesProfileId} className={styles.seriesCostShareParent}>
      <SeriesTitle seriesProfileId={seriesProfileId} />

      {nationalCostShares.map((costShare, i) => {
        return <CostShareRow key={`${costShare.id}-${i}`} costShare={costShare} seriesProfileId={seriesProfileId} />;
      })}
    </div>
  );
};

export default observer(SeriesCostShare);
