import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { LeaseDetails, Offer, OfferingStandardRates } from '../../../../../gql/generated';
import isSupra from '../../../../../utils/isSupra';
import getDefaultTfsShareLease from '../../../utils/getDefaultTfsShareLease';
import SeriesProfileModel from '../../SeriesProfileModel';
import LeaseFormModel from './LeaseFormModel';

class LeaseModel {
  uid = uuidv4();
  rgnlAltId = '';
  forms: LeaseFormModel[] = [];
  selectedLease: LeaseFormModel | undefined;
  brand = '';
  ncsRcf = 0;

  constructor(brand: string, rgnlAltId: string, ncsRcf: number) {
    makeAutoObservable(this);
    this.brand = brand;
    this.rgnlAltId = rgnlAltId;
    this.ncsRcf = ncsRcf;
  }

  /**
   * Be sure to group offers correctly by id and mastetOfferId
   * @param offers
   */
  setForms = (offers: Offer[]) => {
    const forms: LeaseFormModel[] = [];

    for (const offer of offers) {
      if (offer.leaseDetails) {
        const form = new LeaseFormModel(this.brand, isSupra(offer.leaseDetails.series ?? ''), this.ncsRcf);
        form.setData(offer, offer.leaseDetails);
        form.recalculate(true);
        forms.push(form);

        if (!form.offerFields.isExample) {
          this.selectedLease = form;
        }
      }

      this.forms = forms;
    }
  };

  getMaster = () => {
    // this will mostly return an existing master. The new LeaseFormModel is just a placeholder so we don't return undefined
    return this.forms.find(item => !item.offerFields.isExample) ?? new LeaseFormModel(this.brand, false, this.ncsRcf);
  };

  getExamples = () => {
    return this.forms.filter(item => item.offerFields.isExample);
  };

  getExamplesWithDifferentTerm = () => {
    const master = this.getMaster();
    if (master) {
      const examples = this.getExamples();
      return examples.filter(item => item.leaseFields.term !== master.leaseFields.term);
    }

    return [];
  };

  getAdjustedRcf = (adjustmentFactor: number, rcf?: number) => {
    if (rcf) {
      return rcf - adjustmentFactor;
    }

    return adjustmentFactor;
  };

  addBlankForm = (seriesProfile: SeriesProfileModel, standardRates: OfferingStandardRates, rcfAdjustment: number) => {
    const { leaseRates } = standardRates;

    const newLease = new LeaseFormModel(this.brand, isSupra(seriesProfile.data.series), this.ncsRcf);

    newLease.ncsRcf = this.getAdjustedRcf(rcfAdjustment, this.ncsRcf);
    newLease.offerFields.rgnlAltId = this.rgnlAltId;
    newLease.leaseFields.series = seriesProfile.data.series;
    newLease.leaseFields.seriesYear = seriesProfile.data.modelYear;

    // Lexus share tfs logic of toyota according to OR-2345
    newLease.leaseFields.tfsShare = getDefaultTfsShareLease(Number(newLease.leaseFields.term));
    const leaseRate = leaseRates.find(rate => rate.tier === newLease.leaseFields.tier);
    if (leaseRate) {
      const calculatedRate = Math.round((leaseRate.rate - this.getAdjustedRcf(rcfAdjustment, seriesProfile.data.ncsRcf)) * 100000) / 100000;
      newLease.leaseFields.standardRcf = calculatedRate;
      newLease.leaseFields.initalStandardRcf = calculatedRate;
    }
    this.forms = [...this.forms, newLease];
    return newLease;
  };

  addExample = (offer: Offer) => {
    const master = this.getMaster();
    if (master) {
      const form = new LeaseFormModel(this.brand, master.isSupra, this.ncsRcf);
      form.setData(offer, offer.leaseDetails as LeaseDetails);
      this.forms = [...this.forms, form];
      this.selectLease(form.uid);
      return form;
    }
    return undefined;
  };

  selectLease = (uid: string) => {
    const found = this.forms.find(item => item.uid === uid);
    if (found) {
      this.selectedLease = found;
    }
  };

  updateName = (leaseForm: LeaseFormModel, name: string) => {
    leaseForm.setExampleNames(this.getExamples());
    leaseForm.updateOfferField('name', name);
  };

  removeExample = (exampleUid: string) => {
    this.forms = this.forms.filter(item => item.uid !== exampleUid);
    this.selectedLease = this.forms[this.forms.length - 1];
  };

  updateRevs = (offers: Offer[]) => {
    this.forms.forEach(form => {
      const foundOffer = offers.find(o => o.id === form.offerFields.id);
      if (foundOffer) {
        form.offerFields.rev = foundOffer.rev;
      }
    });
  };

  syncExamples = () => {
    const master = this.getMaster();
    const examples = this.getExamples();
    if (master && examples.length) {
      examples.forEach(form => {
        form.leaseFields.rcf = master.leaseFields.rcf;
        form.leaseFields.subCashAmount = master.leaseFields.subCashAmount;
        form.leaseFields.adjustmentFactor = master.leaseFields.adjustmentFactor;
        form.leaseFields.tfsShare = master.leaseFields.tfsShare;
        form.leaseFields.subCashCostShare = master.leaseFields.subCashCostShare;
        form.leaseFields.subCashCostShareCap = master.leaseFields.subCashCostShareCap;
        form.leaseFields.rvSupport = master.leaseFields.rvSupport;
        form.leaseFields.rvSupportPenRate = master.leaseFields.rvSupportPenRate;
        form.leaseFields.rvSupportCostShare = master.leaseFields.rvSupportCostShare;
        form.leaseFields.rvSupportAdjustmentFactor = master.leaseFields.rvSupportAdjustmentFactor;

        // only change penrate if example term hasn't been changed
        if (form.leaseFields.term === master.leaseFields.term) {
          form.offerFields.penetrationRate = master.offerFields.penetrationRate;
        }
        form.recalculate();
      });
    }
  };

  hasError = () => {
    return this.forms.some(form => form.getError());
  };

  get disabledAddExamples() {
    return this.getExamples().length >= 4;
  }
}

export default LeaseModel;
