import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { sortFieldsHelper, uuidv4 } from 'oat-common-ui';
import { Offer, OfferingRate, OfferingStandardRates } from '../../../../gql/generated';
import { processStdRates } from '../../offers/AprSection/utils/processStdRates';
import RgnlAltModel from '../RgnlAltModel';
import SeriesProfileModel from '../SeriesProfileModel';
import CashModel from './CashModel';
import FinalPayModel from './FinalPayModel';
import MiscModel from './MiscModel';
import NationalRyoModel from './NationalRyoModel';
import AprModel from './apr/AprModel';
import LeaseModel from './lease/LeaseModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

class OfferModel {
  uid = uuidv4();
  offerUid = '';

  // Offer details
  cashDetails: CashModel | null = null;
  aprDetails: AprModel | null = null;
  leaseDetails: LeaseModel | null = null;
  miscDetails: MiscModel | null = null;
  finalPayDetails: FinalPayModel | null = null;
  nationalRyoDetails: NationalRyoModel | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  initAprOffer = (rgnlAltId: string, standardRates: OfferingRate[], isLexus: boolean, ncsRate: number) => {
    const apr = new AprModel(rgnlAltId, ncsRate);
    apr.initializeListData(isLexus, processStdRates(standardRates, ncsRate));
    apr.initializeApr();
    this.aprDetails = apr;
    this.offerUid = this.aprDetails.uid;
  };

  setAprOffer = (rgnlAltId: string, offer: Offer, ncsRate: number) => {
    const apr = new AprModel(rgnlAltId, ncsRate);
    apr.setData(offer);
    this.aprDetails = apr;
    this.offerUid = this.aprDetails.uid;
  };

  initLeaseOffer = (brand: string, rgnlAltId: string, seriesProfile: SeriesProfileModel, standards: OfferingStandardRates, rcfAdjustment: number) => {
    const group = new LeaseModel(brand ?? '', rgnlAltId, seriesProfile.data.ncsRcf);
    const newLease = group.addBlankForm(seriesProfile, standards, rcfAdjustment);
    group.selectLease(newLease.uid);
    this.leaseDetails = group;
    this.offerUid = this.leaseDetails.uid;
  };

  setLeaseOffer = (brand: string, rgnlAltId: string, seriesProfile: SeriesProfileModel, offers: Offer[]) => {
    const lease = new LeaseModel(brand, rgnlAltId, seriesProfile.data.ncsRcf);
    const sortedOffers = offers.slice().sort(sortFieldsHelper('leaseExampleSortOrder', true));
    lease.setForms(sortedOffers);
    this.leaseDetails = lease;
    this.offerUid = this.leaseDetails.uid;
  };

  setCashOffer = (data: { rgnlAltId: string; optionType?: string; offer?: Offer }) => {
    const { rgnlAltId, optionType, offer } = data;
    this.cashDetails = new CashModel({ rgnlAltId, optionType, offer });
    this.offerUid = this.cashDetails.uid;
  };

  setMiscOffer = (rgnlAltId: string, offer?: Offer, optionType?: string) => {
    this.miscDetails = new MiscModel({ rgnlAltId, optionType, offer });
    this.offerUid = this.miscDetails.uid;
  };

  setFinalPayOffer = (rgnlAltId: string, offer?: Offer, optionType?: string) => {
    this.finalPayDetails = new FinalPayModel({ rgnlAltId, optionType, offer });
    this.offerUid = this.finalPayDetails.uid;
  };

  setNationalRyoOffer = (rgnlAltId: string, offer?: Offer, optionType?: string, rgnlAlts?: RgnlAltModel[]) => {
    this.nationalRyoDetails = new NationalRyoModel({ rgnlAltId, optionType, offer, rgnlAlts });
    this.offerUid = this.nationalRyoDetails.uid;
  };

  getOfferFields = () => {
    if (this.leaseDetails) {
      return this.leaseDetails.getMaster()?.offerFields;
    }

    const restOffer = this.cashDetails || this.miscDetails || this.aprDetails || this.finalPayDetails || this.nationalRyoDetails;
    return restOffer?.offerFields;
  };

  getOfferId = () => {
    return this.getOfferFields()?.id ?? '';
  };

  getOptionType = () => {
    return this.getOfferFields()?.optionType ?? '';
  };

  getOfferCosts = () => {
    switch (this.getOfferFields()?.optionType) {
      case OPTION_TYPE_NAMES.CUSTOMER_CASH: {
        return this.cashDetails?.offerCostFields;
      }
      case OPTION_TYPE_NAMES.APR: {
        return this.aprDetails?.aprFields;
      }
      case OPTION_TYPE_NAMES.LEASE: {
        return this.leaseDetails?.selectedLease?.leaseFields;
      }
      case OPTION_TYPE_NAMES.NATIONAL_RYO: {
        return this.nationalRyoDetails?.offerCostFields;
      }
      case OPTION_TYPE_NAMES.FINAL_PAY: {
        return this.finalPayDetails?.offerCostFields;
      }
      default: {
        return this.miscDetails?.offerCostFields;
      }
    }
  };

  updateRev = (offers: Offer[]) => {
    if (this.leaseDetails) {
      this.leaseDetails.updateRevs(offers);
    }
    const model = this.cashDetails || this.miscDetails || this.aprDetails || this.finalPayDetails || this.nationalRyoDetails;
    model?.updateOfferField('rev', offers[0].rev);
  };

  isNgl = () => {
    return Boolean(this.leaseDetails && this.leaseDetails.selectedLease?.isNgl);
  };
}

export default OfferModel;
