import { observer } from 'mobx-react-lite';
import { Checkbox, OATIcon, useToast } from 'oat-common-ui';
import { useEffect } from 'react';
import { RgnlAltSummary, useGetPriorSeriesProfileLazyQuery, useGetRgnlAltSummaryLazyQuery } from '../../../../gql/generated';

import useStores from '../../../../stores/useStores';
import SeriesProfileModel, { PreviousMonthScenarioData } from '../../models/SeriesProfileModel';
import SummaryComparison from './SummaryComparison';

import RAComparison from './RAComparison';
import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
}

const CompareScenarios = ({
  seriesProfile: { compareScenarios, isCompareSelectedValid, setIsLastMonthComparison, isLastMonthComparison, setPreviousMonthScenarioData },
  seriesProfile,
}: Props) => {
  const { globalStore } = useStores();
  const { error } = useToast();

  const [getRgnlAltSummary] = useGetRgnlAltSummaryLazyQuery();
  const [getPriorScenario] = useGetPriorSeriesProfileLazyQuery();

  useEffect(() => {
    const getPreviousMonthScenario = async () => {
      try {
        globalStore.setShowProgressBar(true);
        const res = await getPriorScenario({ variables: { seriesProfileId: seriesProfile.id } });

        if (res.data?.getPriorSeriesProfile?.scenario) {
          setPreviousMonthScenarioData(res.data.getPriorSeriesProfile as PreviousMonthScenarioData);
        } else {
          error('There is no scenarios in prior month');
        }
      } catch (e) {
        error((e as Error).message);
      } finally {
        globalStore.setShowProgressBar(false);
      }
    };

    if (isLastMonthComparison) {
      getPreviousMonthScenario();
    }
  }, [error, getPriorScenario, globalStore, isLastMonthComparison, seriesProfile.id, setPreviousMonthScenarioData]);

  useEffect(() => {
    // Set RAs to compareScenarios
    const getRgnlAltWithOffers = async () => {
      for await (const scenario of compareScenarios) {
        try {
          globalStore.setShowProgressBar(true);
          const rgnlAltRes = await getRgnlAltSummary({ variables: { scenarioId: scenario.id } });
          scenario.setRgnlAlts(rgnlAltRes.data?.getRgnlAltSummary as RgnlAltSummary[], seriesProfile);
        } catch (e) {
          error((e as Error).message);
        } finally {
          globalStore.setShowProgressBar(false);
        }
      }
    };

    if (isCompareSelectedValid) {
      getRgnlAltWithOffers();
    }
  }, [compareScenarios, error, getRgnlAltSummary, globalStore, isCompareSelectedValid, seriesProfile]);

  const toggleLastMonthComparison = (checked: boolean) => {
    setIsLastMonthComparison(checked);
    if (!checked) {
      setPreviousMonthScenarioData(undefined);
    }
  };

  return (
    <div className={styles.compareSection}>
      <div className={styles.lastMonthComparisonCheckbox}>
        <Checkbox
          className={styles.checkbox}
          id={`last-month-comparison`}
          isChecked={isLastMonthComparison}
          isDisabled={compareScenarios?.length !== 1 && !isLastMonthComparison}
          isNational
          label="Last Month Comparison"
          onChange={e => {
            toggleLastMonthComparison(e.target.checked);
          }}
        />
        {!isCompareSelectedValid && (
          <p className={styles.warningTxt}>
            <OATIcon className={styles.warningIcon} icon="warning" />
            Please Select 2 Scenarios for Comparison.
          </p>
        )}
      </div>
      {isCompareSelectedValid && !globalStore.showProgressBar && (
        <>
          <SummaryComparison seriesProfile={seriesProfile} />
          <RAComparison seriesProfile={seriesProfile} />
        </>
      )}
    </div>
  );
};

export default observer(CompareScenarios);
