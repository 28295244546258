import { observer } from 'mobx-react-lite';
import { LeaseLocks } from 'oat-admin-common';
import { formatDollars, getSrpLabel, InfoTooltip } from 'oat-common-ui';
import { useState } from 'react';
import BlockLabel from '../../../../../components/BlockLabel';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../utils/appendTfsLabel';
import LeaseFormModel from '../../../models/offers/lease/LeaseFormModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import EditButton from '../components/EditButton';
import NewVinModal, { VinValues } from '../components/NewVinModal';
import SideInput from '../components/SideInput';
import VehicleTooltip from '../components/VehicleTooltip';
import styles from './styles.module.scss';

interface Props {
  leaseForm: LeaseFormModel;
  seriesProfile: SeriesProfileModel;
  onSave: (skipDelay?: boolean) => void;
  useSrpLabelLogic: boolean;
}

const FirstColumn = ({ leaseForm, seriesProfile, onSave, useSrpLabelLogic }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const { uid, leaseFields, updateLeaseFields, updateNewVin } = leaseForm;
  const [addVinModal, setAddVinModal] = useState(false);

  const handleVinSave = (values: VinValues) => {
    updateNewVin(values);
    onSave(true);
  };

  return (
    <>
      <div className={styles.firstCol}>
        <EditButton id={`new-vin-cta-${uid}`} onClick={() => setAddVinModal(true)} disabled={seriesProfile.data.isBudgetConfirmed}>
          New VIN
        </EditButton>
        <div className={styles.modelCode}>
          {`Model Code: `}
          {leaseFields.modelCode && (
            <>
              {leaseFields.modelCode} <InfoTooltip id={`lease-mdl-code-${uid}`} message={<VehicleTooltip leaseForm={leaseForm} />} iconSize={12} />
            </>
          )}
        </div>
        <BlockLabel label={getSrpLabel(useSrpLabelLogic, isLexus(), true)} bottomMargin>
          <SideInput
            disabled={seriesProfile.data.isBudgetConfirmed}
            id={`total-msrp-${uid}`}
            value={leaseFields.totalMsrp.toString()}
            onChange={val => {
              updateLeaseFields('totalMsrp', val, LeaseLocks.TARGET_PAYMENT);
              onSave();
            }}
          />
        </BlockLabel>
        <BlockLabel label={appendTfsLabel(isLexus(), 'Residual Value Rate')} bottomMargin>
          <SideInput
            disabled={seriesProfile.data.isBudgetConfirmed}
            id={`residual-rate-${uid}`}
            percentage
            value={leaseFields.residualRate.toString()}
            onChange={val => {
              updateLeaseFields('residualRate', val, LeaseLocks.TARGET_PAYMENT);
              onSave();
            }}
          />
        </BlockLabel>
        <BlockLabel label={appendTfsLabel(isLexus(), 'Residual Value Amount')} bottomMargin>
          ${formatDollars(leaseFields.residualAmount)}
        </BlockLabel>
        <BlockLabel label="NGL Residual Value Rate" bottomMargin>
          {leaseFields.nglResidualRate}%
        </BlockLabel>
        <BlockLabel label="NGL Residual Value Amount" bottomMargin>
          ${formatDollars(leaseFields.nglResidualAmount)}
        </BlockLabel>
        <BlockLabel label="Dealer Cost per Invoice" bottomMargin>
          <SideInput
            disabled={seriesProfile.data.isBudgetConfirmed}
            id={`dealer-invoice-${uid}`}
            value={leaseFields.dealerCostPerInvoice.toString()}
            onChange={val => {
              updateLeaseFields('dealerCostPerInvoice', val, LeaseLocks.TARGET_PAYMENT);
              onSave();
            }}
          />
        </BlockLabel>
        <BlockLabel label="Gross Cap Cost" bottomMargin>
          ${formatDollars(leaseFields.grossCapCost)}
        </BlockLabel>
        <BlockLabel label="Net Cap Cost" bottomMargin>
          ${formatDollars(leaseFields.netCapCost)}
        </BlockLabel>
        <BlockLabel label="Total Dealer Gross/Proceeds" bottomMargin>
          {leaseFields.vin ? (
            `$${formatDollars(leaseFields.totalDealerGross)}`
          ) : (
            <SideInput
              disabled={seriesProfile.data.isBudgetConfirmed}
              id={`total-dealer-gross-${uid}`}
              value={leaseFields.totalDealerGross.toString()}
              onChange={val => {
                updateLeaseFields('totalDealerGross', val);
                onSave();
              }}
            />
          )}
        </BlockLabel>
      </div>
      {addVinModal && <NewVinModal leaseForm={leaseForm} seriesProfile={seriesProfile} onClose={() => setAddVinModal(false)} onSave={handleVinSave} />}
    </>
  );
};

export default observer(FirstColumn);
