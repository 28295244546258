import { observer } from 'mobx-react-lite';
import { IAssumptionMonth } from '../../../../models/Assumptions';
import useStores from '../../../../stores/useStores';
import AssumptionInput from './Inputs/AssumptionInput';

import styles from '../styles.module.scss';

type Props = {
  assumptions: IAssumptionMonth[];
};

const ResidualValues = ({ assumptions }: Props) => {
  const {
    assumptionsStore: { updateAssumptionField },
  } = useStores();

  return (
    <>
      <div className={styles.ngcRow}>
        <div className={styles.ngcShadowWrapper}>
          <div className={styles.ngcGridRow}>
            <div className={styles.ngcRowHeader}>
              <label>Residual Value</label>
            </div>
            {assumptions.map(assumption => (
              <AssumptionInput
                assumption={assumption}
                field="residualValue"
                id={`residual-value-${assumption.month}`}
                key={`residual-value-${assumption.id}`}
                onChange={updateAssumptionField}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ResidualValues);
