import { SeriesSortItem } from '../../gql/generated';
import ScenarioModel from '../../pages/NationalPrograms/models/ScenarioModel';

export const sortDragItemsList = (seriesList: SeriesSortItem[] | ScenarioModel[], srcIndex: number, destIndex: number) => {
  const seriesListCopy = [...seriesList];
  const [itemToMove] = seriesListCopy.splice(srcIndex, 1);
  seriesListCopy.splice(destIndex, 0, itemToMove);

  return seriesListCopy;
};
