import { observer } from 'mobx-react-lite';
import { assignNumberValue, formatDollars, formatDollarsCents, NumberInput, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import EditButton from '../EditButton';
import styles from './styles.module.scss';

interface Props {
  id: string;
  value: string;
  percentage?: boolean;
  decimal?: boolean;
  error?: boolean;
  disabled?: boolean;
  onChange: (val: string) => void;
}

const SideInput = ({ id, disabled, value, decimal, percentage, error, onChange }: Props) => {
  const [edit, setEdit] = useState(false);

  const handleOnEdit = () => {
    setEdit(true);
  };

  const handleOnSubmit = () => {
    setEdit(false);
  };

  if (edit) {
    const numConfig = {
      wholeNumber: !decimal,
      dollar: decimal,
      allowNegative: false,
    };

    return (
      <div className={styles.sideInputContainer}>
        <NumberInput
          id={`sideinput-${id}`}
          isNational
          className={styles.sideInput}
          value={value}
          {...numConfig}
          onValueChange={val => onChange(val.value)}
          autoFocus
          error={error}
          units
        />
        <OATIcon id={`sideicon-${id}`} icon="check-mark" onClick={handleOnSubmit} pointer />
      </div>
    );
  }

  return (
    <EditButton id={`editbtn-${id}`} onClick={handleOnEdit} disabled={disabled}>
      {!percentage && '$'}
      {value === '' ? value : decimal ? formatDollarsCents(value) : formatDollars(Math.round(assignNumberValue(value)))}
      {percentage && '%'}
    </EditButton>
  );
};

export default observer(SideInput);
