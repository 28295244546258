import { makeAutoObservable } from 'mobx';
import { RCF_INITIAL_VALUE } from '../constants/global';
import { OfferingStandardRates } from '../gql/generated';
import LeaseRateModel from '../pages/IpSettings/RatesTab/models/LeaseRateModel';

class RatesTabLeaseRCFStore {
  id = '';
  rev = '';
  leaseDifferential = RCF_INITIAL_VALUE;
  leaseRates: LeaseRateModel[] = [];
  leaseStandardRcf = 0;
  leaseStandardRcfMaster = 0;
  leaseHasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateLeaseDifferential = (val: number) => {
    this.leaseDifferential = val;
    this.leaseHasUnsavedChanges = true;
  };

  updateLeaseRates = (val: number) => {
    this.leaseRates = this.leaseRates.map(rate => {
      rate.differential = val;
      rate.rate = val + rate.previousRate;
      return rate;
    });
  };

  updateLeaseStandardRcf = (val: number) => {
    this.leaseStandardRcf = val + this.leaseStandardRcfMaster;
  };

  validateLeaseRates = () => {
    return new Set(this.leaseRates.map(rate => rate.differential)).size === 1;
  };

  updateLeaseRate = (uid: string, tier: string, term: number, rate: number) => {
    const foundRate = this.leaseRates.find(leaseRate => leaseRate.uid === uid && leaseRate.tier === tier && leaseRate.term === term);

    if (foundRate) {
      foundRate.differential = rate;
      foundRate.rate = foundRate.previousRate + rate;
      this.leaseDifferential = this.validateLeaseRates() ? rate : RCF_INITIAL_VALUE;
      tier === '1+' && this.updateLeaseStandardRcf(rate);
    }

    this.leaseHasUnsavedChanges = true;
  };

  setLeaseHasUnsavedChanges = (val: boolean) => {
    this.leaseHasUnsavedChanges = val;
  };

  setData = (data: OfferingStandardRates) => {
    this.id = data.id || '';
    this.rev = data.rev || '';
    this.leaseDifferential = data.leaseDifferential === 0 ? RCF_INITIAL_VALUE : data.leaseDifferential;
    this.leaseRates = data.leaseRates.map(rate => new LeaseRateModel(rate));
    this.leaseStandardRcf = this.leaseRates.find(r => r.tier === '1+')?.rate || 0;
    this.leaseStandardRcfMaster = this.leaseRates.find(r => r.tier === '1+')?.previousRate || 0;
  };
}

export default RatesTabLeaseRCFStore;
