import { observer } from 'mobx-react-lite';
import { AtcUtils } from 'oat-admin-common';
import { ButtonGroup, OATIcon, Tooltip } from 'oat-common-ui';
import Button from '../../../../../components/Button';
import useStores from '../../../../../stores/useStores';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from '../styles.module.scss';

interface Props {
  seriesProfileModel: SeriesProfileModel;
  onConfirmBudget: () => void;
  onConfirmSetup: () => void;
}

const SeriesHeaderButtons = ({ seriesProfileModel, onConfirmBudget, onConfirmSetup }: Props) => {
  const {
    nationalProgramsStore: { offering, isOfferingPublished },
  } = useStores();
  const { id, data, toggleShowCompare, toggleIsSetupView, isSetupView, showCompare, isConfirmSetupValid, selectedScenario } = seriesProfileModel;

  // cta flags
  const isSwitchViewDisabled = !data.isBudgetConfirmed || (data.isBudgetConfirmed && !data.hasOffersForRegions) || showCompare;
  const isConfirmBudgetDisabled =
    isSetupView ||
    seriesProfileModel.isSaving ||
    (!data.isBudgetConfirmed && !seriesProfileModel.canConfirm()) ||
    (Boolean(offering.isAtc) && AtcUtils.isAtcId(seriesProfileModel.data.id) && !Boolean(selectedScenario?.isPublished)) ||
    seriesProfileModel.showCompare;

  const isConfirmSetupDisabled = !data.isBudgetConfirmed || !isSetupView || seriesProfileModel.isSaving || !isConfirmSetupValid;

  const handleConfirmation = (forBudget: boolean) => {
    forBudget ? onConfirmBudget() : onConfirmSetup();
  };

  return (
    <>
      {!isOfferingPublished && (
        <ButtonGroup className={styles.marginRight}>
          <Button
            id={`confirm-budget-cta-${id}`}
            variant={!data.isBudgetConfirmed ? 'blue-green' : 'green-blue'}
            onClick={() => handleConfirmation(true)}
            disabled={isConfirmBudgetDisabled}
          >
            {data.isBudgetConfirmed ? 'Edit Budget' : 'Confirm Budget'}
          </Button>
          <Button
            id={`confirm-setup-cta-${id}`}
            disabled={isConfirmSetupDisabled}
            variant={!data.isSetupConfirmed ? 'blue-green' : 'green-blue'}
            onClick={() => handleConfirmation(false)}
          >
            {data.isSetupConfirmed ? 'Edit Setup' : 'Confirm Setup'}
          </Button>
        </ButtonGroup>
      )}
      <Button
        id={`switch-cta-${id}`}
        variant={isSetupView ? 'transparent-blue' : 'transparent-black'}
        onClick={toggleIsSetupView}
        disabled={isSwitchViewDisabled}
        className={styles.marginTop}
      >
        <Tooltip message={`Switch to ${isSetupView ? 'Budget' : 'Setup'} View`} id={`switch-tooltip-${id}`} place="bottom" disable={isSwitchViewDisabled}>
          <OATIcon icon="switch" size={15} />
        </Tooltip>
      </Button>
      <Button
        id={`compare-cta-${id}`}
        onClick={toggleShowCompare}
        variant={showCompare ? 'text' : data.isBudgetConfirmed ? 'gray' : 'black'}
        disabled={data.isBudgetConfirmed}
        className={styles.marginTop}
      >
        <Tooltip message="Compare Scenarios" id={`compare-tooltip-${id}`} place="bottom" disable={data.isBudgetConfirmed}>
          <OATIcon icon="compare" size={30} />
        </Tooltip>
      </Button>
    </>
  );
};

export default observer(SeriesHeaderButtons);
