import { AdminModels } from 'oat-admin-common';
import SeriesListModel from '../../../../models/SeriesListModel';

export const createSaveBudgetPayload = (
  seriesList: SeriesListModel[],
  fyMultiSeries: {
    [month: number]: AdminModels.FYMultiSeriesItem;
  },
) => {
  const months: { [month: number]: AdminModels.BudgetMonthResponse } = {};

  for (const seriesListItem of seriesList) {
    for (const seriesMonth of Object.values(seriesListItem.seriesMonths)) {
      const seriesItem = {
        seriesName: seriesListItem.series,
        modelYears: Object.values(seriesMonth.modelYearDetails).map(m => {
          const { modelYear, sales, stock, pnvs, totalCost } = m;

          return {
            modelYear,
            sales,
            stock,
            pnvs,
            totalCost,
          };
        }),
      };

      const { month, year } = seriesMonth;

      if (!months[month]) {
        months[month] = {
          month,
          year,
          multiSeriesCost: fyMultiSeries[month].totalCost ?? 0,
          multiSeriesOffers: Object.values(fyMultiSeries[month].multiSeriesOffers) ?? [],
          series: [seriesItem],
        };

        continue;
      }

      months[month].series.push(seriesItem);
    }
  }

  for (const fyMultiSeriesItem of Object.values(fyMultiSeries)) {
    const { month, year, totalCost, multiSeriesOffers } = fyMultiSeriesItem;

    if (!months[month]) {
      months[month] = {
        month: month,
        year: year,
        multiSeriesCost: totalCost,
        multiSeriesOffers: Object.values(multiSeriesOffers),
        series: [],
      };
    }
  }

  return Object.values(months);
};
