import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { MultiSeriesOffersBudget, useSaveMultiSeriesOffersBudgetMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';

import styles from '../styles.module.scss';

const MultiSeriesOffersFooter = () => {
  const {
    multiSeriesOffersBudgetStore: { addNewMultiSeriesOffer, rev, brand, fiscalYear, setIsLoading, initData, offers, hasErrror, draftOfferingMonths },
  } = useStores();

  const { error } = useToast();
  const [saveMultiSeriesOffersBudget] = useSaveMultiSeriesOffersBudgetMutation();

  const saveMultiOffersBudgetHandler = async () => {
    const payload: MultiSeriesOffersBudget = {
      rev,
      brand,
      fiscalYear,
      offers:
        offers.map(offer => ({
          id: offer.id,
          sortOrder: offer.sortOrder,
          offerName: offer.offerName,
          months: offer.months.map(month => ({
            month: month.month,
            year: month.year,
            amount: Number(month.amount),
          })),
        })) || [],
    };

    try {
      setIsLoading(true);
      const res = await trackPromise(saveMultiSeriesOffersBudget({ variables: { input: { ...payload } } }));
      initData(brand, res.data?.saveMultiSeriesOffersBudget.multiSeriesOffersBudget as MultiSeriesOffersBudget, draftOfferingMonths);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.multiSeriesFooter}>
      <ButtonGroup>
        <Button variant="text" id="add-offers-btn" onClick={addNewMultiSeriesOffer}>
          <OATIcon icon="plus" className={styles.plusIcon} />
          <span className={styles.addOffersText}>Add Offers</span>
        </Button>
        <Button variant="primary" disabled={hasErrror} onClick={saveMultiOffersBudgetHandler} className={styles.saveOffersBtn} id="save-offers-btn">
          Save Offers
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default observer(MultiSeriesOffersFooter);
