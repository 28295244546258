import { useToast } from 'oat-common-ui';
import useStores from '../../stores/useStores';
import { REPORT_VALUE } from '../../constants/global';
import {
  Report,
  useGenerateBudgetReportMutation,
  useGenerateApprovedBudgetReportMutation,
  useGenerateNationalOfferDetailsReportMutation,
  useGenerateGuidanceReportMutation,
  useGenerateOfferSummaryReportMutation,
  useGenerateNationalOffersSetupReportMutation,
  useGenerateExecutiveOfferSummaryReportMutation,
  useGenerateHistoricalProgramTrackerReportMutation,
} from '../../gql/generated';
import { useCallback } from 'react';

const useGenerateReport = (report: { value: string; label: string }) => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    globalStore: { setShowProgressBar },
    reportsStore: { addReport, setGeneratingReport, selectedOfferingData, fiscalYear, setSelectedReport, defaultReport },
  } = useStores();
  const { error } = useToast();

  const [generateBudgetReport] = useGenerateBudgetReportMutation();
  const [generateApprovedBudgetReport] = useGenerateApprovedBudgetReportMutation();
  const [generateNationalOfferDetailsReport] = useGenerateNationalOfferDetailsReportMutation();
  const [generateGuidanceReport] = useGenerateGuidanceReportMutation();
  const [generateOfferSummaryReport] = useGenerateOfferSummaryReportMutation();
  const [generateNationalOffersSetupReport] = useGenerateNationalOffersSetupReportMutation();
  const [generateExecutiveOfferSummaryReport] = useGenerateExecutiveOfferSummaryReportMutation();
  const [generateHistoricalProgramTrackerReport] = useGenerateHistoricalProgramTrackerReportMutation();

  const generateReport = useCallback(async () => {
    try {
      setShowProgressBar(true);
      setGeneratingReport(true);
      let resultReport: Report | undefined;

      switch (report.value) {
        case REPORT_VALUE.APPROVED:
        case REPORT_VALUE.FORECASTED:
        case REPORT_VALUE.WORKING: {
          const res = await generateBudgetReport({ variables: { input: { brand, fiscalYear: Number(fiscalYear), type: report.value } } });
          if (res.data?.generateBudgetReport.success) {
            resultReport = res.data.generateBudgetReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.FY_INCENTIVE_PNVS:
        case REPORT_VALUE.FY_MODELYEAR_ALLOCATION: {
          const res = await generateApprovedBudgetReport({ variables: { input: { brand, fiscalYear: Number(fiscalYear), reportType: report.value } } });
          if (res.data?.generateApprovedBudgetReport.success) {
            resultReport = res.data.generateApprovedBudgetReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.NATIONAL_OFFER_DETAILS: {
          const res = await generateNationalOfferDetailsReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateNationalOfferDetailsReport.success) {
            resultReport = res.data.generateNationalOfferDetailsReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.NATIONAL_GUIDANCE: {
          const res = await generateGuidanceReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateGuidanceReport.success) {
            resultReport = res.data.generateGuidanceReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.OFFER_SETUP: {
          const res = await generateNationalOffersSetupReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateNationalOffersSetupReport.success) {
            resultReport = res.data.generateNationalOffersSetupReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.SUMMARY: {
          const res = await generateOfferSummaryReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateOfferSummaryReport.success) {
            resultReport = res.data.generateOfferSummaryReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.EXECUTIVE_OFFER_SUMMARY: {
          const res = await generateExecutiveOfferSummaryReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateExecutiveOfferSummaryReport.success) {
            resultReport = res.data.generateExecutiveOfferSummaryReport.report as Report;
          }
          break;
        }
        case REPORT_VALUE.HISTORICAL_PROGRAM_TRACKER: {
          const res = await generateHistoricalProgramTrackerReport({ variables: { input: { offeringId: selectedOfferingData.value } } });
          if (res.data?.generateHistoricalProgramTrackerReport.success) {
            resultReport = res.data.generateHistoricalProgramTrackerReport.report as Report;
          }
          break;
        }

        default:
          break;
      }

      if (resultReport) {
        addReport(resultReport);
        setSelectedReport(defaultReport);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setSelectedReport(defaultReport);
      setShowProgressBar(false);
      setGeneratingReport(false);
    }
  }, [
    addReport,
    brand,
    defaultReport,
    error,
    fiscalYear,
    generateApprovedBudgetReport,
    generateBudgetReport,
    generateExecutiveOfferSummaryReport,
    generateGuidanceReport,
    generateHistoricalProgramTrackerReport,
    generateNationalOfferDetailsReport,
    generateNationalOffersSetupReport,
    generateOfferSummaryReport,
    report.value,
    selectedOfferingData.value,
    setGeneratingReport,
    setSelectedReport,
    setShowProgressBar,
  ]);

  return {
    generateReport,
  };
};

export default useGenerateReport;
