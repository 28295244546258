import { PersonalSettingsLayout } from 'oat-common-ui';
import authClient from '../../../authClient';
import NGCHeader from '../../../components/NGCHeader';
import styles from './styles.module.scss';

const PersonalSettings = () => {
  const leftPanelMessage = `Reset your password or log out of the Offers Admin Tool.`;
  const logoutText = `Log Out of Offers Admin Tool`;
  const resetUrl = 'https://account.activedirectory.windowsazure.com/Profile/Default.aspx';

  const handleOnLogout = () => {
    authClient.logout();
  };

  return (
    <>
      <NGCHeader title="Personal Settings" />
      <PersonalSettingsLayout
        containerClass={styles.settingsContainer}
        leftPanelClass={styles.leftPanelNational}
        leftPanelMessage={leftPanelMessage}
        logoutText={logoutText}
        onClickLogout={handleOnLogout}
        resetUrl={resetUrl}
        rightPanelClass={styles.rightPanelNational}
      />
    </>
  );
};

export default PersonalSettings;
