import { makeAutoObservable } from 'mobx';
import { CreateLeaseModels } from 'oat-common-ui';

export class Fields {
  trimQuery = '';
  configQuery = '';
  vinQuery = '';
  dealerCodeQuery = '';
  trim: CreateLeaseModels.TrimItem | undefined = undefined;
  config: CreateLeaseModels.ConfigItem | undefined = undefined;
  configList: CreateLeaseModels.ConfigItem[] = [];
  vin: CreateLeaseModels.VinItem | undefined = undefined;
  vinList: CreateLeaseModels.VinItem[] = [];
  name = 'Lease';
  pnv = 0;
  tfsCostShare = 20;
  note = '';
  tdaNote = '';
  isSpecialEdition = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export default Fields;
