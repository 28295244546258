import { ReactNode } from 'react';
import { FEATURE_OR_4154 } from '../../constants/global';
import { CostShareItem, useGetCostShareItemByOfferingIdQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import Error from '../../pages/ErrorPage';
import useStores from '../../stores/useStores';
import MainHeader from '../MainHeader';

interface Props {
  children?: ReactNode;
}

const GetNationalTfsCostShares = ({ children }: Props) => {
  const { offeringId } = useUrlParams();

  const {
    nationalCostShareStore: { setCostShares },
  } = useStores();

  const { loading, error, data } = useGetCostShareItemByOfferingIdQuery({ variables: { offeringId }, skip: !FEATURE_OR_4154 });

  if (loading) {
    return <MainHeader showDefault={false} showProgressBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    setCostShares(data.getCostShareItemByOfferingId as CostShareItem);
  }

  return <>{children}</>;
};

export default GetNationalTfsCostShares;
