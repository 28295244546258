import { observer } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import { SeriesTitle } from '../../../../constants/global';
import SeriesMonthModel from '../../models/SeriesMonthModel';
import FYSeriesBoxListItem from './FYSeriesBoxListItem';
import GridItemWrapper from './GridItemWrapper';

interface Props {
  series: string;
  fiscalMonth: AdminModels.FiscalMonth;
  seriesMonthModel?: SeriesMonthModel;
}

const GridItemRowDetail = ({ series, fiscalMonth, seriesMonthModel }: Props) => {
  return (
    <GridItemWrapper>
      <li>
        <FYSeriesBoxListItem title={SeriesTitle.SALES} hasValue={!!seriesMonthModel} itemValue={seriesMonthModel?.totals.sales ?? 0} />
      </li>
      <li>
        <FYSeriesBoxListItem
          title={SeriesTitle.PNVS}
          hasValue={!!seriesMonthModel}
          itemValue={seriesMonthModel?.totals.pnvs ?? 0}
          updateValue={seriesMonthModel?.updateTotalsPnvsValue}
          updateParentValues={seriesMonthModel?.updateParentValues}
        />
      </li>
      <li>
        <FYSeriesBoxListItem title={SeriesTitle.TOTAL} hasValue={!!seriesMonthModel} itemValue={seriesMonthModel?.totals.totalCost ?? 0} />
      </li>
    </GridItemWrapper>
  );
};

export default observer(GridItemRowDetail);
