import { DraggingStyle, NotDraggingStyle } from 'oat-common-ui';
import { SeriesSortItem } from '../../../gql/generated';

export const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => {
  // needed to override default cursor style while dragging
  return {
    ...draggableStyle,
    userSelect: 'none',
    pointerEvents: 'auto',
    cursor: isDragging ? 'all-scroll' : '',
  };
};

export const removeDuplicates = (list: SeriesSortItem[]) => {
  return list.filter((item, i, list) => list.findIndex(listItem => listItem.bosuSeries === item.bosuSeries) === i);
};
