import clsx from 'clsx';
import { BlockInputLabel, Input, NumberInput } from 'oat-common-ui';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import OfferTitle from '../../components/OfferTitle';
import CashModel from '../../models/offers/CashModel';

import { observer } from 'mobx-react-lite';
import BasicPNVS from '../../../../components/BasicPNVS';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import styles from './styles.module.scss';
import useSaveCash from './useSaveCash';

interface Props {
  cash: CashModel;
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;
}

const CashSection = ({ cash, rgnlAlt, seriesProfile }: Props) => {
  const { uid, offerFields, offerCostFields, hasAmountError, hasPenetrationError, updateOfferField, updateAmount } = cash;
  const { saveCashOffer } = useSaveCash(seriesProfile, rgnlAlt, cash);

  return (
    <>
      <OfferHeader>
        <OfferTitle offerType={offerFields.optionType} title={offerFields.optionType} />
        <BasicPNVS offerPnvs={offerCostFields.offerPnvs} />
      </OfferHeader>
      {/* RA Name */}
      <fieldset className={styles.cashOfferSection} disabled={seriesProfile.data.isBudgetConfirmed}>
        <BlockInputLabel label="Name" className={clsx(styles.inputWrapper, styles.textInput)}>
          <Input
            id={`name-${uid}`}
            isNational
            value={offerFields.name}
            className={styles.input}
            onChange={val => {
              seriesProfile.clearErrors();
              updateOfferField('name', val.currentTarget.value);
              saveCashOffer();
            }}
            error={!offerFields.name.length}
          />
        </BlockInputLabel>
        <BlockInputLabel label="Amount" className={clsx(styles.inputWrapper, styles.numberInput)}>
          <NumberInput
            id={`amount-${uid}`}
            isNational
            allowNegative={false}
            className={styles.input}
            decimalScale={0}
            dollarSign
            onChange={e => {
              seriesProfile.clearErrors();
              updateAmount(e.target.value);
              saveCashOffer();
            }}
            value={offerCostFields.amount}
            error={hasAmountError()}
            units
          />
        </BlockInputLabel>
        <BlockInputLabel label="Total Cash Pen %" className={clsx(styles.inputWrapper, styles.numberInput)}>
          <NumberInput
            id={`cash-pen-${uid}`}
            isNational
            className={styles.input}
            percentageSign
            value={offerFields.penetrationRate}
            wholeNumber
            min={0}
            max={100}
            onChange={e => {
              seriesProfile.clearErrors();
              updateOfferField('penetrationRate', e.target.value);
              saveCashOffer();
            }}
            error={hasPenetrationError()}
          />
        </BlockInputLabel>
      </fieldset>

      <OfferFooter
        uid={uid}
        seriesProfile={seriesProfile}
        offerFields={offerFields}
        rgnlAlt={rgnlAlt}
        isForRegions={offerFields.isForRegions}
        isForRegionsDisabled={false}
        updateOfferField={(field, val) => {
          updateOfferField(field, !val);
          saveCashOffer();
        }}
      />
    </>
  );
};

export default observer(CashSection);
