import { observer } from 'mobx-react-lite';
import { ScenarioModalLayout } from '../../../components/SeriesProfileSection/ScenarioActionModal';
import ScenariosMenu from '../../../components/SeriesProfileSection/ScenariosMenu';
import SeriesProfileModel from '../../../models/SeriesProfileModel';

interface Props {
  handleOpenModal: (type: ScenarioModalLayout) => void;
  seriesProfileModel: SeriesProfileModel;
}

const ScenariosSection = ({ handleOpenModal, seriesProfileModel }: Props) => {
  const { id, data, scenarios, showCompare, selectedScenario } = seriesProfileModel;

  return (
    <ScenariosMenu id={id} disabled={showCompare}>
      {data.isBudgetConfirmed ? (
        <>
          <li onClick={() => handleOpenModal('Rename')}>Rename Scenario</li>
          <li onClick={() => handleOpenModal('Export')}>Export Scenario(s)</li>
        </>
      ) : (
        <>
          <li onClick={() => handleOpenModal('Add')}>Add Scenario</li>
          <li onClick={() => handleOpenModal('Rename')}>Rename Scenario</li>
          <li onClick={() => handleOpenModal('Copy')}>Copy Scenario</li>
          <li onClick={() => handleOpenModal('Reorder')}>Reorder Scenarios</li>
          <li onClick={() => handleOpenModal('Export')}>Export Scenario(s)</li>
          {scenarios.length > 1 && !selectedScenario?.isPublished && <li onClick={() => handleOpenModal('Delete')}>Delete Scenario</li>}
        </>
      )}
    </ScenariosMenu>
  );
};

export default observer(ScenariosSection);
