import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { DraggingStyle, OATIcon, Tooltip } from 'oat-common-ui';
import { getFormattedLabel } from '../../pages/NationalPrograms/utils/getFormattedLabel';
import styles from './styles.module.scss';

interface Props {
  item: string;
  itemOrder?: number;
  style: DraggingStyle;
  innerRef: (element: HTMLElement | null) => any;
  isScenarioModal?: boolean;
  moveItemDown?: () => void;
  moveItemUp: () => void;
}

const DragSortingItem = ({ item, itemOrder, innerRef, style, isScenarioModal = false, moveItemUp, moveItemDown, ...rest }: Props) => {
  return (
    <div ref={innerRef} className={styles.dragSortingContainer}>
      <div style={style} className={clsx(styles.dragSortingItem, isScenarioModal && styles.dragScenarios)} {...rest}>
        {!isScenarioModal ? (
          <>
            <span className={styles.name}>{item}</span>
            <OATIcon icon="drag" />
          </>
        ) : (
          <>
            <OATIcon icon="drag" className={styles.dragIcon} />
            <div className={styles.itemNameSection}>
              <Tooltip message={item} place="bottom" id={`tab-tooltip-${itemOrder}`}>
                <span className={styles.orderNumber}>{itemOrder}</span>
                <span className={styles.name}>{getFormattedLabel(item, 50).formattedName}</span>
              </Tooltip>
            </div>
          </>
        )}
      </div>
      {isScenarioModal && (
        <div className={styles.arrowSection}>
          <OATIcon className={styles.arrow} icon="angle-up" onClick={moveItemUp} />
          <OATIcon className={styles.arrow} icon="angle-down" onClick={moveItemDown} />
        </div>
      )}
    </div>
  );
};

export default observer(DragSortingItem);
