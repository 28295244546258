import { observer } from 'mobx-react-lite';
import { DefaultModal, uploadWithPresignedUrl, useToast } from 'oat-common-ui';
import { useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../../components/MainHeader';
import NGCHeader from '../../../components/NGCHeader';
import UploadFile from '../../../components/UploadFile';
import { UPLOAD_TYPE } from '../../../constants/global';
import { PresignedUrlResponse, useUploadStateAprCapsFileMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import getPresignedUploadUrl from '../../../utils/getPresignedUploadUrl';
import Header from '../../FYRollup/components/header/Header';

const StateAprCapsComponent = () => {
  const {
    stateAprCapsStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const { error, success } = useToast();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [uploadStateAprCaps] = useUploadStateAprCapsFileMutation();

  const handleClick = () => hiddenFileInput.current?.click();

  const handleChange = () => {
    const files = hiddenFileInput.current?.files as FileList;
    setFileName(files[0].name);
  };

  const handleUpload = async () => {
    if (hiddenFileInput.current?.files) {
      try {
        const presignedUrlResponse: PresignedUrlResponse = await getPresignedUploadUrl(UPLOAD_TYPE.STATE_APR_CAPS, fileName, brand);
        const file = hiddenFileInput.current?.files[0];
        await uploadWithPresignedUrl(presignedUrlResponse.presignedUrl, file);

        const { data } = await trackPromise(
          uploadStateAprCaps({
            variables: {
              input: {
                brand,
                s3Key: presignedUrlResponse.s3Key,
                fileName: presignedUrlResponse.fileName,
              },
            },
          }),
        );

        if (data?.uploadStateAprCapsFile.success) {
          stateAprCapsStore.setLastUploadedFile(data.uploadStateAprCapsFile.lastUploadedFile);
        }

        setFileName('');

        if (hiddenFileInput.current) {
          hiddenFileInput.current.value = '';
        }
        success(`Uploaded successfully`);
      } catch (e) {
        error((e as Error).message);
      } finally {
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Header>
        <MainHeader showDefault={false} />
        <NGCHeader title="State APR Caps" />
      </Header>
      <UploadFile
        btnText="Publish"
        fileName={fileName}
        hasDate
        handleBrowse={handleClick}
        handleFileChange={handleChange}
        handleUpload={() => setShowModal(true)}
        lastUploadedDate={stateAprCapsStore.lastUploadedDate}
        lastUploadedFile={stateAprCapsStore.lastUploadedFile}
        ref={hiddenFileInput}
        setFileName={setFileName}
        title="State APR Caps File"
      />
      <DefaultModal
        id="state-apr-caps-publish"
        title="Publish State APR Cap"
        message="State APR caps will become effective immediately. Are you sure you want to continue?"
        submitText="Confirm"
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleUpload}
      />
    </>
  );
};

export default observer(StateAprCapsComponent);
