import { formatDollars, formatMillions, formatNumber } from 'oat-common-ui';
import { useMemo } from 'react';

export const FormatValue = (
  val: number | string,
  wholeNumber: boolean,
  dollarSign: boolean,
  roundMillions: boolean,
  useMillions: boolean | undefined = false,
  millionsThreshold?: number,
) => {
  const formattedValue = useMemo(() => {
    if (useMillions) {
      return millionsThreshold === undefined || +val >= millionsThreshold ? `${formatMillions(val, roundMillions)}M` : formatNumber(val, true, false);
    }
    return wholeNumber || dollarSign ? formatDollars(val) : formatNumber(val, true, false);
  }, [dollarSign, useMillions, wholeNumber, val, roundMillions, millionsThreshold]);

  return formattedValue;
};
