import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../../styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const Header = ({ children, className }: Props) => {
  return <div className={clsx(styles.header, className)}>{children}</div>;
};

export default Header;
