import AccordionItemModel from '../models/AccordionItemModel';

const checkPartial = (items: AccordionItemModel[]) => {
  const filteredLength = items.filter(item => item.checked).length;
  return (filteredLength < items.length && filteredLength > 0) || items.some(item => item.isPartial);
};

/**
 *
 * @param items Root items
 */
const recursivePartialSelection = (items: AccordionItemModel[]) => {
  items.forEach(item => {
    if (item.items.every(item => item.items.length)) {
      recursivePartialSelection(item.items);
    }
    item.isPartial = checkPartial(item.items);
  });
};

export default recursivePartialSelection;
