import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { IMultiItem, Input, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import useStores from '../../../../../stores/useStores';
import ModelCodeMappingModal from '../../../components/ModelCodeMappingModal';
import Series from '../Series/Series';
import styles from './styles.module.scss';

const ModelCodeMappings = () => {
  const {
    seriesAndModelsStore: { modelCodeMappings, seriesMapping },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();
  const { filteredSeries, searchInput, setSearchInput, setSelectedBosuSeries } = modelCodeMappings;
  const [selectedItem, setSelecctedItem] = useState<IMultiItem>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = (query: string) => {
    setSearchInput(query);
  };

  const handleOnEdit = (selected: IMultiItem) => {
    setIsModalOpen(true);
    setSelectedBosuSeries(selected, seriesMapping);
    setSelecctedItem(selected);
  };

  return (
    <>
      <div className={styles.parent}>
        <div>
          <BlockInputLabel labelComponent={<span className={styles.modelLabel}>Model Codes Mapping</span>}>
            <BlockInputLabel label="Available Series Profiles" className={styles.listHeader} />
            <Input
              isNational
              id="model-code-mapping-search"
              placeholder="Search by series"
              value={searchInput}
              onChange={e => handleSearch(e.target.value)}
              className={styles.searchInput}
            />
            <OATIcon icon="search" className={styles.searchIcon} />
          </BlockInputLabel>

          <div className={styles.nationalPane}>
            <ul className={clsx(styles.mspList, isPublished && styles.disabledMspList)} data-testid="model-code-mapping-list">
              {filteredSeries.map(listItem => (
                <Series key={listItem.id} listItem={listItem} onEdit={handleOnEdit} />
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isModalOpen && <ModelCodeMappingModal onClose={() => setIsModalOpen(false)} selectedItem={selectedItem} />}
    </>
  );
};

export default observer(ModelCodeMappings);
