import { OATLoadingBar } from 'oat-common-ui';
import { Report, useAvailableFiscalYearReportsQuery, useAvailableNationalProgramReportsQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import NationalPlanningComponent from './NationalPlanningComponent';

const FetchReportsComponent = () => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    reportsStore: { fiscalYear, selectedOfferingData, setAvailableReports },
  } = useStores();

  const {
    data: fData,
    loading: fLoading,
    error: fError,
  } = useAvailableFiscalYearReportsQuery({
    variables: { brand, fiscalYear: +fiscalYear },
    skip: !selectedOfferingData.value,
  });

  const {
    data: nData,
    loading: nLoading,
    error: nError,
  } = useAvailableNationalProgramReportsQuery({
    variables: {
      offeringId: selectedOfferingData.value,
    },
    skip: !selectedOfferingData.value,
  });

  const loading = fLoading || nLoading;
  const error = fError || nError;

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (!loading) {
    setAvailableReports([...(fData?.availableFiscalYearReports ?? []), ...(nData?.availableNationalProgramReports ?? [])] as Report[]);
  }

  return <NationalPlanningComponent />;
};

export default FetchReportsComponent;
