import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Input, OATIcon } from 'oat-common-ui';
import Button from '../../../../components/Button';
import MiscSetupModel from '../../models/offersSetup/MiscSetupModel';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

type Props = {
  optionType: string;
  misc: MiscSetupModel;
  onSave: () => void;
};

const AddVehicle = ({ misc, optionType, onSave }: Props) => {
  const {
    fields: { competitorDetails },
    updateVehicleMakeArray,
  } = misc;

  const isTradeIn = optionType === OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE;
  const label = isTradeIn ? 'Model Restrictions' : 'Competitor Details';

  return (
    <div>
      <BlockInputLabel label={label} labelClass={styles.tableHeaderLabel} />
      <div className={styles.modelTable}>
        {competitorDetails?.map((model, index) => (
          <div key={index} className={styles.modelTableRow}>
            <BlockInputLabel label="Make" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                id={`model-make-${index}`}
                value={model.make}
                isNational
                onChange={e => {
                  updateVehicleMakeArray('edit', index, { make: e.currentTarget.value, model: model.model, years: model.years });
                  onSave();
                }}
                required
                error={!model.make}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Model" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                id={`model-model-${index}`}
                value={model.model}
                isNational
                onChange={e => {
                  updateVehicleMakeArray('edit', index, { make: model.make, model: e.currentTarget.value, years: model.years });
                  onSave();
                }}
                required
                error={!model.model}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Model Year" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                id={`model-year-${index}`}
                value={model.years}
                isNational
                onChange={e => {
                  updateVehicleMakeArray('edit', index, { make: model.make, model: model.model, years: e.currentTarget.value });
                  onSave();
                }}
                required
                error={!model.years}
              />
            </BlockInputLabel>
            {competitorDetails.length >= 2 && (
              <Button
                id={`remove-row-${index}-cta`}
                variant="text"
                className={clsx(styles.iconBtn, styles.removeIcon)}
                onClick={() => {
                  updateVehicleMakeArray('remove', index);
                  onSave();
                }}
              >
                -
              </Button>
            )}
          </div>
        ))}
      </div>
      <Button
        id="add-vehicle-cta"
        className={styles.addCta}
        onClick={() => {
          updateVehicleMakeArray('add');
        }}
        icon={<OATIcon icon="plus" size={12} />}
      >
        Add Vehicle
      </Button>
    </div>
  );
};

export default observer(AddVehicle);
