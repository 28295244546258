import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

interface Props {
  offerType: string;
  title: string;
}

const OfferTitle = ({ offerType, title }: Props) => {
  const getTheme = () => {
    switch (offerType) {
      case OPTION_TYPE_NAMES.LEASE: {
        return styles.lease;
      }
      case OPTION_TYPE_NAMES.APR: {
        return styles.apr;
      }
      default: {
        return styles.cash;
      }
    }
  };
  return <h3 className={clsx(styles.title, getTheme())}>{title}</h3>;
};

export default OfferTitle;
