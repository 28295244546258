import { Button, OATIcon, Tooltip } from 'oat-common-ui';
import RgnlAltModel from '../../../models/RgnlAltModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from '../styles.module.scss';
import { useState } from 'react';

interface Props {
  uid: string;
  handleCopyOffer: (index: number) => void;
  showDeleteModal: (val: boolean) => void;
  handleSort: (val: boolean) => void;
  offerIndex: number;
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
}

const iconSize = 12;

const OfferFooterCtas = ({ uid, handleCopyOffer, showDeleteModal, handleSort, seriesProfile, rgnlAlt, offerIndex }: Props) => {
  const [isCopyOfferTooltipOpen, setIsCopyOfferTooltipOpen] = useState(false);
  return (
    <div className={styles.offerFooterCtas}>
      <Tooltip id={`copy-cta-${uid}`} message="Copy Offer" grayTheme place="bottom" disable={seriesProfile.data.isBudgetConfirmed} isOpen={isCopyOfferTooltipOpen} setIsOpen={setIsCopyOfferTooltipOpen}>
        <Button
          id={`copy-cta-${uid}`}
          className={styles.cta}
          icon={<OATIcon id={`copy-cta-${uid}`} icon="copy" size={iconSize} />}
          onClick={() => {
            handleCopyOffer(offerIndex)
            setIsCopyOfferTooltipOpen(false)
          }}
          disabled={seriesProfile.data.isBudgetConfirmed}
        >
          Copy Offer
        </Button>
      </Tooltip>
      <Tooltip id={`delete-cta-${uid}`} message="Delete Offer" grayTheme place="bottom" disable={seriesProfile.data.isBudgetConfirmed}>
        <Button
          id={`del-offer-cta=${uid}`}
          className={styles.cta}
          icon={<OATIcon icon="trash" size={iconSize} />}
          onClick={() => showDeleteModal(true)}
          disabled={seriesProfile.data.isBudgetConfirmed}
        >
          Delete Offer
        </Button>
      </Tooltip>

      <div className={styles.sortCtas}>
        <Button
          id={`sort-down-cta-${uid}`}
          className={styles.cta}
          icon={<OATIcon id={`sort-down-cta-${uid}`} icon="arrow-down2" size={iconSize} />}
          onClick={() => handleSort(false)}
          disabled={seriesProfile.data.isBudgetConfirmed || offerIndex === rgnlAlt.offers.length - 1}
        />
        <Button
          id={`sort-up-cta-${uid}`}
          className={styles.cta}
          icon={<OATIcon id={`sort-down-cta-${uid}`} icon="arrow-up2" size={iconSize} />}
          onClick={() => handleSort(true)}
          disabled={seriesProfile.data.isBudgetConfirmed || offerIndex === 0}
        />
      </div>
    </div>
  );
};

export default OfferFooterCtas;
