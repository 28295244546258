import { cloneDeep } from '@apollo/client/utilities';
import { makeAutoObservable } from 'mobx';
import { AdminModels, aggregateBudgetTotals, applyMultiSeriesCostsToFYMonths, calculateBudgetPnvs } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import { FYROLLUP_BUTTON_TEXT } from '../constants/global';
import { SeriesMapping } from '../gql/generated';
import SeriesListModel from '../pages/FYRollup/models/SeriesListModel';
import { sortByVehicleGroup } from '../pages/FYRollup/utils';

export interface SeriesListSortItem extends AdminModels.FYSeriesItem {
  sortSeriesName: string;
}

class FYRollupStore {
  fyTotals: AdminModels.Total = {
    stock: 0,
    sales: 0,
    pnvs: 0,
    totalCost: 0,
  };
  fyMonthTotals: { [month: number]: AdminModels.FyMonthTotal } = {};
  fyMultiSeries: { [month: number]: AdminModels.FYMultiSeriesItem } = {};
  seriesList: SeriesListModel[] = [];
  fiscalYear = dateStringToDate(new Date()).getFullYear() + 1;
  isRecalculateWorkingBudget = false;
  isRecalculationRequired = false;
  expandAll = false;
  isEdit = false;
  hasUnsavedChanges = false;
  searchValue = '';
  fySection = '';
  newRoute = '';
  buttonText = FYROLLUP_BUTTON_TEXT.EDIT;

  constructor() {
    makeAutoObservable(this);
  }

  setFYSection = (section: string) => {
    this.fySection = section;
  };

  setSearchValue = (val: string) => {
    this.searchValue = val;
  };

  setIsEdit = (val: boolean) => {
    this.isEdit = val;
    this.buttonText = this.isEdit ? FYROLLUP_BUTTON_TEXT.SAVE : FYROLLUP_BUTTON_TEXT.EDIT;
  };

  setHasUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };

  setNewRoute = (val: string) => {
    this.newRoute = val;
  };

  setData = (data: AdminModels.FYRollup, seriesMapping: SeriesMapping[], recalculateWorkingBudget?: boolean) => {
    const { fyTotals, fyMonthTotals, fyMultiSeries, fiscalYear, seriesList } = data;
    this.fyTotals = fyTotals;
    this.fyMonthTotals = fyMonthTotals;
    this.fyMultiSeries = fyMultiSeries;
    this.isRecalculationRequired = false; // temporary
    this.fiscalYear = fiscalYear;
    this.isRecalculateWorkingBudget = recalculateWorkingBudget ?? false;
    this.seriesList = sortByVehicleGroup(seriesList, seriesMapping).map(seriesListItem => {
      return new SeriesListModel(seriesListItem, seriesListItem.series, seriesListItem.sortSeriesName, this);
    });
  };

  getFYRollup = () => {
    const fyRollup: AdminModels.FYRollup = {
      fiscalYear: this.fiscalYear,
      fyMonthTotals: this.fyMonthTotals,
      fyMultiSeries: this.fyMultiSeries,
      fyTotals: this.fyTotals,
      seriesList: {},
    };

    for (const seriesListItem of Object.values(this.seriesList)) {
      fyRollup.seriesList[seriesListItem.series] = cloneDeep(seriesListItem);
    }

    return fyRollup;
  };

  updateTotals = () => {
    this.updateFYMonthTotals();
    this.updateFYTotals();
    this.hasUnsavedChanges = true; // user input changes
  };

  updateFYMonthTotals = () => {
    // updates both fy month totals and fy totals on every onChange of sales
    const fyMonthTotals: { [month: number]: AdminModels.FyMonthTotal } = {};

    // apply the multi series cost to fymonths
    applyMultiSeriesCostsToFYMonths(fyMonthTotals, this.fyMultiSeries);

    for (const seriesListItem of this.seriesList) {
      for (const seriesMonthItem of Object.values(seriesListItem.seriesMonths)) {
        const month = seriesMonthItem.month;
        if (!fyMonthTotals[month]) {
          // initialize if it doesn't exist
          fyMonthTotals[month] = {
            month: seriesMonthItem.month,
            year: seriesMonthItem.year,
            totals: { sales: 0, stock: 0, pnvs: 0, totalCost: 0 },
          };
        }
        aggregateBudgetTotals(fyMonthTotals[month].totals, seriesMonthItem.totals);
      }
    }

    this.fyMonthTotals = fyMonthTotals;
  };

  updateFYTotals = () => {
    this.fyTotals.stock = Object.values(this.fyMonthTotals).reduce((acc, curr) => acc + curr.totals.stock, 0);
    this.fyTotals.sales = Object.values(this.fyMonthTotals).reduce((acc, curr) => acc + curr.totals.sales, 0);
    this.fyTotals.totalCost = Object.values(this.fyMonthTotals).reduce((acc, curr) => acc + curr.totals.totalCost, 0);
    this.fyTotals.pnvs = calculateBudgetPnvs(this.fyTotals.totalCost, this.fyTotals.sales);
  };

  toggleExpandAll = () => {
    if (this.seriesList && this.seriesList.length) {
      this.expandAll = !this.expandAll;

      this.seriesList.forEach(item => {
        item.showDetails = this.expandAll;
      });
    }
  };

  initEnterFYSection = () => {
    this.isEdit = false;
    this.expandAll = false;
    this.hasUnsavedChanges = false;
    this.searchValue = '';
    this.newRoute = '';
    this.buttonText = FYROLLUP_BUTTON_TEXT.EDIT;
    window.scrollTo({ top: 0 });
  };
}

export default FYRollupStore;
