import { Table, TableContainer } from 'oat-common-ui';
import { SeriesMapping } from '../../../gql/generated';
import SeriesMappingRow from './SeriesMappingRow';
import styles from './styles.module.scss';

interface Props {
  seriesMappingList: SeriesMapping[];
  onUpdate: (series: SeriesMapping, updatedName: string) => void;
}

const SeriesMappingTable = ({ seriesMappingList, onUpdate }: Props) => {
  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          <th className={styles.seriesName}>Series Name</th>
          <th>Display Name</th>
          <th className={styles.actions}>Actions</th>
        </tr>
      </thead>
    );
  };

  const BodyRows = () => {
    return (
      <>
        {seriesMappingList.map(series => (
          <SeriesMappingRow key={series.id} onUpdate={onUpdate} series={series} />
        ))}
      </>
    );
  };

  return (
    <div className={styles.mappingTable}>
      <TableContainer className={styles.tableContainer}>
        <Table>
          <HeaderRow />
          <tbody>
            <BodyRows />
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SeriesMappingTable;
