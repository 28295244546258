import { AdminConstants } from 'oat-admin-common';

const portsMap = (port: string) => {
  if (!AdminConstants.PortsMap[port]) {
    return 'No ports available.';
  }

  return AdminConstants.PortsMap[port];
};

export default portsMap;
