import { useCallback } from 'react';
import { Report, useAvailableFiscalYearReportsLazyQuery, useAvailableNationalProgramReportsLazyQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';

const useFetchReports = () => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    reportsStore: { setAvailableReports },
  } = useStores();
  const [getFiscalYearReports] = useAvailableFiscalYearReportsLazyQuery();
  const [getNationalProgramReports] = useAvailableNationalProgramReportsLazyQuery();

  const fetchReports = useCallback(
    async (fiscalYear: number, offeringId: string) => {
      if (offeringId) {
        const res = await Promise.all([getFiscalYearReports({ variables: { brand, fiscalYear } }), getNationalProgramReports({ variables: { offeringId } })]);
        setAvailableReports([
          ...(res[0].data?.availableFiscalYearReports ?? []), //
          ...(res[1].data?.availableNationalProgramReports ?? []),
        ] as Report[]);
      } else {
        const res = await getFiscalYearReports({ variables: { brand, fiscalYear } });
        setAvailableReports(res.data?.availableFiscalYearReports as Report[]);
      }
    },
    [brand, getFiscalYearReports, getNationalProgramReports, setAvailableReports],
  );

  return {
    fetchReports,
  };
};

export default useFetchReports;
