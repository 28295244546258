import { observer } from 'mobx-react-lite';
import { assignStringValue, dateStringToDate, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import GeneralIpFields from '../../../components/GeneralIpFields';
import { useGetIncentiveCalendarLazyQuery, useUpdateOfferingMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import DefaultNationalModal from '../../Dashboard/DefaultNationalModal';
import SettingsTabWrapper from '../SettingsTabWrapper';
import FooterCtas from '../components/FooterCtas';
import IpSettingsContentWrapper from '../components/IpSettingsContentWrapper';
import IpSettingsHeader from '../components/IpSettingsHeader';
import IpSettingsNav from '../components/IpSettingsNav';
import DatesUpdatedNotification from './DatesUpdatedNotification';

const GeneralTabComponent = () => {
  const {
    ipSettingsStore: {
      endDateError,
      offering: { startDate, endDate, name, note, rev, id, isPublished },
      offeringNameError,
      setHasUnsavedChanges,
      setOffering,
      startDateError,
      updateField,
      offeringDisplayName,
    },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const [showNote, setShowNote] = useState(Boolean(note?.length));
  const [datesUpdated, setDatesUpdated] = useState(false);

  const { error } = useToast();

  const [updateOffering] = useUpdateOfferingMutation();
  const [fetchIncentiveCalendar] = useGetIncentiveCalendarLazyQuery();
  const [isICErrorModalOpen, setIsICErrorModalOpen] = useState(false);

  const handleSave = async (skipError = false) => {
    if (!skipError) {
      try {
        const res = await fetchIncentiveCalendar({ variables: { brand, fiscalYear: dateStringToDate(startDate).getFullYear() } });

        const isMatch = res.data?.fetchIncentiveCalendar?.data?.months?.some(
          month =>
            dateStringToDate(startDate).getTime() === dateStringToDate(month.startDate).getTime() &&
            dateStringToDate(endDate).getTime() === dateStringToDate(month.endDate).getTime(),
        );

        if (!isMatch) {
          setIsICErrorModalOpen(true);

          return;
        }
      } catch (e) {
        error((e as Error).message);
      }
    }

    setIsICErrorModalOpen(false);

    try {
      const res = await trackPromise(updateOffering({ variables: { input: { id, rev, startDate, endDate, name, note } } }));

      if (res.data?.updateOfferingHandler.success) {
        setOffering(res.data.updateOfferingHandler.offering);
        setHasUnsavedChanges(false);
        setDatesUpdated(!!res.data.updateOfferingHandler.isCostShareUpdated);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleTrashIcon = () => {
    setShowNote(false);
    updateField('note', '');
    setHasUnsavedChanges(true);
  };

  const handleFieldChange = (fieldName: 'name' | 'startDate' | 'endDate' | 'note', value: string) => {
    if ((fieldName === 'startDate' || fieldName === 'endDate') && !value) {
      return;
    }

    updateField(fieldName, value);
    setHasUnsavedChanges(true);
  };

  return (
    <IpSettingsContentWrapper>
      <IpSettingsHeader title={`Edit Incentive Period - ${offeringDisplayName}`} />
      <IpSettingsNav />
      <SettingsTabWrapper>
        <GeneralIpFields
          endDate={endDate}
          endDateError={endDateError}
          handleFieldChange={handleFieldChange}
          handleTrashIcon={handleTrashIcon}
          name={name}
          note={assignStringValue(note)}
          noteId={id}
          offeringNameError={offeringNameError}
          setShowNote={setShowNote}
          showNote={showNote}
          startDate={startDate}
          startDateError={startDateError}
        />
        <DatesUpdatedNotification isUpdated={datesUpdated} />
        <FooterCtas onSave={() => handleSave(false)} disabled={offeringNameError || startDateError || endDateError || isPublished} />
        {isICErrorModalOpen && (
          <DefaultNationalModal
            id="delete-offering"
            message="Incentive period dates do not align with the OAT Incentive Calendar. Do you wish to overwrite the OAT Incentive Calendar?"
            onClose={() => setIsICErrorModalOpen(false)}
            onSubmit={() => handleSave(true)}
            submitBtnText="Yes"
            title="Warning"
          />
        )}
      </SettingsTabWrapper>
    </IpSettingsContentWrapper>
  );
};

export default observer(GeneralTabComponent);
