import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'oat-common-ui';
import { useState } from 'react';
import { SeriesMapping } from '../../../gql/generated';
import SeriesNameModal from './SeriesNameModal';
import styles from './styles.module.scss';

interface Props {
  series: SeriesMapping;

  onUpdate: (series: SeriesMapping, updatedName: string) => void;
}

const SeriesMappingRow = ({ series, onUpdate }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState(series.displayName);

  const handleUpdate = () => {
    onUpdate(series, input);
  };

  const hanldeClose = () => {
    setShowModal(!showModal);
    setInput(series.displayName);
  };

  return (
    <tr>
      <td>{series.bosuSeries}</td>
      <td>{series.displayName}</td>
      <td>
        <Tooltip className={styles.toolTip} message={'Edit'} place={'bottom'} grayTheme>
          <FontAwesomeIcon icon={faPen} className={styles.icon} size="1x" onClick={() => setShowModal(!showModal)} />
        </Tooltip>

        {showModal && (
          <SeriesNameModal
            seriesName={series.bosuSeries}
            displayName={input}
            onSubmit={handleUpdate}
            onClose={hanldeClose}
            onBlur={e => {
              if (e.target.value) {
                setInput(e.target.value);
              }
            }}
            submitText={'Submit'}
          />
        )}
      </td>
    </tr>
  );
};

export default SeriesMappingRow;
