import { observer } from 'mobx-react-lite';
import { getFiscalYearMonths } from 'oat-admin-common';
import MainHeader from '../../../components/MainHeader';
import NGCHeader from '../../../components/NGCHeader';
import useStores from '../../../stores/useStores';
import SeriesList from '../components/body/SeriesList';
import CalculatedTotal from '../components/header/CalculatedTotal';
import Header from '../components/header/Header';
import Navbar from '../components/header/Navbar';

const WorkingComponent = () => {
  const {
    fyRollupStore: { fyMonthTotals, fyTotals, fiscalYear, seriesList },
  } = useStores();

  const fiscalMonths = getFiscalYearMonths(fiscalYear);

  return (
    <>
      <Header>
        <MainHeader showDefault={false} />
        <NGCHeader title="FY Rollup" name="Working" />
        <Navbar hasRecalculate />
        <CalculatedTotal fiscalYear={fiscalYear} fiscalMonths={fiscalMonths} fyMonthTotals={fyMonthTotals} fyTotals={fyTotals} />
      </Header>

      <SeriesList fiscalMonths={fiscalMonths} seriesList={seriesList} />
    </>
  );
};

export default observer(WorkingComponent);
