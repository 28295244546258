import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';

import { Offer, SelectedTierTerm, useUpdateAprOfferSetupMutation, useUpdateLeaseOfferSetupMutation, useUpdateOfferSetupDataMutation } from '../../../../gql/generated';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import MiscSetupModel from '../../models/offersSetup/MiscSetupModel';
import OfferSetupModel from '../../models/offersSetup/OfferSetupModel';
import MiscOfferSetup from './MiscOfferSetup';
import OfferSetupFooter from './OfferSetupFooter';
import OfferSetupHeader from './OfferSetupHeader';

import AprOfferSetup from './AprOfferSetup';
import LeaseOfferSetup from './LeaseOfferSetup';
import { createAprSetupInput } from './OfferSetupHeader/RatesTable/utils/createAprSetupInput';
import SpecialEdition from './OfferSetupHeader/SpecialEdition';
import SystemToPayDropdown from './SystemToPayDropdown';

import RgnlAltModel from '../../models/RgnlAltModel';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

type Props = {
  item: OfferSetupModel;
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
};

const OfferSetup = ({ item, seriesProfile, rgnlAlt }: Props) => {
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const [updateOfferSetupData] = useUpdateOfferSetupDataMutation();
  const [updateLeaseSetup] = useUpdateLeaseOfferSetupMutation();
  const [updateAprOfferSetup] = useUpdateAprOfferSetupMutation();
  const showMiscSetupFields =
    item.miscSetupDetails && item.miscSetupDetails.optionType !== OPTION_TYPE_NAMES.RYO_EARNINGS && item.miscSetupDetails.optionType !== OPTION_TYPE_NAMES.LUMPSUM_RYO;

  const saveOfferSetup = async () => {
    setDelay(async () => {
      if (!item.hasOfferLevelError()) {
        try {
          const res = await trackPromise(
            updateOfferSetupData({
              variables: {
                input: {
                  id: item.id,
                  rev: item.rev,
                  offerName: item.fields.offerName,
                  startDate: item.fields.startDate,
                  endDate: item.fields.endDate,
                  compatibilityList: item.fields.compatibilityList,
                  isSpecialEdition: item.fields.isSpecialEdition,
                  specialEditionPackage: item.fields.specialEditionPackage,
                  vehicles: item.fields.vehicles,
                  systemToPay: item.fields.systemToPay,
                  offerNote: item.fields.notes,
                  regions: item.fields.regions,
                },
              },
            }),
          );

          if (res.data?.updateOfferSetupData.success) {
            item.setRevAndId(res.data.updateOfferSetupData.offer);

            if (res.data?.updateOfferSetupData.offer.aprDetails) {
              item.aprSetupDetails?.updateRev(res.data.updateOfferSetupData.offer.rev);
            }
          }
        } catch (e) {
          error((e as Error).message);
        }
      }
    });
  };

  const saveLeaseSetup = async () => {
    setDelay(async () => {
      if (!item.leaseSetupDetails) {
        error('Lease setup details not found');
        return;
      }

      if (item.leaseSetupDetails.termError) {
        return;
      }

      try {
        const { subventionCashSystemToPay, highTerm, lowTerm, tiers } = item.leaseSetupDetails.fields;
        const res = await trackPromise(
          updateLeaseSetup({
            variables: {
              input: {
                offerId: item.id,
                rev: item.rev,
                highTerm,
                lowTerm,
                subventionCashSystemToPay,
                tiers: tiers.map(({ isSelected, tier, standardRcf, subventedRcf }) => ({
                  isSelected,
                  tier,
                  standardRcf,
                  subventedRcf,
                })),
              },
            },
          }),
        );
        item.setRevAndId(res.data?.updateLeaseOfferSetup.offer as Offer);
      } catch (e) {
        error((e as Error).message);
      }
    });
  };

  const saveAprSetup = async () => {
    if (item.aprSetupDetails) {
      const { id, rev, tierTerms, subCashAmount, subventionCashSystemToPay, updateRev } = item.aprSetupDetails;

      const selectedTierTerms: SelectedTierTerm[] = tierTerms.map(tierTerm => {
        return {
          tier: tierTerm.tier,
          term: tierTerm.term,
        };
      });

      const input = createAprSetupInput(id, rev, selectedTierTerms, subCashAmount > 0 ? subventionCashSystemToPay : '');

      try {
        const res = await trackPromise(updateAprOfferSetup({ variables: { input } }));

        if (res.data?.updateAprOfferSetup.success) {
          item.setRevAndId(res.data.updateAprOfferSetup.offer);
          updateRev(res.data.updateAprOfferSetup.offer.rev);
        }
      } catch (e) {
        error((e as Error).message);
      }
    }
  };

  return (
    <div className={styles.offerSetupContainer}>
      <OfferSetupHeader offer={item} seriesProfile={seriesProfile} onSave={saveOfferSetup} />
      <SystemToPayDropdown item={item} seriesProfile={seriesProfile} onSave={saveOfferSetup} saveAprSetup={saveAprSetup} saveLeaseSetup={saveLeaseSetup} />
      {!item.leaseSetupDetails && !item.aprSetupDetails && (
        <SpecialEdition item={item} seriesProfile={seriesProfile} onSave={saveOfferSetup} className={!item.leaseSetupDetails && styles.specialEditionFooter} />
      )}
      {showMiscSetupFields && (
        <MiscOfferSetup item={item} miscSetupDetails={item.miscSetupDetails as MiscSetupModel} optionType={item.fields.optionType} seriesProfile={seriesProfile} />
      )}
      {item.leaseSetupDetails && <LeaseOfferSetup leaseSetupDetails={item.leaseSetupDetails} saveLeaseSetup={saveLeaseSetup} seriesProfile={seriesProfile} />}
      {item.aprSetupDetails && (
        <>
          <AprOfferSetup aprSetupDetails={item.aprSetupDetails} seriesProfile={seriesProfile} />
          <SpecialEdition item={item} seriesProfile={seriesProfile} onSave={saveOfferSetup} className={styles.specialEditionForApr} />
        </>
      )}
      <OfferSetupFooter item={item} onSave={saveOfferSetup} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />
    </div>
  );
};

export default observer(OfferSetup);
