import { SelectedTierTerm, UpdateAprOfferSetupInput } from '../../../../../../../gql/generated';

export const createAprSetupInput = (offerId: string, rev: string, selectedTierTerms: SelectedTierTerm[], subventionCashSystemToPay: string): UpdateAprOfferSetupInput => {
  return {
    offerId,
    rev,
    selectedTierTerms,
    subventionCashSystemToPay,
  };
};
