import { makeAutoObservable } from 'mobx';
import { assignNumberValue, IMultiItem } from 'oat-common-ui';
import { OfferingSeriesItem, SeriesItemInput, SeriesMapping } from '../../../../gql/generated';
import { splitSeriesAndModelYear, xformSeriesSelections } from '../utils';

class SeriesSelectionsModel {
  excluded: IMultiItem[] = [];
  included: IMultiItem[] = [];
  series: IMultiItem[] = [];
  selectedSeries: SeriesItemInput[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelections = (data: OfferingSeriesItem[], seriesMapping: SeriesMapping[]) => {
    this.excluded = xformSeriesSelections(
      data.filter(series => !series.isAdded),
      seriesMapping,
    );
    this.included = xformSeriesSelections(
      data.filter(series => series.isAdded),
      seriesMapping,
    );
    this.series = splitSeriesAndModelYear([...this.included, ...this.excluded]).sort((a, b) => a.series.localeCompare(b.series) || +a.modelYear - +b.modelYear);
    this.setSelected(this.included);
  };

  setSelected = (included: IMultiItem[]) => {
    this.included = this.series.filter(item => included.find(includedItem => item.data === includedItem.data));
    this.excluded = this.series.filter(item => !included.find(includedItem => item.data === includedItem.data));
    this.selectedSeries = this.included.map(series => ({
      series: series.data.slice(5, series.data.length + 4),
      modelYear: assignNumberValue(series.data.split(' ')[0]),
    }));
  };
}

export default SeriesSelectionsModel;
