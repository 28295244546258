import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignNumberValue, assignStringValue, uuidv4 } from 'oat-common-ui';
import { Offer } from '../../../../gql/generated';
import { OfferCostFields, OfferFields } from './OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

class CashModel {
  uid = uuidv4();
  offerFields: OfferFields = {
    id: '',
    isForRegions: true,
    rev: '',
    rgnlAltId: '',
    optionType: OPTION_TYPE_NAMES.CUSTOMER_CASH as string,
    name: OPTION_TYPE_NAMES.CUSTOMER_CASH as string,
    penetrationRate: '',
  };
  offerCostFields: OfferCostFields = {
    amount: '',
    offerEnhancedTfsPnvs: 0,
    offerPnvs: '',
    offerTfsPnvs: 0,
  };

  constructor(data: { offer?: Offer; optionType?: string; rgnlAltId: string }) {
    makeAutoObservable(this);

    this.offerFields.rgnlAltId = data.rgnlAltId;
    this.offerFields.optionType = assignStringValue(data.optionType);
    this.offerFields.name = assignStringValue(data.optionType);

    if (data.offer) {
      this.setData(data.offer);
    }
  }

  setData = (offer: Offer) => {
    if (offer.cashDetails) {
      this.offerFields = {
        id: offer.id,
        rev: offer.rev,
        isForRegions: offer.isForRegions,
        name: offer.name || offer.optionType,
        optionType: offer.optionType,
        penetrationRate: assignStringValue(offer.penetrationRate),
        rgnlAltId: offer.rgnlAltId,
      };

      this.offerCostFields = {
        offerPnvs: assignStringValue(offer.cashDetails.offerPnvs),
        offerTfsPnvs: assignNumberValue(offer.cashDetails.offerTfsPnvs),
        offerEnhancedTfsPnvs: offer.cashDetails.offerEnhancedTfsPnvs,
        amount: assignStringValue(offer.cashDetails.amount),
      };
    }
  };

  setRevAndId = (offer?: Offer) => {
    if (offer) {
      this.offerFields.id = offer.id;
      this.offerFields.rev = offer.rev;
    }
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;
  };

  updateOfferCostField = <T extends keyof OfferCostFields, V extends OfferCostFields[T]>(field: T, value: V) => {
    this.offerCostFields[field] = value;
  };

  updateAmount = (amount: string) => {
    this.offerCostFields.amount = amount;
    this.offerCostFields.offerPnvs = amount;
  };

  hasError = () => {
    return this.hasAmountError() || this.hasPenetrationError();
  };

  hasAmountError = () => {
    return this.offerCostFields.amount === '';
  };

  hasPenetrationError = () => {
    return this.offerFields.penetrationRate === '' || Number(this.offerFields.penetrationRate) > 100;
  };
}

export default CashModel;
