import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import FinalPayModel from '../../../models/offers/FinalPayModel';
import useSaveFinalPay from '../../../offers/FinalPaySection/useSaveFinalPay';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import { useSummaryCosts } from '../useSummaryCosts';
import OfferName from './OfferName';
import RowProps from './RowProps';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

const FinalPayRow = ({ seriesProfile, rgnlAlt, offer }: RowProps<FinalPayModel>) => {
  const { uid, updateOfferCostFields } = offer;
  const { saveFinalPayOffer } = useSaveFinalPay(seriesProfile, rgnlAlt, offer);
  const { tmsCost, tfsCost } = useSummaryCosts(rgnlAlt, offer);

  const handleAmount = (val: string) => {
    seriesProfile.clearErrors();
    updateOfferCostFields('amount', val);
    saveFinalPayOffer();
  };

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{offer.offerFields.name}</OfferName>
      </td>
      <td>
        <RgnlAltInput
          id={`finalPay-summary-amount-${uid}`}
          isNational
          wholeNumber
          dollarSign
          value={offer.offerCostFields.amount}
          onChange={e => handleAmount(e.currentTarget.value)}
          error={offer.amountError}
          disabled
          units
        />
      </td>
      <td>${formatDollars(offer.offerCostFields.offerPnvs)}</td>
      <td>
        <RgnlAltInput
          id={`finalPay-summary-penrate-${uid}`}
          isNational
          wholeNumber
          percentageSign
          min={0}
          max={100}
          value={offer.offerFields.penetrationRate}
          error={offer.penetrationRateError}
          disabled
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost}</td>
          <td>${tfsCost}</td>
        </>
      )}
    </tr>
  );
};

export default observer(FinalPayRow);
