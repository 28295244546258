import { EntityError } from '../../../../gql/generated';
import { ICostShareSeriesProfile } from '../../../../stores/NationalCostShareStore';

export const handleValidStdRatesCheck = (errorsList: EntityError[], costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile>) => {
  for (const errorItem of errorsList) {
    const foundSp = costSharesBySeriesProfile.get(errorItem.entityId);
    const foundSpCostShares = foundSp?.costShares ?? [];

    for (const erroredCostShareId of errorItem.errors) {
      const erroredCostShare = foundSpCostShares.find(cs => cs.id === erroredCostShareId);

      if (erroredCostShare) {
        erroredCostShare.updateHasInvalidStdRate(true);
      }
    }
  }
};
