import { makeAutoObservable } from 'mobx';
import { RCF_INITIAL_VALUE } from '../constants/global';
import { OfferingStandardRates } from '../gql/generated';
import { processTfsLeaseSection, processTfsLeasesByTerm } from '../pages/IpSettings/RatesTab/TFSStandardRcf/utils';
import TFSLeaseRateModel from '../pages/IpSettings/RatesTab/models/TFSLeaseRateModel';
import { processRate } from '../pages/IpSettings/RatesTab/utils';

class RatesTabTFSLeaseRCFStore {
  id = '';
  rev = '';
  tfsStandardRcf = 0;
  tfsStandardRcfMaster = 0;
  tfsLeaseDifferential = RCF_INITIAL_VALUE;
  tfsLeaseRates: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionOne: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionOneMaster: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionOneFull: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionTwo: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionTwoMaster: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionTwoFull: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionThree: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionThreeMaster: TFSLeaseRateModel[] = [];
  tfsLeaseRatesSectionThreeFull: TFSLeaseRateModel[] = [];
  tfsHasUnsavedChanges = false;
  showHiddenTfsOne = false;
  showHiddenTfsTwo = false;
  showHiddenTfsThree = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateTfsLeaseDifferential = (val: number) => {
    this.tfsLeaseDifferential = val;
    this.tfsHasUnsavedChanges = true;
  };

  updateTfsLeaseRates = (val: number) => {
    this.tfsLeaseRates = this.tfsLeaseRates.map(rate => {
      rate.differential = val;
      rate.rate = processRate(rate.previousRate, val);

      return rate;
    });
  };

  updateTfsLeaseStandardRcf = (val: number) => {
    this.tfsStandardRcf = val + this.tfsStandardRcfMaster;
  };

  validateTfsLeaseRates = () => {
    return new Set(this.tfsLeaseRates.map(rate => rate.differential)).size === 1;
  };

  updateTfsLeaseRate = (uid: string, tier: string, term: number, rate: number) => {
    const foundTFS = this.tfsLeaseRates.find(rate => rate.uid === uid && rate.tier === tier && rate.term === term);

    if (foundTFS) {
      foundTFS.differential = rate;
      foundTFS.rate = processRate(foundTFS.previousRate, rate);
      this.tfsLeaseDifferential = this.validateTfsLeaseRates() ? rate : RCF_INITIAL_VALUE;
      this.updatePreviousThreeRates(term, tier, rate);
    }

    this.tfsHasUnsavedChanges = true;
  };

  updatePreviousThreeRates = (term: number, tier: string, rate: number) => {
    if (term === 36 || term === 48 || term === 60) {
      for (const tfsLeaseRate of this.tfsLeaseRates) {
        if (tfsLeaseRate.tier === tier && (tfsLeaseRate.term === term - 3 || tfsLeaseRate.term === term - 6 || tfsLeaseRate.term === term - 9)) {
          tfsLeaseRate.differential = rate;
          tfsLeaseRate.rate = processRate(tfsLeaseRate.previousRate, rate);
        }
      }
    }
  };

  setTfsLeaseRatesSectionOne = (tfsLeaseRates: TFSLeaseRateModel[]) => {
    this.tfsLeaseRatesSectionOne = tfsLeaseRates;
  };

  setTfsLeaseRatesSectionTwo = (tfsLeaseRates: TFSLeaseRateModel[]) => {
    this.tfsLeaseRatesSectionTwo = tfsLeaseRates;
  };

  setTfsLeaseRatesSectionThree = (tfsLeaseRates: TFSLeaseRateModel[]) => {
    this.tfsLeaseRatesSectionThree = tfsLeaseRates;
  };

  setTfsHasUnsavedChanges = (val: boolean) => {
    this.tfsHasUnsavedChanges = val;
  };

  setShowHiddenTfsOne = (val: boolean) => {
    this.showHiddenTfsOne = val;
  };

  setShowHiddenTfsTwo = (val: boolean) => {
    this.showHiddenTfsTwo = val;
  };

  setShowHiddenTfsThree = (val: boolean) => {
    this.showHiddenTfsThree = val;
  };

  setData = (data: OfferingStandardRates, isLexus: boolean) => {
    this.id = data.id || '';
    this.rev = data.rev || '';
    this.tfsLeaseDifferential = data.tfsLeaseDifferential === 0 ? RCF_INITIAL_VALUE : data.tfsLeaseDifferential;
    this.tfsLeaseRates = processTfsLeasesByTerm(data.tfsLeaseRates).map(rate => new TFSLeaseRateModel(rate));
    this.tfsLeaseRatesSectionOne = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 24),
      isLexus,
    );
    this.tfsLeaseRatesSectionOneMaster = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 24),
      isLexus,
    );
    this.tfsLeaseRatesSectionOneFull = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term < 36),
      isLexus,
    );
    this.tfsLeaseRatesSectionTwo = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 36 || t.term === 48),
      isLexus,
    );
    this.tfsLeaseRatesSectionTwoMaster = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 36 || t.term === 48),
      isLexus,
    );
    this.tfsLeaseRatesSectionTwoFull = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term >= 36 && t.term <= 48),
      isLexus,
    );
    this.tfsLeaseRatesSectionThree = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 60),
      isLexus,
    );
    this.tfsLeaseRatesSectionThreeMaster = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term === 60),
      isLexus,
    );
    this.tfsLeaseRatesSectionThreeFull = processTfsLeaseSection(
      this.tfsLeaseRates.filter(t => t.term > 48),
      isLexus,
    );
    this.tfsStandardRcf = this.tfsLeaseRates.find(r => r.tier === '1+' && r.term === 24)?.rate || 0;
    this.tfsStandardRcfMaster = this.tfsLeaseRates.find(r => r.tier === '1+' && r.term === 24)?.previousRate || 0;
  };
}

export default RatesTabTFSLeaseRCFStore;
