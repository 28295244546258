import { DropdownItem } from 'oat-common-ui';
import { ICostShareSeriesProfile } from '../../../../stores/NationalCostShareStore';

export const disableTypeDropdownOnSave = (addedSeriesOptions: DropdownItem[], costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile>) => {
  for (const addedSeriesOption of addedSeriesOptions) {
    const costSharesBySp = costSharesBySeriesProfile.get(addedSeriesOption.value);

    if (costSharesBySp && costSharesBySp.costShares.length) {
      const costShares = costSharesBySp.costShares;

      for (const costShare of costShares) {
        costShare.setOptionTypeNameIsDisabled(true);
      }
    }
  }
};
