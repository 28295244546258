import { observer } from 'mobx-react-lite';
import MainHeader from '../../../components/MainHeader';
import NGCHeader from '../../../components/NGCHeader';
import useStores from '../../../stores/useStores';
import Body from './components/Body';
import SeriesSortingList from './components/SeriesSortingList';

const SeriesSortingSettingsComponent = () => {
  const { seriesSettingsStore } = useStores();

  return (
    <>
      <MainHeader showDefault={false} />
      <NGCHeader title="Series Sorting" />
      <Body>
        <SeriesSortingList seriesSortingList={seriesSettingsStore.seriesSortingList} />
      </Body>
    </>
  );
};

export default observer(SeriesSortingSettingsComponent);
