import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import RgnlAltModel from '../../models/RgnlAltModel';
import ScenarioModel from '../../models/ScenarioModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import SetupConfirmErrors from '../SetupConfirmErrors';
import RgnlAltTitle from './RgnlAltTitle';

import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
  rgnlAlt: RgnlAltModel;
  scenario: ScenarioModel;
}

const TitleSummarySetup = ({ seriesProfile, scenario, rgnlAlt }: Props) => {
  return (
    <div className={styles.summarySection}>
      <RgnlAltTitle seriesProfile={seriesProfile} scenario={scenario} rgnlAlt={rgnlAlt} isDisabled={seriesProfile.data.isBudgetConfirmed} showCtas={false} />
      {rgnlAlt.setupConfirmErrors && <SetupConfirmErrors errors={rgnlAlt.setupConfirmErrors} />}
      <fieldset disabled={seriesProfile.data.isBudgetConfirmed}>
        {rgnlAlt.setupOffers.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className={styles.offerTypeCol}>Offer Type</th>
                <th>Offer Cost</th>
              </tr>
            </thead>
            <tbody>
              {rgnlAlt.setupOffers.map(offer => (
                <tr key={offer.uid}>
                  <td className={styles.label}>{offer.fields.offerName}</td>
                  {offer.cashSetupDetails && <td>${formatDollars(offer.cashSetupDetails?.offerPnvs)}</td>}
                  {offer.aprSetupDetails && <td>${formatDollars(offer.aprSetupDetails?.offerPnvs)}</td>}
                  {offer.leaseSetupDetails && <td>${formatDollars(offer.leaseSetupDetails?.fields.offerPnvs)}</td>}
                  {offer.miscSetupDetails && <td>${formatDollars(offer.miscSetupDetails?.offerPnvs)}</td>}
                  {offer.finalPaySetupDetails && <td>${formatDollars(offer.finalPaySetupDetails?.offerPnvs)}</td>}
                  {offer.nationalRyoSetupDetails && <td>${formatDollars(offer.nationalRyoSetupDetails?.offerPnvs)}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </fieldset>
    </div>
  );
};

export default observer(TitleSummarySetup);
