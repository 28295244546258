import clsx from 'clsx';
import { AdminModels } from 'oat-admin-common';
import SeriesListModel from '../../../models/SeriesListModel';
import styles from '../../../styles.module.scss';
import GridWrapper from '../GridWrapper';
import SeriesItemRowDetail from '../SeriesItemRowDetail';
import FYSeriesDetailsWrapper from './FYSeriesDetailsWrapper';
import FYSeriesYearsListItem from './FYSeriesYearsListItem';

interface Props {
  seriesItem: SeriesListModel;
  fiscalMonths: AdminModels.FiscalMonth[];
  title: string;
}

const FYSeriesDetail = ({ seriesItem, fiscalMonths, title }: Props) => {
  const { series, modelYears, seriesMonths, seriesFYMYTotals } = seriesItem;

  return (
    <div className={styles.fySeriesDetails}>
      <GridWrapper>
        <span className={clsx(styles.fySeriesDetailsTitle, title.length > 5 ? styles.long : styles.short)}>{title}</span>
        <FYSeriesDetailsWrapper>
          {seriesItem.modelYears.map(year => (
            <FYSeriesYearsListItem key={year} year={year} />
          ))}
        </FYSeriesDetailsWrapper>
        {fiscalMonths.map(item => {
          return (
            <FYSeriesDetailsWrapper key={`detail-${series}-${item.month}`}>
              <SeriesItemRowDetail modelYearDetails={seriesMonths[item.month]?.modelYearDetails} title={title} yearsList={modelYears} />
            </FYSeriesDetailsWrapper>
          );
        })}
        <div className={styles.seriesItemTotals}>
          <SeriesItemRowDetail seriesFYMYTotals={seriesFYMYTotals} title={title} isFyTotal={true} yearsList={modelYears} />
        </div>
      </GridWrapper>
    </div>
  );
};

export default FYSeriesDetail;
