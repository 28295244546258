import { makeAutoObservable } from 'mobx';
import { dateFormatISOString } from 'oat-common-ui';
import { IncentiveCalendar } from '../gql/generated';
import { IncentiveCalendarLocal, parseIncentiveCalendarMonths } from '../pages/Settings/IncentiveCalendar/parseIncentiveCalendarMonths';

class IncentiveCalendarStore {
  incentiveCalendar: IncentiveCalendarLocal = {
    rev: '',
    brand: '',
    fiscalYear: new Date().getFullYear(),
    months: parseIncentiveCalendarMonths(new Date().getFullYear()),
  };
  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setHasError = (errorMsg: string) => {
    // find specific months with errors from errorMsg
    // assign hasError to true for specific months found from errorMsg
    // errors for two months overlapping date ranges and date gap between months
    if (errorMsg.includes('Period')) {
      const firstMonth = Number(errorMsg.substring(12, 14)) - 1;
      const secondMonth = Number(errorMsg.substring(40, 42)) - 1;

      this.incentiveCalendar.months[firstMonth].hasError = true;
      this.incentiveCalendar.months[secondMonth].hasError = true;
    } else {
      const firstMonth = Number(errorMsg.substring(28, 30)) - 1;
      const secondMonth = Number(errorMsg.substring(43, 45)) - 1;

      this.incentiveCalendar.months[firstMonth].hasError = true;
      this.incentiveCalendar.months[secondMonth].hasError = true;
    }
  };

  setYear = (year: number) => {
    this.incentiveCalendar.fiscalYear = year;
    this.incentiveCalendar.months = parseIncentiveCalendarMonths(year);
  };

  setBrand = (brand: string) => {
    this.incentiveCalendar.brand = brand;
  };

  setMonthDate = (date: Date | undefined, month: number, field: 'startDate' | 'endDate') => {
    if (date) {
      this.incentiveCalendar.months[month][field] = dateFormatISOString(date);
    }
  };

  setIncentiveCalendar = (incentiveCalendar: IncentiveCalendar) => {
    this.incentiveCalendar.brand = incentiveCalendar.brand;
    this.incentiveCalendar.rev = incentiveCalendar.rev;
    this.incentiveCalendar.fiscalYear = incentiveCalendar.fiscalYear;
    this.incentiveCalendar.months = parseIncentiveCalendarMonths(incentiveCalendar.fiscalYear, incentiveCalendar);
  };

  setHasUnsavedChanges = (value: boolean) => {
    this.hasUnsavedChanges = value;
  };
}

export default IncentiveCalendarStore;
