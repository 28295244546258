import { OATWrapper } from 'oat-common-ui';
import MainFooter from '../../components/MainFooter';

type Props = {
  message?: string;
};

const Error = ({ message = 'Service unavailable. Please try again later.' }: Props) => {
  return (
    <>
      <OATWrapper>
        <h2>{message}</h2>
      </OATWrapper>
      <MainFooter />
    </>
  );
};

export default Error;
