import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import Button from '../../../../../../components/Button';

import AprModel from '../../../../models/offers/apr/AprModel';
import styles from './styles.module.scss';

interface Props {
  apr: AprModel;
  onSave: () => void;
}

const StdRatesButton = ({ apr, onSave }: Props) => {
  const { uid, applyStdRatesDisabled, applyStandardRates, recalculateAll } = apr;

  const handleOnClick = () => {
    applyStandardRates();
    recalculateAll();
    onSave();
  };

  return (
    <Button variant="text" id={`${uid}-apply-std-rates-btn`} className={styles.stdRatesBtn} onClick={handleOnClick} disabled={applyStdRatesDisabled}>
      <OATIcon icon="reset" />
      <span className={styles.btnText}>Apply Standard Rates</span>
    </Button>
  );
};

export default observer(StdRatesButton);
