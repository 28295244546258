import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Checkbox, DefaultModal, Input } from 'oat-common-ui';
import { useState } from 'react';
import BlockInputLabel from '../../../../components/BlockInputLabel';
import Button from '../../../../components/Button';
import { FEATURE_OR_4767 } from '../../../../constants/global';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
}

const RgnlAltSetupFields = ({ rgnlAlt, seriesProfile, onSave }: Props) => {
  const { data, updateDataField, toggleRegionCode, toggleStateSelection, toggleAllRegions, applyStates, getAppliedStates, resetRegionsToInitialValue } = rgnlAlt;

  const [selectedRegion, setSelectedRegion] = useState('');
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

  const raNameLabel = data.number ? `RA-${data.number} Name` : 'RA- Name';

  const handleToggleAllRegions = () => {
    const regionsSelected = data.regions.some(region => !region.selected);
    toggleAllRegions(regionsSelected);

    if (!regionsSelected) {
      setSelectedRegion('');
    }

    handleOnSave();
  };

  const handleOnSave = () => {
    if (!rgnlAlt.getSetupError()) {
      if (FEATURE_OR_4767) {
        setIsOpenAlertModal(true);
      } else {
        onSave();
      }
    }
  };

  return (
    <fieldset className={styles.raInfoSection} disabled={seriesProfile.isConfirmed}>
      <section className={styles.primaryFieldSection}>
        {/* RA Name */}
        <BlockInputLabel label={raNameLabel} className={styles.textInput}>
          <Input
            id={`${rgnlAlt.uid}-ra-name`}
            value={data.name ?? ''}
            error={!data.name}
            isNational
            onChange={val => {
              updateDataField('name', val.currentTarget.value);
              handleOnSave();
            }}
            className={styles.raInput}
          />
        </BlockInputLabel>
      </section>
      {/* Region/Area and states selection */}
      <section className={styles.regionsSelector}>
        <div className={styles.checkboxesListContainer}>
          <span>Regions</span>
          <ul className={styles.checkboxesList}>
            <li key="all-regions" className={styles.allCheckbox}>
              <Checkbox
                id={`${rgnlAlt.uid}-toggle-all`}
                checkmarkClass={styles.checkMarkRedesign}
                className={styles.checkbox}
                isChecked={!data.regions.some(region => !region.selected)}
                label={
                  <Button
                    id={`${rgnlAlt.uid}-toggle-all-regions-btn`}
                    disabled={seriesProfile.isConfirmed}
                    className={styles.checkboxLabel}
                    onClick={() => handleToggleAllRegions()}
                  >
                    All
                  </Button>
                }
                onChange={() => handleToggleAllRegions()}
              />
            </li>
            {data.regions.map(region => (
              <li key={region.code} className={styles.checkboxItem}>
                <div className={styles.test}>
                  <Checkbox
                    id={`${rgnlAlt.uid}-toggle-${region.code}`}
                    isDisabled={seriesProfile.isConfirmed}
                    checkmarkClass={styles.checkMarkRedesign}
                    className={styles.checkbox}
                    isChecked={region.selected}
                    onChange={() => {
                      toggleRegionCode(region.code);
                      handleOnSave();
                    }}
                  />
                  <Button
                    id={`${rgnlAlt.uid}-${region.code}-toggle-btn`}
                    className={clsx(styles.checkboxLabel, { [styles.selected]: region.code === selectedRegion })}
                    role="button"
                    disabled={seriesProfile.isConfirmed}
                    onClick={() => setSelectedRegion(region.code)}
                  >
                    <span className={styles.regionCode}>{region.code}</span>
                    <span>{region.name}</span>
                  </Button>
                </div>
                <span className={styles.appliedStates}>{getAppliedStates(region.code)?.length !== region.states.length ? getAppliedStates(region.code)?.join(',') : ''}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.checkboxesListContainer}>
          <span>States</span>
          <p className={styles.subtitle}>Select a Region Name to Edit States</p>
          <ul className={styles.checkboxesList}>
            {data.regions
              .find(region => region.code === selectedRegion)
              ?.states.map(state => (
                <li key={state.state} className={styles.checkboxItem}>
                  <Checkbox
                    id={`${rgnlAlt.uid}-select-state-${state.state}`}
                    checkmarkClass={styles.checkMarkRedesign}
                    className={styles.checkbox}
                    isChecked={state.selected}
                    label={state.state}
                    onChange={() => toggleStateSelection(selectedRegion, state.state)}
                  />
                </li>
              ))}
          </ul>
          <Button
            id={`${rgnlAlt.uid}-apply-btn`}
            onClick={() => {
              applyStates(selectedRegion);
              handleOnSave();
            }}
            variant="white"
            className={styles.applyBtn}
          >
            Apply
          </Button>
        </div>
        {isOpenAlertModal && FEATURE_OR_4767 && (
          <DefaultModal
            message="The data will be overwritten if there are offers within the regional alternative whose state restrictions did not match the regional alternative state restrictions."
            modalBodyClassName={styles.alertModal}
            onClose={() => {
              resetRegionsToInitialValue();
              setIsOpenAlertModal(false);
            }}
            onSubmit={() => {
              onSave();
              setIsOpenAlertModal(false);
            }}
            open
            submitText="OK"
            title="Alert"
          />
        )}
      </section>
    </fieldset>
  );
};

export default observer(RgnlAltSetupFields);
