import { Button, DropdownItem } from 'oat-common-ui';
import { handleScrollOnClick } from '../../../../FYRollup/components/header/Navbar/utils';
import NationalCostShareModel from '../../models/NationalCostShareModel';
import styles from './styles.module.scss';

interface Props {
  option: DropdownItem;
  costShares: NationalCostShareModel[];
}

const SeriesNameButton = ({ option, costShares }: Props) => {
  const handleClick = () => {
    handleScrollOnClick(option.value, 2);
  };

  return (
    <Button onClick={() => handleClick()} className={styles.seriesListItemBtn} id={`added-series-${option.value}`}>
      <span className={styles.seriesName}>{option.label}</span>
      <span className={styles.costShareCount}>({costShares.length})</span>
    </Button>
  );
};

export default SeriesNameButton;
