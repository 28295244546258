import { AdminModels } from 'oat-admin-common';

export const processGradeTitle = (series: string, gradeTitle: string, modelCode: string) => {
  const grade = gradeTitle ? ` ${gradeTitle.replace(/\bHybrid\b\s?/gi, '').trim()}` : '';
  return `${series}${grade} (${modelCode})`;
};

/**
 * Builds series string for Lease model code info pop up
 */
const buildSeriesString = (isLexus: boolean, { modelCode, modelDescription, series, grade }: AdminModels.EFCVinResponse) => {
  let modelSeries = '';
  let gradeTitle = '';

  if (isLexus) {
    // model description could contain series (eg. ES 350)
    // if it contains series, use model description as series and blank out grade Title
    const useModelDescription = modelDescription.indexOf(series) !== -1;

    const lexusSeries = useModelDescription ? modelDescription : series;
    gradeTitle = useModelDescription ? '' : modelDescription;

    modelSeries = processGradeTitle(lexusSeries, gradeTitle, modelCode);
  } else {
    gradeTitle = grade && grade.length ? grade[0].title : '';

    const index = gradeTitle !== '' ? gradeTitle.toLowerCase().indexOf(series.toLowerCase()) : 0; // lower case all and find index of repeat series. (ex. The series could be Prius C and the grade could be Prius c :/)
    if (index !== -1) {
      // now use the substring of the index to index + series.length to do a replace afterwards
      const replaceStr = gradeTitle !== '' ? gradeTitle.substring(index, index + series.length) : ''; // take substring of index to string length;
      gradeTitle = gradeTitle.replace(replaceStr, '').trim(); // do replace and trim whitespaces
    }
    modelSeries = processGradeTitle(series, gradeTitle, modelCode);
  }
  return modelSeries;
};

export default buildSeriesString;
