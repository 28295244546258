import { useLocalObservable } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { CreateLeaseModels } from 'oat-common-ui';
import { useEffect } from 'react';
import { FEATURE_OR_4641 } from '../../../../../../constants/global';
import { ModelCodeMappingResponse, ModelCodeResponse } from '../../../../../../gql/generated';
import useStores from '../../../../../../stores/useStores';
import getPreNglResidualItem from '../../../../../../utils/getPreNglResidualItem';
import SeriesProfileModel from '../../../../models/SeriesProfileModel';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import AddVinStore from './AddVinStore';
const { BRAND_TOYOTA } = AdminConstants;

const useNewVinModalService = (seriesProfile: SeriesProfileModel, leaseForm: LeaseFormModel) => {
  const {
    seriesSettingsStore: { getOatSeriesBySeriesAndYear },
    nationalProgramsStore: {
      residualValues,
      modelCodeMappings,
      standardRates: { rvDifferential },
    },
    efcStore,
  } = useStores();

  const addVinStore = useLocalObservable(() => new AddVinStore());

  // Initial load to fetch trims
  useEffect(() => {
    const oatSeries = getOatSeriesBySeriesAndYear(seriesProfile.data.series, seriesProfile.data.modelYear);
    const year = seriesProfile.data.modelYear;
    const series = oatSeries?.seriesCode ?? '';
    const brand = seriesProfile.data.brand ?? BRAND_TOYOTA;
    const trimItems: CreateLeaseModels.TrimItem[] = [];
    const promises: Promise<CreateLeaseModels.TrimItem>[] = [];

    const buildTrimItem = async (mdl: ModelCodeResponse, year: number, mcItem: ModelCodeMappingResponse, series: string, brand: string) => {
      const { getInventoryApi } = efcStore;
      const rvItem = getPreNglResidualItem(residualValues, +mdl.modelCode, year, +leaseForm.leaseFields.term, leaseForm.leaseFields.mileage, rvDifferential);
      const rvDesc = rvItem?.description ?? '';
      const trimTitle = [`(${mdl.modelCode})${rvDesc ? ' ' + rvDesc + ' :' : ''}`, mdl.vehicleDescription].join(' ');
      const seriesTitle = [mdl.vehicleDescription.replace(mdl.modelCode, ''), `(${mdl.modelCode})`].join(' ');
      let vinCount = 0;
      if (FEATURE_OR_4641) {
        try {
          const vinData = await getInventoryApi(mdl.modelCode, mcItem?.year, series, brand);
          if (vinData.body.response.docs.length) {
            vinData.body.response.docs.forEach((vinItem: any) => (vinCount += Number(vinItem.vincount)));
          }
        } catch (e) {
          console.log(e);
        }
      }

      const trimItem: CreateLeaseModels.TrimItem = {
        code: mdl.modelCode,
        grade: rvItem?.description ?? '',
        title: mdl.vehicleDescription,
        trimTitle,
        seriesTitle, // used for vehicleDescription payload
        carJelly: '',
        residual: (rvItem?.adjustedRv ?? 0).toString(),
        count: FEATURE_OR_4641 ? vinCount : undefined,
      };
      return trimItem;
    };

    (async () => {
      addVinStore.loading = true;
      for (const mcItem of modelCodeMappings) {
        if (mcItem?.bosuSeries.trim() === seriesProfile.data.series && mcItem?.year === year.toString()) {
          if (mcItem?.models){
            for (const mdl of mcItem.models) {
              promises.push(buildTrimItem(mdl, year, mcItem, series, brand));
            }
          }
        }
      };
      const res = await Promise.all(promises);
      res.forEach(item => {
        trimItems.push(item);
      });
      addVinStore.setTrimList(trimItems, series, year.toString());
      addVinStore.loading = false;
    })();
  }, [getOatSeriesBySeriesAndYear, modelCodeMappings, residualValues, seriesProfile, addVinStore, leaseForm, rvDifferential, efcStore]);

  return {
    addVinStore,
  };
};

export default useNewVinModalService;
