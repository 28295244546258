import { APRCalcFns } from 'oat-admin-common';
import { DefaultTfsShares } from '../../../models/offers/apr/AprModel';
import TermWeightedCostModel from '../models/TermWeightedCostModel';
import TierPenRateModel from '../models/TierPenRateModel';
import TierTermModel from '../models/TierTermModel';
import { createCalcParams } from './createCalcParams';

export const calcPnvsValues = (tierTerm: TierTermModel, term: number, defaultTfsShares: DefaultTfsShares, ncsRate: number) => {
  const pnvsParams = createCalcParams(tierTerm, false, Number(tierTerm.penetrationRate) / 100, Number(tierTerm.tfsShare));
  const fullPnvsParams = createCalcParams(tierTerm, false, 1, Number(tierTerm.tfsShare));
  const tfsPnvsParams = createCalcParams(tierTerm, true, Number(tierTerm.penetrationRate) / 100, Number(tierTerm.tfsShare));
  const fullTfsPnvsParams = createCalcParams(tierTerm, true, 1, Number(tierTerm.tfsShare));
  const defaultTfsShare = defaultTfsShares[tierTerm.term] ?? 0;
  const enhancedTfsShare = Number(tierTerm.tfsShare) - defaultTfsShare;
  const enhancedTfsPnvsParams = createCalcParams(tierTerm, true, Number(tierTerm.penetrationRate) / 100, enhancedTfsShare);

  // non tfs calc values
  const pnvs = APRCalcFns.calculateWeightedPnvsWithRates(pnvsParams); // top value on APRPnvs
  const fullPnvs = APRCalcFns.calculateWeightedPnvsWithRates(fullPnvsParams); // bottom value on APRPnvs

  // tfs calc values
  const tfsPnvs = APRCalcFns.calculateWeightedPnvsWithRates(tfsPnvsParams);
  const fullTfsPnvs = APRCalcFns.calculateWeightedPnvsWithRates(fullTfsPnvsParams);
  const enhancedTfsPnvs = APRCalcFns.calculateWeightedPnvsWithRates(enhancedTfsPnvsParams);

  // ncs calc values
  const tierTermNcsPnvs = APRCalcFns.calculateTierTermNcsTfsPnvs(
    tierTerm.standardRate / 100,
    ncsRate / 100,
    term,
    Number(tierTerm.penetrationRate) / 100,
    Number(tierTerm.averageAmountFinanced),
  );

  const enhTierTermNcsPnvs = APRCalcFns.calculateEnhancedTierTermNcsTfsPnvs(
    tierTerm.standardRate / 100,
    ncsRate / 100,
    term,
    defaultTfsShares[term] ? defaultTfsShares[term] / 100 : 0,
    Number(tierTerm.penetrationRate) / 100,
    Number(tierTerm.averageAmountFinanced),
  );

  tierTerm.pnvs = pnvs;
  tierTerm.fullPnvs = fullPnvs;
  tierTerm.tfsPnvs = tfsPnvs;
  tierTerm.fullTfsPnvs = fullTfsPnvs;
  tierTerm.enhancedTfsPnvs = enhancedTfsPnvs;
  tierTerm.ncsTfsPnvs = tierTermNcsPnvs;
  tierTerm.ncsEnhancedTfsPnvs = enhTierTermNcsPnvs;
};

export const calculateWeightedTermPnvsValues = (termWeightedCost: TermWeightedCostModel, filteredByTerm: TierTermModel[], tierPenRates: TierPenRateModel[]) => {
  termWeightedCost.pnvs = Math.round(
    filteredByTerm.reduce((acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.pnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)), 0),
  );

  termWeightedCost.fullPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.fullPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );

  termWeightedCost.tfsPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.tfsPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );

  termWeightedCost.fullTfsPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.fullTfsPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );

  termWeightedCost.enhancedTfsPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.enhancedTfsPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );

  termWeightedCost.ncsTfsPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.ncsTfsPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );

  termWeightedCost.ncsEnhancedTfsPnvs = Math.round(
    filteredByTerm.reduce(
      (acc, curr) => acc + APRCalcFns.calculateWeightedTierTermPnvs(curr.ncsEnhancedTfsPnvs, Number(tierPenRates.find(t => t.tier === curr.tier)?.penetrationRate ?? 0)),
      0,
    ),
  );
};
