import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import SeriesCostShare from '../SeriesCostShare';
import styles from './styles.module.scss';

const RightSide = () => {
  const {
    nationalCostShareStore: { addedSeriesOptions, costSharesBySeriesProfile },
  } = useStores();

  return (
    <div className={styles.rightSide}>
      {addedSeriesOptions.map((series, i) => {
        const costShareSeriesProfile = costSharesBySeriesProfile.get(series.value);

        if (costShareSeriesProfile?.costShares.length) {
          return <SeriesCostShare key={`${series.value}-${i}`} nationalCostShares={costShareSeriesProfile.costShares} seriesProfileId={series.value} />;
        }

        return null;
      })}
    </div>
  );
};

export default observer(RightSide);
