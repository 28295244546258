import AccordionItemModel from '../models/AccordionItemModel';

/**
 * @param items Should be root items
 */
const recursiveToggleAll = (accItems: AccordionItemModel[], checked: boolean) => {
  accItems.forEach(i => {
    if (!i.disabled) {
      i.checked = checked;
      if (i.items.length > 0) {
        recursiveToggleAll(i.items, checked);
      }
    }
  });
};

export default recursiveToggleAll;
