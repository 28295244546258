import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { assignNumberValue, BlockInputLabel, Input, NumberInput, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import BasicPNVS from '../../../../components/BasicPNVS';
import { Offer, OfferingCosts, useCreateNationalRyoOfferMutation, useUpdateNationalRyoOfferBudgetMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import OfferTitle from '../../components/OfferTitle';
import NationalRyoModel, { NationalRYOCostFields } from '../../models/offers/NationalRyoModel';
import { OfferFields } from '../../models/offers/OfferFields';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import IncludedSection from './components/NationalRyo/IncludedSection';
import { getCreateNationalRyoOfferPayload, getUpdateNationalRyoOfferBudgetPayload } from './createNationalRyoPayload';
import styles from './styles.module.scss';

interface Props {
  nationalRyo: NationalRyoModel;
  rgnlAlt: RgnlAltModel;
  rgnlAlts: RgnlAltModel[];
  seriesProfile: SeriesProfileModel;
}

const NationalRyoSection = ({ nationalRyo, rgnlAlt, rgnlAlts, seriesProfile }: Props) => {
  const { uid, offerFields, offerCostFields, updateOfferField, updateOfferCostFields, nameError, penetrationRateError, setRevAndId, setPercentageActive } = nationalRyo;
  const hasMoreThanOneRas = () => {
    return rgnlAlts.filter(ra => ra.uid !== rgnlAlt.uid).length > 0;
  };
  const includedSectionRgnlAlts = rgnlAlts.filter(r => r.data.id !== rgnlAlt.data.id);

  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();
  const { error } = useToast();
  const { setDelay } = useInputDelay();
  const [createNationalRyoOffer] = useCreateNationalRyoOfferMutation();
  const [updateNationalRyoOfferBudget] = useUpdateNationalRyoOfferBudgetMutation();

  const handleSaveOffer = async () => {
    if (!offerFields.name || !offerFields.penetrationRate) {
      return;
    }

    setDelay(async () => {
      if (!offerFields.id) {
        try {
          const res = await trackPromise(createNationalRyoOffer({ variables: { input: { ...getCreateNationalRyoOfferPayload(nationalRyo) } } }));
          if (res.data?.createNationalRyoOffer.success) {
            setRevAndId(res.data?.createNationalRyoOffer.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.createNationalRyoOffer.offeringCosts as OfferingCosts);

            if (res.data?.createNationalRyoOffer.rgnlAltId && res.data?.createNationalRyoOffer.rgnlAltRev) {
              rgnlAlt.updateRev(res.data?.createNationalRyoOffer.rgnlAltId, res.data?.createNationalRyoOffer.rgnlAltRev);
            }

            const newOfferSortOrder = { offerId: res.data?.createNationalRyoOffer.offer.id, sortOrder: rgnlAlt.getSortOrderByOfferUid(nationalRyo.uid) };
            rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
          }
        } catch (e) {
          error((e as Error).message);
        }
      } else {
        try {
          const res = await trackPromise(updateNationalRyoOfferBudget({ variables: { input: { ...getUpdateNationalRyoOfferBudgetPayload(nationalRyo) } } }));
          if (res.data?.updateNationalRyoOfferBudget.success) {
            setRevAndId(res.data?.updateNationalRyoOfferBudget.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.updateNationalRyoOfferBudget.offeringCosts as OfferingCosts);
            setPercentageActive(res.data?.updateNationalRyoOfferBudget.offer.nationalRyoDetails?.isPercentSelected || false);
          }
        } catch (e) {
          error((e as Error).message);
        }
      }
    });
  };

  const handleUpdateRyoField = (fieldName: keyof NationalRYOCostFields, value: string | number) => {
    seriesProfile.clearErrors();
    updateOfferCostFields(fieldName, value);
    handleSaveOffer();
  };

  const handleUpdateOfferField = (fieldName: keyof OfferFields, value: string | number | boolean) => {
    seriesProfile.clearErrors();
    updateOfferField(fieldName, value);
    handleSaveOffer();
  };

  return (
    <>
      <OfferHeader>
        <OfferTitle offerType="ryo" title={offerFields.optionType} />
        <BasicPNVS offerPnvs={offerCostFields.offerPnvs} />
      </OfferHeader>
      <div className={styles.offerBody}>
        <div className={styles.fieldsSection}>
          <fieldset className={styles.ryoForm} disabled={seriesProfile?.data.isBudgetConfirmed}>
            <div className={styles.ryoFormName}>
              <BlockInputLabel label="Name">
                <Input id={`name-${uid}`} isNational value={offerFields.name} onChange={val => handleUpdateOfferField('name', val.currentTarget.value)} error={nameError} />
              </BlockInputLabel>
            </div>
            <div className={styles.ryoFormFields}>
              <BlockInputLabel label="Amount">
                <NumberInput id={`amount-${uid}`} dollarSign isNational value={offerCostFields.amount} wholeNumber disabled units />
              </BlockInputLabel>
              <BlockInputLabel label="Calculate RYO Amount" labelClass={styles.ryoAmountLabel}>
                <div className={styles.ryoCalculatorContainer}>
                  <NumberInput
                    id={`ryo-amount-${uid}`}
                    isNational
                    min={0}
                    onChange={val => handleUpdateRyoField('ryoAmountPercent', val.currentTarget.value)}
                    onFocus={() => setPercentageActive(true)}
                    required
                    value={offerCostFields.ryoAmountPercent}
                    wholeNumber
                    percentageSign
                    className={clsx(styles.percentageInput, offerCostFields.isPercentSelected && styles.activeInput)}
                    units
                  />
                  <div className={styles.divider} />
                  <NumberInput
                    id={`ryo-cash-${uid}`}
                    isNational
                    allowNegative
                    onChange={val => handleUpdateRyoField('ryoAmountCash', assignNumberValue(val.currentTarget.value.replace(',', '')))}
                    onFocus={() => setPercentageActive(false)}
                    required
                    value={offerCostFields.ryoAmountCash}
                    wholeNumber
                    dollarSign
                    className={clsx(styles.amountInput, !offerCostFields.isPercentSelected && styles.activeInput)}
                    units
                  />
                  <span className={clsx(styles.sign, !offerCostFields.isPercentSelected && styles.iconSpan)}>+/-</span>
                </div>
              </BlockInputLabel>
              <BlockInputLabel label="Total Cash Pen %">
                <div className={styles.totalCashPen}>
                  <NumberInput
                    id={`cash-pen-${uid}`}
                    error={penetrationRateError}
                    isNational
                    max={100}
                    min={0}
                    onChange={val => handleUpdateOfferField('penetrationRate', val.currentTarget.value)}
                    required
                    percentageSign
                    value={offerFields.penetrationRate}
                    wholeNumber
                  />
                </div>
              </BlockInputLabel>
            </div>
          </fieldset>
        </div>
      </div>
      {hasMoreThanOneRas() ? (
        <IncludedSection nationalRyo={nationalRyo} onSave={handleSaveOffer} rgnlAlts={includedSectionRgnlAlts} seriesProfile={seriesProfile} />
      ) : (
        <span className={styles.error}>Please add offers to calculate National RYO.</span>
      )}

      <OfferFooter
        uid={nationalRyo.uid}
        seriesProfile={seriesProfile}
        offerFields={nationalRyo.offerFields}
        rgnlAlt={rgnlAlt}
        isForRegions={offerFields.isForRegions}
        isForRegionsDisabled={false}
        updateOfferField={(field, val) => {
          handleUpdateOfferField(field, !val);
        }}
      />
    </>
  );
};

export default observer(NationalRyoSection);
