import { RCF_INITIAL_VALUE } from '../../../constants/global';
import AprRateModel from './models/AprRateModel';
import LeaseRateModel from './models/LeaseRateModel';
import TFSLeaseRateModel from './models/TFSLeaseRateModel';

export const processRcf = (rate: number) => {
  return rate === 0 ? '' : rate.toFixed(5);
};

export const formatRates = (rates: AprRateModel[] | LeaseRateModel[] | TFSLeaseRateModel[], isApr: boolean) => {
  for (const rate of rates) {
    rate.rate = Number(rate.rate.toFixed(isApr ? 2 : 5));
  }

  return rates;
};

export const createRatesPayload = (rates: AprRateModel[] | LeaseRateModel[] | TFSLeaseRateModel[]) => {
  return rates.map(r => ({ differential: r.differential, tier: r.tier, term: r.term, rate: r.rate, previousRate: r.previousRate }));
};

export const createDifferentialPayload = (differential: number) => {
  return differential === RCF_INITIAL_VALUE ? 0 : differential;
};

export const processRate = (previousRate: number, rate: number) => {
  return previousRate !== 0 ? previousRate + rate : previousRate;
};
