import { observer } from 'mobx-react-lite';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Button from '../../../../../components/Button';
import useStores from '../../../../../stores/useStores';
import styles from '../styles.module.scss';

interface Props {
  fiscalYear: string;
  isOpen: boolean;
  onClose: () => void;
}

const ErrorGeneratingReportModal = ({ fiscalYear, isOpen, onClose }: Props) => {
  const {
    reportsStore: { setSelectedReport },
  } = useStores();

  return (
    <Modal className="generating-report-error-modal" isOpen={isOpen} onClose={onClose}>
      <ModalHeader className={styles.header} onClose={onClose}>
        Error Generating Report
      </ModalHeader>
      <ModalBody>
        <div>
          <span>{`Please Re-calculate Budget for Fiscal Year - ${fiscalYear}`}</span>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button className={styles.footerActions} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={styles.footerActions}
          onClick={() => {
            onClose();
            setSelectedReport({ label: 'Working', value: 'working' });
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ErrorGeneratingReportModal);
