import { OATLoadingBar } from 'oat-common-ui';
import { useGetOfferingByIdQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import GeneralTabComponent from './GeneralTabComponent';

const GeneralTab = () => {
  const {
    ipSettingsStore: { setOffering },
  } = useStores();

  const { offeringId } = useUrlParams();

  const { data, loading, error } = useGetOfferingByIdQuery({
    variables: {
      id: offeringId,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    setOffering(data.getOffering);
  }

  return <GeneralTabComponent />;
};

export default GeneralTab;
