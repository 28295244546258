import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import InputsWrapper from '../components/InputsWrapper';
import SectionLabel from '../components/SectionLabel';
import ShowAdvancedSettingsButton from '../components/ShowAdvancedSettingsButton';
import APRRatesTable from './components/APRRatesTable';
import ChangeInRateInput from './components/ChangeInRateInput';

const APRStandardRate = () => {
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  return (
    <>
      <SectionLabel label="APR Standard Rate" />
      <InputsWrapper>
        <ChangeInRateInput />
        <ShowAdvancedSettingsButton
          id="apr-standard-rate-show-advanced-settings-btn"
          showAdvancedSettings={showAdvancedSettings}
          onClickHandler={() => setShowAdvancedSettings(!showAdvancedSettings)}
        />
      </InputsWrapper>
      {showAdvancedSettings && <APRRatesTable />}
    </>
  );
};

export default observer(APRStandardRate);
