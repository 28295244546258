import { makeAutoObservable } from 'mobx';

class UploadsTabStore {
  uploadsHasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUploadsHasUnsavedChanges = (val: boolean) => {
    this.uploadsHasUnsavedChanges = val;
  };
}

export default UploadsTabStore;
