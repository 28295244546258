import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import NGCHeader from '../../../components/NGCHeader';
import { SeriesMapping, useUpdateSeriesMappingMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import SeriesMappingTable from './SeriesMappingTable';

// Using GetSeriesMappingData HOC to fetch data on load
const SeriesMappingSettings = () => {
  const { seriesSettingsStore } = useStores();
  const { error } = useToast();

  const [updateSeriesName] = useUpdateSeriesMappingMutation();

  const handleUpdateSeriesname = async (series: SeriesMapping, updatedName: string) => {
    try {
      const res = await trackPromise(
        updateSeriesName({
          variables: {
            input: {
              id: series.id,
              rev: series.rev,
              displayName: updatedName,
            },
          },
        }),
      );
      const resSeries = res.data?.updateSeriesMapping.seriesMapping;
      if (resSeries) {
        seriesSettingsStore.updateSeriesName(resSeries);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <NGCHeader title="Series Mapping" />
      <SeriesMappingTable seriesMappingList={seriesSettingsStore.seriesMapping} onUpdate={handleUpdateSeriesname} />
    </>
  );
};

export default observer(SeriesMappingSettings);
