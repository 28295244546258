import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Input, OATIcon } from 'oat-common-ui';
import { ChangeEvent, useState } from 'react';
import InlineCheckBox from '../../../../../../../components/InlineCheckBox';
import SeriesProfileModel from '../../../../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  seriesProfiles: SeriesProfileModel[];
  activeSeriesProfile: SeriesProfileModel;
}

const ExportSection = ({ seriesProfiles, activeSeriesProfile }: Props) => {
  const [filteredProfiles, setFilteredProfiles] = useState<SeriesProfileModel[]>(seriesProfiles.filter(s => s.id !== activeSeriesProfile.id));

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase().trim();
    !searchValue
      ? setFilteredProfiles(seriesProfiles.filter(s => s.id !== activeSeriesProfile.id))
      : setFilteredProfiles(
          seriesProfiles.filter(sp => {
            if (sp.id === activeSeriesProfile.id) {
              return false;
            }

            const valueToFilterBy =
              sp.data.multiSeriesOfferId && sp.data.multiSeriesOfferName
                ? `${sp.data.multiSeriesOfferName.toLowerCase().trim()}`
                : `${sp.data.modelYear} ${sp.series.toLowerCase().trim()}`;

            return valueToFilterBy.includes(searchValue);
          }),
        );
  };

  return (
    <div className={styles.export}>
      <BlockInputLabel labelComponent={<span className={styles.header}>Export to</span>}>
        <Input id="search-by-series" isNational placeholder="Search by series" className={styles.searchInput} onChange={handleSearch} />
        <OATIcon icon="search" className={styles.searchIcon} />
      </BlockInputLabel>
      <div className={styles.exportList}>
        <ul>
          {filteredProfiles.map(s => {
            const exportToSeriesName = s.data.multiSeriesOfferId ? `${s.data.multiSeriesOfferName}` : `${s.data.modelYear} ${s.displayName}`;

            return (
              <li key={s.id}>
                <InlineCheckBox text={exportToSeriesName} checked={s.exportTo} handleChange={() => s.toggleExportTo()} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default observer(ExportSection);
