import { OATLoadingBar } from 'oat-common-ui';
import { OfferingUploadData, useGetOfferingByIdQuery, useGetOfferingUploadDataQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import UploadsTabComponent from './UploadsTabComponent';

const UploadsTab = () => {
  const {
    ipSettingsStore: { offering, setOffering, setOfferingUploadData },
  } = useStores();

  const { offeringId } = useUrlParams();

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useGetOfferingByIdQuery({
    variables: {
      id: offeringId,
    },
    skip: !!offering.id,
  });

  const { data: offeringUploadData, loading: offeringUploadLoading, error: offeringUploadError } = useGetOfferingUploadDataQuery({ variables: { offeringId: offeringId } });

  if (offeringLoading || offeringUploadLoading) {
    return <OATLoadingBar />;
  }

  if (offeringError || offeringUploadError) {
    return <Error />;
  }

  if (offeringData) {
    setOffering(offeringData.getOffering);
  }

  if (offeringUploadData) {
    setOfferingUploadData(offeringUploadData.getOfferingUploadData as OfferingUploadData);
  }

  return <UploadsTabComponent />;
};

export default UploadsTab;
