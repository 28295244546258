import { makeAutoObservable } from 'mobx';
import { AdminModels, calculateBudgetPnvs } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import ModelYearDetailModel from './ModelYearDetailModel';
import SeriesListModel from './SeriesListModel';

class SeriesMonthModel implements AdminModels.SeriesMonth {
  month: number = dateStringToDate(new Date()).getMonth();
  year: number = dateStringToDate(new Date()).getFullYear();
  modelYearDetails: { [modelYear: number]: ModelYearDetailModel } = {};
  totals = {
    pnvs: 0,
    sales: 0,
    stock: 0,
    totalCost: 0,
  };
  seriesListModel;
  isDisplayOnly = false;

  constructor(data: AdminModels.SeriesMonth, seriesListModel: SeriesListModel) {
    makeAutoObservable(this);

    const { month, year, modelYearDetails, totals } = data;
    this.month = month;
    this.year = year;
    Object.values(modelYearDetails).forEach(modelYearDetail => {
      this.modelYearDetails[modelYearDetail.modelYear] = new ModelYearDetailModel(modelYearDetail, this);
    });
    this.totals = totals;
    this.seriesListModel = seriesListModel;
  }

  updateTotals = () => {
    // handles update of totals, seriesFYTotals from SeriesListModel, and fyMonthTotals & fyTotals from FYRollupStore
    this.totals.stock = Object.values(this.modelYearDetails).reduce((acc, curr) => acc + curr.stock, 0);
    this.totals.sales = Object.values(this.modelYearDetails).reduce((acc, curr) => acc + curr.sales, 0);
    this.totals.totalCost = Object.values(this.modelYearDetails).reduce((acc, curr) => acc + curr.totalCost, 0);
    this.totals.pnvs = calculateBudgetPnvs(this.totals.totalCost, this.totals.sales);

    // update parent Series List totals
    this.seriesListModel.updateTotals();
  };

  updateTotalsPnvsValue = (value: number) => {
    let differential = 0;

    if (value.toString().includes('-') || value < 0) {
      const valueParam = Number(value.toString().replace('-', ''));
      this.totals = { ...this.totals, pnvs: 0, totalCost: 0 };
      differential = this.totals.pnvs - valueParam;
    } else {
      differential = value - this.totals.pnvs;
      this.totals = { ...this.totals, pnvs: value, totalCost: value * this.totals.sales };
    }

    for (const modelYearDetail of Object.values(this.modelYearDetails)) {
      const pnvs = modelYearDetail.pnvs + differential;
      pnvs > 0 ? (modelYearDetail.pnvs = pnvs) : (modelYearDetail.pnvs = 0);
      modelYearDetail.totalCost = modelYearDetail.sales * modelYearDetail.pnvs;
    }
  };

  updateParentValues = () => {
    // update parent Series List totals
    this.seriesListModel.updateTotals();
  }
}

export default SeriesMonthModel;
