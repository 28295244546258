import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromOfferRegions from '../../../../components/Accordion/utils/createAccordionFromOfferRegions';
import { OfferRegion } from '../../../../gql/generated';
import RgnlAltModel from '../../models/RgnlAltModel';
import styles from './styles.module.scss';

interface Props {
  disabled?: boolean;
  hideCtas?: boolean;
  id?: string;
  onClose: () => void;
  onSubmit: (items: AccordionItemModel[]) => void;
  regions: OfferRegion[];
  rgnAlt: RgnlAltModel;
}

const StateRestrictionsModal = ({ hideCtas = false, id, onClose, onSubmit, regions, rgnAlt }: Props) => {
  const accordion = useLocalObservable(() => new AccordionModel(createAccordionFromOfferRegions(rgnAlt.data.regions, regions)));

  const hasAccordionItems = accordion.items.length > 0;

  return (
    <Modal className={styles.width} isOpen onClose={onClose}>
      <ModalHeader onClose={onClose}>Select Applicable States</ModalHeader>
      <ModalBody>
        <div className={styles.accordionWrapper}>
          {hasAccordionItems ? <AccordionComponent accordion={accordion} isDisabled={hideCtas} /> : <p className={styles.noModelsError}>No States found.</p>}
        </div>
      </ModalBody>
      {!hideCtas && (
        <ModalFooter className={styles.footerWrapper}>
          <ButtonGroup>
            <Button id={`default-modal-submit-${id}`} variant="primary" onClick={() => onSubmit(accordion.items)} disabled={accordion.isAllSelected(false)}>
              Submit
            </Button>
          </ButtonGroup>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default observer(StateRestrictionsModal);
