import { makeAutoObservable } from 'mobx';
import { DropdownItem, defaultStringToUndefined } from 'oat-common-ui';
import { CostShareItem, CostShareSeriesProfile, Maybe, SeriesProfile } from '../gql/generated';
import NationalCostShareModel from '../pages/IpSettings/CostShareTab/models/NationalCostShareModel';
import { sortCostSharesByType } from '../pages/IpSettings/CostShareTab/utils/sortCostSharesByType';

export type ICostShareSeriesProfile = {
  costShares: NationalCostShareModel[];
  created: string;
  fullMonthAprCostShareId?: string;
  fullMonthLeaseCostShareId?: string;
};

class NationalCostShareStore {
  id = '';
  rev = '';

  series: SeriesProfile[] = [];
  seriesOptions: DropdownItem[] = [];

  addedSeriesOptions: DropdownItem[] = [];
  costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile> = new Map();
  hasOverlapDatesError = false;
  hasInvalidDatesError = false;
  hasStdRatesError = false;
  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSeries = (profiles: SeriesProfile[]) => {
    this.series = profiles;

    this.seriesOptions = profiles
      .filter(profile => !profile.multiSeriesOfferId)
      .map(item => ({
        value: item.id,
        label: `${item.modelYear + ' '}${item.series}`,
      }));
  };

  processSeriesProfileOptions = () => {
    return this.seriesOptions.filter(s => !this.addedSeriesOptions.find(a => a.value === s.value));
  };

  setAddedSeriesOptions = (seriesProfiles: CostShareSeriesProfile[]) => {
    this.addedSeriesOptions = seriesProfiles.map(sp => {
      const foundSp = this.seriesOptions.find(s => s.value === sp.id);

      return {
        label: foundSp?.label ?? '',
        value: foundSp?.value ?? '',
      };
    });
  };

  // loop through seriesProfiles within costshareItem and set costShares per seriesProfile
  setCostShares = (costShareItem: CostShareItem) => {
    const { seriesProfiles, id, rev } = costShareItem;

    if (!seriesProfiles) {
      return;
    }

    this.id = id;
    this.rev = rev;

    const sortedSeriesProfiles = seriesProfiles.slice().sort((a, b) => new Date(a?.created ?? '').getTime() - new Date(b?.created ?? '').getTime());

    this.setAddedSeriesOptions(sortedSeriesProfiles as CostShareSeriesProfile[]);

    sortedSeriesProfiles.forEach(sp => {
      if (sp) {
        sp.costShares.forEach(costShare => {
          if (costShare) {
            const costSharesBySP = this.costSharesBySeriesProfile.get(sp.id);
            const costShareModel = new NationalCostShareModel({
              seriesProfileId: sp.id,
              ...costShare,
            });

            if (!costSharesBySP) {
              this.costSharesBySeriesProfile.set(sp.id, {
                costShares: [costShareModel],
                created: sp.created,
                fullMonthAprCostShareId: defaultStringToUndefined(sp.fullMonthAprCostShareId),
                fullMonthLeaseCostShareId: defaultStringToUndefined(sp.fullMonthLeaseCostShareId),
              });
            } else {
              const foundCostShare = costSharesBySP.costShares.find(c => c.id === costShareModel.id);

              if (!foundCostShare) {
                costSharesBySP.costShares = sortCostSharesByType([...costSharesBySP.costShares, costShareModel]);
              } else {
                costSharesBySP.costShares[costSharesBySP.costShares.indexOf(foundCostShare)] = costShareModel;
              }

              this.costSharesBySeriesProfile.set(sp.id, costSharesBySP);
            }
          }
        });
      }
    });
  };

  updateCostShares = (updatedCostShareItem: CostShareItem) => {
    const { id, seriesProfiles } = updatedCostShareItem;

    if (!seriesProfiles) {
      return;
    }

    this.costSharesBySeriesProfile.clear();

    this.id = id;

    seriesProfiles.forEach(sp => {
      if (sp) {
        const costShares = sp.costShares.map(costShare => {
          if (costShare) {
            const costShareModel = new NationalCostShareModel({
              seriesProfileId: sp.id,
              ...costShare,
            });

            return costShareModel;
          }

          return new NationalCostShareModel();
        });

        this.costSharesBySeriesProfile.set(sp.id, {
          costShares,
          created: sp.created,
          fullMonthAprCostShareId: defaultStringToUndefined(sp.fullMonthAprCostShareId),
          fullMonthLeaseCostShareId: defaultStringToUndefined(sp.fullMonthLeaseCostShareId),
        });
      }
    });
  };

  // Adding new/blank costShare to SeriesProfile
  addNewCostShare = (seriesProfileId: string) => {
    const sp = this.costSharesBySeriesProfile.get(seriesProfileId);
    const costShares = sp?.costShares || [];
    costShares.push(new NationalCostShareModel());

    this.costSharesBySeriesProfile.set(seriesProfileId, { costShares, created: sp?.created ?? '' });
  };

  deleteCostShare = (seriesProfileId: string, costShareId: string) => {
    const sp = this.costSharesBySeriesProfile.get(seriesProfileId);
    const costSharesBySP = sp?.costShares ?? [];
    const filteredCostSharesBySP = costSharesBySP.filter(costShare => costShare.id !== costShareId);

    if (filteredCostSharesBySP.length > 0) {
      this.costSharesBySeriesProfile.set(seriesProfileId, { costShares: filteredCostSharesBySP, created: sp?.created ?? '' });
    } else {
      this.deleteSeriesProfile(seriesProfileId);
    }
  };

  deleteSeriesProfile = (seriesProfileId: string) => {
    this.costSharesBySeriesProfile.delete(seriesProfileId);
    this.addedSeriesOptions = this.addedSeriesOptions.filter(option => option.value !== seriesProfileId);
  };

  getCostSharesByType = (seriesProfileId: string, offerType: string): ICostShareSeriesProfile | undefined => {
    const csSeriesProfile = this.costSharesBySeriesProfile.get(seriesProfileId);
    return {
      ...csSeriesProfile,
      costShares: csSeriesProfile?.costShares?.filter(cs => cs.fields.optionTypeName === offerType) || [],
      created: csSeriesProfile?.created ?? '',
    };
  };

  getCostShareById = (seriesProfileId: string, id: Maybe<string>): NationalCostShareModel | undefined => {
    return this.costSharesBySeriesProfile.get(seriesProfileId)?.costShares.find(cs => cs.id === id);
  };

  // Adding new seriesProfile with one blank cost share
  addNewSeries = (seriesDropdownItem: DropdownItem) => {
    const { label, value } = seriesDropdownItem;
    this.addedSeriesOptions = [...this.addedSeriesOptions, { label, value }];

    const newModelParam = this.createNewSeries(seriesDropdownItem);
    this.costSharesBySeriesProfile.set(value, { costShares: [new NationalCostShareModel(newModelParam)], created: new Date().toISOString() });
  };

  createNewSeries = (seriesDropdownItem: DropdownItem) => {
    const nationalCostShareFields = {
      seriesProfileId: seriesDropdownItem.value,
      id: '',
    };
    const costShareFields = {
      optionTypeName: '',
      value: '',
      startDate: '',
      endDate: '',
    };

    return { ...nationalCostShareFields, ...costShareFields };
  };

  // checking to see if any fields for each cost share is falsy
  hasInvalidSeriesProfile = () => {
    let allCostShares: NationalCostShareModel[] = [];

    for (const seriesOption of this.addedSeriesOptions) {
      const costShares = this.costSharesBySeriesProfile.get(seriesOption.value)?.costShares;

      if (costShares?.length) {
        allCostShares = [...allCostShares, ...costShares];
      }
    }

    return allCostShares.some(c => c.hasFieldsError);
  };

  resetCostShareDatesErrors = () => {
    if (this.hasInvalidDatesError || this.hasOverlapDatesError) {
      for (const addedSeriesOption of this.addedSeriesOptions) {
        const costSharesBySeries = this.costSharesBySeriesProfile.get(addedSeriesOption.value);

        if (costSharesBySeries?.costShares.length) {
          const costShares = costSharesBySeries?.costShares;

          const filteredCostShares = costShares.filter(c => !c.hasDatesError && !c.hasOverlappingDates);
          costSharesBySeries.costShares = filteredCostShares;
        }
      }

      this.updateHasInvalidDatesError(false);
      this.updateHasOverLapDatesError(false);
    }
  };

  resetStdRatesErrors = () => {
    for (const addedSeriesOption of this.addedSeriesOptions) {
      const costSharesBySeries = this.costSharesBySeriesProfile.get(addedSeriesOption.value);

      if (costSharesBySeries?.costShares.length) {
        const costShares = costSharesBySeries?.costShares;

        for (const costShare of costShares) {
          if (costShare.hasInvalidStdRate) {
            costShare.updateHasInvalidStdRate(false);
          }
        }
      }
    }
  };

  setHasUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };

  updateHasOverLapDatesError = (hasError: boolean) => {
    this.hasOverlapDatesError = hasError;
  };

  updateHasInvalidDatesError = (hasError: boolean) => {
    this.hasInvalidDatesError = hasError;
  };

  updateHasStdRatesError = (hasError: boolean) => {
    this.hasStdRatesError = hasError;
  };

  updateRev = (newRev: string) => {
    this.rev = newRev;
  };
}

export default NationalCostShareStore;
