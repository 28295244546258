import { observer } from 'mobx-react-lite';
import { OATWrapper } from 'oat-common-ui';
import { useCallback, useEffect, useState } from 'react';
import MainHeader from '../../../components/MainHeader';
import { FYReports, NPReports } from '../../../constants/global';
import useStores from '../../../stores/useStores';
import FiscalYear from '../components/FiscalYear';
import GeneratedReports from '../components/GeneratedReports';
import GeneratingReportErrorModal from '../components/Modal/GeneratingReportErrorModal';
import RegenerateReportModal from '../components/Modal/RegenerateReportModal';
import NationalPlanningReports from '../components/NationalPlanningReports';
import ReportsHeadline from '../components/ReportsHeadline';
import useGenerateReport from '../useGenerateReport';
import styles from './styles.module.scss';

const NationalPlanningComponent = () => {
  const {
    reportsStore: { fiscalYear, generatingReport, setSelectedReport, selectedReport, availableReports, defaultReport },
  } = useStores();

  const { generateReport } = useGenerateReport(selectedReport);

  const reportsBreadCrumb = [{ name: 'Dashboard', component: <a href="/">Dashboard</a> }, { name: 'Reports' }];
  const [isUpToDate, setIsUpToDate] = useState<boolean>(false);

  const isReportOverride = useCallback(() => {
    return availableReports.findIndex(report => report.reportName === selectedReport.label) !== -1;
  }, [availableReports, selectedReport.label]);

  useEffect(() => {
    // If report is already generated, we are calling generateReport() from <RegenerateReportModal>
    if (!isReportOverride() && selectedReport.value) {
      generateReport();
    }
  }, [generateReport, isReportOverride, selectedReport]);

  return (
    <>
      <MainHeader breadCrumbs={reportsBreadCrumb} />
      <OATWrapper className={styles.reportsSection}>
        <div className={styles.reportsLeftSection}>
          <ReportsHeadline title="Fiscal Year" />
          <FiscalYear fiscalYear={fiscalYear} reports={FYReports} setIsUpToDate={setIsUpToDate} />
          <ReportsHeadline title="National Planning" />
          <NationalPlanningReports reports={NPReports} />
        </div>
        <div className={styles.reportsRightSection}>
          <div className={styles.reportsRightSection}>
            {generatingReport && <p>Your reports are being generated.</p>}
            <GeneratedReports />
          </div>
        </div>
      </OATWrapper>
      <GeneratingReportErrorModal isOpen={isUpToDate} onClose={() => setIsUpToDate(false)} fiscalYear={fiscalYear} />
      <RegenerateReportModal isOpen={isReportOverride()} onClose={() => setSelectedReport(defaultReport)} report={selectedReport} />
    </>
  );
};

export default observer(NationalPlanningComponent);
