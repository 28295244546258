import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import AccordionItemModel from './models/AccordionItemModel';
import styles from './styles.module.scss';

interface Props extends HTMLAttributes<HTMLElement> {
  onToggle: (value: boolean) => void;
  item: AccordionItemModel;
  expand: boolean;
  level?: number;
  hasChildren?: boolean;
}

const AccordionHeader = ({ onToggle, expand, item, level = 1, children, hasChildren = false }: Props) => {
  const cssClass = clsx(styles.accordionHeader, styles[`level${level}`], item.disabled && styles.incompatible);

  return (
    <header className={cssClass}>
      <div className={styles.childrenSection}>{children}</div>
      <div className={styles.caretWrap}>
        {hasChildren && !expand && <FontAwesomeIcon className={styles.caret} icon={faCaretDown} onClick={() => onToggle(!expand)} />}
        {hasChildren && expand && <FontAwesomeIcon className={styles.caret} icon={faCaretUp} onClick={() => onToggle(!expand)} />}
      </div>
    </header>
  );
};

export default AccordionHeader;
