import { observer } from 'mobx-react-lite';
import { NumberInput, useToast } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useSavePnvsThresholdMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';

import BlockInputLabel from '../../../components/BlockInputLabel';
import Button from '../../../components/Button';
import NGCHeader from '../../../components/NGCHeader';

import styles from './styles.module.scss';

const PNVSThresholdSettingsComponent = () => {
  const {
    pnvsTresholdStore: { pnvs, updatePNVS, setPNVSTreshold },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const { error } = useToast();

  const [savePNVSTreshold] = useSavePnvsThresholdMutation();

  const saveHandler = async () => {
    try {
      const { data } = await trackPromise(
        savePNVSTreshold({
          variables: {
            input: {
              id: pnvs.id,
              rev: pnvs.rev,
              brand,
              pnvsThreshold: pnvs.pnvsThreshold,
            },
          },
        }),
      );

      if (data?.savePNVSThreshold.success) {
        setPNVSTreshold(data.savePNVSThreshold.pnvsThreshold);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    updatePNVS(Number(e.target.value));
  };

  return (
    <>
      <NGCHeader title="PNVS Threshold" />
      <section className={styles.container}>
        <BlockInputLabel className={styles.blockInputLabel} label={`Series Threshold %:`}>
          <NumberInput
            percentageSign
            id="pnvs-threshold-input"
            isNational
            className={styles.thresholdInput}
            value={pnvs.pnvsThreshold}
            onChange={onChangeHandler}
            error={!pnvs.pnvsThreshold}
          />
        </BlockInputLabel>

        <Button variant="blue" id="save-threshold-btn" className={styles.thresholdBtn} onClick={saveHandler}>
          Save
        </Button>
      </section>
    </>
  );
};

export default observer(PNVSThresholdSettingsComponent);
