import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import useStores from '../../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../../utils/appendTfsLabel';
import { AprFields } from '../../../../models/offers/apr/AprFields';
import AprModel from '../../../../models/offers/apr/AprModel';
import styles from './styles.module.scss';

interface Props {
  apr: AprModel;
  onSave: () => void;
}

const TFSData = ({ apr, onSave }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const { uid, updateAprField, aprFields, subCashError, costShareError, costShareCapError } = apr;
  const subCashLabel = appendTfsLabel(isLexus(), 'Sub Cash');
  const subCashCostShareLabel = appendTfsLabel(isLexus(), 'Cost Share', 'Sub Cash');
  const costShareCapLabel = appendTfsLabel(isLexus(), 'Cap');

  const handleValueChange = <T extends keyof AprFields, V extends AprFields[T]>(field: T, value: V) => {
    updateAprField(field, value);
    onSave();
  };

  return (
    <div className={styles.tfsData}>
      <BlockInputLabel label={subCashLabel} className={clsx(styles.inputWrapper, styles.marginBottom)}>
        <NumberInput
          id={`${uid}-sub-cash-input`}
          isNational
          value={aprFields.subCashAmount}
          dollarSign
          thousandSeparator
          units
          wholeNumber
          min={0}
          onValueChange={val => handleValueChange('subCashAmount', val.value)}
          className={styles.dollarInput}
          error={subCashError}
        />
      </BlockInputLabel>
      <BlockInputLabel label={subCashCostShareLabel} className={clsx(styles.inputWrapper, styles.marginBottom)}>
        <NumberInput
          id={`${uid}-cost-share-input`}
          isNational
          value={aprFields.subCashCostShare}
          percentageSign
          onValueChange={val => handleValueChange('subCashCostShare', val.value)}
          min={0}
          max={100}
          wholeNumber
          className={clsx([styles.rateInput, Number(aprFields.subCashCostShare) > 0 && styles.enhancedInput])}
          error={costShareError}
        />
      </BlockInputLabel>
      <BlockInputLabel label={costShareCapLabel} className={styles.inputWrapper}>
        <NumberInput
          id={`${uid}-cost-share-cap-input`}
          isNational
          value={aprFields.subCashCostShareCap}
          dollarSign
          min={0}
          wholeNumber
          thousandSeparator
          units
          onValueChange={val => handleValueChange('subCashCostShareCap', val.value)}
          className={clsx([styles.dollarInput, Number(aprFields.subCashCostShareCap) > 0 && styles.enhancedInput])}
          error={costShareCapError}
        />
      </BlockInputLabel>
    </div>
  );
};

export default observer(TFSData);
