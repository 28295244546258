import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import RCFNavItem from './RCFNavItem';
import styles from './styles.module.scss';

const RCFNav = () => {
  const {
    ratesTabTFSLeaseRCFStore: {
      tfsLeaseRatesSectionOneMaster,
      tfsLeaseRatesSectionOneFull,
      tfsLeaseRatesSectionTwoMaster,
      tfsLeaseRatesSectionTwoFull,
      tfsLeaseRatesSectionThreeMaster,
      tfsLeaseRatesSectionThreeFull,
      setTfsLeaseRatesSectionOne,
      setTfsLeaseRatesSectionTwo,
      setTfsLeaseRatesSectionThree,
      setShowHiddenTfsOne,
      showHiddenTfsOne,
      setShowHiddenTfsTwo,
      showHiddenTfsTwo,
      setShowHiddenTfsThree,
      showHiddenTfsThree,
    },
  } = useStores();

  const onClickHandler = (term: number) => {
    if (term === 24) {
      setShowHiddenTfsOne(!showHiddenTfsOne);
      return;
    }

    if (term === 36) {
      setShowHiddenTfsTwo(!showHiddenTfsTwo);
      return;
    }

    setShowHiddenTfsThree(!showHiddenTfsThree);
  };

  const updateTfsLeaseRatesToShow = (term: number) => {
    if (term === 24) {
      showHiddenTfsOne ? setTfsLeaseRatesSectionOne(tfsLeaseRatesSectionOneMaster) : setTfsLeaseRatesSectionOne(tfsLeaseRatesSectionOneFull);
      return;
    }

    if (term === 36) {
      showHiddenTfsTwo ? setTfsLeaseRatesSectionTwo(tfsLeaseRatesSectionTwoMaster) : setTfsLeaseRatesSectionTwo(tfsLeaseRatesSectionTwoFull);
      return;
    }

    showHiddenTfsThree ? setTfsLeaseRatesSectionThree(tfsLeaseRatesSectionThreeMaster) : setTfsLeaseRatesSectionThree(tfsLeaseRatesSectionThreeFull);
  };

  const handleChange = (term: number) => {
    onClickHandler(term);
    updateTfsLeaseRatesToShow(term);
  };

  return (
    <div className={styles.rcfNav}>
      <RCFNavItem term={24} showHidden={showHiddenTfsOne} handleChange={handleChange} />
      <RCFNavItem term={36} showHidden={showHiddenTfsTwo} handleChange={handleChange} />
      <RCFNavItem term={48} showHidden={showHiddenTfsThree} handleChange={handleChange} />
      <div className={styles.rcfNavFixed}>
        <span className={styles.labelText}>{60}</span>
      </div>
    </div>
  );
};

export default observer(RCFNav);
