import { observer } from 'mobx-react-lite';
import { LeaseLocks } from 'oat-admin-common';
import { NumberInput, NumberInputProps, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import Button from '../../../../../../components/Button';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface Props extends NumberInputProps {
  value: number | string;
  label: string;
  lease?: LeaseFormModel;
  lock?: LeaseLocks;
  subLabel?: ReactNode;
}

const LeaseInput = ({ id, className, value, label, subLabel, lease, lock, disabled, onValueChange, ...props }: Props) => {
  const locked = lease && lock && lock === lease.lock;

  const handleLock = () => {
    if (lease && lock) {
      lease.setLock(lock);
    }
  };

  return (
    <BlockInputLabel label={label} smallLabel>
      <div className={styles.leaseInput}>
        {lock && (
          <Button id={`lock-${id}`} variant={locked ? 'blue' : 'unlocked'} className={styles.lock} onClick={handleLock} disabled={disabled}>
            <OATIcon icon="target" />
          </Button>
        )}
        <NumberInput
          {...props}
          id={id}
          value={value}
          isNational
          className={clsx([lock && styles.withLock, className])}
          onValueChange={(val, src) => {
            if (onValueChange && src.source === 'event') {
              onValueChange(val, src);
            }
          }}
          disabled={locked || disabled}
        />
      </div>
      {subLabel}
    </BlockInputLabel>
  );
};

export default observer(LeaseInput);
