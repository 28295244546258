import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import AprModel from '../../../../models/offers/apr/AprModel';
import getSortedTiers from '../../../../utils/getSortedTiers';
import styles from './styles.module.scss';

interface Props {
  apr: AprModel;
  onSave: () => void;
}

const TierPenPercentage = ({ apr, onSave }: Props) => {
  const {
    updateTierPenRate,
    aprFields: { tierPenRates },
    tierPenRatesHasError,
    recalculateAll,
    uid,
  } = apr;

  const handleOnChange = (tier: string, value: number) => {
    updateTierPenRate(tier, value);
    recalculateAll();
    onSave();
  };

  const tierPenRatesToShow = getSortedTiers(tierPenRates);

  return (
    <div className={styles.tierPenPercentage}>
      {tierPenRatesToShow.map((tierPenRate, i) => {
        const { tier, penetrationRate } = tierPenRate;

        return (
          <BlockInputLabel label={`Tier ${tier} Pen %`} className={clsx(styles.inputWrapper, i < 3 ? styles.marginRight : '')} key={tier}>
            <NumberInput
              id={`Tier ${tier} Pen %-${uid}`}
              isNational
              percentageSign
              min={0}
              max={100}
              wholeNumber
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(tier, Number(e.target.value))}
              value={penetrationRate}
              error={tierPenRatesHasError}
              className={styles.rateInput}
            />
          </BlockInputLabel>
        );
      })}
    </div>
  );
};

export default observer(TierPenPercentage);
