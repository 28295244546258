import { observer } from 'mobx-react-lite';
import { assignNumberValue, formatDollars } from 'oat-common-ui';
import CashModel from '../../../models/offers/CashModel';
import useSaveCash from '../../../offers/CashSection/useSaveCash';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import { useSummaryCosts } from '../useSummaryCosts';
import OfferName from './OfferName';
import RowProps from './RowProps';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

const CashRow = ({ seriesProfile, rgnlAlt, offer }: RowProps<CashModel>) => {
  const { uid, updateOfferField, updateAmount } = offer;
  const { saveCashOffer } = useSaveCash(seriesProfile, rgnlAlt, offer);
  const { tmsCost } = useSummaryCosts(rgnlAlt, offer);

  const handleAmount = (val: string) => {
    updateAmount(val);
    saveCashOffer();
  };

  const handlePenRate = (val: string) => {
    seriesProfile.clearErrors();
    updateOfferField('penetrationRate', val);
    saveCashOffer();
  };

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{offer.offerFields.name}</OfferName>
      </td>
      <td>
        <RgnlAltInput
          id={`cash-summary-amount-${uid}`}
          isNational
          wholeNumber
          dollarSign
          value={offer.offerCostFields.amount}
          onChange={e => handleAmount(e.currentTarget.value)}
          error={offer.hasAmountError()}
          units
        />
      </td>
      <td>${formatDollars(assignNumberValue(offer.offerCostFields.offerPnvs))}</td>
      <td>
        <RgnlAltInput
          id={`cash-summary-penrate-${uid}`}
          isNational
          wholeNumber
          percentageSign
          min={0}
          max={100}
          value={offer.offerFields.penetrationRate}
          onValueChange={val => handlePenRate(val.value)}
          error={offer.hasPenetrationError()}
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost} </td>
          <td>$0</td>
        </>
      )}
    </tr>
  );
};

export default observer(CashRow);
