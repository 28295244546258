import { AdminConstants } from 'oat-admin-common';
import { DropdownItem } from 'oat-common-ui';

type DropdownNameType = typeof AdminConstants.GIFT_DELIVERY;

export const getDropdownItemFromEnum = (name: DropdownNameType) => {
  const newItems: DropdownItem[] = [];
  Object.values(name).forEach(value => newItems.push({ label: value.toString(), value: value.toString() }));

  return newItems;
};
