import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { assignNumberValue, Input, NumberInput, OATIcon, Textarea } from 'oat-common-ui';
import BlockInputLabel from '../../../../components/BlockInputLabel';
import useStores from '../../../../stores/useStores';
import { appendTmsLabel } from '../../../../utils/appendTfsLabel';
import NumberStack from '../../components/NumberStack';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  rgnlAlt: RgnlAltModel;
  onSave: () => void;
  seriesProfile: SeriesProfileModel;
}

const RgnlAltBudgetFields = ({ rgnlAlt, onSave, seriesProfile }: Props) => {
  const {
    offeringCostsStore: { rgnlAltCosts },
    userInfoStore: { isLexus },
  } = useStores();

  const costs = rgnlAltCosts[rgnlAlt.data.id];
  const { data, updateDataField } = rgnlAlt;
  const raNameLabel = data.number ? `RA-${data.number} Name` : 'RA- Name';

  return (
    <fieldset className={styles.raInfoSection} disabled={seriesProfile.data.isBudgetConfirmed}>
      <div className={styles.primaryFieldSection}>
        {/* RA Number */}
        <BlockInputLabel label="RA #" className={styles.numberInput}>
          <NumberInput
            id={`${rgnlAlt.uid}-ra-number`}
            value={data.number}
            isNational
            wholeNumber
            onValueChange={val => {
              seriesProfile.clearErrors();
              updateDataField('number', val.value);
              onSave();
            }}
            className={styles.raInput}
            error={rgnlAlt.numberError}
          />
        </BlockInputLabel>

        {/* RA Name */}
        <BlockInputLabel label={raNameLabel} className={styles.textInput}>
          <Input
            id={`${rgnlAlt.uid}-ra-name`}
            isNational
            value={data.name ?? ''}
            onChange={val => {
              seriesProfile.clearErrors();
              updateDataField('name', val.currentTarget.value);
              onSave();
            }}
            className={styles.raInput}
            error={rgnlAlt.nameError}
          />
        </BlockInputLabel>

        {/* RA Costs */}
        <div className={styles.costs}>
          <NumberStack classNameContainer={styles.costsBlock} label={'PNVS'} value={assignNumberValue(costs?.pnvs)} smallFormat textAlign="left" />
          <NumberStack classNameContainer={styles.costsBlock} label={'Sales'} dollarSign={false} value={assignNumberValue(costs?.sales)} smallFormat textAlign="left" />
          <NumberStack
            classNameContainer={styles.costsBlock}
            label={appendTmsLabel(isLexus(), 'Cost')}
            value={assignNumberValue(costs?.cost)}
            useMillions
            roundMillions
            millionsThreshold={100_000}
            smallFormat
            textAlign="left"
          />
        </div>

        {/* Notes */}
        <BlockInputLabel
          labelComponent={
            <>
              <OATIcon icon="plus" size="12" /> Notes
            </>
          }
          labelClass={styles.notesLabel}
          className={clsx(styles.textInput)}
        >
          <Textarea
            id={`${rgnlAlt.uid}-notes`}
            isNational
            value={data.note ?? ''}
            onChange={val => {
              seriesProfile.clearErrors();
              updateDataField('note', val.currentTarget.value);
              onSave();
            }}
            className={styles.raInput}
          />
        </BlockInputLabel>
      </div>

      {/* Pen Rate */}
      <div className={styles.bottomRow}>
        <div className={styles.raPenRate}>
          <BlockInputLabel label="Pen Rate">
            <NumberInput
              id={`${rgnlAlt.uid}-pen-rate`}
              isNational
              wholeNumber
              percentageSign
              value={data.penetrationRate}
              onValueChange={val => {
                seriesProfile.clearErrors();
                updateDataField('penetrationRate', val.value);
                onSave();
              }}
              error={rgnlAlt.penRateError}
              min={0}
              max={100}
            />
          </BlockInputLabel>
        </div>

        {/* Validation msgs */}
        {rgnlAlt.budgetErrorMsg && (
          <div className={styles.error}>
            <OATIcon icon="warning" />
            {rgnlAlt.budgetErrorMsg}
          </div>
        )}
      </div>
    </fieldset>
  );
};

export default observer(RgnlAltBudgetFields);
