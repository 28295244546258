import { makeAutoObservable, runInAction } from 'mobx';
import { sortFieldsHelper } from 'oat-common-ui';
import { GetOfferingsResponse, Offering } from '../gql/generated';
import { isDateActive } from '../utils/isDateActive';

class DashboardStore {
  isLoading = true;
  active: Offering[] = [];
  inactive: Offering[] = [];
  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  processQuery(data: GetOfferingsResponse) {
    runInAction(() => {
      if (data) {
        const sortField = 'startDate';
        const sortAscending = false;

        this.active = (data.active ?? []).slice().sort(sortFieldsHelper(sortField, sortAscending));
        this.inactive = (data.inactive ?? []).slice().sort(sortFieldsHelper(sortField, sortAscending));
      }
    });
  }

  addActiveOffering = (offering: Offering) => {
    this.active = [...this.active, offering];
  };

  addCopiedOffering = (offering: Offering, isAtc = false) => {
    const sortField = 'startDate';
    const sortAscending = false;

    if (isDateActive(offering.startDate) || isAtc) {
      this.active = [...this.active, offering].slice().sort(sortFieldsHelper(sortField, sortAscending));
    } else {
      this.inactive = [...this.inactive, offering].slice().sort(sortFieldsHelper(sortField, sortAscending));
    }
  };

  deleteOfferingById = (offeringId: string) => {
    const deleteItem = (offerings: Offering[]) => {
      if (offerings.find(item => item.id === offeringId)) {
        return offerings.filter(item => item.id !== offeringId);
      }

      return undefined;
    };

    const newActiveOfferings = deleteItem(this.active);
    if (newActiveOfferings) {
      this.active = newActiveOfferings;
    }

    const newInactiveOfferings = deleteItem(this.inactive);
    if (newInactiveOfferings) {
      this.inactive = newInactiveOfferings;
    }
  };

  updateByOffering = (offering: Offering) => {
    this.active = this.active.map(item => (item.id === offering.id ? offering : item));
    this.inactive = this.inactive.map(item => (item.id === offering.id ? offering : item));
  };

  sortActive = (field: string, sortAscending: boolean) => {
    this.active = this.active.slice().sort(sortFieldsHelper(field, sortAscending));
  };

  sortArchived = (field: string, sortAscending: boolean) => {
    this.inactive = this.inactive.slice().sort(sortFieldsHelper(field, sortAscending));
  };

  setUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };

  getOfferingDisplayName = (offering: Offering) => {
    return `${offering.name} ${offering.isAtc ? '- ATC' : ''}`;
  };
}

export default DashboardStore;
