import { defaultTiersTerms } from '../../../../../../../constants/global';
import styles from '../styles.module.scss';

const TiersRow = () => {
  const { tiers } = defaultTiersTerms;

  return (
    <tr>
      <td className={styles.tierTD} />
      {tiers.map(tier => {
        return (
          <td key={tier} className={styles.tierTD}>
            Tier {tier}
          </td>
        );
      })}
    </tr>
  );
};

export default TiersRow;
