import { OATLoadingBar } from 'oat-common-ui';
import { useGetStateAprCapLogQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import StateAprCapsComponent from './StateAprCapsComponent';

const StateAprCapsSettings = () => {
  const {
    stateAprCapsStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { data, loading, error } = useGetStateAprCapLogQuery({
    variables: {
      brand,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data && data.stateAprCapLog) {
    stateAprCapsStore.setLastUploadedFile(data.stateAprCapLog);
  }

  return <StateAprCapsComponent />;
};

export default StateAprCapsSettings;
