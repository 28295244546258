import { observer } from 'mobx-react-lite';
import { NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import { MULTIPLE_RCF_APPLIED } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import styles from '../../components/styles.module.scss';

const ChangeInRcfInput = () => {
  const {
    ratesTabLeaseRCFStore: { leaseDifferential, updateLeaseDifferential, updateLeaseRates, updateLeaseStandardRcf, validateLeaseRates },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    updateLeaseDifferential(newValue);
    updateLeaseRates(newValue);
    updateLeaseStandardRcf(newValue);
  };

  const multipleRcfs = !validateLeaseRates() ? MULTIPLE_RCF_APPLIED : '';

  return (
    <BlockInputLabel label="Change in RCF">
      <NumberInput
        isNational
        placeholder={multipleRcfs}
        id="change-in-rcf-input"
        className={styles.changeInInput}
        value={!multipleRcfs ? leaseDifferential : ''}
        onChange={onChangeHandler}
        rcf
        allowNegative
        max={9.99999}
        min={-9.99999}
        disabled={isPublished}
      />
    </BlockInputLabel>
  );
};

export default observer(ChangeInRcfInput);
