import { observer } from 'mobx-react-lite';
import AprModel from '../../../../models/offers/apr/AprModel';
import TermTableRow from './TermTableRow';

interface Props {
  apr: AprModel;
  onSave: () => void;
}

const TermTable = ({ apr, onSave }: Props) => {
  return (
    <div data-testid="term-table">
      {apr.tierTerms.map((tierTerm, i) => {
        return <TermTableRow key={tierTerm.term + i} tierTerm={tierTerm} apr={apr} onSave={onSave} />;
      })}
    </div>
  );
};

export default observer(TermTable);
