const returnOffset = (id: string, offset: number) => {
  return Math.max(document.getElementById(id)?.offsetTop || 0) - offset;
};

const getScrollTopFromClick = (id: string, offset = 250) => {
  return returnOffset(id, offset);
};

export const handleScrollOnClick = (id: string, offset?: number) => {
  window.scrollTo({
    top: getScrollTopFromClick(id, offset),
    behavior: 'smooth',
  });
};
