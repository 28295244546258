import clsx from 'clsx';
import { Button, NumberInput, OATIcon, SlideToggle } from 'oat-common-ui';
import { FormatValue } from './formatValue';
import styles from './styles.module.scss';

interface Props {
  classNameContainer?: string;
  classNameLabel?: string;
  classNameValue?: string;
  classNameNumberInput?: string;
  dollarSign?: boolean;
  addSign?: boolean;
  id?: string;
  label: string;
  onToggle?: () => void;
  opositeColors?: boolean;
  rightBorder?: boolean;
  roundMillions?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  toggleSelected?: boolean;
  useColors?: boolean;
  useMillions?: boolean;
  millionsThreshold?: number; // used to convert to convert number to million when reaching threshold
  value: number | string;
  showBottomValue?: boolean;
  bottomValue?: number | string;
  wholeNumber?: boolean;
  sup?: boolean;
  width?: number;
  labelOnTop?: boolean;
  addEditBtn?: boolean;
  editHandler?: () => void;
  edit?: boolean;
  onValueChangeHandler?: (val: string) => void;
  addOne?: () => void;
  subtractOne?: () => void;

  // themes
  active?: boolean;
  aprColor?: boolean;
  aprDefaultColor?: boolean;
  aprPnvsTheme?: boolean;
  leasePnvsTheme?: boolean;
  smallFormat?: boolean;
  miniFormat?: boolean;
}

const NumberStack = ({
  classNameContainer,
  classNameLabel,
  classNameValue,
  classNameNumberInput,
  dollarSign = true,
  addSign = false,
  label,
  onToggle,
  opositeColors = false,
  rightBorder,
  roundMillions = false,
  textAlign = 'center',
  toggleSelected = false,
  useColors,
  useMillions,
  millionsThreshold,
  value,
  bottomValue = '',
  showBottomValue = false,
  wholeNumber = false,
  labelOnTop = false,
  width,
  addEditBtn = false,
  edit = false,
  sup = false,
  editHandler,
  onValueChangeHandler,
  addOne,
  subtractOne,

  active = false,
  aprPnvsTheme = false,
  leasePnvsTheme = false,
  smallFormat = false,
  miniFormat = false,
}: Props) => {
  const getTheme = () => {
    if (miniFormat) {
      return styles.miniTheme;
    }

    if (smallFormat) {
      return styles.smallTheme;
    }

    if (aprPnvsTheme) {
      return styles.aprPnvsTheme;
    }

    if (leasePnvsTheme) {
      return styles.leasePnvsTheme;
    }

    return styles.defaultTheme;
  };

  const style = {
    width: width ? `${width}px` : undefined,
  };

  const getColorStyle = () => {
    const absValue = value.toString().replace('-', '');
    const isNegative = value.toString().includes('-');

    if (!useColors || absValue === '0') {
      return styles.defaultColor;
    }

    if (opositeColors) {
      return isNegative ? styles.positive : styles.negative;
    } else {
      return isNegative ? styles.negative : styles.positive;
    }
  };

  const formattedValue = FormatValue(value, wholeNumber, dollarSign, roundMillions, useMillions, millionsThreshold);

  return (
    <div style={style} className={clsx(styles.stackContainer, rightBorder && styles.rightBorder, classNameContainer, getTheme(), active && styles.active)}>
      {labelOnTop && <div className={clsx(styles.label, classNameLabel)}>{label}</div>}

      <div className={clsx(styles.value, styles[textAlign])}>
        <div className={styles.content}>
          {onToggle && (
            <div className={styles.contentToggle}>
              <SlideToggle containerClass={styles.slideToggle} selected={toggleSelected} toggleSelected={onToggle} />
            </div>
          )}
        </div>

        <div className={clsx(styles.dollarSign, getColorStyle())}>
          {dollarSign && !edit && <span className={sup ? styles.sup : ''}>$</span>}
          {!edit ? (
            <span className={clsx(styles.value, classNameValue)}>{formattedValue}</span>
          ) : (
            <NumberInput
              id={`${label}-number-input`}
              min={0}
              isNational
              wholeNumber
              units
              className={clsx(styles.numberInput, classNameNumberInput)}
              showArrowBtns
              value={formattedValue}
              onValueChange={val => {
                if (onValueChangeHandler) {
                  onValueChangeHandler(val.value);
                }
              }}
              addOne={addOne}
              subtractOne={subtractOne}
            />
          )}
          {addSign && <span className={styles.addSign}>+</span>}
        </div>
        {addEditBtn && (
          <Button
            variant="text"
            id="edit-btn"
            className={clsx(styles.editBtn, !edit ? styles.defaultColor : styles.blackColor)}
            onClick={() => {
              if (editHandler) {
                editHandler();
              }
            }}
          >
            <OATIcon icon={!edit ? 'edit' : 'check-mark'} size={11} className={styles.editIcon} />
          </Button>
        )}
      </div>

      {showBottomValue && (
        <div className={clsx(styles.value, styles[textAlign], styles.bottomContainer)}>
          <div className={styles.dollarSign}>
            {dollarSign && <span className={sup ? styles.sup : ''}>$</span>}
            <span className={clsx(styles.value, styles.bottomText)}>{FormatValue(bottomValue, wholeNumber, dollarSign, roundMillions, useMillions)}</span>
          </div>
        </div>
      )}

      {/* Label section */}
      {!labelOnTop && <div className={clsx(styles.label, classNameLabel)}>{label}</div>}
    </div>
  );
};

export default NumberStack;
