import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { dateStringToDate, uuidv4, validator } from 'oat-common-ui';

const { OPTION_TYPE_NAMES } = AdminConstants;

export type CostShareFields = {
  optionTypeName: string;
  value: number | string;
  startDate: string;
  endDate: string;
};

export type NationalCostShare = {
  seriesProfileId: string;
  id: string;
} & CostShareFields;

class NationalCostShareModel {
  id: string = uuidv4();
  seriesProfileId = '';
  fields: CostShareFields = {
    optionTypeName: '',
    value: '',
    startDate: '',
    endDate: '',
  };
  hasInvalidStdRate = false;
  hasOverlappingDates = false;
  optionTypeNameIsDisabled = false;

  constructor(costShare?: NationalCostShare) {
    makeAutoObservable(this);

    if (costShare) {
      if (!!costShare.id) {
        this.id = costShare.id;
        this.optionTypeNameIsDisabled = true;
      }

      this.seriesProfileId = costShare.seriesProfileId;
      this.fields.optionTypeName = costShare.optionTypeName;
      this.fields.value = costShare.value;
      this.fields.startDate = costShare.startDate;
      this.fields.endDate = costShare.endDate;
    }
  }

  updateField = <T extends keyof CostShareFields, V extends CostShareFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateHasOverlappingDates = (val: boolean) => {
    this.hasOverlappingDates = val;
  };

  updateHasInvalidStdRate = (val: boolean) => {
    this.hasInvalidStdRate = val;
  };

  setOptionTypeNameIsDisabled = (val: boolean) => {
    this.optionTypeNameIsDisabled = val;
  };

  get isApr() {
    return this.fields.optionTypeName === OPTION_TYPE_NAMES.APR;
  }

  get isLease() {
    return this.fields.optionTypeName === OPTION_TYPE_NAMES.LEASE;
  }

  get hasValueError() {
    return validator(this.fields.value, { required: true, min: 0, max: this.isApr ? 99 : undefined }) !== undefined;
  }

  get hasFieldsError() {
    return !this.fields.optionTypeName || !this.fields.value || !this.fields.startDate || !this.fields.endDate;
  }

  get hasDatesError() {
    return dateStringToDate(this.fields.startDate).getTime() > dateStringToDate(this.fields.endDate).getTime();
  }
}

export default NationalCostShareModel;
