import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignBooleanValue, assignStringValue, dateStringToDate, uuidv4, validator } from 'oat-common-ui';
import { Maybe, Offer, OfferRegion, Vehicle } from '../../../../gql/generated';
import { OfferSetupFields } from '../offers/OfferFields';
import AprSetupModel from './AprSetupModel';
import CashSetupModel from './CashSetupModel';
import FinalPaySetupModel from './FinalPaySetupModel';
import LeaseSetupModel from './LeaseSetupModel';
import MiscSetupModel from './MiscSetupModel';
import NationalRYOSetupModel from './NationalRYOSetupModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

class OfferSetupModel {
  uid = '';
  id = '';
  rev = '';

  // Common fields for Offer setup header and footer
  fields: OfferSetupFields = {
    compatibilityList: [],
    endDate: '',
    isSpecialEdition: false,
    notes: '',
    offerName: '',
    optionType: '',
    specialEditionPackage: '',
    startDate: '',
    systemToPay: '',
    vehicles: [],
    regions: [],
  };

  finalPaySetupDetails?: FinalPaySetupModel | null = null;
  miscSetupDetails?: MiscSetupModel | null = null;
  cashSetupDetails?: CashSetupModel | null = null;
  aprSetupDetails?: AprSetupModel | null = null;
  leaseSetupDetails?: LeaseSetupModel | null = null;
  nationalRyoSetupDetails?: NationalRYOSetupModel | null = null;

  setupConfirmErrors: string[] = [];

  constructor(offer: Maybe<Offer>) {
    if (offer) {
      switch (offer.optionType) {
        case OPTION_TYPE_NAMES.CUSTOMER_CASH:
        case OPTION_TYPE_NAMES.ADDITIONAL_RYO:
          this.cashSetupDetails = new CashSetupModel(offer, this.uid);
          break;
        case OPTION_TYPE_NAMES.APR:
          this.aprSetupDetails = new AprSetupModel(offer, this.uid);
          break;
        case OPTION_TYPE_NAMES.LEASE:
          this.leaseSetupDetails = new LeaseSetupModel(offer, this.uid);
          break;
        case OPTION_TYPE_NAMES.NATIONAL_RYO:
          this.nationalRyoSetupDetails = new NationalRYOSetupModel(offer, this.uid);
          break;
        case OPTION_TYPE_NAMES.FINAL_PAY:
          this.finalPaySetupDetails = new FinalPaySetupModel(offer, this.uid);
          break;
        default:
          this.miscSetupDetails = new MiscSetupModel(offer, this.uid);
      }

      this.id = offer.id;
      this.rev = offer.rev;
      this.uid = uuidv4();

      this.setData(offer);
    }

    makeAutoObservable(this);
  }

  setData = (offer: Offer) => {
    this.fields.compatibilityList = offer?.compatibilityList as string[];
    this.fields.endDate = assignStringValue(offer?.endDate);
    this.fields.isSpecialEdition = assignBooleanValue(offer?.isSpecialEdition);
    this.fields.notes = assignStringValue(offer.offerNote);
    this.fields.offerName = assignStringValue(offer?.offerName);
    this.fields.optionType = assignStringValue(offer?.optionType);
    this.fields.specialEditionPackage = assignStringValue(offer?.specialEditionPackage);
    this.fields.startDate = assignStringValue(offer?.startDate);
    this.fields.systemToPay = assignStringValue(offer.systemToPay);
    this.fields.vehicles = offer?.vehicles as Vehicle[];
    this.fields.regions = offer?.regions as OfferRegion[];
  };

  setRevAndId = (offer?: Offer) => {
    if (offer) {
      this.id = offer.id;
      this.rev = offer.rev;
    }
  };

  updateField = <T extends keyof OfferSetupFields, V extends OfferSetupFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  setSetupConfirmErrors = (errors: string[]) => {
    this.setupConfirmErrors = errors;
  };

  hasOfferLevelError = () => {
    return this.datesOrderError || this.startDateError || this.endDateError || this.nameError || this.hasVehiclesError();
  };

  hasVehiclesError = () => {
    return (
      this.fields.optionType !== OPTION_TYPE_NAMES.BONUS &&
      this.fields.optionType !== OPTION_TYPE_NAMES.DOWN_PAYMENT_ASSISTANCE &&
      this.fields.optionType !== OPTION_TYPE_NAMES.COMPLIMENTARY_FIRST_PAYMENT &&
      this.fields.optionType !== OPTION_TYPE_NAMES.EVENT &&
      this.fields.optionType !== OPTION_TYPE_NAMES.VENDOR_PURCHASE_PROGRAM &&
      this.fields.optionType !== OPTION_TYPE_NAMES.VSPP &&
      this.fields.optionType !== OPTION_TYPE_NAMES.DEALER_CASH &&
      this.fields.optionType !== OPTION_TYPE_NAMES.DEALER_TFS_CASH &&
      this.fields.optionType !== OPTION_TYPE_NAMES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH &&
      this.fields.optionType !== OPTION_TYPE_NAMES.SALES_MANAGER_FLAT_CASH &&
      this.fields.optionType !== OPTION_TYPE_NAMES.SALES_PERSON_FLAT_CASH &&
      this.fields.optionType !== OPTION_TYPE_NAMES.DEALER_WHOLESALE_PROGRAM &&
      this.fields.vehicles.length <= 0
    );
  };

  hasError = () => {
    return (
      this.hasOfferLevelError() ||
      (this.aprSetupDetails && this.aprSetupDetails.selectedTierTermsError) ||
      (this.leaseSetupDetails && this.leaseSetupDetails.termError) ||
      (this.miscSetupDetails && this.miscSetupDetails.hasErrors())
    );
  };

  get datesOrderError() {
    return dateStringToDate(this.fields.startDate) > dateStringToDate(this.fields.endDate);
  }

  get startDateError() {
    return Boolean(validator(this.fields.startDate, { required: true }));
  }

  get endDateError() {
    return Boolean(validator(this.fields.endDate, { required: true }));
  }

  get nameError() {
    return Boolean(validator(this.fields.offerName, { required: true }));
  }
}

export default OfferSetupModel;
