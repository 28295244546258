import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { assignStringValue, BlockInputLabel, Button, CustomDatePicker, dateFormatISOString, dateStringToDate, InlineInputLabel, Input, OATIcon, Textarea } from 'oat-common-ui';
import useStores from '../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  endDate: string;
  endDateError: boolean;
  handleFieldChange: (field: 'name' | 'startDate' | 'endDate' | 'note', value: string) => void;
  handleTrashIcon: () => void;
  name: string;
  note: string;
  noteId: string;
  offeringNameError: boolean;
  setShowNote: (val: boolean) => void;
  showNote: boolean;
  startDate: string;
  startDateError: boolean;
}

const GeneralIpFields = ({
  endDate,
  endDateError,
  handleFieldChange,
  handleTrashIcon,
  name,
  note,
  noteId,
  offeringNameError,
  setShowNote,
  showNote,
  startDate,
  startDateError,
}: Props) => {
  const {
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  return (
    <>
      <BlockInputLabel className={styles.generalTabField} labelClass={styles.labelColor} label="Offering Name">
        <Input
          id="offering-name-input"
          isNational
          value={name}
          onChange={val => handleFieldChange('name', val.currentTarget.value)}
          required
          error={offeringNameError}
          disabled={isPublished}
        />
      </BlockInputLabel>
      <div className={clsx(styles.generalTabField, styles.dates, isPublished && styles.unsetCursor)}>
        <BlockInputLabel className={styles.date} labelClass={styles.labelColor} label="Start Date">
          <CustomDatePicker
            id="general-tab-start-date"
            isNational
            selected={startDate ? dateStringToDate(startDate) : undefined}
            onChange={val => handleFieldChange('startDate', dateFormatISOString(val))}
            error={startDateError}
            disabled={isPublished}
          />
        </BlockInputLabel>
        <span className={styles.separator}>-</span>
        <BlockInputLabel className={styles.date} labelClass={styles.labelColor} label="End Date">
          <CustomDatePicker
            id="general-tab-end-date"
            isNational
            selected={endDate ? dateStringToDate(endDate) : undefined}
            onChange={val => handleFieldChange('endDate', dateFormatISOString(val))}
            error={endDateError}
            disabled={isPublished}
          />
        </BlockInputLabel>
      </div>
      <div className={styles.noteInputWrapper}>
        {!showNote && (
          <Button variant="text" onClick={() => setShowNote(!showNote)} className={styles.addNoteBtn} id="add-note-btn" disabled={isPublished}>
            <p>
              <OATIcon icon="plus" className={styles.noteIcon} />
              Add Note
            </p>
          </Button>
        )}
        {showNote && (
          <>
            <div>
              <InlineInputLabel label="Note" contentClass={clsx(styles.cancelIcon, isPublished && styles.unsetCursor)} className={styles.noteLabel}>
                <Button variant="text" onClick={handleTrashIcon} id="delete-note-btn" disabled={isPublished}>
                  <OATIcon icon="trash" colorTheme="blue" size={16} />
                </Button>
              </InlineInputLabel>
            </div>
            <div className={styles.noteInput}>
              <Textarea
                data-testid="general-ip-note-textarea"
                id={noteId}
                value={assignStringValue(note)}
                onChange={e => handleFieldChange('note', e.currentTarget.value)}
                required
                disabled={isPublished}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default observer(GeneralIpFields);
