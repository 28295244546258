import AccordionItemModel from '../models/AccordionItemModel';

/**
 *
 * @param accItems Should be root items
 */
const recursiveReverseSelection = (accItems: AccordionItemModel[], parent: AccordionItemModel | null) => {
  accItems.forEach(item => {
    if (item.items.length > 0) {
      recursiveReverseSelection(item.items, item);
    } else {
      item.checked = !item.checked;
    }

    if (parent && !parent.disabled) {
      parent.checked = accItems.some(i => i.checked);
    }
  });
};

export default recursiveReverseSelection;
