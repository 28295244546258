import { AdminConstants } from 'oat-admin-common';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const initialOfferFields = {
  id: '',
  isExample: false,
  isForRegions: true,
  isEnhTfsCostShareForRegions: true,
  isEnhSubCashTfsCostShareForRegions: true,
  rev: '',
  rgnlAltId: '',
  masterOfferId: '',
  penetrationRate: '',
  optionType: OPTION_TYPE_NAMES.APR as string,
  name: OPTION_TYPE_NAMES.APR as string,
  offerName: '',
  startDate: '',
  endDate: '',
  compatibilityList: [],
  isSpecialEdition: false,
  specialEditionPackage: '',
  vehicles: [],
  costShareId: undefined,
};

export const initialAprFields = {
  offerPnvs: 0,
  offerTfsPnvs: 0,
  offerEnhancedTfsPnvs: 0,
  isNcsApplied: false,
  tierTerms: [],
  tierPenRates: [],
  termWeightedCosts: [],
  subCashAmount: 0,
  subCashCostShare: 0,
  subCashCostShareCap: 0,
  subCashPnvs: 0,
  subCashTfsPnvs: 0,
  subCashEnhancedTfsPnvs: 0,
};
