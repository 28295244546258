import { Input } from 'oat-common-ui';
import GridItem from '../Grid/GridItem';
import GridRow from '../Grid/GridRow';
import GridRowHead from '../Grid/GridRowHead';
import styles from '../styles.module.scss';

interface Props {
  totalValues: Array<{ key: string; value: number }>;
  totalBudget: number;
}

const MultiSeriesOfferTotals = ({ totalValues, totalBudget }: Props) => {
  return (
    <GridRow>
      <GridRowHead>
        <Input readOnly value="Total" className={styles.totalInput} />
      </GridRowHead>

      {totalValues.map(totalValue => {
        return <GridItem key={totalValue.key} value={totalValue.value.toString()} isTotal />;
      })}
      <GridItem key="total-budget" value={totalBudget.toString()} isTotal />
    </GridRow>
  );
};

export default MultiSeriesOfferTotals;
