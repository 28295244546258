import { observer } from 'mobx-react-lite';
import { OATLoadingBar } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Offering, useGetAtcOfferingChangesQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import Error from '../ErrorPage';

interface Props {
  children: ReactNode;
  offering: Offering;
  isConfirmed: boolean;
}

const GetAtcOfferingChanges = ({ children, offering, isConfirmed }: Props) => {
  const {
    nationalProgramsStore: { setChanges },
  } = useStores();

  const { data, loading, error } = useGetAtcOfferingChangesQuery({ variables: { offeringId: offering.id }, skip: !offering.isAtc || !isConfirmed });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    // for publish CTA
    const { hasBudgetChanges, hasSetupChanges, hasEnhancedRcfChanges } = data.getAtcOfferingChanges;
    setChanges(hasBudgetChanges, hasSetupChanges, hasEnhancedRcfChanges);
  }

  return <>{children}</>;
};

export default observer(GetAtcOfferingChanges);
