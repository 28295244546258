import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { NumberInput, formatStdRate } from 'oat-common-ui';
import { useState } from 'react';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import AprModel from '../../../../models/offers/apr/AprModel';
import TierTermModel from '../../models/TierTermModel';
import styles from '../styles.module.scss';

interface Props {
  tierTerm: TierTermModel;
  index: number;
  apr: AprModel;
  onSave: () => void;
}

const TermRateTableCol = ({ tierTerm, index, apr, onSave }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { term, rate, tier, standardRate, rateError } = tierTerm;

  const handleValueChange = (value: string) => {
    apr.updateTermField(tier, term, 'rate', value);
    onSave();
  };

  const termToShow = index < 5 && <span className={clsx(styles.term, term === 24 && styles.term24)}>{term}</span>;
  const label = index % 5 === 0 ? `Tier ${tier} Rate` : '';

  return (
    <div className={styles.termRateTableCol} onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      {termToShow}
      <BlockInputLabel label={label}>
        <NumberInput
          id={`${tierTerm.uid}-rate-input`}
          isNational
          min={0}
          max={100}
          value={rate}
          onValueChange={val => handleValueChange(val.value)}
          className={styles.rateInput}
          error={rateError}
          rate
          data-testid={`${tierTerm.term}-${tierTerm.tier}-rate-input`}
        />
      </BlockInputLabel>
      {showTooltip && <span className={styles.rateTooltip}>{formatStdRate(standardRate)}</span>}
    </div>
  );
};

export default observer(TermRateTableCol);
