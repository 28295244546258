import { OfferingRate } from '../../../../gql/generated';
import TFSLeaseRateModel from '../models/TFSLeaseRateModel';

export const processTfsLeasesByTerm = (tfsLeases: OfferingRate[]) => {
  const tfsLeasesByTerm: { [term: number]: OfferingRate[] } = {};

  for (const tfsLease of tfsLeases) {
    if (!tfsLeasesByTerm[tfsLease.term]) {
      tfsLeasesByTerm[tfsLease.term] = [tfsLease];
      continue;
    }

    tfsLeasesByTerm[tfsLease.term] = [...tfsLeasesByTerm[tfsLease.term], tfsLease];
  }

  return Object.values(tfsLeasesByTerm).flat();
};

export const processTfsLeaseSection = (tfsLeases: TFSLeaseRateModel[], isLexus: boolean) => {
  if (!isLexus) {
    return tfsLeases.filter(l => l.tier !== '7+');
  }

  return tfsLeases;
};
