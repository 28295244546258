import { observer } from 'mobx-react-lite';
import { LeaseLocks } from 'oat-admin-common';
import { formatDollars } from 'oat-common-ui';
import LeaseFormModel from '../../../models/offers/lease/LeaseFormModel';
import LeaseModel from '../../../models/offers/lease/LeaseModel';
import useSaveLease from '../../../offers/LeaseSection/useSaveLease';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import OfferName from './OfferName';
import RowProps from './RowProps';
import { useSummaryCosts } from '../useSummaryCosts';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

interface PropsItem<T> extends RowProps<LeaseModel> {
  leaseForm: T;
  nglOfferCost?: number;
  disablePenRate?: boolean;
  setOnFocus?: (val: boolean) => void;
}

export const LeaseRowItem = observer(({ seriesProfile, rgnlAlt, offer, leaseForm, disablePenRate }: PropsItem<LeaseFormModel>) => {
  const { uid, setLock, updateLeaseFields, updateOfferField } = leaseForm;
  const { saveLease } = useSaveLease(seriesProfile, rgnlAlt, offer, leaseForm);

  const { tfsCost, tmsCost } = useSummaryCosts(rgnlAlt, leaseForm);

  const handleRcf = (val: string) => {
    setLock(LeaseLocks.TARGET_PAYMENT);
    updateLeaseFields('rcf', val);
    saveLease();
  };

  const handlePenRate = (val: string) => {
    seriesProfile.clearErrors();
    updateOfferField('penetrationRate', val);
    saveLease();
  };

  const subCashIsGreaterThanZero = Number(leaseForm.leaseFields.subCashAmount) > 0;

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{leaseForm.offerFields.name}</OfferName>
      </td>
      <td>
        <RgnlAltInput
          id={`lease-summary-rcf-${uid}`}
          isNational
          rcf
          value={leaseForm.leaseFields.rcf}
          onValueChange={val => handleRcf(val.value)}
          error={leaseForm.rcfError}
          disabled={leaseForm.isExample}
        />
      </td>
      <td>${formatDollars(leaseForm.leaseFields.rcfPnvs + leaseForm.leaseFields.subCashPnvs)}</td>
      <td>
        <RgnlAltInput
          id={`lease-summary-penrate-${uid}`}
          isNational
          wholeNumber
          percentageSign
          min={0}
          max={100}
          value={leaseForm.offerFields.penetrationRate}
          onValueChange={val => handlePenRate(val.value)}
          error={leaseForm.penRateError}
          disabled={disablePenRate}
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost}</td>
          <td>${tfsCost}</td>
        </>
      )}
      {subCashIsGreaterThanZero && (
        <td className={styles.paddingLeft}>
          ${formatDollars(leaseForm.leaseFields.subCashAmount)} <span className={styles.labelsm}>Sub Cash</span>
        </td>
      )}
      <td className={subCashIsGreaterThanZero ? '' : styles.paddingLeft}>
        ${formatDollars(leaseForm.leaseFields.targetPayment)} <span className={styles.labelsm}>Monthly Payment</span>
      </td>
      <td>
        ${formatDollars(leaseForm.leaseFields.dueAtSigning)} <span className={styles.labelsm}>Due At Signing</span>
      </td>
    </tr>
  );
});

export const LeaseNglRowItem = observer(({ seriesProfile, rgnlAlt, offer, leaseForm, setOnFocus, nglOfferCost }: PropsItem<LeaseFormModel>) => {
  const { uid, updateLeaseFields } = leaseForm;
  const { saveLease } = useSaveLease(seriesProfile, rgnlAlt, offer, leaseForm);

  const { tfsCost, tmsCost } = useSummaryCosts(rgnlAlt, leaseForm, true);

  const handleRvSupport = (val: string) => {
    updateLeaseFields('rvSupport', val);
    saveLease();
  };

  const handleRvSupportPenRate = (val: string) => {
    updateLeaseFields('rvSupportPenRate', val);
    saveLease();
  };

  return (
    <tr>
      <td className={styles.label}>NGL</td>
      <td className={styles.rvSupport}>
        <div>
          <RgnlAltInput
            id={`lease-summary-rvsupp-${uid}`}
            isNational
            wholeNumber
            value={leaseForm.leaseFields.rvSupport}
            onValueChange={val => {
              handleRvSupport(val.value);
            }}
            onBlur={() => setOnFocus && setOnFocus(false)}
            onFocus={() => setOnFocus && setOnFocus(true)}
            error={leaseForm.rvSupportError}
          />
          <span>%</span>
        </div>
      </td>
      <td>${formatDollars(nglOfferCost)}</td>
      <td>
        <RgnlAltInput
          id={`lease-summary-rv-penrate-${uid}`}
          isNational
          wholeNumber
          percentageSign
          min={0}
          max={100}
          value={leaseForm.leaseFields.rvSupportPenRate}
          onValueChange={val => handleRvSupportPenRate(val.value)}
          error={leaseForm.rvSupportPenRateError}
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost}</td>
          <td>${tfsCost}</td>
        </>
      )}
    </tr>
  );
});
