import { AdminConstants } from 'oat-admin-common';
import { assignBooleanValue } from 'oat-common-ui';
import { Brand, defaultTfsContracts, FEATURE_OR_5454_PHASE_2, getTargetedAudienceCollageGraduateText, getTargetedAudienceMilitaryText } from '../../../constants/global';
import { Offer, TierTerm } from '../../../gql/generated';
import { LocalTfsContract } from '../models/offers/OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const getTransferableDefaultValue = (offer: Offer) => {
  const defaultValue = offer.optionType === OPTION_TYPE_NAMES.MILITARY;

  return assignBooleanValue(offer.miscDetails?.isTransferable, defaultValue);
};

const processMiscTfsTierTerms = (localTfs: LocalTfsContract[], offer: Offer) => {
  if (offer.miscDetails?.tfsContracts) {
    const tfsContracts = offer.miscDetails.tfsContracts;

    for (const tfsContract of tfsContracts) {
      if (tfsContract?.tierTerms && tfsContract?.tierTerms.length > 0) {
        const localTfsIndex = localTfs.findIndex(localTfs => localTfs.isSubvented === tfsContract.isSubvented && localTfs.contractType === tfsContract.contractType);

        if (localTfsIndex >= 0) {
          const localTfsAtIndex = localTfs[localTfsIndex];
          localTfsAtIndex.tierTerms = tfsContract.tierTerms as TierTerm[];

          if (localTfsAtIndex.tierTerms.length) {
            const termsList = localTfsAtIndex.termsList;
            const tiersList = localTfsAtIndex.tiersList;

            for (const tierTerm of localTfsAtIndex.tierTerms) {
              if (!termsList.find(term => term === tierTerm.term)) {
                termsList.push(tierTerm.term);
              }

              if (!tiersList.find(tier => tier === tierTerm.tier)) {
                tiersList.push(tierTerm.tier);
              }
            }
          }
        }
      }
    }
  }
};

export const getTfsContractsDefaultValue = (offer: Offer) => {
  const localTfs: LocalTfsContract[] = JSON.parse(JSON.stringify(defaultTfsContracts));

  processMiscTfsTierTerms(localTfs, offer);

  switch (offer.optionType) {
    case OPTION_TYPE_NAMES.TFS_APR_CASH:
    case OPTION_TYPE_NAMES.TFS_FINANCE_CASH:
    case OPTION_TYPE_NAMES.DEALER_TFS_APR_SUBVENTION_CASH:
    case OPTION_TYPE_NAMES.DEALER_TFS_FINANCE_SUBVENTION_CASH:
    case OPTION_TYPE_NAMES.DEFERRED_PAYMENT: {
      return localTfs.filter(tfs => tfs.contractType === OPTION_TYPE_NAMES.APR);
    }
    case OPTION_TYPE_NAMES.TFS_LEASE_CASH:
    case OPTION_TYPE_NAMES.DEALER_TFS_LEASE_SUBVENTION_CASH: {
      return localTfs.filter(tfs => tfs.contractType === OPTION_TYPE_NAMES.LEASE);
    }
    case OPTION_TYPE_NAMES.LEASE_LOYALTY: {
      if (!offer.miscDetails?.tfsContracts) {
        const tfs: LocalTfsContract[] = localTfs.filter(tfs => tfs.contractType === OPTION_TYPE_NAMES.LEASE);

        const leaseSubTier = ['1+', '1', '2'];
        const leaseSubTerm = [24, 36];
        const leaseNotSubTier = ['1+', '1', '2', '3', '4', '5', '6', '7'];
        const leaseNotSubTerm = [24, 36, 48, 60];

        if (offer.brand === Brand.TOYOTA) {
          leaseSubTier.push('3');
        } else {
          leaseSubTerm.push(48);
        }

        tfs.forEach(t => {
          if (t.isSubvented) {
            leaseSubTier.forEach(tier => {
              leaseSubTerm.forEach(term => {
                t.tierTerms.push({ tier, term });
              });
            });
          } else {
            leaseNotSubTier.forEach(tier => {
              leaseNotSubTerm.forEach(term => {
                t.tierTerms.push({ tier, term });
              });
            });
          }
        });

        return tfs;
      }
    }
  }

  return localTfs;
};

export const getTargetedAudienceDefaultValue = (offer: Offer) => {
  if (offer.miscDetails?.targetedAudience) {
    return offer.miscDetails.targetedAudience;
  }

  if (offer.optionType === (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE)) {
    return getTargetedAudienceCollageGraduateText(offer.brand as Brand);
  } else if (offer.optionType === OPTION_TYPE_NAMES.MILITARY) {
    return getTargetedAudienceMilitaryText(offer.brand as Brand);
  }

  return '';
};
