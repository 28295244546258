import { AdminConstants } from 'oat-admin-common';
import RgnlAltModel from '../../models/RgnlAltModel';

export const buildOfferSelectionsChecklist = (rgnlAlts: RgnlAltModel[]): Array<{ id: string; name: string; items: Array<{ id: string; uid: string; name: string }> }> => {
  const filteredRas = rgnlAlts.filter(ra => ra.data.number !== 1 || !ra.data.number);
  const list = filteredRas.map(ra => {
    return {
      id: ra.data.id,
      name: `RA-${ra.data.number} ${ra.data.name}`,
      items: ra.offers.map(offer => {
        return {
          id: offer.getOfferId(),
          uid: offer.uid,
          name: offer.getOfferFields()?.name ?? offer.getOptionType(),
        };
      }),
    };
  });

  filteredRas.forEach(ra => {
    const filtered = list.find(raItem => raItem.id === ra.data.id);

    ra.offers.forEach((ofr, i) => {
      const leaseDetailsList: { id: string; name: string; uid: string }[] = [];
      const filteredIndex = filtered?.items.findIndex(item => item.id === ofr.getOfferId());

      if (ofr.getOptionType() === AdminConstants.OPTION_TYPE_NAMES.LEASE) {
        if (ofr.leaseDetails?.getExamplesWithDifferentTerm()) {
          ofr.leaseDetails?.getExamplesWithDifferentTerm().forEach(detail => {
            leaseDetailsList.push({
              uid: detail.uid,
              id: detail.offerFields.id,
              name: detail.offerFields.name,
            });
          });
        }

        if (ofr.leaseDetails?.selectedLease?.isNgl) {
          leaseDetailsList.push({
            uid: ofr.uid,
            id: ofr.getOfferId(),
            name: 'NGL',
          });
        }

        filtered?.items.splice(filteredIndex ? filteredIndex + 1 : i + 1, 0, ...leaseDetailsList);
      }
    });
  });

  return list;
};
