import { ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Button from '../../../components/Button';

import styles from './styles.module.scss';

interface Props {
  message: string;
  onClose: () => void;
  onSubmit: () => void;
  submitBtnText: string;
  title: string;
  id: string;
}

const DefaultNationalModal = ({ id, onClose, onSubmit, message, submitBtnText, title }: Props) => {
  return (
    <Modal isOpen onClose={onClose} className={styles.defauleModalWrapper}>
      <ModalHeader className={styles.header} onClose={onClose}>
        {title}
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p className={styles.message}>{message}</p>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <ButtonGroup>
          <Button id={`cancel-${id}`} onClick={onClose} variant="white" className={styles.cancelBtn}>
            Cancel
          </Button>
          <Button id={id} variant="blue" onClick={onSubmit}>
            {submitBtnText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DefaultNationalModal;
