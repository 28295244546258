import { Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import { useState } from 'react';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import LeaseModel from '../../../../models/offers/lease/LeaseModel';
import styles from './style.module.scss';

interface Props {
  onClose: () => void;
  onSave: (name: string) => void;
  lease: LeaseModel;
}

const AddExampleModal = ({ onClose, onSave, lease }: Props) => {
  const { getExamples } = lease;
  const [name, setName] = useState('');
  const [leaseExampleNameError, setLeaseExampleNameError] = useState(false);
  const hasNameError = !name.trim() || leaseExampleNameError;

  const handleOnSave = () => {
    const nameTrim = name.trim();
    if (!hasNameError) {
      onSave(nameTrim);
      onClose();
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Create Lease Example" onClose={onClose} />
      <ModalBody>
        <BlockInputLabel label="Name:">
          <Input
            id="modal-name-input"
            isNational
            value={name}
            onChange={e => {
              setName(e.currentTarget.value);
              setLeaseExampleNameError(getExamples().some(ex => ex.offerFields.name === e.currentTarget.value));
            }}
            error={hasNameError}
          />
        </BlockInputLabel>
        {leaseExampleNameError && <div className={styles.error}>Please enter a unique Lease Example name.</div>}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-cancel-btn" variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="modal-save-btn" variant="primary" onClick={handleOnSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default AddExampleModal;
