import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import styles from '../styles.module.scss';

interface Props {
  offerName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteModal = ({ offerName, onClose, onSubmit }: Props) => {
  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <span className={styles.modalTextHeader}>{`Delete ${offerName}`}</span>
      </ModalHeader>
      <ModalBody>
        <span className={styles.modalTextBody}>{`Are you sure you want to delete the ${offerName} offer? This action cannot be undone.`}</span>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button className={styles.modalBtn} onClick={onClose}>
            Cancel
          </Button>
          <Button className={styles.modalBtn} onClick={onSubmit}>
            Delete Multi-Series Offer
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
