import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, DropdownItem, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: (dropdownItem: DropdownItem) => void;
  seriesOptions: DropdownItem[];
}

const SelectSeriesModal = ({ onClose, onSubmit, seriesOptions }: Props) => {
  const {
    nationalCostShareStore: { setHasUnsavedChanges },
  } = useStores();
  const [searchBySeries, setSearchBySeries] = useState('');
  const [selectedSeries, setSelectedSeries] = useState<DropdownItem>({ value: '', label: '' });

  const handleSearchSeries = (val: string) => {
    setSearchBySeries(val);
  };

  const handleSelectSeries = (option: DropdownItem) => {
    if (selectedSeries.value === option.value) {
      setSelectedSeries({ value: '', label: '' });
      return;
    }

    setSelectedSeries(option);
  };

  const handleConfirm = () => {
    onSubmit(selectedSeries);
    setHasUnsavedChanges(true);
  };

  const seriesOptionsCopy = [...seriesOptions];
  const seriesOptionsToDisplay = !searchBySeries ? seriesOptions : seriesOptionsCopy.filter(o => o.label.toLowerCase().trim().includes(searchBySeries.toLowerCase().trim()));

  return (
    <Modal isOpen onClose={onClose} className={styles.parent}>
      <ModalHeader className={styles.modalHeader} onClose={onClose} closeBtnClass={styles.modalHeader}>
        Select a series
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <div className={styles.selectSeries}>
          <Input
            id="select-series-input"
            className={styles.selectSeriesInput}
            isNational
            placeholder="Search by series"
            value={searchBySeries}
            onChange={e => handleSearchSeries(e.target.value)}
          />
          <OATIcon icon="search" size={16} className={styles.searchIcon} />
        </div>
        <div className={seriesOptionsToDisplay.length ? styles.seriesOptions : ''}>
          {seriesOptionsToDisplay.map((seriesOption, i) => {
            return (
              <Button
                key={`${seriesOption.value}-${i}`}
                id={`${seriesOption.label}-${seriesOption.value}`}
                variant="text"
                className={clsx(styles.seriesOptionBtn, selectedSeries.value === seriesOption.value ? styles.seriesSelected : '')}
                onClick={() => handleSelectSeries(seriesOption)}
              >
                {seriesOption.label}
              </Button>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button variant="text" id="select-series-cancel-btn" onClick={() => onClose()} className={styles.cancelBtn}>
          Cancel
        </Button>
        <Button variant="primary" id="select-series-confirm-btn" onClick={() => handleConfirm()} disabled={!selectedSeries.label && !selectedSeries.value}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(SelectSeriesModal);
