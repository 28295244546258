import TierTermModel from '../models/TierTermModel';

export const createCalcParams = (tierTerm: TierTermModel, forTfsCost: boolean, distribution: number, tfsShare: number) => {
  return {
    nationalRate: tierTerm.standardRate / 100,
    regionalRate: Number(tierTerm.rate) / 100,
    forTfsCost,
    tfsShare: Number(tfsShare) / 100,
    term: tierTerm.term,
    distribution,
    averageAmountFinanced: Number(tierTerm.averageAmountFinanced),
    subventionCash: 0, // always 0 for national APR tierterm calcs
  };
};
