import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import styles from '../../TFSStandardRcf/components/styles.module.scss';
import StandardRcfTableInput from './StandardRcfTableInput';

const StandardRcfTable = () => {
  const {
    ratesTabLeaseRCFStore: { leaseRates },
  } = useStores();

  return (
    <div className={styles.standardRcfTable}>
      {leaseRates.map(rateModel => {
        return <StandardRcfTableInput key={rateModel.uid} model={rateModel} />;
      })}
    </div>
  );
};

export default observer(StandardRcfTable);
