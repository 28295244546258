import { LazyQueryExecFunction } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Exact, GetAllAssumptionsQuery } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import FiscalYearNav from '../../components/header/Navbar/components/FiscalYearNav';
import AssumptionsGridItems from './AssumptionsGridItems';

import { useToast } from 'oat-common-ui';
import { AssumptionsModel } from '../../../../models/Assumptions';
import styles from '../styles.module.scss';

type Props = {
  fetchAssumptions: LazyQueryExecFunction<GetAllAssumptionsQuery, Exact<{ brand: string; fiscalYear: number }>>;
};

const AssumptionsNav = ({ fetchAssumptions }: Props) => {
  const { error } = useToast();

  const {
    assumptionsStore: { fiscalYear, setAssumptions, setFiscalYear, currentYear, setIsLoading, isDisabledNext, isDisabledPrevious },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const changeFiscalYearHandler = async (year: number) => {
    // We are showing one fiscal year max forward and backward
    // FiscalYear is updated within setAssumptions function
    if (Math.abs(currentYear - year) <= 2 || currentYear === year) {
      setIsLoading(true);
      try {
        const assumptionsResult = await fetchAssumptions({ variables: { brand, fiscalYear: year } });
        setFiscalYear(year);
        setAssumptions(assumptionsResult.data?.queryAssumptions as AssumptionsModel);
      } catch (e) {
        error((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.date}>
      <div className={styles.gridWrapper}>
        <AssumptionsGridItems />
        <FiscalYearNav
          isDisabledNext={isDisabledNext}
          isDisabledPrev={isDisabledPrevious}
          label="Fiscal Year"
          toNextFiscalYear={() => {
            changeFiscalYearHandler(fiscalYear + 1);
          }}
          toPrevFiscalYear={() => {
            changeFiscalYearHandler(fiscalYear - 1);
          }}
        />
      </div>
    </div>
  );
};

export default observer(AssumptionsNav);
