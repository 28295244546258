import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Checkbox, InlineInputLabel, Input } from 'oat-common-ui';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';
import styles from './styles.module.scss';

interface Props {
  item: OfferSetupModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
  className?: string;
}

const SpecialEdition = ({ item, seriesProfile, onSave, className }: Props) => {
  const { updateField } = item;

  return (
    <div className={clsx(styles.specialEdition, className)}>
      <InlineInputLabel labelComponent={<span className={styles.specialEditionLabel}>Special Edition:</span>}>
        <Checkbox
          isNational
          id={`special-edition-checkbox-${item.uid}`}
          onChange={e => {
            updateField('isSpecialEdition', e.target.checked);
            onSave();
          }}
          isChecked={item.fields.isSpecialEdition}
          isDisabled={seriesProfile.isConfirmed}
        />
      </InlineInputLabel>
      {item.fields.isSpecialEdition && (
        <InlineInputLabel label="Package:">
          <Input
            id={`special-edition-pkg-${item.uid}`}
            isNational
            alphaNumericWithComma
            value={item.fields.specialEditionPackage}
            disabled={seriesProfile.isConfirmed}
            onChange={e => {
              updateField('specialEditionPackage', e.target.value);
              onSave();
            }}
          />
        </InlineInputLabel>
      )}
    </div>
  );
};

export default observer(SpecialEdition);
