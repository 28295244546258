import { makeAutoObservable, runInAction } from 'mobx';
import { assignStringValue } from 'oat-common-ui';
import {
  ModelCodeMappingResponse,
  ModelCodeResponse,
  ModelCodesResponse,
  OfferingSeriesItem,
  SaveSeriesProfileVehiclesResult,
  SeriesMapping,
  SeriesProfile
} from '../gql/generated';
import ModelCodeMappingModel from '../pages/IpSettings/SeriesModelsTab/models/ModelCodeMappingModel';
import MultiSeriesProfileModel from '../pages/IpSettings/SeriesModelsTab/models/MultiSeriesProfileModel';
import SeriesSelectionsModel from '../pages/IpSettings/SeriesModelsTab/models/SeriesSelectionsModel';

class SeriesAndModelsStore {
  seriesMapping: SeriesMapping[] = [];
  allModelCodes: ModelCodesResponse[] = [];
  seriesSelections = new SeriesSelectionsModel();
  modelCodeMappings = new ModelCodeMappingModel();
  multiSeriesProfiles: MultiSeriesProfileModel[] = [];
  seriesProfiles: SeriesProfile[] = [];
  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSeriesMapping = (seriesMapping: SeriesMapping[]) => {
    this.seriesMapping = seriesMapping;
  };

  setAllModelCodes = (modelCodes: ModelCodesResponse[]) => runInAction(() => (this.allModelCodes = modelCodes));

  setSeriesSelections = (selections: OfferingSeriesItem[]) => {
    this.seriesSelections.setSelections(selections, this.seriesMapping);
  };

  setModelCodeMappings = (data: ModelCodeMappingResponse[]) => {
    const availableSeries = this.seriesSelections.excluded.concat(this.seriesSelections.included);
    this.modelCodeMappings.setMappings(data, this.allModelCodes, availableSeries);
  };

  updateModelCodesRev = (updatedModels: ModelCodeResponse[]) => {
    this.allModelCodes.forEach(model => {
      model.models.forEach(mc => {
        const update = updatedModels.find(updated => updated.id === mc.id);
        if (update) {
          mc.rev = update.rev;
        }
      });
    });
  };

  setMultiSeriesProfiles = (data: SeriesProfile[]) => {
    this.seriesProfiles = data;
    this.multiSeriesProfiles = data.map(item => new MultiSeriesProfileModel(item, this.modelCodeMappings.mappings));
  };

  updateSeriesProfile = (data: SaveSeriesProfileVehiclesResult) => {
    this.multiSeriesProfiles.forEach(profile => {
      if (profile.id === data.seriesProfileId && data && data.vehicles) {
        const vehicles = data.vehicles.map(vehicle => ({ id: assignStringValue(vehicle?.id), isInclusion: vehicle?.isInclusion || false }));
        profile.vehicles = vehicles;
        profile.rev = assignStringValue(data.rev);
      }
    });
  };

  setHasUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };
}

export default SeriesAndModelsStore;
