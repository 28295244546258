import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Button from '../../../../../../components/Button';
import { VIEW_ALL_RATES } from '../../../../../../constants/global';
import { TiersListItem } from '../../../../models/offers/apr/AprFields';
import styles from './styles.module.scss';

interface Props {
  uid: string;
  tiersList: TiersListItem[];
  selectTier: (tier: string) => void;
  selectedTier: string;
}

const TiersList = ({ uid, tiersList, selectTier, selectedTier }: Props) => {
  const tierButtonStyle = (tiersListItem: TiersListItem) =>
    clsx(styles.tierBtnError, tiersListItem.tier === selectedTier && !tiersListItem.hasError && tiersListItem.isComplete && styles.tierBtnSelected);

  const tierButtonText = (tier: string) => {
    if (tier === VIEW_ALL_RATES) {
      return tier;
    }

    return `Tier ${tier}`;
  };

  const handleOnClick = (tier: string) => {
    selectTier(tier);
  };

  return (
    <div className={styles.tiersList}>
      {tiersList.map(t => {
        const tierBtnStyle = clsx(
          styles.tierBtn,
          tierButtonStyle(t),
          selectedTier !== t.tier && t.hasError && styles.tierBtnError,
          selectedTier !== t.tier && t.isComplete && styles.tierBtnComplete,
        );

        return (
          <Button key={t.tier} id={`tier-cta-${uid}-${t.tier}`} className={tierBtnStyle} onClick={() => handleOnClick(t.tier)} variant="text">
            {tierButtonText(t.tier)}
          </Button>
        );
      })}
    </div>
  );
};

export default observer(TiersList);
