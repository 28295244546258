import SectionLabel from '../components/SectionLabel';
import ResidualValueRateInput from './components/ResidualValueRateInput';

const ResidualValueRate = () => {
  return (
    <>
      <SectionLabel label="Residual Value Rate" />
      <ResidualValueRateInput />
    </>
  );
};

export default ResidualValueRate;
