import { assignNumberValue } from 'oat-common-ui';
import { CreateMiscOfferInput, UpdateMiscOfferInput } from '../../../../gql/generated';
import MiscModel from '../../models/offers/MiscModel';

export const getCreateMiscOfferPayload = (brand: string, offeringId: string, misc: MiscModel, sortOrder: number, isLexus: boolean): CreateMiscOfferInput => {
  return {
    brand,
    offeringId,
    rgnlAltId: misc.offerFields.rgnlAltId,
    isForRegions: misc.offerFields.isForRegions,
    isEnhTfsCostShareForRegions: isLexus ? true : misc.offerFields.isEnhTfsCostShareForRegions,
    penetrationRate: assignNumberValue(misc.offerFields.penetrationRate),
    optionType: misc.offerFields.optionType,
    name: misc.offerFields.name,
    ...misc.offerCostFields,
    amount: assignNumberValue(misc.offerCostFields.amount),
    offerPnvs: assignNumberValue(misc.offerCostFields.offerPnvs),
    sortOrder,
  };
};

export const getUpdateMiscOfferPayload = (misc: MiscModel, isLexus: boolean): UpdateMiscOfferInput => {
  return {
    id: misc.offerFields.id,
    rev: misc.offerFields.rev,
    isForRegions: misc.offerFields.isForRegions,
    isEnhTfsCostShareForRegions: isLexus ? true : misc.offerFields.isEnhTfsCostShareForRegions,
    penetrationRate: assignNumberValue(misc.offerFields.penetrationRate),
    name: misc.offerFields.name,
    ...misc.offerCostFields,
    amount: assignNumberValue(misc.offerCostFields.amount),
    offerPnvs: assignNumberValue(misc.offerCostFields.offerPnvs),
  };
};
