import { observer } from 'mobx-react-lite';
import { ChangeEvent } from 'react';
import { Brand } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import { OfferFields } from '../../models/offers/OfferFields';
import RegionsCheckbox from './RegionsCheckbox';
import { buildRegionsCheckboxes } from './buildRegionsCheckboxes';
import styles from './styles.module.scss';

interface Props {
  brand: string;
  offerCheckboxIsDisabled?: boolean;
  offerIsChecked: boolean;
  offerType: string;
  onChange?: (fieldName: keyof OfferFields, value: string | number | boolean) => void;
  seriesProfile: SeriesProfileModel;
  subTfsCheckboxIsDisabled?: boolean;
  subTfsIsChecked: boolean;
  tfsCheckboxIsDisabled?: boolean;
  tfsIsChecked: boolean;
  uid: string;
}

const DoNotSendToRegions = ({
  brand,
  offerCheckboxIsDisabled,
  offerIsChecked,
  offerType,
  onChange,
  seriesProfile,
  subTfsCheckboxIsDisabled,
  subTfsIsChecked,
  tfsCheckboxIsDisabled,
  tfsIsChecked,
  uid,
}: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const {
    data: { isBudgetConfirmed },
  } = seriesProfile;

  const id = `${offerType}-${uid}`;
  const { ryoAndCashOfferTypes, miscWithoutCostShareOfferTypes, miscWithCostShareOfferTypes } = buildRegionsCheckboxes();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, field: keyof OfferFields) => {
    if (onChange) {
      onChange(field, e.currentTarget.checked);
    }
  };

  // update accordingly depending on offer type
  const showCheckboxes = () => {
    if ([...ryoAndCashOfferTypes, ...miscWithoutCostShareOfferTypes].includes(offerType) || brand === Brand.LEXUS) {
      return (
        <RegionsCheckbox
          label="Offer"
          id={id}
          onChangeHandler={e => onChangeHandler(e, 'isForRegions')}
          isDisabled={isBudgetConfirmed || !!offerCheckboxIsDisabled}
          isChecked={offerIsChecked}
        />
      );
    }
    

    if (miscWithCostShareOfferTypes.includes(offerType)) {
      return (
        <>
          <RegionsCheckbox
            label="Offer"
            id={id}
            onChangeHandler={e => onChangeHandler(e, 'isForRegions')}
            isDisabled={isBudgetConfirmed || !!offerCheckboxIsDisabled}
            isChecked={offerIsChecked}
          />
          <RegionsCheckbox
            label={appendTfsLabel(isLexus(), 'Cost Share(s)', 'Enhanced')}
            id={id}
            onChangeHandler={e => onChangeHandler(e, 'isEnhTfsCostShareForRegions')}
            isDisabled={isBudgetConfirmed || !!tfsCheckboxIsDisabled}
            isChecked={tfsIsChecked}
          />
        </>
      );
    }

    return (
      <>
        <RegionsCheckbox
          label="Offer"
          id={id}
          onChangeHandler={e => onChangeHandler(e, 'isForRegions')}
          isDisabled={isBudgetConfirmed || !!offerCheckboxIsDisabled}
          isChecked={offerIsChecked}
        />
        <RegionsCheckbox
          label={appendTfsLabel(isLexus(), 'Cost Share(s)', 'Enhanced')}
          id={id}
          onChangeHandler={e => onChangeHandler(e, 'isEnhTfsCostShareForRegions')}
          isDisabled={isBudgetConfirmed || !!tfsCheckboxIsDisabled}
          isChecked={tfsIsChecked}
        />
        <RegionsCheckbox
          label={appendTfsLabel(isLexus(), 'Cost Share', 'Enhanced Sub Cash')}
          id={id}
          onChangeHandler={e => onChangeHandler(e, 'isEnhSubCashTfsCostShareForRegions')}
          isDisabled={isBudgetConfirmed || !!subTfsCheckboxIsDisabled}
          isChecked={subTfsIsChecked}
        />
      </>
    );
  };

  return (
    <div className={styles.checkboxesContainer}>
      <span className={styles.dontSendText}>Do Not Send To {brand === Brand.TOYOTA ? 'Regions' : 'Areas'}: </span>
      {showCheckboxes()}
    </div>
  );
};

export default observer(DoNotSendToRegions);
