import { Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Button from '../../../../../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  id: string;
  onClose: () => void;
  onSubmit: () => void;
}

const WarningModal = ({ id, onClose, onSubmit }: Props) => {
  return (
    <Modal isOpen>
      <ModalHeader title="Warning" onClose={onClose} className={styles.header} closeBtnClass={styles.closeBtn} />
      <ModalBody className={styles.body}>Are you sure you want to leave this page? Any changes will not be saved.</ModalBody>
      <ModalFooter className={styles.body}>
        <Button variant="text" onClick={() => onClose()} className={styles.cancelBtn} id={`${id}-warning-modal-cancel-btn`}>
          Cancel
        </Button>
        <Button variant="blue" onClick={() => onSubmit()} id={`${id}-warning-modal-yes-btn`}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarningModal;
