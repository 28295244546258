import { observer } from 'mobx-react-lite';
import { assignStringValue } from 'oat-common-ui';
import { Fragment } from 'react';

import SeriesProfileModel from '../../../models/SeriesProfileModel';
import useCompareData from './useCompareData';
import { addSign, getOferTypeCompareLabel } from './utils';

import styles from '../styles.module.scss';
import OfferCompareRow from './OfferCompareRow';

type Props = {
  seriesProfile: SeriesProfileModel;
};

const RAComparison = ({ seriesProfile }: Props) => {
  const { compareData } = useCompareData(seriesProfile.compareScenarios, seriesProfile.previousMonthScenarioData);

  return (
    <div className={styles.raComparisonsWrapper}>
      {compareData?.map((data, index) => (
        <div className={styles.raComparison} key={index}>
          <table>
            <thead>
              <tr>
                <td colSpan={2} className={styles.noBorder}>
                  RA-{data.raNumber} Comparison
                </td>
                <td className={styles.scenarioName}>{data.originalScenarioName}</td>
                <td className={styles.compareScenarioName}>{data.compareScenarioName}</td>
              </tr>
            </thead>
            <tbody>
              {data.offers.map((offer, index) => (
                <tr key={`offer-${offer.originalOffer?.offerUid}-${index}`}>
                  {index === 0 ? <td className={styles.label}>Offer</td> : <td className={styles.noBorder} />}
                  <OfferCompareRow offer={offer} />
                </tr>
              ))}
              {data.offers.map((offer, index) => (
                <tr key={`offer-cost-${offer.originalOffer?.offerUid}-${index}`}>
                  {index === 0 ? <td className={styles.label}>Offer Cost</td> : <td className={styles.noBorder} />}
                  <>
                    <td className={styles.subLabel}>{getOferTypeCompareLabel(offer)}</td>
                    <td className={styles.value}>{addSign(true, false, assignStringValue(offer.originalOffer?.getOfferCosts()?.offerPnvs))}</td>
                    <td className={styles.value}>{addSign(true, false, assignStringValue(offer.compareOffer?.getOfferCosts()?.offerPnvs))}</td>
                  </>
                </tr>
              ))}
              {data.offers.map((offer, index) => (
                <tr key={`offer-pen-rate-${offer.originalOffer?.offerUid}-${index}`}>
                  {index === 0 ? <td className={styles.label}>Pen Rate</td> : <td className={styles.noBorder} />}
                  <>
                    <td className={styles.subLabel}>{getOferTypeCompareLabel(offer)}</td>
                    <td className={styles.value}>{addSign(false, true, assignStringValue(offer.originalOffer?.getOfferFields()?.penetrationRate))}</td>
                    <td className={styles.value}>{addSign(false, true, assignStringValue(offer.compareOffer?.getOfferFields()?.penetrationRate))}</td>
                  </>
                </tr>
              ))}
              {data.leaseExOffers &&
                data.leaseExOffers.map((leaseEx, index) => (
                  <Fragment key={`lease-ex-monthly-payment-${leaseEx.originalOffer?.offerUid}-${index}`}>
                    <tr>
                      <td className={styles.label}>Lease Ex.</td>
                      <td className={styles.subLabel}>Monthly payment</td>
                      <td className={styles.value}>{addSign(true, false, assignStringValue(leaseEx.originalOffer?.leaseDetails?.selectedLease?.leaseFields.targetPayment))}</td>
                      <td className={styles.value}>{addSign(true, false, assignStringValue(leaseEx.compareOffer?.leaseDetails?.selectedLease?.leaseFields.targetPayment))}</td>
                    </tr>
                  </Fragment>
                ))}
              {data.leaseExOffers &&
                data.leaseExOffers.map((leaseEx, index) => (
                  <Fragment key={`lease-ex-due-at-signing-${leaseEx.originalOffer?.offerUid}-${index}`}>
                    <tr>
                      <td className={styles.noBorder} />
                      <td className={styles.subLabel}>Due at Signing</td>
                      <td className={styles.value}>{addSign(true, false, assignStringValue(leaseEx.originalOffer?.leaseDetails?.selectedLease?.leaseFields.dueAtSigning))}</td>
                      <td className={styles.value}>{addSign(true, false, assignStringValue(leaseEx.compareOffer?.leaseDetails?.selectedLease?.leaseFields.dueAtSigning))}</td>
                    </tr>
                  </Fragment>
                ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default observer(RAComparison);
