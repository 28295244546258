import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  label?: string;
  smallLabel?: boolean;
  labelComponent?: ReactNode;
  className?: string;
  labelClass?: string;
}

const BlockInputLabel = ({ children, label, smallLabel, labelComponent, className, labelClass }: Props) => {
  return (
    <div className={clsx(styles.container, className)}>
      <span className={clsx(styles.label, smallLabel && styles.smallLabel, labelClass)}>{labelComponent || label}</span>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default BlockInputLabel;
