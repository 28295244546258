import { OATLoadingBar } from 'oat-common-ui';
import { ReactNode } from 'react';
import { SeriesMapping, SeriesSortingResponse, SeriesSortItem, useGetSeriesMappingQuery } from '../../gql/generated';
import Error from '../../pages/ErrorPage';
import useStores from '../../stores/useStores';

interface Props {
  children: ReactNode;
}

const GetSeriesMappingData = ({ children }: Props) => {
  const {
    seriesSettingsStore: { seriesMappingMapData, setSeriesMapping, isSeriesMappingLoaded, setSeriesSortingList },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { data, loading, error } = useGetSeriesMappingQuery({
    variables: { brand },
    skip: isSeriesMappingLoaded(brand),
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    const seriesMappingsData = data.seriesMappingData.seriesMappings as SeriesMapping[];
    const seriesSortingData = data.seriesMappingData.seriesSorting as SeriesSortingResponse;

    seriesMappingMapData.set(`${brand}`, seriesMappingsData);
    setSeriesMapping(seriesMappingsData);
    // setSeriesSortingList(removeDuplicates(seriesMappingsData.map((series, i) => ({ id: series.id, sortOrder: i, bosuSeries: series.bosuSeries }))));
    setSeriesSortingList(seriesSortingData.sortList as SeriesSortItem[], seriesSortingData.rev ?? '');
  }

  return <>{children}</>;
};

export default GetSeriesMappingData;
