import { AdminModels } from 'oat-admin-common';
import styles from '../../../styles.module.scss';
import GridWrapper from '../GridWrapper';
import OtherItemRowDetail from './OtherItemRowDetail';
import OtherItemRowTotalDetail from './OtherItemRowTotalDetail';

interface Props {
  fiscalMonths: AdminModels.FiscalMonth[];
  multiSeriesCosts: { [month: number]: AdminModels.FYMultiSeriesItem };
}

const Other = ({ fiscalMonths, multiSeriesCosts }: Props) => {
  const total = Object.values(multiSeriesCosts).reduce((acc, curr) => acc + curr.totalCost, 0);

  return (
    <GridWrapper>
      <div className={styles.fySeriesBoxHeader}>Other</div>
      {fiscalMonths.map((fiscalMonth, i) => (
        <OtherItemRowDetail key={`${fiscalMonth.month}-${i}`} multiSeriesCostItem={multiSeriesCosts[fiscalMonth.month]} />
      ))}
      <OtherItemRowTotalDetail multiSeriesTotal={total} />
    </GridWrapper>
  );
};

export default Other;
