import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignNumberValue, defaultStringToUndefined } from 'oat-common-ui';
import { Offer, SelectedTierTerm } from '../../../../gql/generated';
import TierTermModel from '../../offers/AprSection/models/TierTermModel';

const { SYSTEM_TO_PAY } = AdminConstants;

class AprSetupModel {
  uid = '';
  id = '';
  rev = '';

  tierTerms: TierTermModel[] = [];
  subCashAmount = 0;
  subventionCashSystemToPay: string = SYSTEM_TO_PAY.IPLUS;
  selectedTierTerms: SelectedTierTerm[] = [];
  offerPnvs = 0;
  costShareId?: string = undefined;

  constructor(offer: Offer, offerUid: string) {
    makeAutoObservable(this);

    this.uid = offerUid;
    this.id = offer.id;
    this.rev = offer.rev;
    this.costShareId = defaultStringToUndefined(offer.aprDetails?.costShareId);

    this.offerPnvs = assignNumberValue(offer.aprDetails?.offerPnvs);
    if (offer.aprDetails) {
      const { subCashAmount, tierTerms, subventionCashSystemToPay, selectedTierTerms } = offer.aprDetails;

      this.subCashAmount = subCashAmount;
      this.tierTerms = tierTerms.map(t => new TierTermModel(t));
      this.selectedTierTerms = selectedTierTerms || [];

      this.subventionCashSystemToPay = subventionCashSystemToPay || SYSTEM_TO_PAY.IPLUS;
    }
  }

  deleteRatesByTerm = (term: number) => {
    this.selectedTierTerms = this.selectedTierTerms.filter(item => item.term !== term);
  };

  deleteRatesByTier = (tier: string) => {
    this.selectedTierTerms = this.selectedTierTerms.filter(item => item.tier !== tier);
  };

  updateSubventionCashSystemToPay = (val: string) => {
    this.subventionCashSystemToPay = val;
  };

  updateRev = (rev: string) => {
    this.rev = rev;
  };

  get selectedTierTermsError() {
    return !this.selectedTierTerms.length;
  }
}

export default AprSetupModel;
