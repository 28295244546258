import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import NGCHeader from '../../components/NGCHeader';
import { usePublishOfferingMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import { appendTfsLabel, appendTmsLabel } from '../../utils/appendTfsLabel';
import SeriesProfileSection from './SeriesProfileSection';
import NumberStack from './components/NumberStack';
import styles from './styles.module.scss';

const NationalProgramsComponent = () => {
  const {
    nationalProgramsStore: { offering, seriesProfiles, showPublishCta, showAtcPublishToBudgetCta, showPublishToRegionsCta, updateOfferingRev, offeringDisplayName },
    offeringCostsStore: { offeringCosts, toggleShowTfsEnhanced, showTfsEnhanced },
    userInfoStore: { isLexus },
    globalStore: { setShowProgressBar },
  } = useStores();

  const { sales, budgetedPnvs, pnvs, cost, variance, tfsCost, enhancedTfsCost, pnvsDifference } = offeringCosts;

  const { error } = useToast();
  const history = useHistory();
  const [publishOffering] = usePublishOfferingMutation();

  const publishOfferingHandler = async () => {
    try {
      setShowProgressBar(true);
      const res = await trackPromise(
        publishOffering({
          variables: {
            input: {
              offeringId: offering.id,
              rev: offering.rev,
            },
          },
        }),
      );

      if (res.data?.publishOffering) {
        updateOfferingRev(res.data.publishOffering.rev);
      }

      history.push('/dashboard');
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowProgressBar(false);
    }
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <NGCHeader
          title={offeringDisplayName}
          isNationalPrograms
          boxShadow
          rightContent={
            <>
              <NumberStack value={sales} label="Volume" dollarSign={false} />
              <NumberStack value={budgetedPnvs} label="Budgeted" sup={true} />
              <NumberStack value={pnvs} label="PNVS" sup={true} />
              <NumberStack value={pnvsDifference} label="Difference" useColors rightBorder sup={true} />
              <NumberStack value={cost} label={appendTmsLabel(isLexus(), 'Cost', '')} useMillions={Math.abs(cost) >= 100000} roundMillions sup={true} />
              <NumberStack value={variance} label="Variance" useColors opositeColors useMillions={Math.abs(variance) >= 100000} roundMillions sup={true} />
              <NumberStack
                classNameLabel={showTfsEnhanced ? styles.enhancedTfsLabel : undefined}
                label={appendTfsLabel(isLexus(), 'Cost', showTfsEnhanced ? 'Enh. ' : '')}
                onToggle={toggleShowTfsEnhanced}
                roundMillions
                toggleSelected={showTfsEnhanced}
                useMillions={showTfsEnhanced ? Math.abs(enhancedTfsCost) >= 100000 : Math.abs(tfsCost) >= 100000}
                value={showTfsEnhanced ? enhancedTfsCost : tfsCost}
                sup={true}
              />
            </>
          }
        />
        {(showPublishCta || showAtcPublishToBudgetCta) && (
          <div className={styles.publishCtaWrapper}>
            <Button id="publish-cta" className={clsx(styles.publishCta, showAtcPublishToBudgetCta && styles.atcPublishCta)} color="green" onClick={publishOfferingHandler}>
              <OATIcon icon="checkmark" className={styles.icon} />
              {`Publish ${showAtcPublishToBudgetCta ? 'to Budget' : ''}`}
            </Button>
          </div>
        )}
        {showPublishToRegionsCta && (
          <div className={styles.publishCtaWrapper}>
            <Button id="publish-regions-cta" className={styles.atcPublishRegionCta} onClick={publishOfferingHandler} color="blue">
              <OATIcon icon="checkmark" className={styles.icon} />
              {`Publish to ${!isLexus() ? 'Region' : 'Areas'}`}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.content}>
        {seriesProfiles.map((sp, index) => {
          return <SeriesProfileSection key={sp.data.id} seriesProfile={sp} zIndex={seriesProfiles.length - index} />;
        })}
      </div>
    </>
  );
};

export default observer(NationalProgramsComponent);
