import { observer } from 'mobx-react-lite';
import { AdminModels, logger, recalculateFYRollup } from 'oat-admin-common';
import { Button, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import apolloClient from '../../../../../apolloClient';
import { FYROLLUP, FYROLLUP_BUTTON_TEXT } from '../../../../../constants/global';
import {
  GetBosuDataForFiscalYearDocument,
  GetBudgetDataByOfferingsDocument,
  GetBudgetDataDocument,
  RecalculateWorkingBudgetsInput,
  SaveBudgetDataInput,
  useRecalculateWorkingBudgetMutation,
  useSaveBudgetDataMutation,
} from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { formatBudgetData } from '../../../utils';
import TypeaheadSearch from '../../TypeaheadSearch';
import FiscalYearNav from './components/FiscalYearNav';
import NavButton from './components/NavButton';
import styles from './styles.module.scss';
import applyBudgetAttributes from './utils/applyBudgetAttributes';
import applyMultiSeriesAttributes from './utils/applyMultiSeriesAttributes';
import { createSaveBudgetPayload } from './utils/createSaveBudgetPayload';

interface Props {
  hasPnvsFromForecasted?: boolean;
  hasSalesFromForecasted?: boolean;
  hasImportSales?: boolean;
  hasRecalculate?: boolean;
  hasPnvsFromWorking?: boolean;
  hasSalesFromWorking?: boolean;
  hasMultiSeriesOffers?: boolean;
}

const NavBar = ({ hasPnvsFromForecasted, hasSalesFromForecasted, hasImportSales, hasRecalculate, hasPnvsFromWorking, hasSalesFromWorking, hasMultiSeriesOffers }: Props) => {
  const {
    fyRollupStore: {
      buttonText,
      isEdit,
      searchValue,
      setSearchValue,
      expandAll,
      toggleExpandAll,
      fySection,
      setData,
      setIsEdit,
      initEnterFYSection,
      fiscalYear,
      seriesList,
      setHasUnsavedChanges,
      getFYRollup,
      fyMultiSeries,
      isRecalculateWorkingBudget,
    },
    userInfoStore: {
      userInfo: { brand },
    },
    seriesSettingsStore: { seriesMapping },
  } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const [saveBudgetData] = useSaveBudgetDataMutation();
  const [recalculateWorkingBudget] = useRecalculateWorkingBudgetMutation();

  const toNewFiscalYear = (toNext: boolean) => {
    const fiscalYearParam = toNext ? fiscalYear + 1 : fiscalYear - 1;

    setDelay(async () => {
      try {
        const res = await trackPromise(
          apolloClient.query({
            query: GetBudgetDataDocument,
            variables: {
              brand,
              type: fySection,
              fiscalYear: fiscalYearParam.toString(),
            },
          }),
        );

        if (!res?.data?.budgetData?.months.length) {
          error('No budget data found for this fiscal year.');
          return;
        }

        setData(formatBudgetData(res.data.budgetData as AdminModels.BudgetResponse), seriesMapping, !!res.data.budgetData.recalculateWorkingBudget);
        initEnterFYSection();
      } catch (e) {
        error((e as Error).message);
      }
    }, 300);
  };

  const handleImportSales = async () => {
    try {
      const res = await trackPromise(
        apolloClient.query({
          query: GetBosuDataForFiscalYearDocument,
          variables: {
            brand,
            fiscalYear: fiscalYear.toString(),
          },
        }),
      );

      const bosuBudgetRollup = formatBudgetData(res.data.getBosuDataForFiscalYear as AdminModels.BudgetResponse);
      // apply the budget attribute 'sales' from bosu data to fyrollup data
      const currentFyRollupData = getFYRollup();
      applyBudgetAttributes(bosuBudgetRollup, currentFyRollupData, ['sales', 'stock'], true);
      // recalculate the current fyrollup
      recalculateFYRollup(currentFyRollupData);
      // set the updated data to store
      setData(currentFyRollupData, seriesMapping);
      setHasUnsavedChanges(true);
    } catch (e) {
      logger.log(e);
      error((e as Error).message);
    }
  };

  const syncValuesFromOtherBudget = async (keys: Array<keyof AdminModels.Total>, budgetType: FYROLLUP, syncMultiSeriesCost = false) => {
    const isSyncPnvsFromWorking = keys.length === 1 && keys[0] === 'pnvs' && budgetType === FYROLLUP.WORKING;

    try {
      const res = await trackPromise(
        apolloClient.query({
          query: !isSyncPnvsFromWorking ? GetBudgetDataDocument : GetBudgetDataByOfferingsDocument,
          // OR-2979 - sync pnvs from working should fetch data for months only where IP exists
          variables: {
            brand,
            type: budgetType,
            fiscalYear: fiscalYear.toString(),
          },
        }),
      );
      // OR-3929 only use case where series with 0 sales are not removed, so that the sync process can 0 them out
      const sourceBudgetData = formatBudgetData((!isSyncPnvsFromWorking ? res.data.budgetData : res.data.getBudgetDataByOfferings) as AdminModels.BudgetResponse, !isSyncPnvsFromWorking);
      // apply the budget attribute 'sales' from bosu data to fyrollup data
      const currentFyRollupData = getFYRollup();
      if (keys.length) {
        applyBudgetAttributes(sourceBudgetData, currentFyRollupData, keys, !isSyncPnvsFromWorking);
      }
      // apply multi series costs
      if (syncMultiSeriesCost) {
        applyMultiSeriesAttributes(currentFyRollupData, sourceBudgetData.fyMultiSeries);
      }
      // recalculate the current fyrollup
      recalculateFYRollup(currentFyRollupData);
      // set the updated data to store
      setData(currentFyRollupData, seriesMapping);
      setHasUnsavedChanges(true);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveBudget = async () => {
    const input: SaveBudgetDataInput = {
      rev: '',
      brand,
      fiscalYear,
      type: fySection,
      months: createSaveBudgetPayload(seriesList, fyMultiSeries),
    };

    try {
      await trackPromise(saveBudgetData({ variables: { input } }));
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleQueryWorkingBudget = async () => {
    try {
      const res = await trackPromise(
        apolloClient.query({
          query: GetBudgetDataDocument,
          variables: {
            brand,
            type: FYROLLUP.WORKING,
            fiscalYear: fiscalYear.toString(),
          },
        }),
      );
      const workingBudgetResponse = res.data.budgetData;
      setData(formatBudgetData(workingBudgetResponse), seriesMapping, !!workingBudgetResponse.recalculateWorkingBudget);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleRecalculate = async () => {
    try {
      const input: RecalculateWorkingBudgetsInput = { brand };
      const res = await trackPromise(recalculateWorkingBudget({ variables: { input } }));

      if (res.data?.recalculateWorkingBudgets.success) {
        handleQueryWorkingBudget();
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleToggle = () => {
    if (isEdit && buttonText === FYROLLUP_BUTTON_TEXT.SAVE) {
      handleSaveBudget();
    }

    setHasUnsavedChanges(false);
    setIsEdit(!isEdit);
  };

  const recalcIsDisabled = isEdit || !isRecalculateWorkingBudget;

  return (
    <div className={styles.navBar}>
      <div className={styles.navContent}>
        <div className={styles.navLeft}>
          <NavButton
            id="expand-all-btn"
            parentClass={styles.expandButton}
            textClass={styles.expandText}
            iconId={!expandAll ? 'angle-down' : 'angle-up'}
            buttonText="Expand all"
            onClickHandler={() => toggleExpandAll()}
          />
          <Button
            id={`budget-${buttonText}-btn`}
            variant="text"
            className={!isEdit ? styles.editButton : styles.saveChangesButton}
            onClick={() => {
              handleToggle();
            }}
          >
            {buttonText}
          </Button>

          {hasPnvsFromForecasted && (
            <NavButton
              id="sync-pnvs-forecasted-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="reset"
              buttonText="Sync PNVS from Forecasted"
              onClickHandler={() => syncValuesFromOtherBudget(['pnvs'], FYROLLUP.FORECASTED, true)}
            />
          )}
          {hasPnvsFromWorking && (
            <NavButton
              id="sync-pnvs-working-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="reset"
              buttonText="Sync PNVS from Working"
              onClickHandler={() => syncValuesFromOtherBudget(['pnvs'], FYROLLUP.WORKING)}
            />
          )}
          {hasSalesFromForecasted && (
            <NavButton
              id="sync-sales-forecasted-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="reset"
              buttonText="Sync Sales from Forecasted"
              onClickHandler={() => syncValuesFromOtherBudget(['sales', 'stock'], FYROLLUP.FORECASTED)}
            />
          )}
          {hasSalesFromWorking && (
            <NavButton
              id="sync-sales-working-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="reset"
              buttonText="Sync Sales from Working"
              onClickHandler={() => syncValuesFromOtherBudget(['sales', 'stock'], FYROLLUP.WORKING)}
            />
          )}
          {hasMultiSeriesOffers && (
            <NavButton
              id="multi-series-offers-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="reset"
              buttonText="Sync Multi-Series Offers"
              onClickHandler={() => syncValuesFromOtherBudget([], FYROLLUP.WORKING, true)} // OR-1477 Only syncing multi series from working (as working will be updated)
            />
          )}
          {hasImportSales && (
            <NavButton
              id="import-sales-btn"
              disabled={!isEdit}
              parentClass={isEdit ? styles.enabled : ''}
              iconId="export"
              buttonText="Import Sales"
              onClickHandler={() => handleImportSales()}
            />
          )}
          {hasRecalculate && (
            <NavButton
              id="recalculate-btn"
              disabled={recalcIsDisabled}
              parentClass={!recalcIsDisabled ? styles.enabled : ''}
              iconId="reset"
              buttonText="Recalculate"
              onClickHandler={() => handleRecalculate()}
            />
          )}
        </div>

        <div className={styles.navRight}>
          <TypeaheadSearch
            options={seriesList || []}
            labelKey="displayName"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            fySection={fySection}
            fiscalYear={fiscalYear}
          />
          <FiscalYearNav label="Fiscal Year:" toPrevFiscalYear={toNewFiscalYear} toNextFiscalYear={toNewFiscalYear} />
        </div>
      </div>
    </div>
  );
};

export default observer(NavBar);
