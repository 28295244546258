import { Button, OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  handleAddSeries: () => void;
  isDisabled: boolean;
}

const AddSeriesButton = ({ handleAddSeries, isDisabled }: Props) => {
  return (
    <Button variant="primary" id="add-series-btn" onClick={() => handleAddSeries()} className={styles.addSeriesBtn} disabled={isDisabled}>
      <OATIcon icon="plus" />
      <span className={styles.btnText}>Add Series</span>
    </Button>
  );
};

export default AddSeriesButton;
