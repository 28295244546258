import { MultiSeriesField, UpdateMultiSeriesValuesInput } from '../../../../../gql/generated';
import SeriesProfileModel from '../../../models/SeriesProfileModel';

export const createMultiSeriesInput = (seriesProfile: SeriesProfileModel, field: MultiSeriesField, value: number): UpdateMultiSeriesValuesInput => {
  return {
    seriesProfileId: seriesProfile.id,
    multiSeriesOfferId: seriesProfile.data.multiSeriesOfferId || '',
    field,
    value,
    rev: seriesProfile.data.rev,
  };
};
