import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import useStores from '../../../../stores/useStores';
import InputsWrapper from '../components/InputsWrapper';
import SectionLabel from '../components/SectionLabel';
import ShowAdvancedSettingsButton from '../components/ShowAdvancedSettingsButton';
import StandardRcfValue from '../components/StandardRcfValue';
import ChangeInRcfInput from './components/ChangeInRcfInput';
import StandardRcfTable from './components/StandardRcfTable';

const NationalLeaseStandardRcf = () => {
  const {
    ratesTabLeaseRCFStore: { leaseStandardRcf },
  } = useStores();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  return (
    <>
      <SectionLabel label="National Lease Standard RCF" />
      <InputsWrapper>
        <ChangeInRcfInput />
        <StandardRcfValue label="National Lease Standard RCF" rcf={leaseStandardRcf} />
        <ShowAdvancedSettingsButton
          id="ntl-std-rcf-show-advanced-settings-btn"
          showAdvancedSettings={showAdvancedSettings}
          onClickHandler={() => setShowAdvancedSettings(!showAdvancedSettings)}
        />
      </InputsWrapper>
      <InputsWrapper>{showAdvancedSettings && <StandardRcfTable />}</InputsWrapper>
    </>
  );
};

export default observer(NationalLeaseStandardRcf);
