import { OATIcon } from 'oat-common-ui';
import Button from '../../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  id: string;
  onClickHandler: (show: boolean) => void;
  showAdvancedSettings: boolean;
}

const ShowAdvancedSettingsButton = ({ id, onClickHandler, showAdvancedSettings }: Props) => {
  return (
    <Button id={id} variant="text" onClick={() => onClickHandler(!showAdvancedSettings)} className={styles.showAdvancedSettingsBtn}>
      Show Advanced Settings
      <OATIcon icon={showAdvancedSettings ? 'caret-up' : 'caret-down'} />
    </Button>
  );
};

export default ShowAdvancedSettingsButton;
