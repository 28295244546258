import { observer } from 'mobx-react-lite';
import { Dropdown, DropdownItem, OATIcon, sortFieldsHelper, useToast } from 'oat-common-ui';
import useStores from '../../../../stores/useStores';
import useFetchReports from '../useFetchReports';
import styles from './styles.module.scss';

interface Props {
  reports: { label: string; value: string }[];
}

const NationalPlanningReports = ({ reports }: Props) => {
  const {
    dashboardStore: { active, inactive, getOfferingDisplayName },
    reportsStore: { fiscalYear, selectedOfferingData, setSelectedOfferingData, setSelectedReport },
  } = useStores();
  const { error } = useToast();
  const { fetchReports } = useFetchReports();

  const getNPDropdownOptions = () => {
    const options: DropdownItem[] = [];

    // sort ATC below parent IP
    const sortedActive = active.slice().sort(sortFieldsHelper('isAtc', true)).sort(sortFieldsHelper('startDate', false));
    const sortedInactive = inactive.slice().sort(sortFieldsHelper('isAtc', true)).sort(sortFieldsHelper('startDate', false));

    [...sortedActive, ...sortedInactive].forEach(offering => {
      options.push({ label: getOfferingDisplayName(offering), value: offering.id });
    });

    return options;
  };

  const handleOfferingChange = async (item: DropdownItem) => {
    setSelectedOfferingData(item);

    try {
      await fetchReports(+fiscalYear, item.value);
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <Dropdown id="np-reports" value={selectedOfferingData.label} options={getNPDropdownOptions()} onChange={handleOfferingChange} darkTheme />
      <ul className={styles.reportsList}>
        {reports.map(report => {
          return (
            <li className={styles.singleReport} key={report.label}>
              <button id={`report-${report}`} onClick={() => setSelectedReport(report)}>
                <OATIcon icon="notes" className={styles.reportIcon} />
                <span>{report.label}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default observer(NationalPlanningReports);
