import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.scss';

interface Props {
  hideTier7Plus?: boolean;
}

const TiersRow = ({ hideTier7Plus }: Props) => {
  const tiers = hideTier7Plus ? ['1+', '1', '2', '3', '4', '5', '6', '7'] : ['1+', '1', '2', '3', '4', '5', '6', '7', '7+'];

  return (
    <div className={styles.tiersRow}>
      {tiers.map(tier => {
        return (
          <div key={tier} className={clsx(styles.tierItem, styles.labelText, hideTier7Plus ? styles.tfsToyotaWidth : styles.tfsLexusWidth)}>
            Tier {tier}
          </div>
        );
      })}
    </div>
  );
};

export default observer(TiersRow);
