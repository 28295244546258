import { makeAutoObservable } from 'mobx';
import { Offer, RgnlAltSummary, SaveSeriesProfileVehiclesResult, Scenario, ScenarioCosts, SeriesMapping, SeriesProfile, Vehicle } from '../../../gql/generated';
import ScenarioModel from './ScenarioModel';

export type PreviousMonthScenarioData = {
  seriesProfile: SeriesProfile;
  scenarioCost: ScenarioCosts;
  scenario: Scenario;
  rgnlAlts: RgnlAltSummary[];
};

class SeriesProfileModel {
  id = '';
  region = '';
  series = '';
  displayName = '';
  data: SeriesProfile = {
    brand: '',
    created: '',
    createdBy: '',
    id: '',
    multiSeriesOfferId: '',
    isBudgetConfirmed: false,
    isSetupConfirmed: false,
    modelYear: 0,
    ncsRate: 0,
    ncsRcf: 0,
    offeringId: '',
    rev: '',
    sales: 0,
    series: '',
    stock: 0,
    updated: '',
    updatedBy: '',
    vehicles: [],
    hasModelCodeUpdates: false,
    hasOffersForRegions: false,
    isCostShareUpdated: false,
  };
  scenarios: ScenarioModel[] = [];
  tempScenarios: ScenarioModel[] = [];
  selectedScenario: ScenarioModel | undefined;
  isOverridableBudgetError = false;
  isSetupView = false;
  isSaving = false;
  showCompare = false;
  budgetConfirmErrors = '';
  setupConfirmErrors = '';
  isLastMonthComparison = false;
  previousMonthScenarioData: PreviousMonthScenarioData | undefined = undefined;

  exportTo = false;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (data: SeriesProfile, brand: string, seriesMapping: SeriesMapping[]) => {
    const { scenarios, ...sp } = data;
    this.data = sp;
    this.data.brand = brand;
    this.id = data.id;
    this.series = data.series;
    this.data.isCostShareUpdated = data.isCostShareUpdated;

    this.displayName = seriesMapping.find(sm => sm.bosuSeries === sp.series)?.displayName || data.series;

    this.scenarios =
      scenarios?.map((scenarioItem, i) => {
        const scenario = new ScenarioModel(this.data.brand);
        scenario.setData(scenarioItem as Scenario);
        scenario.sortOrder = i;

        if (scenario.isSelected) {
          this.selectedScenario = scenario;
          scenario.compareSelected = true;
          scenario.exportSelected = true;
        }

        return scenario;
      }) || [];

    this.tempScenarios = this.scenarios;
  };

  updateDataField = <T extends keyof SeriesProfile, V extends SeriesProfile[T]>(field: T, value: V) => {
    this.data[field] = value;
  };

  setErrors = (value: string[]) => (this.budgetConfirmErrors = value.join(', '));
  clearErrors = () => this.setErrors([]);
  setCofirmSetupErrors = (errors: string[]) => (this.setupConfirmErrors = errors.join(', '));
  setIsOverridableBudgetError = (value: boolean) => (this.isOverridableBudgetError = value);

  setRev = (v: string) => (this.data.rev = v);
  setIsSaving = (v: boolean) => (this.isSaving = v);

  /**
   * Used when clicking series profile name to toggle scenario expanded
   */
  toggleScenario = () => {
    if (this.selectedScenario) {
      this.selectedScenario.activated = true;
      this.selectedScenario.expanded = !this.selectedScenario.expanded;
    }
  };

  updateSelectedScenario = (val: boolean) => {
    if (this.selectedScenario) {
      this.selectedScenario.activated = val;
      this.selectedScenario.expanded = val;
    }
  };

  toggleIsSetupView = () => {
    this.isSetupView = !this.isSetupView;

    if (this.isSetupView) {
      this.updateSelectedScenario(true);
      return;
    }

    this.updateSelectedScenario(false);
  };

  toggleIsBudgetConfirmed = async () => {
    this.data.isBudgetConfirmed = !this.data.isBudgetConfirmed;

    if (!this.data.isBudgetConfirmed) {
      this.data.isSetupConfirmed = false;
    }
  };

  toggleIsSetupConfirmed = () => {
    this.data.isSetupConfirmed = !this.data.isSetupConfirmed;
  };

  toggleShowCompare = () => {
    this.showCompare = !this.showCompare;

    if (this.showCompare) {
      this.scenarios.forEach(scenario => {
        scenario.compareSelected = scenario.isSelected;
      });
    }
  };

  toggleExportTo = () => {
    this.exportTo = !this.exportTo;
  };

  setExportTo = (val: boolean) => {
    this.exportTo = val;
  };

  resetExportScenarios = () => {
    this.scenarios.forEach(s => (s.exportSelected = !!s.isSelected));
  };

  selectScenario = (id: string, rev?: string) => {
    this.scenarios.forEach(item => {
      item.isSelected = item.id === id;

      if (item.isSelected) {
        item.activated = true;
        item.expanded = !item.expanded;
        item.exportSelected = true;
        this.selectedScenario = item;
        item.rgnlAlts.forEach(rgAl => (rgAl.expanded = false));

        if (rev) {
          item.rev = rev;
        }
      } else {
        item.activated = false;
        item.expanded = false;
        item.exportSelected = false;
      }
    });
  };

  updateSelectScenarioRevs = (newScenarioId: string, newScenarioRev: string, oldScenarioId: string, oldScenarioRev: string) => {
    this.scenarios.forEach(item => {
      if (item.id === newScenarioId) {
        item.rev = newScenarioRev;
      }

      if (item.id === oldScenarioId) {
        item.rev = oldScenarioRev;
      }
    });
  };

  addScenario = (scenario: Scenario) => {
    this.scenarios.push(new ScenarioModel(this.data.brand, scenario));
    this.tempScenarios = this.scenarios;
  };

  removeScenario = (id: string) => {
    this.scenarios = this.scenarios.filter(scenario => scenario.id !== id);
    this.tempScenarios = this.scenarios;
  };

  renameScenario = (scenario: Scenario) => {
    this.scenarios.forEach(s => {
      if (s.id === scenario.id) {
        s.name = scenario.name;
        s.rev = scenario.rev;
      }
    });
    this.tempScenarios = this.scenarios;
  };

  setIsLastMonthComparison = (isChecked: boolean) => {
    this.isLastMonthComparison = isChecked;
  };

  get compareScenarios() {
    return this.scenarios.filter(item => item.compareSelected);
  }

  setPreviousMonthScenarioData = (data?: PreviousMonthScenarioData) => {
    this.previousMonthScenarioData = data;
  };

  canConfirm = () => {
    let canConfirm = true;

    if (this.selectedScenario) {
      this.selectedScenario.rgnlAlts.forEach(ra => {
        if (ra.hasError()) {
          canConfirm = false;
        } else {
          ra.offers.forEach(offer => {
            if (
              Boolean(offer.aprDetails && !offer.aprDetails.hasNoErrors()) ||
              Boolean(offer.leaseDetails && offer.leaseDetails.hasError()) ||
              Boolean(offer.cashDetails && offer.cashDetails.hasError()) ||
              Boolean(offer.miscDetails && offer.miscDetails.hasError()) ||
              Boolean(offer.nationalRyoDetails && offer.nationalRyoDetails.hasError()) ||
              Boolean(offer.finalPayDetails && offer.finalPayDetails.hasError())
            ) {
              canConfirm = false;
            }
          });
        }
      });
    }

    return canConfirm;
  };

  getPublishedScenario = (): ScenarioModel | undefined => {
    return this.scenarios.find(scenario => scenario.isPublished);
  };

  updateSetupOfferVehicles = (spVehicles: SaveSeriesProfileVehiclesResult) => {
    this.data.vehicles = spVehicles.vehicles as Vehicle[];
    const offerMap = new Map<string, Offer>((spVehicles.updatedOffers as Offer[]).map(offer => [offer.id, offer]));

    this.scenarios.forEach(scenario => {
      scenario.rgnlAlts.forEach(ra => {
        ra.setupOffers.forEach(offer => {
          const foundOffer = offerMap.get(offer.id);
          if (foundOffer) {
            offer.setRevAndId(foundOffer);
            offer.fields.vehicles = foundOffer.vehicles as Vehicle[];
          }
        });
      });
    });
  };

  sortScenariosList = (scenarios: ScenarioModel[]) => {
    const getScenarioIndex = (scenario: ScenarioModel) => scenarios.findIndex(({ id }) => id === scenario.id);
    this.scenarios = this.scenarios.sort((scenarioA, scenarioB) => getScenarioIndex(scenarioA) - getScenarioIndex(scenarioB));
  };

  setTempScenariosList = (scenarios: ScenarioModel[]) => {
    this.tempScenarios = scenarios;
  };

  get compareSelected() {
    return this.scenarios.filter(item => item.compareSelected);
  }

  get isConfirmSetupValid() {
    let canConfirm = !this.data.hasModelCodeUpdates;

    this.selectedScenario?.rgnlAlts.forEach(ra => {
      if (ra.setupOffers.length) {
        if (ra.getSetupError()) {
          canConfirm = false;
        } else {
          ra.setupOffers.forEach(offer => {
            if (offer.hasError()) {
              canConfirm = false;
            }
          });
        }
      }
    });
    return canConfirm;
  }

  get isConfirmed() {
    return this.data.isBudgetConfirmed && this.data.isSetupConfirmed;
  }

  get isCompareSelectedValid() {
    return (
      (this.compareScenarios.length === 2 && !this.isLastMonthComparison) || (this.compareScenarios.length === 1 && this.isLastMonthComparison && this.previousMonthScenarioData)
    );
  }

  get hasOfferWithUpdatedCostShare() {
    if (this.selectedScenario?.rgnlAlts.length === 0) return null;

    return this.selectedScenario?.rgnlAlts.some(ra =>
      ra.offers.some(offer => {
        return offer.leaseDetails?.forms.some(form => form.leaseFields.isCostShareUpdated) || offer.aprDetails?.offerFields.isCostShareUpdated;
      }),
    );
  }
}

export default SeriesProfileModel;
