import { OATLoadingBar } from 'oat-common-ui';
import { useGetPnvsTresholdQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import PNVSThresholdSettingsComponent from './PNVSThresholdSettingsComponent';
import Error from '../../ErrorPage';

const PNVSThresholdSettings = () => {
  const {
    pnvsTresholdStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { data, loading, error } = useGetPnvsTresholdQuery({
    variables: {
      brand,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data && data.queryPNVSThreshold) {
    pnvsTresholdStore.setPNVSTreshold(data.queryPNVSThreshold);
  }

  return <PNVSThresholdSettingsComponent />;
};

export default PNVSThresholdSettings;
