import { makeAutoObservable } from 'mobx';
import { OfferingStandardRates } from '../gql/generated';
import AprRateModel from '../pages/IpSettings/RatesTab/models/AprRateModel';

class RatesTabAPRRatesStore {
  id = '';
  rev = '';
  aprDifferential = 0;
  aprRates: AprRateModel[] = [];
  aprHasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateAprDifferential = (val: number) => {
    this.aprDifferential = val;
    this.aprHasUnsavedChanges = true;
  };

  updateAprRates = (val: number) => {
    this.aprRates = this.aprRates.map(rate => {
      rate.differential = val;
      rate.rate = val + rate.previousRate;
      return rate;
    });
  };

  validateAprRates = () => {
    return new Set(this.aprRates.map(rate => rate.differential)).size === 1;
  };

  updateAprRate = (uid: string, tier: string, term: number, rate: number) => {
    const foundRate = this.aprRates.find(aprRate => aprRate.uid === uid && aprRate.tier === tier && aprRate.term === term);

    if (foundRate) {
      foundRate.differential = rate;
      foundRate.rate = foundRate.previousRate + rate;
      this.aprDifferential = this.validateAprRates() ? rate : 0;
    }

    this.aprHasUnsavedChanges = true;
  };

  setAprHasUnsavedChanges = (val: boolean) => {
    this.aprHasUnsavedChanges = val;
  };

  setData = (data: OfferingStandardRates) => {
    this.id = data.id || '';
    this.rev = data.rev || '';
    this.aprDifferential = data.aprDifferential;
    this.aprRates = data.aprRates.map(rate => new AprRateModel(rate));
  };
}

export default RatesTabAPRRatesStore;
