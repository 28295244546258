import { InlineInputLabel, Input } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  text: string;
  checked: boolean;
  handleChange: () => void;
}

const InlineCheckBox = ({ text, checked, handleChange }: Props) => {
  const handleChecked = () => {
    handleChange();
  };

  return (
    <InlineInputLabel
      vertical
      className={styles.containerClass}
      inputWrapperClass={styles.containerClass}
      contentClass={styles.contentClass}
      labelComponent={<Input type="checkbox" className={styles.checkbox} checked={checked} onChange={handleChecked} />}
    >
      <span className={styles.inlineText} onClick={() => handleChecked()}>
        {text}
      </span>
    </InlineInputLabel>
  );
};

export default InlineCheckBox;
