import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';

import { observer } from 'mobx-react-lite';
import { formatDollars, formatRcf } from 'oat-common-ui';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../utils/appendTfsLabel';
import { getFormattedRate } from '../../../utils/getFormattedRate';
import SetupConfirmErrors from '../../SetupConfirmErrors';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

interface Props {
  offerSetup: OfferSetupModel;
}

const OfferSetupTitle = ({ offerSetup }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const {
    fields: { optionType },
    setupConfirmErrors,
  } = offerSetup;

  const DetailsByType = ({ optionType }: { optionType: string }) => {
    switch (optionType) {
      case OPTION_TYPE_NAMES.APR:
        const aprDetails = offerSetup.aprSetupDetails;
        const tierTerm60 = aprDetails?.tierTerms.find(t => t.tier === '1+' && t.term === 60);
        const tierTerm72 = aprDetails?.tierTerms.find(t => t.tier === '1+' && t.term === 72);

        return (
          <>
            <span className={styles.titleDetails}>
              <span className={styles.titleDetailsItem}>{`60 mo. ${getFormattedRate(tierTerm60?.rate)}%`}</span>
            </span>
            <span className={styles.titleDetails}>
              <span className={styles.titleDetailsItem}>{`72 mo. ${getFormattedRate(tierTerm72?.rate)}%`}</span>            </span>
            {aprDetails && aprDetails.subCashAmount > 0 && (
              <span className={styles.titleDetails}>
                <span className={styles.titleDetailsItem}>{appendTfsLabel(isLexus(), `Subvention Cash: $${formatDollars(aprDetails?.subCashAmount)}`)}</span>
              </span>
            )}
          </>
        );

      case OPTION_TYPE_NAMES.LEASE: {
        const details = offerSetup?.leaseSetupDetails;

        return (
          <>
            {details && (
              <span className={styles.titleDetails}>
                <span className={styles.titleDetailsItem}>RCF: {formatRcf(details.fields.rcf)}</span>
                <span className={styles.titleDetailsItem}>Annual Mileage: {details.fields.mileage}</span>
                <span className={styles.titleDetailsItem}>Term: {details.fields.term}</span>
                {details.fields.subventionCash > 0 && (
                  <span className={styles.titleDetailsItem}>{appendTfsLabel(isLexus(), `Subvention Cash: $${formatDollars(details.fields.subventionCash)}`)}</span>
                )}
              </span>
            )}
          </>
        );
      }
      default: {
        const details = offerSetup.cashSetupDetails || offerSetup.finalPaySetupDetails || offerSetup.miscSetupDetails || offerSetup.nationalRyoSetupDetails;

        return (
          <span className={styles.titleDetails}>
            <span className={styles.titleDetailsItem}>Amount: ${details?.amount}</span>
          </span>
        );
      }
    }
  };

  const getTheme = () => {
    switch (optionType) {
      case OPTION_TYPE_NAMES.LEASE: {
        return styles.lease;
      }
      case OPTION_TYPE_NAMES.APR: {
        return styles.apr;
      }
      case OPTION_TYPE_NAMES.CUSTOMER_CASH:
      case OPTION_TYPE_NAMES.NATIONAL_RYO: {
        return styles.cash;
      }
      default: {
        return styles.misc;
      }
    }
  };

  return (
    <div>
      <h3 className={clsx(styles.title, getTheme())}>{optionType}</h3>
      <DetailsByType optionType={optionType} />
      {setupConfirmErrors && <SetupConfirmErrors errors={setupConfirmErrors} />}
    </div>
  );
};

export default observer(OfferSetupTitle);
