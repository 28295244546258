import { createContext, useContext } from 'react';
import OfferingCostsStore from '../models/OfferingCostsStore';
import AssumptionsStore from './AssumptionsStore';
import BosuStore from './BosuStore';
import CostShareTabStore from './CostShareTabStore';
import DashboardStore from './DashboardStore';
import EFCStore from './EFCStore';
import FYRollupStore from './FYRollupStore';
import GlobalStore from './GlobalStore';
import IncentiveCalendarStore from './IncentiveCalendarStore';
import IpSettingsStore from './IpSettingsStore';
import MultiSeriesOffersBudgetStore from './MultiSeriesOffersBudgetStore';
import NationalCostShareStore from './NationalCostShareStore';
import NationalProgramsStore from './NationalProgramsStore';
import PNVSTresholdStore from './PNVSTresholdStore';
import RatesTabAPRRatesStore from './RatesTabAPRRatesStore';
import RatesTabLeaseRCFStore from './RatesTabLeaseRCFStore';
import RatesTabRVRateStore from './RatesTabRVRateStore';
import RatesTabTFSLeaseRCFStore from './RatesTabTFSLeaseRCFStore';
import RegionsStore from './RegionsStore';
import ReportsStore from './ReportsStore';
import SeriesAndModelsStore from './SeriesAndModelsStore';
import SeriesNameStore from './SeriesNameStore';
import SeriesSettingsStore from './SeriesSettingsStore';
import StateAprCapsStore from './StateAprCapsStore';
import UploadsTabStore from './UploadsTabStore';
import UserInfoStore from './UserInfoStore';

const storesContext = createContext({
  assumptionsStore: new AssumptionsStore(),
  bosuStore: new BosuStore(),
  costShareTabStore: new CostShareTabStore(),
  nationalCostShareStore: new NationalCostShareStore(),
  dashboardStore: new DashboardStore(),
  efcStore: new EFCStore(),
  fyRollupStore: new FYRollupStore(),
  globalStore: new GlobalStore(),
  incentiveCalendarStore: new IncentiveCalendarStore(),
  ipSettingsStore: new IpSettingsStore(),
  multiSeriesOffersBudgetStore: new MultiSeriesOffersBudgetStore(),
  nationalProgramsStore: new NationalProgramsStore(),
  offeringCostsStore: new OfferingCostsStore(),
  pnvsTresholdStore: new PNVSTresholdStore(),
  ratesTabAPRRatesStore: new RatesTabAPRRatesStore(),
  ratesTabLeaseRCFStore: new RatesTabLeaseRCFStore(),
  ratesTabRVRateStore: new RatesTabRVRateStore(),
  ratesTabTFSLeaseRCFStore: new RatesTabTFSLeaseRCFStore(),
  uploadsTabStore: new UploadsTabStore(),
  regionsStore: new RegionsStore(),
  reportsStore: new ReportsStore(),
  seriesAndModelsStore: new SeriesAndModelsStore(),
  seriesNameStore: new SeriesNameStore(),
  seriesSettingsStore: new SeriesSettingsStore(),
  stateAprCapsStore: new StateAprCapsStore(),
  userInfoStore: new UserInfoStore(),
});

const useStores = () => useContext(storesContext);

export default useStores;
