import { OATLoadingBar } from 'oat-common-ui';
import { ModelCodeMappingResponse, ModelCodesResponse, OfferingSeriesItem, SeriesMapping, SeriesProfile, useGetSeriesAndModelDataQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import SeriesAndModelsTabComponent from './SeriesAndModelsTabComponent';

const SeriesModelsTab = () => {
  const { offeringId } = useUrlParams();
  const {
    ipSettingsStore: { setOffering },
    seriesAndModelsStore: { setSeriesMapping, setAllModelCodes, setSeriesSelections, setModelCodeMappings, setMultiSeriesProfiles },
    userInfoStore: { userInfo: { brand } }
  } = useStores();

  const { data, loading, error } = useGetSeriesAndModelDataQuery({ variables: { brand, offeringId } });

  if (data) {
    setOffering(data.offeringData);
    setSeriesMapping(data.seriesMappingData.seriesMappings as SeriesMapping[]);
    setAllModelCodes(data.allModelCodes as ModelCodesResponse[]);
    setMultiSeriesProfiles(data.seriesSelectionsData.multiSeriesProfiles as SeriesProfile[]);
    setSeriesSelections(data.seriesSelectionsData.seriesList as OfferingSeriesItem[]);
    setModelCodeMappings(data.modelCodeMappingData as ModelCodeMappingResponse[]);
  }

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  return <SeriesAndModelsTabComponent />;
};

export default SeriesModelsTab;
