import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const SettingsTabWrapper = ({ children }: Props) => {
  return <div className={styles.tabWrapper}>{children}</div>;
};

export default SettingsTabWrapper;
