import styles from './styles.module.scss';

interface Props {
  title: string;
}

const ReportsHeadline = ({ title }: Props) => {
  return (
    <div className={styles.reportsHeadline}>
      <p>{title}</p>
    </div>
  );
};

export default ReportsHeadline;
