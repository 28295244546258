import { MonthFullNameNumberConstant } from 'oat-common-ui';
import { IncentiveCalendar, IncentiveCalendarMonth } from '../../../gql/generated';

export interface IncentiveCalendarLocal extends IncentiveCalendar {
  months: IncentiveCalendarMonthLocal[];
}

interface IncentiveCalendarMonthLocal extends IncentiveCalendarMonth {
  hasError?: boolean;
}

export const parseIncentiveCalendarMonths = (year: number, apiData?: IncentiveCalendar | null) => {
  const months: IncentiveCalendarMonthLocal[] = [];

  if (apiData) {
    apiData.months.sort((a, b) => a.month - b.month);
  }

  Object.keys(MonthFullNameNumberConstant).forEach((month, index) => {
    const currentMonthFromApi = apiData?.months[index];
    if (currentMonthFromApi && currentMonthFromApi.startDate && currentMonthFromApi.endDate) {
      months.push({ year, month: Number(month), startDate: currentMonthFromApi.startDate, endDate: currentMonthFromApi.endDate, hasError: false });
    } else {
      months.push({ year, month: Number(month), startDate: '', endDate: '', hasError: false });
    }
  });

  return months;
};
