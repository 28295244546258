import { AdminModels } from 'oat-admin-common';
import styles from '../../../styles.module.scss';
import OtherItemWrapper from './OtherItemWrapper';
import { processMultiSeriesCostItem } from './utils';

interface Props {
  multiSeriesCostItem: AdminModels.FYMultiSeriesItem;
}

const OtherItemRowDetail = ({ multiSeriesCostItem }: Props) => {
  return (
    <OtherItemWrapper>
      <span className={styles.valTextColor}>{processMultiSeriesCostItem(multiSeriesCostItem)}</span>
    </OtherItemWrapper>
  );
};

export default OtherItemRowDetail;
