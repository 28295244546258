import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import {
  ButtonGroup,
  DragDrop,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  DropResult,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'oat-common-ui';
import { useState } from 'react';
import Button from '../../../../../components/Button';
import DragSortingItem from '../../../../../components/DragSortingItem/DragSortingItem';
import useStores from '../../../../../stores/useStores';
import { getItemStyle } from '../../../../Settings/SeriesSortingSettings/utils';
import ScenarioModel from '../../../models/ScenarioModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import ExportScenarioLayout from './ExportScenarioLayout';
import styles from './styles.module.scss';

export type ScenarioModalLayout = 'Add' | 'Rename' | 'Copy' | 'Delete' | 'Export' | 'Reorder';

type Props = {
  addScenarioInProgress?: boolean;
  handleOnDragEnd: (result: DropResult) => void;
  id?: string;
  layout: ScenarioModalLayout;
  moveScenario?: (element: ScenarioModel, location: 'up' | 'down') => void;
  onAdd?: (scenario: string) => void;
  onClose: () => void;
  onCopy?: (name: string) => void;
  onDelete?: () => void;
  onExport?: () => void;
  onRename?: (scenarioId: string, name: string) => void;
  onReorder?: () => void;
  scenarioName?: string;
  scenarios: ScenarioModel[];
  tempScenarios: ScenarioModel[];
  seriesProfile: SeriesProfileModel;
};

const ScenarioActionModal = ({
  addScenarioInProgress = false,
  handleOnDragEnd,
  id,
  layout,
  moveScenario,
  onAdd,
  onClose,
  onCopy,
  onDelete,
  onExport,
  onRename,
  onReorder,
  scenarioName,
  scenarios,
  tempScenarios,
  seriesProfile,
}: Props) => {
  const [name, setName] = useState(scenarioName || '');
  const {
    data: { modelYear },
  } = seriesProfile;
  const {
    nationalProgramsStore: { seriesProfiles },
  } = useStores();

  const isExportLayout = layout === 'Export';
  const isDeleteLayout = layout === 'Delete';
  const isReorderLayout = layout === 'Reorder';

  const modalActionButton = () => {
    const getButtonAction = () => {
      switch (layout) {
        case 'Add':
          return onAdd && onAdd(name);
        case 'Rename':
          return onRename && id && onRename(id, name);
        case 'Copy':
          return onCopy && onCopy(name);
        case 'Delete':
          return onDelete && onDelete();
        case 'Export':
          return onExport && onExport();
        case 'Reorder':
          return onReorder && onReorder();
      }
    };

    const exportScenarioIsDisabled = isExportLayout && (scenarios.every(s => !s.isSelected) || seriesProfiles.every(s => !s.exportTo));

    return (
      <Button id={`${layout}-new-scenario`} variant="blue" onClick={getButtonAction} disabled={!Boolean(name.length) || exportScenarioIsDisabled || addScenarioInProgress}>
        {isReorderLayout ? 'Apply' : `${layout} Scenario${isExportLayout ? '(s)' : ''}`}
      </Button>
    );
  };

  const modalHeader = !isExportLayout
    ? `${layout} Scenario${isReorderLayout ? 's' : ''}`
    : `${layout} ${modelYear} ${seriesProfile.data.multiSeriesOfferId ? seriesProfile.data.multiSeriesOfferName : seriesProfile.displayName} Scenario(s)`;

  return (
    <Modal isOpen onClose={onClose} className={clsx(styles.container, isExportLayout && styles.exportContainer, isReorderLayout && styles.reorderContainer)}>
      <ModalHeader className={styles.header} onClose={onClose}>
        {modalHeader}
      </ModalHeader>
      <ModalBody className={styles.body}>
        {isDeleteLayout && <p className={styles.deleteMsg}>Are you sure you want to delete {scenarioName}? This action cannot be undone.</p>}
        {!isDeleteLayout && !isExportLayout && !isReorderLayout && (
          <>
            <label className={styles.label}>Scenario Name</label>
            <Input value={name} onChange={e => setName(e.target.value)} className={styles.scenarioInput} id={`${layout}-scenario-input`} />
          </>
        )}
        {isExportLayout && <ExportScenarioLayout scenarios={scenarios} seriesProfile={seriesProfile} />}
        {isReorderLayout && (
          <>
            <p className={styles.seriesTitle}>
              <span>{seriesProfile.displayName}</span> {seriesProfile.data.modelYear}
            </p>
            <DragDrop droppableId="seriesSorting" handleOnDragEnd={handleOnDragEnd}>
              {tempScenarios.map((item, i) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                      return (
                        <DragSortingItem
                          innerRef={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          isScenarioModal
                          item={item.name}
                          itemOrder={i + 1}
                          key={item.id}
                          moveItemUp={() => moveScenario && moveScenario(item, 'up')}
                          moveItemDown={() => moveScenario && moveScenario(item, 'down')}
                          style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style) as DraggingStyle}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            </DragDrop>
          </>
        )}
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <ButtonGroup>
          {!isExportLayout && (
            <Button id={`cancel`} onClick={onClose} variant="white" className={styles.cancelBtn}>
              Cancel
            </Button>
          )}
          {modalActionButton()}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ScenarioActionModal);
