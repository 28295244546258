import { BreadCrumbType, DefaultHeaderLayout, OATHeaderLogo, OATHeaderTitle, OATLoadingBar, OATToastContainer } from 'oat-common-ui';
import { useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import useStores from '../../stores/useStores';

interface Props {
  breadCrumbs?: BreadCrumbType[];
  showProgressBar?: boolean;
  showDefault?: boolean;
}

const MainHeader = ({ breadCrumbs = [], showProgressBar, showDefault = true }: Props) => {
  const { promiseInProgress } = usePromiseTracker();
  const {
    userInfoStore: { isLoaded, isLexus },
  } = useStores();

  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  return (
    <>
      {showDefault && (
        <DefaultHeaderLayout
          isProd={!isLowerEnv}
          breadCrumbs={breadCrumbs}
          renderLogo={isLoaded && <OATHeaderLogo isLexus={isLexus()} />}
          renderTitle={<OATHeaderTitle>Reports</OATHeaderTitle>}
        />
      )}
      <OATToastContainer />
      {(promiseInProgress || showProgressBar) && <OATLoadingBar />}
    </>
  );
};

export default MainHeader;
