import { AdminModels } from 'oat-admin-common';
import { SeriesTitle } from '../../../../../constants/global';
import SeriesListModel from '../../../models/SeriesListModel';
import FYSeriesDetail from './FYSeriesDetail';

interface Props {
  fiscalMonths: AdminModels.FiscalMonth[];
  seriesItem: SeriesListModel;
}

const FYSeriesDetails = ({ fiscalMonths, seriesItem }: Props) => {
  return (
    <>
      <FYSeriesDetail seriesItem={seriesItem} fiscalMonths={fiscalMonths} title={SeriesTitle.STOCK} />
      <FYSeriesDetail seriesItem={seriesItem} fiscalMonths={fiscalMonths} title={SeriesTitle.SALES} />
      <FYSeriesDetail seriesItem={seriesItem} fiscalMonths={fiscalMonths} title={SeriesTitle.PNVS} />
      <FYSeriesDetail seriesItem={seriesItem} fiscalMonths={fiscalMonths} title={SeriesTitle.TOTAL} />
    </>
  );
};

export default FYSeriesDetails;
