import { Vehicle } from "../../../gql/generated";

const mapExcludedVehicleIds = (vehicles: Vehicle[]): string[] => {
  const disabledExclusionsVehicleIds: string[] = [];

  vehicles.forEach(vehicle => {
    if (vehicle && vehicle.id && !vehicle.isInclusion) {
      disabledExclusionsVehicleIds.push(vehicle.id);
    }
  });

  return disabledExclusionsVehicleIds;
}

export default mapExcludedVehicleIds;