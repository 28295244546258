import AccordionItemModel from '../models/AccordionItemModel';

const recursiveToggleChildren = (items: AccordionItemModel[], checked: boolean) => {
  items.forEach(item => {
    if (!item.disabled) {
      item.setChecked(checked);
    }
    if (item.items) {
      recursiveToggleChildren(item.items, checked);
    }
  });
};

/**
 *
 * @param items Root items
 * @param uid
 * @param parent
 */
const recursiveToggle = (items: AccordionItemModel[], uid: string, checked: boolean, parent: AccordionItemModel | null) => {
  items.forEach(item => {
    if (item.uid !== uid && item.items.length > 0) {
      recursiveToggle(item.items, uid, checked, item);
    } else if (item.uid === uid && !item.disabled) {
      item.setChecked(checked);
      recursiveToggleChildren(item.items, checked);
    }
  });

  if (parent && !parent.disabled) {
    parent.setChecked(items.some(item => item.checked));
  }
};

export default recursiveToggle;
