import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { NumberInput, formatStdRate } from 'oat-common-ui';
import { useState } from 'react';
import BlockInputLabel from '../../../../../../components/BlockInputLabel';
import useStores from '../../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../../utils/appendTfsLabel';
import AprModel from '../../../../models/offers/apr/AprModel';
import TierTermModel from '../../models/TierTermModel';
import styles from '../styles.module.scss';

interface Props {
  tierTerm: TierTermModel;
  apr: AprModel;
  onSave: () => void;
}

const TermTableRow = ({ tierTerm, apr, onSave }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const { rateError, tfsError, avgAmtFinancedError, term, rate, tfsShare, penetrationRate, standardRate, averageAmountFinanced, monthlyPayment, tier, uid } = tierTerm;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleValueChange = (field: keyof TierTermModel, value: string) => {
    apr.updateTermField(tier, term, field, value);
    onSave();
  };

  return (
    <div className={styles.termTableRow}>
      <div onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        <span className={clsx(styles.term, term === 24 && styles.term24)}>{term}</span>
        <BlockInputLabel label={term === 24 ? 'Rate' : ''} className={clsx(styles.inputWrapper, styles.marginRight)}>
          <NumberInput
            id={`${uid}-term-table-rate-input`}
            data-testid={`${tier}-${term}-term-rate-input`}
            isNational
            percentageSign
            min={0}
            max={100}
            value={rate}
            error={rateError}
            onValueChange={val => handleValueChange('rate', val.value)}
            className={styles.rateInput}
            rate
            allowNegative={false}
          />
          {showTooltip && <span className={styles.rateTooltip}>{formatStdRate(standardRate)}</span>}
        </BlockInputLabel>
      </div>
      <BlockInputLabel label={term === 24 ? appendTfsLabel(isLexus(), 'Cost Share') : ''} className={clsx(styles.inputWrapper, styles.marginRight)}>
        <NumberInput
          id={`${uid}-term-table-costshare-input`}
          isNational
          percentageSign
          wholeNumber
          min={0}
          max={100}
          value={tfsShare}
          error={tfsError}
          onValueChange={val => handleValueChange('tfsShare', val.value)}
          className={clsx(styles.rateInput, apr.isTfsCostShareEnhanced(term, Number(tfsShare)) && styles.enhancedInput)}
        />
      </BlockInputLabel>
      <BlockInputLabel label={term === 24 ? 'Term Pen %' : ''} className={clsx(styles.inputWrapper, styles.marginRight)}>
        <NumberInput
          id={`${uid}-penrate-input`}
          isNational
          percentageSign
          wholeNumber
          min={0}
          max={100}
          value={penetrationRate}
          onValueChange={val => handleValueChange('penetrationRate', val.value)}
          className={styles.rateInput}
          error={apr.termTablePenRatesHasError}
        />
      </BlockInputLabel>
      <BlockInputLabel label={term === 24 ? 'Avg Amount Financed' : ''} className={clsx(styles.inputWrapper, styles.marginRight)}>
        <NumberInput
          id={`${uid}-avg-financed-input`}
          min={0}
          isNational
          wholeNumber
          dollarSign
          thousandSeparator
          units
          value={averageAmountFinanced}
          onValueChange={val => handleValueChange('averageAmountFinanced', val.value)}
          className={styles.dollarInput}
          error={avgAmtFinancedError}
        />
      </BlockInputLabel>
      <BlockInputLabel label={term === 24 ? 'Monthly Payment' : ''} className={styles.inputWrapper}>
        <NumberInput id={`${uid}-monthly-payment-input`} isNational wholeNumber dollarSign thousandSeparator units disabled value={monthlyPayment} className={styles.dollarInput} />
      </BlockInputLabel>
    </div>
  );
};

export default observer(TermTableRow);
