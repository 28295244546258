import { makeAutoObservable } from 'mobx';
import { AssumptionsModel, IAssumptionMonth } from '../models/Assumptions';

import { dateStringToDate } from 'oat-common-ui';
import { isAssumptionValid, parseAssumptionMonths } from '../pages/FYRollup/Assumptions/Rows/Inputs/utils';
import { setEmptyAssumptionMonths, setMonthsToParse } from '../pages/FYRollup/Assumptions/utils';

class AssumptionsStore {
  assumptions: IAssumptionMonth[] = [];
  currentYear: number = new Date().getFullYear();
  fiscalYear: number = dateStringToDate(new Date()).getFullYear() + 1;
  isFetched = false;
  loading = false;
  rev = '';

  constructor() {
    makeAutoObservable(this);
  }

  setAssumptions = (data: AssumptionsModel) => {
    const months = data ? setMonthsToParse(this.fiscalYear, data) : setEmptyAssumptionMonths(this.fiscalYear);
    const rev = data?.rev ?? '';

    this.rev = rev;
    this.assumptions = parseAssumptionMonths(months);
  };

  setFiscalYear = (year: number) => {
    this.fiscalYear = year;
  };

  updateAssumptionField = (field: 'standardRate' | 'standardRcf' | 'residualValue', month: number, value?: string) => {
    const index = this.assumptions.findIndex(a => a.month === month);

    if (index !== -1) {
      this.assumptions[index] = { ...this.assumptions[index], [field]: { value, isValid: isAssumptionValid(field, value) } };
    }
  };

  setIsLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  updateRev = (rev: string) => {
    this.rev = rev;
  };

  setIsFetched = (val: boolean) => {
    this.isFetched = val;
  };

  get isDisabledNext() {
    return this.fiscalYear - this.currentYear === 2;
  }

  get isDisabledPrevious() {
    return this.currentYear - this.fiscalYear === 0;
  }
}

export default AssumptionsStore;
