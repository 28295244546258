import { OATIcon } from 'oat-common-ui';
import AnchorNav from '../../../../components/MainSideNav/AnchorNav';
import NGCHeader from '../../../../components/NGCHeader';
import styles from './styles.module.scss';

interface Props {
  title: string;
}

const IpSettingsHeader = ({ title }: Props) => {
  return (
    <div className={styles.header}>
      <NGCHeader title={title} />
      <AnchorNav to="/dashboard">
        <OATIcon icon="cancel-circle" colorTheme="blue" />
      </AnchorNav>
    </div>
  );
};

export default IpSettingsHeader;
