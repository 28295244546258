import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Tooltip } from 'oat-common-ui';
import Button from '../../../../../../components/Button';
import OfferTitle from '../../../../components/OfferTitle';
import LeaseFormModel from '../../../../models/offers/lease/LeaseFormModel';
import LeaseGroupModel from '../../../../models/offers/lease/LeaseModel';
import { getFormattedLabel } from '../../../../utils/getFormattedLabel';
import styles from './styles.module.scss';

interface Props {
  lease: LeaseFormModel;
  leaseGroup: LeaseGroupModel;
}

const LeaseTitleExampleTabs = ({ lease, leaseGroup }: Props) => {
  const { getMaster, getExamples, selectLease } = leaseGroup;
  const master = getMaster();

  return (
    <div className={styles.titleSection}>
      <Button id={`master-sel-${leaseGroup.uid}`} onClick={() => selectLease(master?.uid || '')}>
        <OfferTitle offerType={AdminConstants.OPTION_TYPE_NAMES.LEASE} title={AdminConstants.OPTION_TYPE_NAMES.LEASE} />
      </Button>
      <div className={styles.examplesList}>
        {getExamples().map(item => (
          <Tooltip message={item.offerFields.name} key={item.uid} id={`ex-${item.uid}`} place="bottom" className={styles.tooltip}>
            <Button
              id={`ex-select-${item.uid}`}
              className={clsx(item.uid === lease.uid && styles.active)}
              onClick={() => {
                selectLease(item.uid);
              }}
            >
              {getFormattedLabel(item.offerFields.name).formattedName}
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default observer(LeaseTitleExampleTabs);
