import useStores from '../../../../../stores/useStores';
import MultiSeriesOfferModel from '../../models/MultiSeriesOfferModel';
import DeleteModal from '../DeleteModal';
import GridItem from '../Grid/GridItem';
import GridRow from '../Grid/GridRow';

import styles from '../styles.module.scss';
import GridRowHead from '../Grid/GridRowHead';
import { Button, Input, OATIcon, useInputDelay } from 'oat-common-ui';
import { ChangeEvent, useCallback, useState } from 'react';

interface Props {
  multiSeriesOffer: MultiSeriesOfferModel;
  index: number;
  id: string;
  innerRef: (element: HTMLElement | null) => any;
  setTotalCosts: () => void;
}

const MultiSeriesOffer = ({ multiSeriesOffer, index, innerRef, id, setTotalCosts, ...rest }: Props) => {
  const {
    multiSeriesOffersBudgetStore: { deleteMultiSeriesOffer, draftOfferingMonths },
  } = useStores();
  const { setDelay } = useInputDelay();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offerName, setOfferName] = useState(multiSeriesOffer.offerName);

  const isDraft = useCallback(
    (month: number) => {
      return draftOfferingMonths.findIndex(draft => draft.month === month) !== -1;
    },
    [draftOfferingMonths],
  );

  const changeInputHandler = (month: number, value: string) => {
    multiSeriesOffer.setOfferMonthCost(month, value);
    setTotalCosts();
  };

  const changeNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOfferName(e.target.value);

    setDelay(async () => {
      multiSeriesOffer.setOfferName(e.target.value);
    });
  };

  return (
    <div ref={innerRef} {...rest}>
      <GridRow>
        {showDeleteModal && (
          <DeleteModal offerName={multiSeriesOffer.offerName} onSubmit={() => deleteMultiSeriesOffer(multiSeriesOffer.id)} onClose={() => setShowDeleteModal(false)} />
        )}

        {/* Offer Name */}
        <GridRowHead>
          <Input
            className={!multiSeriesOffer.offerName ? styles.offerNameInputError : styles.offerNameInput}
            id={`offer-name-input-${id}`}
            onChange={changeNameHandler}
            disabled={multiSeriesOffer.hasPublished()}
            placeholder="Offer Name"
            type="text"
            value={offerName}
          />
        </GridRowHead>

        {/* Offer costs by month */}
        {multiSeriesOffer.months.map(month => {
          return (
            <GridItem
              disabled={!isDraft(month.month)}
              key={`${id}-${month.month}`}
              value={month.amount as string}
              onChange={(value: string) => {
                changeInputHandler(month.month, value);
              }}
            />
          );
        })}

        {/* Offer total cost by year */}
        <GridItem isTotal value={multiSeriesOffer.totalCost.toString()} />

        {/* Remove CTA for standalone offers*/}
        {!multiSeriesOffer.hasPublished() && (
          <Button variant="text" onClick={() => setShowDeleteModal(true)} id="delete-multi-series-offer-btn" className={styles.deleteBtn}>
            <OATIcon icon="trash" color="black" />
          </Button>
        )}
      </GridRow>
    </div>
  );
};

export default MultiSeriesOffer;
