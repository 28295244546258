import { SeriesName, SeriesNameModel } from '../models/SeriesName';

class SeriesNameStore {
  seriesName: SeriesName[] = [];

  setData = (data: SeriesNameModel) => {
    this.seriesName = data.seriesName;
  };
}

export default SeriesNameStore;
