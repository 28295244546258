import clsx from 'clsx';
import { NumberInput } from 'oat-common-ui';
import styles from '../styles.module.scss';

interface Props {
  disabled?: boolean;
  isTotal?: boolean;
  onChange?: (value: string) => void;
  value: string;
}

const GridItem = ({ value, isTotal, onChange, disabled = false }: Props) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value.toString().replaceAll(',', ''));
    }
  };

  return (
    <div className={clsx(styles.gridItem, styles.gridDetail)}>
      <span className={clsx(styles.dollarSign, isTotal ? styles.tcDsColor : styles.dsColor)}>$</span>
      <NumberInput wholeNumber onChange={e => onChangeHandler(e)} disabled={isTotal || disabled} className={isTotal ? styles.totalCost : styles.offerInput} value={value} units />
    </div>
  );
};

export default GridItem;
