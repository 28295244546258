import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import RCFNav from './RCFNav';
import TFSTableInput from './TFSTableInput';
import TiersRow from './TiersRow';
import styles from './styles.module.scss';

const TFSStandardRcfTable = () => {
  const {
    ratesTabTFSLeaseRCFStore: { tfsLeaseRatesSectionOne, tfsLeaseRatesSectionTwo, tfsLeaseRatesSectionThree, showHiddenTfsOne, showHiddenTfsTwo, showHiddenTfsThree },
    userInfoStore: { isLexus },
  } = useStores();

  const rcfItem = clsx(styles.rcfRow, !isLexus() ? styles.tfsToyotaWidth : styles.tfsLexusWidth);
  return (
    <div className={styles.tfsParent}>
      <RCFNav />
      <div className={styles.tfsStandardRcfTable}>
        <TiersRow hideTier7Plus={!isLexus()} />
        {tfsLeaseRatesSectionOne.map(rateModel => (
          <div key={rateModel.uid} className={rcfItem}>
            <TFSTableInput model={rateModel} showHidden={showHiddenTfsOne} />
          </div>
        ))}
        {tfsLeaseRatesSectionTwo.map(rateModel => {
          return (
            <div key={rateModel.uid} className={rcfItem}>
              <TFSTableInput model={rateModel} showHidden={showHiddenTfsTwo} />
            </div>
          );
        })}
        {tfsLeaseRatesSectionThree.map(rateModel => {
          return (
            <div key={rateModel.uid} className={rcfItem}>
              <TFSTableInput model={rateModel} showHidden={showHiddenTfsThree} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(TFSStandardRcfTable);
