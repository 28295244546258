import { DefaultModal, InlineInputLabel, Input, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Brand, FEATURE_OR_2477 } from '../../../../constants/global';
import { Offer, OfferingCosts, useCopyOfferMutation, useDeleteOfferMutation, useSaveOfferSortInRaMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import { OfferFields } from '../../models/offers/OfferFields';
import DoNotSendToRegions from '../DoNotSendToRegions';
import OfferFooterCtas from './OfferFooterCtas';
import styles from './styles.module.scss';

interface Props {
  uid: string; // offer uid
  offerFields: OfferFields;
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;

  updateOfferField: (fieldName: keyof OfferFields, value: string | number | boolean) => void;

  isEnhSubCashTfsCostShareForRegions?: boolean;
  isEnhSubCashTfsCostShareForRegionsDisabled?: boolean;

  isEnhTfsCostShareForRegions?: boolean;
  isEnhTfsCostShareForRegionsDisabled?: boolean;

  isForRegions: boolean;
  isForRegionsDisabled?: boolean;
}

const OfferFooter = ({
  uid,
  isForRegions,
  isEnhTfsCostShareForRegions,
  isEnhSubCashTfsCostShareForRegions,
  offerFields,
  seriesProfile,
  rgnlAlt,
  updateOfferField,
  isForRegionsDisabled,
  isEnhTfsCostShareForRegionsDisabled,
  isEnhSubCashTfsCostShareForRegionsDisabled,
}: Props) => {
  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const [deleteModal, showDeleteModal] = useState(false);
  const [saveOfferSortMutation] = useSaveOfferSortInRaMutation();
  const [copyOffer] = useCopyOfferMutation();
  const [deleteOffer] = useDeleteOfferMutation();
  const { error } = useToast();

  const handleCopyOffer = async (index: number) => {
    try {
      if (offerFields.id) {
        const { id, rev } = offerFields;
        const sortOrderParam = index + 1;

        const newCopyOffer = { offerId: id, sortOrder: sortOrderParam };
        const currentOfferSortOrder = rgnlAlt.data.offerSortOrder.map(o => {
          const sortOrder = Number(o.sortOrder) >= sortOrderParam ? Number(o.sortOrder) + 1 : Number(o.sortOrder);

          return {
            offerId: o.offerId || '',
            sortOrder,
          };
        });
        const newOfferSortOrder = [...currentOfferSortOrder, { ...newCopyOffer }];

        const input = {
          ...newCopyOffer,
          offerRev: rev,
          offerSortOrder: newOfferSortOrder,
        };

        const res = await trackPromise(
          copyOffer({
            variables: { input },
          }),
        );

        if (res.data?.copyOffer.success) {
          rgnlAlt.copyOffer(res.data.copyOffer.offers as Offer[], offerFields.optionType, rgnlAlt.data.id, seriesProfile, offerFields.id);
          updateOfferingCostsFromResponse(res.data.copyOffer.offeringCosts as OfferingCosts);
          seriesProfile.selectedScenario?.updateRyoOffers(res.data.copyOffer.ryoOffers as Offer[]);
          seriesProfile.clearErrors();
        }
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleDelete = async () => {
    try {
      if (offerFields.id) {
        const res = await trackPromise(deleteOffer({ variables: { input: { offerId: offerFields.id, offerRev: offerFields.rev } } }));
        if (res.data?.deleteOffer.success) {
          updateOfferingCostsFromResponse(res.data.deleteOffer.offeringCosts as OfferingCosts);
          seriesProfile.selectedScenario?.updateRyoOffers(res.data.deleteOffer.ryoOffers as Offer[]);
        }
      }

      rgnlAlt.deleteOffer(uid);
      rgnlAlt.deleteOfferSortOrder(offerFields.id);
      seriesProfile.clearErrors();
    } catch (e) {
      error((e as Error).message);
    } finally {
      showDeleteModal(false);
    }
  };

  const handleSort = async (up: boolean) => {
    const changed = rgnlAlt.sortOffers(uid, up);
    if (!changed) {
      return;
    }

    const res = await trackPromise(
      saveOfferSortMutation({
        variables: {
          input: {
            rgnlAltId: rgnlAlt.data.id,
            offerSortOrder: rgnlAlt.data.offerSortOrder,
          },
        },
      }),
    );

    if (res?.data?.saveOfferSortInRA) {
      rgnlAlt.updateRev(res.data.saveOfferSortInRA.rgnlAltId ?? '', res.data.saveOfferSortInRA.rev ?? '');
      seriesProfile.clearErrors();
    }
  };

  const offerIndex = rgnlAlt.getOfferIndexByUid(uid);

  return (
    <section className={styles.offerFooter}>
      <div className={styles.firstCol}>
        {!FEATURE_OR_2477 ? (
          <InlineInputLabel
            className={styles.checkboxPadding}
            labelComponent={
              <Input
                isNational
                id={`is-for-regions-${uid}`}
                type="checkbox"
                checked={!isForRegions}
                onChange={e => {
                  updateOfferField('isForRegions', e.currentTarget.checked);
                  seriesProfile.clearErrors();
                }}
                disabled={seriesProfile.data.isBudgetConfirmed}
              />
            }
          >
            <label htmlFor={`is-for-regions-${uid}`} className={seriesProfile.data.isBudgetConfirmed ? styles.labelDisabled : ''}>
              Do not send to {brand === Brand.TOYOTA ? 'Regions' : 'Areas'}
            </label>
          </InlineInputLabel>
        ) : (
          <DoNotSendToRegions
            uid={uid}
            brand={brand}
            offerType={offerFields.optionType}
            seriesProfile={seriesProfile}
            onChange={updateOfferField}
            offerCheckboxIsDisabled={isForRegionsDisabled}
            offerIsChecked={!isForRegions}
            subTfsCheckboxIsDisabled={isEnhSubCashTfsCostShareForRegionsDisabled || !isForRegions}
            subTfsIsChecked={!isEnhSubCashTfsCostShareForRegions}
            tfsCheckboxIsDisabled={isEnhTfsCostShareForRegionsDisabled || !isForRegions}
            tfsIsChecked={!isEnhTfsCostShareForRegions}
          />
        )}
      </div>
      <div className={styles.secondCol}>
        <OfferFooterCtas
          uid={uid}
          offerIndex={offerIndex}
          seriesProfile={seriesProfile}
          rgnlAlt={rgnlAlt}
          handleCopyOffer={handleCopyOffer}
          showDeleteModal={showDeleteModal}
          handleSort={handleSort}
        />
      </div>
      {deleteModal && (
        <DefaultModal
          open
          title={`Delete ${offerFields.name} Offer`}
          message={`Are you sure you want to delete ${offerFields.name}? This action cannot be undone.`}
          onSubmit={handleDelete}
          onClose={() => showDeleteModal(false)}
        />
      )}
    </section>
  );
};

export default OfferFooter;
