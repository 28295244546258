import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import Button from '../../../../../../../components/Button';
import { SelectedTierTerm } from '../../../../../../../gql/generated';
import useStores from '../../../../../../../stores/useStores';
import TierTermModel from '../../../../../offers/AprSection/models/TierTermModel';
import { getFormattedRate } from '../../../../../utils/getFormattedRate';
import styles from '../styles.module.scss';

interface Props {
  disabled: boolean;
  handleDelete: () => void;
  term: string;
  tierTerms: TierTermModel[];
  selectedTierTerms: SelectedTierTerm[];
}

const TermsRow = ({ term, tierTerms, selectedTierTerms, handleDelete, disabled }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  return (
    <tr className={styles.tRow}>
      <td className={styles.rateTD}>{term}</td>
      {tierTerms.map(t => {
        const isLexusTier3 = isLexus() && t.tier === '3';
        const showRate = !isLexusTier3 && Boolean(selectedTierTerms.find(item => item.term === t.term && item.tier === t.tier));

        return (
          <td className={styles.rateTD} key={`${t.tier}-${t.term}`}>
            <span className={showRate ? '' : styles.hide}>{getFormattedRate(t.rate)}</span>
          </td>
        );
      })}
      <td>
        <Button disabled={disabled} variant="text" id="term-24-delete-btn" className={styles.iconBtn} onClick={handleDelete}>
          <OATIcon icon="minus" size={20} />
        </Button>
      </td>
    </tr>
  );
};

export default observer(TermsRow);
