import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../utils/appendTfsLabel';
import LeaseFormModel from '../../../models/offers/lease/LeaseFormModel';
import LeaseInput from '../components/LeaseInput';
import styles from './styles.module.scss';

interface Props {
  leaseForm: LeaseFormModel;
  onSave: () => void;
}

const LastColumn = ({ leaseForm, onSave }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const { uid, leaseFields, updateLeaseFields, isTfsCostShareEnhanced, isTfsSubCashCostShareEnhanced } = leaseForm;

  return (
    <div className={styles.divider}>
      <div className={styles.row}>
        {/* Adjustment Factor */}
        <LeaseInput
          id={`l-adj-${uid}`}
          label="Adjustment Factor"
          error={leaseForm.adjFactorError}
          value={leaseFields.adjustmentFactor}
          disabled={leaseForm.isExample}
          min={0}
          adjustmentFactor
          onValueChange={val => {
            updateLeaseFields('adjustmentFactor', val.value);
            onSave();
          }}
        />
      </div>

      <div className={styles.row}>
        {/* TFS Share */}
        <LeaseInput
          id={`l-tfsshare-${uid}`}
          label={appendTfsLabel(leaseForm.brand === AdminConstants.BRAND_LEXUS, 'Share')}
          value={leaseFields.tfsShare}
          disabled={leaseForm.isExample}
          wholeNumber
          percentageSign
          className={isTfsCostShareEnhanced ? styles.enhancedInput : undefined}
          min={0}
          max={100}
          error={leaseForm.tfsShareError}
          onValueChange={val => {
            updateLeaseFields('tfsShare', val.value);
            onSave();
          }}
        />
      </div>

      <div className={styles.row}>
        {/* Sub Cash Cost Share */}
        <LeaseInput
          id={`l-subcash-cost-${uid}`}
          label="Sub Cash Cost Share"
          value={leaseFields.subCashCostShare}
          disabled={leaseForm.isExample}
          error={leaseForm.subCashCostShareError}
          className={isTfsSubCashCostShareEnhanced ? styles.enhancedInput : undefined}
          wholeNumber
          percentageSign
          min={0}
          max={100}
          onValueChange={val => {
            updateLeaseFields('subCashCostShare', val.value);
            onSave();
          }}
        />

        {/* Sub Cash Cost Share Cap */}
        <LeaseInput
          id={`l-${appendTfsLabel(isLexus(), '')}-cap-${uid}`}
          label={appendTfsLabel(isLexus(), 'Cap')}
          value={leaseFields.subCashCostShareCap}
          disabled={leaseForm.isExample}
          wholeNumber
          className={Number(leaseFields.subCashCostShareCap) > 0 ? styles.enhancedInput : undefined}
          error={leaseForm.subCashCostShareCapError}
          onValueChange={val => {
            updateLeaseFields('subCashCostShareCap', val.value);
            onSave();
          }}
          dollarSign
        />
      </div>

      <div className={styles.row}>
        {/* RV Support */}
        <LeaseInput
          id={`l-rv-supp-${uid}`}
          label="RV Support"
          value={leaseFields.rvSupport}
          disabled={leaseForm.isExample}
          wholeNumber
          percentageSign
          error={leaseForm.rvSupportError}
          onValueChange={val => {
            updateLeaseFields('rvSupport', val.value);
            onSave();
          }}
        />

        {/* RV Support Pen */}
        <LeaseInput
          id={`l-rv-supp-pen-${uid}`}
          label="RV Support Pen %"
          value={leaseFields.rvSupportPenRate}
          disabled={leaseForm.isExample}
          wholeNumber
          percentageSign
          error={leaseForm.rvSupportPenRateError}
          onValueChange={val => {
            updateLeaseFields('rvSupportPenRate', val.value);
            onSave();
          }}
        />
      </div>

      <div className={clsx(styles.row, styles.bottom)}>
        {/* RV Support Cost */}
        <LeaseInput
          id={`l-rv-supp-cost-${uid}`}
          label="RV Support Cost"
          value={leaseFields.rvSupportCostShare}
          disabled={leaseForm.isExample}
          wholeNumber
          percentageSign
          min={0}
          max={100}
          error={leaseForm.rvSupportCostShareError}
          onValueChange={val => {
            updateLeaseFields('rvSupportCostShare', val.value);
            onSave();
          }}
        />

        {/* RV Support Adjustment Factor */}
        <LeaseInput
          id={`l-rv-supp-adj-${uid}`}
          label="RV Support Adjustment Factor"
          value={leaseFields.rvSupportAdjustmentFactor}
          disabled={leaseForm.isExample}
          error={leaseForm.rvSupportAdjFactorError}
          adjustmentFactor
          onValueChange={val => {
            updateLeaseFields('rvSupportAdjustmentFactor', val.value);
            onSave();
          }}
        />
      </div>
    </div>
  );
};

export default observer(LastColumn);
