import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Dropdown, OATIcon } from 'oat-common-ui';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';
import styles from '../OfferSetupHeader/styles.module.scss';
import { getSystemToPayDropdownItems } from '../OfferSetupHeader/utils';

interface Props {
  item: OfferSetupModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
  saveLeaseSetup: () => void;
  saveAprSetup: () => void;
}

const SystemToPayDropdown = ({ item, seriesProfile, onSave, saveAprSetup, saveLeaseSetup }: Props) => {
  const { updateField, aprSetupDetails, leaseSetupDetails, uid } = item;
  const { OPTION_TYPE_NAMES } = AdminConstants;

  return (
    <>
      <div className={styles.dropdown}>
        <BlockInputLabel label="System to Pay" className={clsx(styles.blockInput, styles.dropdownItem)} labelClass={styles.blockInputLabel}>
          <Dropdown
            id={`system-to-pay-${uid}`}
            value={item.fields.systemToPay}
            options={getSystemToPayDropdownItems()}
            onChange={item => {
              updateField('systemToPay', item.value);
              onSave();
            }}
            disabled={seriesProfile.isConfirmed || item.fields.optionType === OPTION_TYPE_NAMES.DEFERRED_PAYMENT}
          />
        </BlockInputLabel>
        {aprSetupDetails && aprSetupDetails.subCashAmount > 0 && (
          <BlockInputLabel label="Subvention Cash System to Pay" className={clsx(styles.blockInput, styles.dropdownItem)} labelClass={styles.blockInputLabel}>
            <Dropdown
              id={`subven-system-to-pay-${uid}`}
              options={getSystemToPayDropdownItems()}
              onChange={item => {
                aprSetupDetails.updateSubventionCashSystemToPay(item.value);
                saveAprSetup();
              }}
              value={aprSetupDetails.subventionCashSystemToPay}
              disabled={seriesProfile.isConfirmed}
            />
          </BlockInputLabel>
        )}
        {leaseSetupDetails && (
          <div className={styles.leaseTerms}>
            <BlockInputLabel label="Low Term" className={clsx(styles.blockInput, styles.dropdownItem, styles.leaseTerm)} labelClass={styles.blockInputLabel}>
              <Dropdown
                id={`lease-low-term-${uid}`}
                options={AdminConstants.NATIONAL_TERMS.map(term => ({ value: term.toString(), label: term.toString() }))}
                onChange={item => {
                  leaseSetupDetails.updateField('lowTerm', Number(item.value));
                  saveLeaseSetup();
                }}
                disabled={seriesProfile.isConfirmed}
                value={String(leaseSetupDetails.fields.lowTerm)}
                error={leaseSetupDetails.termError}
              />
            </BlockInputLabel>
            <BlockInputLabel label="High Term" className={clsx(styles.blockInput, styles.dropdownItem, styles.leaseTerm)} labelClass={styles.blockInputLabel}>
              <Dropdown
                id={`lease-high-term-${uid}`}
                options={AdminConstants.NATIONAL_TERMS.map(term => ({ value: term.toString(), label: term.toString() }))}
                onChange={item => {
                  leaseSetupDetails.updateField('highTerm', Number(item.value));
                  saveLeaseSetup();
                }}
                disabled={seriesProfile.isConfirmed}
                value={String(leaseSetupDetails.fields.highTerm)}
                error={leaseSetupDetails.termError}
              />
            </BlockInputLabel>
            {leaseSetupDetails.termError && (
              <span className={styles.termError}>
                <OATIcon icon="warning" className={styles.termErrorIcon} />
                Please update term range
              </span>
            )}
          </div>
        )}
      </div>
      {leaseSetupDetails && leaseSetupDetails.fields.subventionCash > 0 && (
        <div className={styles.subventionSystemToPay}>
          <BlockInputLabel label="Subvention Cash System to Pay" className={clsx(styles.blockInput, styles.dropdownItem)} labelClass={styles.blockInputLabel}>
            <Dropdown
              id={`subven-system-to-pay-lease-${leaseSetupDetails.uid}`}
              options={getSystemToPayDropdownItems()}
              onChange={item => {
                leaseSetupDetails.updateField('subventionCashSystemToPay', item.value);
                if (!leaseSetupDetails.termError) {
                  saveLeaseSetup();
                }
              }}
              value={leaseSetupDetails.fields.subventionCashSystemToPay}
              disabled={seriesProfile.isConfirmed}
            />
          </BlockInputLabel>
        </div>
      )}
    </>
  );
};

export default observer(SystemToPayDropdown);
