import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Button from '../../../../../../components/Button';
import NumberStack from '../../../../components/NumberStack';
import AprModel from '../../../../models/offers/apr/AprModel';
import styles from './styles.module.scss';

interface Props {
  aprModel: AprModel;
}

const APRPnvs = ({ aprModel }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    aprFields: { offerPnvs, subCashPnvs, termWeightedCosts },
  } = aprModel;

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.aprPnvs}>
      {expanded && (
        <>
          {termWeightedCosts.map(weightedCost => {
            return (
              <NumberStack
                key={weightedCost.uid}
                labelOnTop
                classNameLabel={clsx(styles.marginBottom, styles.blackText)}
                label={`Term ${weightedCost.term}`}
                value={weightedCost.pnvs}
                showBottomValue
                bottomValue={weightedCost.fullPnvs}
                wholeNumber
                miniFormat
                addSign
                dollarSign
                classNameContainer={styles.stackContainer}
              />
            );
          })}
          <NumberStack
            labelOnTop
            classNameLabel={clsx(styles.marginBottom, styles.blackText)}
            label="Sub Cash"
            value={subCashPnvs}
            wholeNumber
            miniFormat
            dollarSign
            classNameContainer={styles.stackContainer}
          />
          <span className={styles.equalSign}>=</span>
        </>
      )}
      <Button variant="text" id={`apr-pnvs-toggle-${aprModel.uid}`} onClick={handleToggle} className={styles.toggleBtn}>
        <NumberStack label="PNVS" value={offerPnvs} wholeNumber aprPnvsTheme active={expanded} classNameContainer={styles.stackContainer} />
      </Button>
      {expanded && <span className={styles.expandedText}>Weighted Term PNVS</span>}
    </div>
  );
};

export default observer(APRPnvs);
