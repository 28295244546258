import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { CustomDatePicker, dateFormatISOString, dateStringToDate, getDateParts, MonthFullNameNumberConstant, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { GetIncentiveCalendarDocument, useSaveIncentiveCalendarMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';

import BlockInputLabel from '../../../components/BlockInputLabel';
import Button from '../../../components/Button';
import MainHeader from '../../../components/MainHeader';
import NGCHeader from '../../../components/NGCHeader';
import Header from '../../FYRollup/components/header/Header';

import styles from './styles.module.scss';

const IncentiveCalendarComponent = () => {
  const {
    incentiveCalendarStore: { incentiveCalendar, setYear, setMonthDate, setIncentiveCalendar, setHasError, setHasUnsavedChanges },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const currentDate = dateFormatISOString(new Date());
  const { year: currentYear } = getDateParts(currentDate);

  const { error } = useToast();

  const [saveIncentiveCalendar] = useSaveIncentiveCalendarMutation();
  const [fetchIncentiveCalendar] = useLazyQuery(GetIncentiveCalendarDocument, {
    onCompleted: data => {
      if (data.fetchIncentiveCalendar.success) {
        setIncentiveCalendar(data.fetchIncentiveCalendar.data);
      }
    },
  });

  const handleSaveCalendar = async () => {
    const localCalendarData = { ...incentiveCalendar };
    const monthsWithDates = localCalendarData.months
      .filter(month => month.startDate && month.endDate)
      .map(month => {
        delete month.hasError;
        return month;
      });

    try {
      const res = await trackPromise(saveIncentiveCalendar({ variables: { input: { ...localCalendarData, months: monthsWithDates } } }));

      if (res.data?.saveIncentiveCalendar.success) {
        setIncentiveCalendar(res.data.saveIncentiveCalendar.incentiveCalendar);
        setHasUnsavedChanges(false);
      }
    } catch (e) {
      setHasError((e as Error).message);
      error((e as Error).message);
    }
  };

  return (
    <div>
      <Header className={styles.header}>
        <MainHeader showDefault={false} />
        <NGCHeader title="Incentive Month Calendar" />
        <div className={styles.yearSection}>
          <span className={styles.activeYear}>{incentiveCalendar.fiscalYear}</span>
          <Button
            id="fiscal-yr-decrement-btn"
            className={styles.arrow}
            onClick={() => {
              setYear(incentiveCalendar.fiscalYear - 1);
              fetchIncentiveCalendar({ variables: { brand, fiscalYear: incentiveCalendar.fiscalYear } });
            }}
            disabled={incentiveCalendar.fiscalYear <= Number(currentYear) - 2}
          >
            <OATIcon icon="angle-left" />
          </Button>
          <Button
            id="fiscal-yr-increment-btn"
            className={styles.arrow}
            onClick={() => {
              setYear(incentiveCalendar.fiscalYear + 1);
              fetchIncentiveCalendar({ variables: { brand, fiscalYear: incentiveCalendar.fiscalYear } });
            }}
            disabled={incentiveCalendar.fiscalYear >= Number(currentYear) + 2}
          >
            <OATIcon icon="angle-right" />
          </Button>
        </div>
      </Header>
      <div className={styles.months}>
        {incentiveCalendar.months.map((monthData, index) => {
          return (
            <div className={styles.monthRow} key={monthData.month}>
              <span className={styles.monthText}>
                {MonthFullNameNumberConstant[monthData.month]} {incentiveCalendar.fiscalYear}
              </span>
              <BlockInputLabel label="Start Date" className={styles.date}>
                <CustomDatePicker
                  isNational
                  id={`${monthData.month}-${incentiveCalendar.fiscalYear}-start-date`}
                  selected={monthData.startDate ? dateStringToDate(monthData.startDate) : undefined}
                  onChange={date => {
                    setMonthDate(date, index, 'startDate');
                    setHasUnsavedChanges(true);
                  }}
                  error={monthData.hasError}
                  disabled={currentDate >= monthData.endDate && Boolean(monthData.endDate) && Boolean(monthData.startDate)}
                />
              </BlockInputLabel>
              <BlockInputLabel label="End Date" className={styles.date}>
                <CustomDatePicker
                  isNational
                  id={`${monthData.month}-${incentiveCalendar.fiscalYear}-end-date`}
                  selected={monthData.endDate ? dateStringToDate(monthData.endDate) : undefined}
                  onChange={date => {
                    setMonthDate(date, index, 'endDate');
                    setHasUnsavedChanges(true);
                  }}
                  error={monthData.hasError}
                  disabled={currentDate >= monthData.endDate && Boolean(monthData.endDate)}
                />
              </BlockInputLabel>
            </div>
          );
        })}
      </div>
      <div className={styles.footerBtn}>
        <Button variant="blue" onClick={handleSaveCalendar} id="incentive-calendar-save-btn">
          Save
        </Button>
      </div>
    </div>
  );
};

export default observer(IncentiveCalendarComponent);
