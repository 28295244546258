import { makeAutoObservable } from 'mobx';
import { BosuLog } from '../gql/generated';

class BosuStore {
  constructor() {
    makeAutoObservable(this);
  }

  lastUploadedFile = '';

  setLastUploadedFile = (lastBosuLog?: BosuLog) => {
    if (lastBosuLog) {
      this.lastUploadedFile = lastBosuLog.fileName;
    }
  };
}

export default BosuStore;
