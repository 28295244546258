import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import AprSetupModel from '../../../models/offersSetup/AprSetupModel';
import RatesTable from '../OfferSetupHeader/RatesTable';

interface Props {
  aprSetupDetails: AprSetupModel;
  seriesProfile: SeriesProfileModel;
}

const APROfferSetup = ({ aprSetupDetails, seriesProfile }: Props) => {
  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();

  return <RatesTable aprSetupDetails={aprSetupDetails} isDisabled={seriesProfile.isConfirmed || isOfferingPublished} />;
};

export default observer(APROfferSetup);
