import clsx from 'clsx';
import React from 'react';
import styles from '../../styles.module.scss';

const GridItemTotalName = () => {
  return (
    <div className={styles.fySeriesBox}>
      <div className={styles.paddingTop}>
        <div className={clsx(styles.seriesBoxListItem, styles.totalTextColor, styles.alignLeft)}>Calculated Total</div>
        <div className={clsx(styles.seriesBoxListItem, styles.valTextColor, styles.alignLeft)}>Sales...</div>
        <div className={clsx(styles.seriesBoxListItem, styles.valTextColor, styles.alignLeft)}>PNVS...</div>
        <div className={clsx(styles.seriesBoxListItem, styles.valTextColor, styles.alignLeft)}>Total...</div>
      </div>
    </div>
  );
};

export default GridItemTotalName;
