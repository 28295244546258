import { observer } from 'mobx-react-lite';
import useStores from '../../../../../../stores/useStores';
import ScenarioModel from '../../../../models/ScenarioModel';
import SeriesProfileModel from '../../../../models/SeriesProfileModel';
import ExportSection from './components/ExportSection';
import SelectSection from './components/SelectSection';
import styles from './components/styles.module.scss';

interface Props {
  scenarios: ScenarioModel[];
  seriesProfile: SeriesProfileModel;
}

const ExportScenarioLayout = ({ scenarios, seriesProfile }: Props) => {
  const {
    nationalProgramsStore: { seriesProfiles },
  } = useStores();

  return (
    <div className={styles.exportBody}>
      <SelectSection scenarios={scenarios} />
      <div className={styles.midDivider} />
      <ExportSection seriesProfiles={seriesProfiles} activeSeriesProfile={seriesProfile} />
    </div>
  );
};

export default observer(ExportScenarioLayout);
