import { ReactNode } from 'react';
import styles from '../styles.module.scss';

interface Props {
  children: ReactNode;
}

const TermTableWrapper = ({ children }: Props) => {
  return <div className={styles.termTableWrapper}>{children}</div>;
};

export default TermTableWrapper;
