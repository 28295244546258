import { makeAutoObservable } from 'mobx';
import { assignNumberValue } from 'oat-common-ui';
import { Offer } from '../../../../gql/generated';

class CashSetupModel {
  uid = '';
  id = '';
  rev = '';
  amount = 0;
  offerPnvs = 0;

  constructor(offer: Offer, offerUid: string) {
    makeAutoObservable(this);

    this.uid = offerUid;
    this.id = offer.id;
    this.rev = offer.rev;
    this.amount = assignNumberValue(offer.cashDetails?.amount);
    this.offerPnvs = assignNumberValue(offer.cashDetails?.offerPnvs);
  }

  updateAmount = (value: number) => {
    this.amount = value;
  };

  setRevAndId = (offer?: Offer) => {
    if (offer) {
      this.id = offer.id;
      this.rev = offer.rev;
    }
  };
}

export default CashSetupModel;
