import { formatRcf } from 'oat-common-ui';
import { useState } from 'react';
import { LeaseTier } from '../../../../models/offers/OfferFields';
import styles from '../styles.module.scss';

interface Props {
  tier: number;
  tiers: LeaseTier[];
}

const CreditTiersTable = ({ tier, tiers }: Props) => {
  const [data] = useState(tiers);

  const filteredData = tier === 0 ? [data[0]] : data.slice(0, tier + 1);

  return (
    <table className={styles.creditTierTable}>
      <thead className={styles.creditTierTableHead}>
        <tr>
          <th />
          <th className={styles.creditTierTableCell}>Standard</th>
          <th className={styles.creditTierTableCell}>Subvented</th>
          <th className={styles.creditTierTableCell}>Differential</th>
        </tr>
      </thead>
      <tbody className={styles.creditTierTableBody}>
        {filteredData.map(row => (
          <tr key={row.tier} className={styles.creditTierTableRow}>
            <td>Tier {row.tier}</td>
            <td className={styles.creditTierTableCell}>{formatRcf(row.standardRcf)}</td>
            <td className={styles.creditTierTableCell}>{formatRcf(row.subventedRcf)}</td>
            <td className={styles.creditTierTableCell}>{formatRcf(row.differential)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CreditTiersTable;
