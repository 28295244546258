import { makeAutoObservable } from 'mobx';
import { validator } from 'oat-common-ui';
import { Offering, OfferingUploadData, OfferingUploadItem } from '../gql/generated';

class IpSettingsStore {
  offering: Offering = {
    atcOfferingId: '',
    brand: '',
    created: '',
    createdBy: '',
    endDate: '',
    id: '',
    isAtc: false,
    isDownstreamReady: false,
    isPublished: false,
    name: '',
    note: '',
    originalOfferingId: '',
    parentId: '',
    regionCode: '',
    rev: '',
    startDate: '',
    status: '',
    updated: '',
    updatedBy: '',
  };
  offeringUploads: OfferingUploadData = {
    offeringId: '',
    bosu: {} as OfferingUploadItem,
    modelCodes: {} as OfferingUploadItem,
    postNglResidual: {} as OfferingUploadItem,
    preNglResidual: {} as OfferingUploadItem,
    regionalAprRates: {} as OfferingUploadItem,
  };
  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setHasUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };

  updateField = <T extends keyof Offering, V extends Offering[T]>(field: T, value: V) => {
    this.offering[field] = value;
  };

  setOffering = (offering: Offering) => {
    this.offering = offering;
  };

  setOfferingUploadData = (offeringUploads: OfferingUploadData) => {
    this.offeringUploads = offeringUploads;
  };

  updateUploadObject = (objectName: 'bosu' | 'modelCodes' | 'postNglResidual' | 'preNglResidual' | 'regionalAprRates' | 'enhancedStandardRcf') => (value: OfferingUploadItem) => {
    this.offeringUploads[objectName] = value;
  };

  get offeringDisplayName() {
    return `${this.offering.name} ${this.offering.isAtc ? '- ATC' : ''}`;
  }

  get offeringNameError() {
    return !!validator(this.offering.name, { required: true });
  }

  get startDateError() {
    return !!validator(this.offering.startDate, { required: true });
  }

  get endDateError() {
    return !!validator(this.offering.endDate, { required: true });
  }
}

export default IpSettingsStore;
