import cx from 'clsx';
import { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface Props extends HTMLAttributes<HTMLElement> {
  expand: boolean;
}

const AccordionSection = ({ expand, ...rest }: Props) => {
  return <div className={cx(styles.accordionSection, expand && styles.expand)} {...rest} />;
};

export default AccordionSection;
