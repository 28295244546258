import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { IMultiItem, MultiSelectPanes } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

const SeriesSelections = () => {
  const {
    seriesAndModelsStore: { seriesSelections, setHasUnsavedChanges },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const [unfiltered, setUnfiltered] = useState<IMultiItem[]>(seriesSelections.included);
  const [includedList, setIncludedList] = useState<IMultiItem[]>(seriesSelections.included);
  const [excludedList, setExcludedList] = useState<IMultiItem[]>(seriesSelections.excluded);
  const [queryExcluded, setQueryExcluded] = useState('');
  const [queryIncluded, setQueryIncluded] = useState('');

  const handleSelect = (excluded: IMultiItem[], included: IMultiItem[]) => {
    if (isPublished) {
      return;
    }

    setIncludedList(included);
    setExcludedList(excluded);
  };

  const handleChange = (excluded: IMultiItem[], included: IMultiItem[], sel: IMultiItem[], exclude: boolean) => {
    const selections = seriesSelections[!exclude ? 'excluded' : 'included'].filter(item => item.selected);
    if (!selections.length) {
      return;
    }
    const newList = !exclude ? [...included, ...selections] : included.filter(item => !selections.find(selectedItem => item === selectedItem));
    seriesSelections.setSelected(newList);
    selections.forEach(item => (item.selected = false));
    setUnfiltered(newList);
    handleSelect(seriesSelections.excluded, seriesSelections.included);
    setHasUnsavedChanges(true);
  };

  return (
    <div className={clsx(styles.selectorPanels, isPublished && styles.disabledSelectorPanels)}>
      <MultiSelectPanes
        excludedList={excludedList.filter(item => item.data.toLowerCase().includes(queryExcluded.toLowerCase()))}
        includedList={includedList.filter(item => item.data.toLowerCase().includes(queryIncluded.toLowerCase()))}
        onMove={(exc, inc, sel, dir) => handleChange(exc, unfiltered, sel, dir)}
        onSelect={(exc, inc) => handleSelect(exc, inc)}
        isNational
        allowSearch
        leftLabel="Available Series Profiles"
        leftSearchPlaceholder="Search by series"
        rightLabel="Added to Incentive Period"
        rightSearchPlaceholder="Search by series"
        searchOnExcluded={(exc, query) => setQueryExcluded(query)}
        searchOnIncluded={(inc, query) => setQueryIncluded(query)}
        disabled={isPublished}
      />
    </div>
  );
};

export default observer(SeriesSelections);
