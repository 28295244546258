import { observer } from 'mobx-react-lite';
import { uploadWithPresignedUrl, useToast } from 'oat-common-ui';
import { useCallback, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../../components/MainHeader';
import NGCHeader from '../../../components/NGCHeader';
import UploadFile from '../../../components/UploadFile';
import { UPLOAD_TYPE } from '../../../constants/global';
import { PresignedUrlResponse, useUploadBosuSettingsMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import getPresignedUploadUrl from '../../../utils/getPresignedUploadUrl';
import Header from '../components/header/Header';
import { BOSU_FILE_EXTENSION, BOSU_INVALID_FILE_EXTENSION_MESSAGE, validateBosuFileExtension } from '../../../utils/bosu';

const BosuComponent = () => {
  const {
    bosuStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();
  const { error, success } = useToast();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');

  const [uploadBosuSettings] = useUploadBosuSettingsMutation();

  const handleClick = () => hiddenFileInput.current?.click();

  const notifyInvalidFileExtension = useCallback(() => error(BOSU_INVALID_FILE_EXTENSION_MESSAGE), [error]);

  const isValidFileExtension = useCallback(validateBosuFileExtension, []);

  const handleChange = () => {
    const files = hiddenFileInput.current?.files as FileList;
    setFileName(files[0].name);

    if (!isValidFileExtension(files[0].name)) {
      notifyInvalidFileExtension();
    }
  };

  const handleUpload = async () => {
    if (hiddenFileInput.current?.files) {
      try {
        const file = hiddenFileInput.current?.files[0];

        if (isValidFileExtension(file.name)) {
          const presignedUrlResponse: PresignedUrlResponse = await getPresignedUploadUrl(UPLOAD_TYPE.BOSU, fileName, brand);
          await uploadWithPresignedUrl(presignedUrlResponse.presignedUrl, file);

          const { data } = await trackPromise(
            uploadBosuSettings({
              variables: {
                input: {
                  brand,
                  s3Key: presignedUrlResponse.s3Key,
                  fileName: presignedUrlResponse.fileName,
                },
              },
            }),
          );
          bosuStore.setLastUploadedFile(data?.uploadBosuContent?.lastUploadedFile);
          setFileName('');
          if (hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
          }
          success(`Uploaded successfully`);
        } else {
          notifyInvalidFileExtension();
        }
      } catch (e) {
        error((e as Error).message);
      }
    }
  };

  return (
    <>
      <Header>
        <MainHeader showDefault={false} />
        <NGCHeader title="BOSU" />
      </Header>
      <UploadFile
        fileName={fileName}
        handleBrowse={handleClick}
        handleFileChange={handleChange}
        handleUpload={handleUpload}
        hasDate={false}
        lastUploadedFile={bosuStore.lastUploadedFile}
        ref={hiddenFileInput}
        setFileName={setFileName}
        title="Bosu File"
        accept={BOSU_FILE_EXTENSION}
      />
    </>
  );
};

export default observer(BosuComponent);
