import { NumberInput } from 'oat-common-ui';
import styles from '../../styles.module.scss';

interface Props {
  value: string | 0;
  onChangeHandler: (val: number) => void;
  onBlurHandler: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const UpdateValueInput = ({ value, onChangeHandler, onBlurHandler }: Props) => {
  return (
    <div className={styles.inputContainer}>
      <span className={styles.dollarSign}>$</span>
      <NumberInput
        units
        isNational
        wholeNumber
        maxLength={6}
        className={styles.seriesBoxListItemInput}
        value={value}
        onValueChange={val => onChangeHandler(Number(val.value))}
        onBlur={e => onBlurHandler(e)}
      />
    </div>
  );
};

export default UpdateValueInput;
