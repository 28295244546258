import { useCallback, useMemo } from 'react';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import { formatMillions, formatDollars } from 'oat-common-ui';
import AprModel from '../../models/offers/apr/AprModel';
import LeaseFormModel from '../../models/offers/lease/LeaseFormModel';
import FinalPayModel from '../../models/offers/FinalPayModel';
import MiscModel from '../../models/offers/MiscModel';
import CashModel from '../../models/offers/CashModel';
import NationalRyoModel from '../../models/offers/NationalRyoModel';
import { AdminConstants } from 'oat-admin-common/';

interface SummaryCosts {
  tfsCost: string;
  tmsCost: string;
}

type T = AprModel | MiscModel | FinalPayModel | LeaseFormModel | CashModel | NationalRyoModel;

const { OPTION_TYPE_NAMES } = AdminConstants;

export const useSummaryCosts = (rgnlAlt: RgnlAltModel, offer: T, isNgl = false): SummaryCosts => {
  const {
    offeringCostsStore: { getOfferCost, showTfsEnhanced, rgnlAltCosts, offeringCosts },
  } = useStores();

  const formatValue = useCallback((value: number) => {
    if (value < 100_000) {
      return formatDollars(value);
    } else {
      return formatMillions(value, true, 2, true);
    }
  }, []);

  const { tmsCost, tfsCost } = useMemo(() => {
    const offerCost = getOfferCost(rgnlAlt.data.id, offer.offerFields.id);
    const raCost = rgnlAltCosts[rgnlAlt.data.id];

    if (!offerCost || !offeringCosts.rev) {
      return {
        tmsCost: formatValue(0),
        tfsCost: formatValue(0),
      };
    }

    switch (offer.offerFields.optionType) {
      case OPTION_TYPE_NAMES.LEASE:
        const leaseOffer = offer as LeaseFormModel;

        if (isNgl) {
          const { rvSupportPenRate, rvSupportPnvs, rvSupportTfsPnvs } = leaseOffer.leaseFields;
          const rvSupportPenetrationRate = Number(rvSupportPenRate) / 100;

          return {
            tmsCost: formatValue(Math.round(Math.round(Number(rvSupportPnvs) * rvSupportPenetrationRate) * raCost.sales)),
            tfsCost: showTfsEnhanced ? formatValue(0) : formatValue(Math.round(Number(rvSupportTfsPnvs) * rvSupportPenetrationRate) * raCost.sales),
          };
        }
        const penetrationRate = Number(offer.offerFields.penetrationRate) / 100;
        const { rcfPnvs, subCashPnvs, rcfTfsPnvs, subCashTfsPnvs, subCashEnhancedTfsPnvs, ncsEnhancedTfsPnvs, rcfEnhancedTfsPnvs, ncsTfsPnvs } = leaseOffer.leaseFields;

        return {
          tmsCost: formatValue(Math.round(Math.round((rcfPnvs + subCashPnvs) * penetrationRate) * raCost.sales)),
          tfsCost: showTfsEnhanced
            ? formatValue(Math.round(Math.round((rcfEnhancedTfsPnvs + subCashEnhancedTfsPnvs + ncsEnhancedTfsPnvs) * penetrationRate) * raCost.sales))
            : formatValue(Math.round(Math.round((rcfTfsPnvs + subCashTfsPnvs + ncsTfsPnvs) * penetrationRate) * raCost.sales)),
        };

      default:
        return {
          tmsCost: formatValue(Math.round(offerCost.offerRaPnvs * raCost.sales)),
          tfsCost: showTfsEnhanced ? formatValue(Math.round(offerCost.offerRaEnhancedTfsPnvs * raCost.sales)) : formatValue(Math.round(offerCost.offerRaTfsPnvs * raCost.sales)),
        };
    }
  }, [formatValue, getOfferCost, isNgl, offer, rgnlAlt.data.id, rgnlAltCosts, showTfsEnhanced, offeringCosts.rev]);

  return { tfsCost, tmsCost };
};
