import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import ModelYearDetailModel from '../../models/ModelYearDetailModel';
import styles from '../../styles.module.scss';
import { getUpdateFunctions, seriesItemToRender } from '../../utils';
import FYSeriesBoxListItem from './FYSeriesBoxListItem';

interface Props {
  title: string;
  modelYearDetails?:{ [modelYear: number]: ModelYearDetailModel };
  seriesFYMYTotals?: { [modelYear: number]: AdminModels.SeriesFYMYTotal };
  isFyTotal?: boolean;
  yearsList: number[];
}

const SeriesItemRowDetail = ({ modelYearDetails, seriesFYMYTotals, title, isFyTotal, yearsList }: Props) => {

  return (
    <div className={clsx(styles.fySeriesItemSeriesBox)}>
      <ul className={styles.fySeriesBoxList}>
        {yearsList.map((year, i) => {
          if (isFyTotal && seriesFYMYTotals) {
            const item = seriesFYMYTotals[year];
            return (
              <li key={`${year}-${title}-${i}`}>
                <FYSeriesBoxListItem hasValue={!!item} itemValue={seriesItemToRender(title, item)} title={title} isFyTotal={isFyTotal} />
              </li>
            );
          } else {
            const item = modelYearDetails?.[year];
            const { updateValue, updateParentValues } = getUpdateFunctions(title, item);
            return (
              <li key={`${year}-${title}-${i}`}>
                <FYSeriesBoxListItem hasValue={!!item} itemValue={seriesItemToRender(title, item)} title={title} updateValue={updateValue} updateParentValues={updateParentValues} />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default observer(SeriesItemRowDetail);
