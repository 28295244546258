import { OATLoadingBar } from 'oat-common-ui';
import { useGetLastBosuLogQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import BosuComponent from './BosuComponent';

const Bosu = () => {
  const {
    bosuStore,
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const {
    data: bosuLogData,
    loading: bosuLogLoading,
    error: bosuLogError,
  } = useGetLastBosuLogQuery({
    variables: {
      brand,
    },
  });

  if (bosuLogLoading) {
    return <OATLoadingBar />;
  }

  if (bosuLogError) {
    return <Error />;
  }

  if (bosuLogData && bosuLogData.lastBosuLog) {
    bosuStore.setLastUploadedFile(bosuLogData.lastBosuLog);
  }

  return <BosuComponent />;
};

export default Bosu;
