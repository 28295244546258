import { makeAutoObservable } from 'mobx';
import { getFiscalYearMonths } from 'oat-admin-common';
import { FiscalMonth } from 'oat-admin-common/dist/types/models';
import { dateStringToDate, sortFieldsHelper, uuidv4 } from 'oat-common-ui';
import { MultiSeriesOffersBudget, OfferingYearMonth } from '../gql/generated';
import MultiSeriesOfferModel from '../pages/FYRollup/MultiSeriesOffers/models/MultiSeriesOfferModel';
import { sortForFiscalYear } from '../utils/sortForFiscalYear';

type MultiOfferTotalCost = Array<{ key: string; value: number }>;

class MultiSeriesOffersBudgetStore {
  id = uuidv4();
  rev: string = '';
  brand: string = '';
  fiscalYear: number = dateStringToDate(new Date()).getFullYear() + 1;

  offers: MultiSeriesOfferModel[] = [];
  totalCosts: MultiOfferTotalCost = [];
  draftOfferingMonths: OfferingYearMonth[] = [];

  isFetched = false;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFiscalYear = (year: number) => {
    this.fiscalYear = year;
  };

  setIsLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  setIsFetched = (val: boolean) => {
    this.isFetched = val;
  };

  updateRev = (rev: string) => {
    this.rev = rev;
  };

  initData = (brand: string, budget?: MultiSeriesOffersBudget | null, draftOfferingMonths?: OfferingYearMonth[]) => {
    this.rev = budget?.rev ?? '';
    this.brand = brand;
    this.fiscalYear = budget?.fiscalYear ?? this.fiscalYear;
    this.draftOfferingMonths = draftOfferingMonths ?? [];

    this.offers =
      budget?.offers?.sort(sortFieldsHelper('sortOrder', true)).map((offer, index) => {
        return new MultiSeriesOfferModel({ ...offer, sortOrder: index });
      }) || [];

    this.setTotalCosts();
  };

  setTotalCosts = () => {
    this.totalCosts = [];
    const totalCosts: MultiOfferTotalCost = [];

    for (const offer of this.offers) {
      for (const month of sortForFiscalYear(offer.months)) {
        const totalItemIndex = totalCosts.findIndex(cost => cost.key === `${month.month}_${month.year}`);

        if (totalItemIndex !== -1) {
          totalCosts[totalItemIndex].value += Number(month.amount);
        } else {
          totalCosts.push({ key: `${month.month}_${month.year}`, value: Number(month.amount) });
        }
      }
    }

    this.totalCosts = totalCosts;
  };

  setOffers = (offers: MultiSeriesOfferModel[]) => {
    this.offers = offers;
  };

  addNewMultiSeriesOffer = () => {
    const emptyMonths = getFiscalYearMonths(this.fiscalYear);

    const newMultiSeriesOffer = new MultiSeriesOfferModel({
      id: uuidv4(),
      months: sortForFiscalYear(emptyMonths.map(month => ({ ...month, id: uuidv4(), amount: 0 }))),
      offerName: '',
      sortOrder: this.offers.length,
    });

    this.offers.push(newMultiSeriesOffer);
    this.setTotalCosts();
  };

  deleteMultiSeriesOffer = (id: string) => {
    this.offers = this.offers.filter(multiSeriesOffer => multiSeriesOffer.id !== id);
    this.setTotalCosts();
  };

  get totalBudget(): number {
    return this.totalCosts.reduce((acc, curr) => acc + curr.value, 0);
  }

  get hasErrror() {
    return this.offers.some(offer => !offer.offerName);
  }

  get headerDetails(): FiscalMonth[] {
    return getFiscalYearMonths(this.fiscalYear);
  }
}

export default MultiSeriesOffersBudgetStore;
