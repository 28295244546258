import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { SeriesSelectionInput, useSaveSeriesSelectionsMutation } from '../../../../../gql/generated';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import FooterCtas from '../../../components/FooterCtas';
import { xformToBosuSeries } from '../../utils';

const Footer = () => {
  const {
    seriesAndModelsStore: {
      seriesMapping,
      seriesSelections: { selectedSeries },
      setHasUnsavedChanges,
    },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();
  const [saveSeriesSelections] = useSaveSeriesSelectionsMutation();
  const { error } = useToast();
  const { offeringId } = useUrlParams();

  const handleSave = async () => {
    const seriesSelectionsInput: SeriesSelectionInput = {
      offeringId,
      selectedSeries: xformToBosuSeries(selectedSeries, seriesMapping),
    };

    try {
      await trackPromise(saveSeriesSelections({ variables: { input: seriesSelectionsInput } }));
      setHasUnsavedChanges(false);
    } catch (e) {
      error((e as Error).message);
    }
  };

  return <FooterCtas onSave={handleSave} disabled={isPublished} />;
};

export default observer(Footer);
