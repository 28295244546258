import { CreateAprOfferInput, UpdateAprOfferInput } from '../../../../../gql/generated';
import AprModel from '../../../models/offers/apr/AprModel';
import TermWeightedCostModel from '../models/TermWeightedCostModel';
import TierPenRateModel from '../models/TierPenRateModel';
import TierTermModel from '../models/TierTermModel';

const processTierTerms = (tierTerms: TierTermModel[]) => {
  return tierTerms.map(t => ({
    tier: t.tier,
    term: t.term,
    rate: Number(t.rate),
    standardRate: t.standardRate,
    tfsShare: Number(t.tfsShare),
    penetrationRate: Number(t.penetrationRate),
    averageAmountFinanced: Number(t.averageAmountFinanced),
    monthlyPayment: Number(t.monthlyPayment),
    pnvs: t.pnvs,
    fullPnvs: t.fullPnvs,
    tfsPnvs: t.tfsPnvs,
    fullTfsPnvs: t.fullTfsPnvs,
    enhancedTfsPnvs: t.enhancedTfsPnvs,
    ncsTfsPnvs: t.ncsTfsPnvs,
    ncsEnhancedTfsPnvs: t.ncsEnhancedTfsPnvs,
  }));
};

const processTierPenRates = (tierPenRates: TierPenRateModel[]) => {
  return tierPenRates.map(t => ({
    tier: t.tier,
    penetrationRate: Number(t.penetrationRate),
  }));
};

const processTermWeightedCosts = (termWeightedCosts: TermWeightedCostModel[]) => {
  return termWeightedCosts.map(t => ({
    term: t.term,
    pnvs: t.pnvs,
    tfsPnvs: t.tfsPnvs,
    enhancedTfsPnvs: t.enhancedTfsPnvs,
    fullPnvs: t.fullPnvs,
    fullTfsPnvs: t.fullTfsPnvs,
    ncsTfsPnvs: t.ncsTfsPnvs,
    ncsEnhancedTfsPnvs: t.ncsEnhancedTfsPnvs,
  }));
};

const createAprParams = (apr: AprModel) => {
  const {
    aprFields: {
      tierTerms,
      tierPenRates,
      termWeightedCosts,
      subCashAmount,
      subCashCostShare,
      subCashCostShareCap,
      subCashPnvs,
      subCashTfsPnvs,
      subCashEnhancedTfsPnvs,
      offerPnvs,
      offerTfsPnvs,
      offerEnhancedTfsPnvs,
    },
    ncsRate,
  } = apr;

  return {
    isNcsApplied: ncsRate > 0,
    tierTerms: processTierTerms(tierTerms),
    tierPenRates: processTierPenRates(tierPenRates),
    termWeightedCosts: processTermWeightedCosts(termWeightedCosts),
    subCashAmount: Number(subCashAmount),
    subCashCostShare: Number(subCashCostShare),
    subCashCostShareCap: Number(subCashCostShareCap),
    subCashPnvs,
    subCashTfsPnvs,
    subCashEnhancedTfsPnvs,
    offerPnvs,
    offerTfsPnvs,
    offerEnhancedTfsPnvs,
    costShareId: apr.offerFields.costShareId,
  };
};

export const createNewAprParams = (apr: AprModel, sortOrder: number, isLexus: boolean): CreateAprOfferInput => {
  const { offerFields } = apr;

  return {
    name: offerFields.name,
    isForRegions: offerFields.isForRegions,
    isEnhTfsCostShareForRegions: isLexus ? true : offerFields.isEnhTfsCostShareForRegions,
    isEnhSubCashTfsCostShareForRegions: isLexus ? true : offerFields.isEnhSubCashTfsCostShareForRegions,
    optionType: offerFields.optionType,
    rgnlAltId: offerFields.rgnlAltId,
    penetrationRate: Number(offerFields.penetrationRate),
    sortOrder,
    ...createAprParams(apr),
  };
};

export const createUpdateAprParams = (apr: AprModel, isLexus: boolean): UpdateAprOfferInput => {
  const { offerFields } = apr;

  return {
    id: offerFields.id,
    rev: offerFields.rev,
    name: offerFields.name,
    isForRegions: offerFields.isForRegions,
    isEnhTfsCostShareForRegions: isLexus ? true : offerFields.isEnhTfsCostShareForRegions,
    isEnhSubCashTfsCostShareForRegions: isLexus ? true : offerFields.isEnhSubCashTfsCostShareForRegions,
    optionType: offerFields.optionType,
    rgnlAltId: offerFields.rgnlAltId,
    penetrationRate: Number(offerFields.penetrationRate),
    isCostShareUpdated: offerFields.isCostShareUpdated,
    ...createAprParams(apr),
  };
};
