import buildOfferTypes from './buildOfferTypes';

export const sortAddOffers = (offers: string[]) => {
  const { frequent, other } = buildOfferTypes();
  const offerTypesMap = new Map<string, number>([...frequent, ...other].map((offerType, i) => [offerType, i]));

  const sortedAddOffers = offers.slice().sort((a, b) => {
    const sortOrderA = offerTypesMap.get(a) ?? 0;
    const sortOrderB = offerTypesMap.get(b) ?? 0;

    return sortOrderA - sortOrderB;
  });

  return sortedAddOffers;
};
