import { useLocation, useParams } from 'react-router-dom';

type PathParamType = {
  ip: string;
  offeringId: string;
  tdaCode: string;
};

const useUrlParams = () => {
  const { ip, offeringId, tdaCode } = useParams<PathParamType>();
  const query = new URLSearchParams(useLocation().search);
  const offerId = query.get('offerId') ?? '';
  const lang = query.get('lang') ?? '';
  const site = query.get('site') ?? '';

  return {
    site,
    tdaCode,
    ip,
    offeringId,
    offerId,
    lang,
  };
};

export default useUrlParams;
