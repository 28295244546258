import apolloClient from '../apolloClient';
import { UPLOAD_TYPE } from '../constants/global';
import { GetPresignedUploadUrlDocument, GetPresignedUploadUrlQueryVariables, PresignedUrlResponse } from '../gql/generated';

const getVars = (type: string, fileName: string, brand: string, offeringId?: string) => {
  const vars: GetPresignedUploadUrlQueryVariables = {
    type: type,
    fileName: fileName,
    brand: brand,
    offeringId,
  };

  return vars;
};

const getPresignedUploadUrl = async (type: UPLOAD_TYPE, fileName: string, brand: string, offeringId?: string): Promise<PresignedUrlResponse> => {
  const response = await apolloClient.query({ query: GetPresignedUploadUrlDocument, variables: getVars(type, fileName, brand, offeringId) });
  return response.data.presignedUploadUrl;
};

export default getPresignedUploadUrl;
