import AccordionItemModel from '../models/AccordionItemModel';

const recursiveDisplay = (items: AccordionItemModel[], displayParam?: string[]) => {
  const display = displayParam ? displayParam : [];
  items.forEach(item => {
    if (item.checked && item.items.length > 0) {
      recursiveDisplay(item.items, display);
    } else if (item.checked && !item.items.length && !display.includes(item.label)) {
      display.push(item.label);
    }
  });

  return display;
};

export default recursiveDisplay;
