import { observer } from 'mobx-react-lite';
import { OATLoadingBar } from 'oat-common-ui';
import { GetOfferingsResponse, useGetOfferingsQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import Error from '../ErrorPage';
import DashboardComponent from './DashboardComponent';

const Dashboard = () => {
  const { dashboardStore, userInfoStore } = useStores();

  const { data, loading, error } = useGetOfferingsQuery({
    variables: {
      regionCode: userInfoStore.userInfo.regionCodes,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    dashboardStore.processQuery(data.getOfferings as GetOfferingsResponse);
  }

  return <DashboardComponent />;
};

export default observer(Dashboard);
