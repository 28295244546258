import AccordionItemModel from '../models/AccordionItemModel';

/**
 * @param items Should be root items
 */
const recursiveToggleExpandAll = (accItems: AccordionItemModel[], isExpand: boolean) => {
  accItems.forEach(i => {
    if (!i.disabled) {
      i.expand = isExpand;
      if (i.items.length > 0) {
        recursiveToggleExpandAll(i.items, isExpand);
      }
    }
  });
};

export default recursiveToggleExpandAll;
