import { cloneDeep } from '@apollo/client/utilities';
import { AdminModels } from 'oat-admin-common';

const applyBudgetAttributes = (source: AdminModels.FYRollup, target: AdminModels.FYRollup, keys: Array<keyof AdminModels.Total>, addRemoveSeriesMY = false): void => {

  // loop thru the target series list
  // if series is found in source, then apply the values and remove it
  // if addRemoveSeriesMY is true and if no source item is found, then set the value as 0.
  // if addRemoveSeriesMY is true, add any new items to target (new modelYearItems, new months in a series, new series), always 0 the pnvs. This use case is only when syncing sales

  for (const seriesItem of Object.values(target.seriesList)) {
    const sourceSeriesItem = source.seriesList[seriesItem.series] ?? undefined;
    if (sourceSeriesItem || addRemoveSeriesMY) {
      // apply the values for all the months
      for (const seriesMonth of Object.values(seriesItem.seriesMonths)) {
        const sourceSeriesMonth = sourceSeriesItem?.seriesMonths[seriesMonth.month] ?? undefined;
        if (sourceSeriesMonth || addRemoveSeriesMY) {
          // loop through the modelYearDetails in the month
          for (const modelYearItem of Object.values(seriesMonth.modelYearDetails)) {
            const sourceModelYearItem = sourceSeriesMonth?.modelYearDetails[modelYearItem.modelYear] ?? undefined;
            if (sourceModelYearItem || addRemoveSeriesMY) {
              // apply the values for the model year item
              keys.forEach(key => modelYearItem[key] = sourceModelYearItem?.[key] ?? 0);
            }
            // delete the item from source
            delete sourceSeriesMonth?.modelYearDetails[modelYearItem.modelYear];
          }
          // if there are any remaining model years in the series month, add them to target
          if (addRemoveSeriesMY && sourceSeriesMonth && Object.values(sourceSeriesMonth.modelYearDetails).length) {
            Object.values(sourceSeriesMonth.modelYearDetails).forEach(sourceModelYearItem => {
              seriesMonth.modelYearDetails[sourceModelYearItem.modelYear] = { ...sourceModelYearItem, pnvs: 0, totalCost: 0 };
            });
          }
        }
        // delete the item from source
        delete sourceSeriesItem?.seriesMonths[seriesMonth.month];
      }
      // if there are any remaining months in the series item, add them to target
      if (addRemoveSeriesMY && sourceSeriesItem && Object.values(sourceSeriesItem.seriesMonths).length) {
        Object.values(sourceSeriesItem.seriesMonths).forEach(sourceSeriesMonth => {
          const copiedSeriesMonth = cloneDeep(sourceSeriesMonth);
          // 0 the pnvs & total cost
          Object.values(copiedSeriesMonth.modelYearDetails).forEach(item => {
            item.pnvs = 0;
            item.totalCost = 0;
          });
          seriesItem.seriesMonths[sourceSeriesMonth.month] = copiedSeriesMonth;
        });
      }
    }
    // delete the item from source
    delete source.seriesList[seriesItem.series];
  }
  // if there are any remaining series in the source, add them to target
  if (addRemoveSeriesMY && Object.values(source.seriesList).length) {
    Object.values(source.seriesList).forEach(sourceSeriesItem => {
      const copiedSeries = cloneDeep(sourceSeriesItem);
      // 0 the pnvs & total cost
      Object.values(copiedSeries.seriesMonths).forEach(copiedMonth => {
        Object.values(copiedMonth.modelYearDetails).forEach(item => {
          item.pnvs = 0;
          item.totalCost = 0;
        });
      })
      target.seriesList[sourceSeriesItem.series] = copiedSeries;
    });
  }
}

export default applyBudgetAttributes;
