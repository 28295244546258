import { observer } from 'mobx-react-lite';
import { Textarea, assignNumberValue, useInputDelay, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import { MultiSeriesField, OfferingCosts, Scenario, useSaveScenarioNoteMutation, useUpdateMultiSeriesValuesMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel, appendTmsLabel } from '../../../../../utils/appendTfsLabel';
import NumberStack from '../../../components/NumberStack';
import ScenarioModel from '../../../models/ScenarioModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import { createMultiSeriesInput } from '../utils/createMultiSeriesInput';
import styles from './styles.module.scss';

interface Props {
  scenario: ScenarioModel;
  seriesProfile: SeriesProfileModel;
}

const cellWidth = 85;
const editableCellWidth = 120;

const MultiSeriesScenarioInfo = ({ scenario, seriesProfile }: Props) => {
  const {
    offeringCostsStore: { showTfsEnhanced, scenarioCosts, updateSaleByOne, updateScenarioField, updateCostByOne, updateOfferingCostsFromResponse },
    userInfoStore: { isLexus },
  } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const [editSales, setEditSales] = useState(false);
  const [editProposedTms, setEditProposedTms] = useState(false);

  const [saveScenarioNote] = useSaveScenarioNoteMutation();
  const [updateMultiSeriesValues] = useUpdateMultiSeriesValuesMutation();

  const handleNoteChange = (note: string) => {
    scenario.updateNote(note);

    setDelay(async () => {
      try {
        const res = await trackPromise(
          saveScenarioNote({
            variables: {
              input: {
                note: scenario.note,
                rev: scenario.rev,
                scenarioId: scenario.id,
              },
            },
          }),
        );

        if (res.data?.saveScenarioNote.data) {
          scenario.setData(res.data.saveScenarioNote.data as Scenario);
        }
      } catch (e) {
        error((e as Error).message);
      }
    }, 1000);
  };

  const handleSaveMultiSeriesValues = async (field: MultiSeriesField, value: number) => {
    try {
      const res = await trackPromise(updateMultiSeriesValues({ variables: { input: createMultiSeriesInput(seriesProfile, field, value) } }));
      seriesProfile.setRev(res.data?.updateMultiSeriesValues.seriesProfile.rev || '');
      updateOfferingCostsFromResponse(res.data?.updateMultiSeriesValues.offeringCosts as OfferingCosts);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const editSalesHandler = () => {
    setEditSales(!editSales);

    if (editSales) {
      handleSaveMultiSeriesValues(MultiSeriesField.Sales, scenarioCosts[scenario.id].sales);
    }
  };

  const salesOnChangeHandler = (value: string) => {
    updateScenarioField(scenario.id, 'sales', Number(value));
  };

  const editProposedTmsHandler = () => {
    setEditProposedTms(!editProposedTms);

    if (editProposedTms) {
      handleSaveMultiSeriesValues(MultiSeriesField.Cost, scenarioCosts[scenario.id].cost);
    }
  };

  // updates pnvs in offeringCosts
  const proposedTmsOnChangeHandler = (value: string) => {
    updateScenarioField(scenario.id, 'cost', Number(value));
  };

  const costs = scenarioCosts[scenario.id];
  const tfsCostValue = showTfsEnhanced ? costs?.enhancedTfsCost : costs?.tfsCost;

  return (
    <fieldset className={styles.scenarioInfo} disabled={seriesProfile.data.isBudgetConfirmed}>
      <NumberStack
        value={assignNumberValue(costs?.sales)}
        wholeNumber
        label="Sales"
        smallFormat
        width={editableCellWidth}
        dollarSign={false}
        classNameContainer={styles.stackContainer}
        classNameNumberInput={styles.editInput}
        addEditBtn={!seriesProfile.data.isBudgetConfirmed}
        editHandler={editSalesHandler}
        edit={editSales}
        onValueChangeHandler={salesOnChangeHandler}
        addOne={() => updateSaleByOne(scenario.id, true)}
        subtractOne={() => updateSaleByOne(scenario.id, false)}
        rightBorder
      />
      <NumberStack
        value={assignNumberValue(costs?.budgetedCost)}
        label={appendTmsLabel(isLexus(), 'Cost', 'Budgeted')}
        width={cellWidth}
        classNameContainer={styles.stackContainer}
        smallFormat
      />
      <NumberStack
        value={assignNumberValue(costs?.cost)}
        wholeNumber
        label={appendTmsLabel(isLexus(), 'Cost', 'Proposed')}
        width={cellWidth}
        classNameContainer={styles.stackContainer}
        classNameNumberInput={styles.editInput}
        smallFormat
        addEditBtn={!seriesProfile.data.isBudgetConfirmed}
        onValueChangeHandler={proposedTmsOnChangeHandler}
        editHandler={editProposedTmsHandler}
        addOne={() => updateCostByOne(true, scenario.id)}
        subtractOne={() => updateCostByOne(false, scenario.id)}
        edit={editProposedTms}
      />
      <NumberStack
        value={assignNumberValue(costs?.costDifference)}
        label="Difference"
        width={cellWidth}
        classNameContainer={styles.stackContainer}
        useColors
        smallFormat
        rightBorder
      />
      <NumberStack
        value={assignNumberValue(tfsCostValue)}
        label={appendTfsLabel(isLexus(), 'Cost', showTfsEnhanced ? 'Enh. ' : '')}
        width={cellWidth}
        classNameContainer={styles.stackContainer}
        smallFormat
        rightBorder
        useMillions={tfsCostValue >= 100000}
        roundMillions={tfsCostValue >= 100000}
      />
      <BlockInputLabel label="Notes" className={styles.notesWrapper}>
        <Textarea
          id="scenario-notes-input"
          className={styles.multiSeriesNote}
          value={scenario.note}
          onChange={e => handleNoteChange(e.target.value)}
          disabled={seriesProfile.data.isBudgetConfirmed}
        />
      </BlockInputLabel>
    </fieldset>
  );
};

export default observer(MultiSeriesScenarioInfo);
