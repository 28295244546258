import { sortFieldsHelper } from 'oat-common-ui';

const getSortedTiers = (tiers: Array<any>) => {
  const tierOnePlusTierRate = tiers?.filter(t => t.tier === '1+');
  const otherTierRate = tiers?.filter(t => t.tier !== '1+').sort(sortFieldsHelper('tier', true));
  const tierPenRatesToShow = [...tierOnePlusTierRate, ...otherTierRate];

  return tierPenRatesToShow;
};
export default getSortedTiers;
