import cx from 'clsx';
import { OATIcon, Tooltip, useComponentVisible } from 'oat-common-ui';
import { ReactNode } from 'react';

import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  id: string;
};

const ScenariosMenu = ({ children, className, disabled, id }: Props) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div className={cx(styles.manageScenariosMenu, className)}>
      <Tooltip disable={disabled} message={'Manage Scenarios'} id={`manage-tooltip-${id}`} place="bottom">
        <button id={`manage-scenarios-cta-${id}`} disabled={disabled} onClick={() => setIsComponentVisible(!isComponentVisible)}>
          <OATIcon icon="navigation-more" size={25} />
        </button>
      </Tooltip>
      {isComponentVisible && (
        <ul className={styles.list} ref={ref} onClick={() => setIsComponentVisible(false)}>
          {children}
        </ul>
      )}
    </div>
  );
};

export default ScenariosMenu;
