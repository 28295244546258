import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { AprTermWeightedCosts } from '../../../../../gql/generated';

class TermWeightedCostModel {
  uid = uuidv4();
  term = 0;
  pnvs = 0;
  tfsPnvs = 0;
  enhancedTfsPnvs = 0;
  fullPnvs = 0;
  fullTfsPnvs = 0;
  ncsTfsPnvs = 0;
  ncsEnhancedTfsPnvs = 0;

  constructor(data?: AprTermWeightedCosts) {
    makeAutoObservable(this);

    if (data) {
      this.term = data.term;
      this.pnvs = data.pnvs;
      this.tfsPnvs = data.tfsPnvs;
      this.enhancedTfsPnvs = data.enhancedTfsPnvs;
      this.fullPnvs = data.fullPnvs;
      this.fullTfsPnvs = data.fullTfsPnvs;
      this.ncsTfsPnvs = data.ncsTfsPnvs;
      this.ncsEnhancedTfsPnvs = data.ncsEnhancedTfsPnvs;
    }
  }
}

export default TermWeightedCostModel;
