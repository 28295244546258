import { OATIcon } from 'oat-common-ui';
import Button from '../../../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  term: number;
  showHidden: boolean;
  handleChange: (term: number) => void;
}

const RCFNavItem = ({ term, showHidden, handleChange }: Props) => {
  return (
    <>
      <div className={styles.rcfNavFixed}>
        <span className={styles.labelText}>{term}</span>
      </div>
      <div className={!showHidden ? styles.rcfNavLineCollapsed : styles.rcfNavLine} />
      <div className={styles.rcfNavFixed}>
        <Button id={`${term}-expand-btn`} variant="text" className={styles.rcfNavBtn} onClick={() => handleChange(term)}>
          <OATIcon icon={showHidden ? 'minus' : 'add'} colorTheme="blue" />
        </Button>
      </div>
      <div className={!showHidden ? styles.rcfNavLineCollapsed : styles.rcfNavLine} />
    </>
  );
};

export default RCFNavItem;
