import { makeAutoObservable } from 'mobx';
import { assignStringValue, uuidv4, validator } from 'oat-common-ui';
import { SeriesProfile } from '../../../../gql/generated';

class CostShareModel {
  uid = uuidv4();
  series = '';
  seriesYear = '';
  ncsRate = '';
  ncsRcf = '';
  rev = '';
  label = '';
  seriesProfileId = '';

  constructor(series?: SeriesProfile) {
    makeAutoObservable(this);

    if (series) {
      this.setSeriesProfileData(series);
      this.setCostsFromSeriesProfile(series);
    }
  }

  addNew = () => {
    this.series = '';
    this.ncsRate = '';
    this.ncsRcf = '';
  };

  setSeriesProfileData = (seriesProfile: SeriesProfile) => {
    this.seriesProfileId = seriesProfile.id;
    this.series = seriesProfile.series;
    this.seriesYear = `${seriesProfile.modelYear}`;
    this.rev = seriesProfile.rev;
    this.label = !seriesProfile.multiSeriesOfferId ? `${this.seriesYear} ${this.series}` : this.series;
  };

  setCostsFromSeriesProfile = (seriesProfile: SeriesProfile) => {
    this.ncsRate = assignStringValue(seriesProfile?.ncsRate);
    this.ncsRcf = assignStringValue(seriesProfile?.ncsRcf);
  };

  updateNcsRate = (value: string) => {
    this.ncsRate = value;
  };

  updateNcsRcf = (value: string) => {
    this.ncsRcf = value;
  };

  get hasRcfError() {
    return validator(this.ncsRcf, { required: true, min: 0 }) !== undefined;
  }

  get hasRateError() {
    return validator(this.ncsRate, { required: true, min: 0, max: 99 }) !== undefined;
  }
}

export default CostShareModel;
