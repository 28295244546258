import { OATFooter } from 'oat-common-ui';
import React, { ReactNode } from 'react';
import authClient from '../../authClient';

interface Props {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  darkTheme?: boolean;
  isShowPublish?: boolean;
  isDisablePublish?: boolean;
  onPublish?: () => void;
  onPreviewPublish?: () => void;
  helpUrl?: string;
}

const MainFooter = ({ onPublish, onPreviewPublish, ...rest }: Props) => {
  const handlePublish = () => {
    if (onPublish) {
      onPublish();
    }
  };

  const handlePreviewPublish = () => {
    if (onPreviewPublish) {
      onPreviewPublish();
    }
  };

  return <OATFooter {...rest} onClickLogout={() => authClient.logout()} onPublish={handlePublish} onPreviewPublish={handlePreviewPublish} />;
};

export default MainFooter;
