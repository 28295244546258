import { DropdownItem, formatNumber } from 'oat-common-ui';

/**
 * Converts array of numbers to dropdown
 */
const toDDList = (arr: number[]): DropdownItem[] => {
  return arr.map(item => ({
    value: item.toString(),
    label: formatNumber(item, true),
  }));
};

export default toDDList;
