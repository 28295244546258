import { observer } from 'mobx-react-lite';
import { RcfInput } from 'oat-common-ui';
import { IAssumptionMonth } from '../../../../models/Assumptions';
import useStores from '../../../../stores/useStores';

import styles from '../styles.module.scss';

interface Props {
  assumptions: IAssumptionMonth[];
}

const StandardRcf = ({ assumptions }: Props) => {
  const {
    assumptionsStore: { updateAssumptionField },
  } = useStores();

  return (
    <div className={styles.ngcRow}>
      <div className={styles.ngcShadowWrapper}>
        <div className={styles.ngcGridRow}>
          <div className={styles.ngcRowHeader}>
            <label>Standard RCF</label>
          </div>
          {assumptions.map(assumption => (
            <div className={styles.ngcRowDetail} key={`standard-rcf-${assumption.id}`}>
              <RcfInput
                inputWrapperClassName={styles.rcfInputWrapper}
                inputClassName={styles.rcfInput}
                id={`standard-rcf-${assumption.month}`}
                onChange={(value: string) => {
                  updateAssumptionField('standardRcf', assumption.month, value === '' ? undefined : value);
                }}
                value={assumption.standardRcf.value ?? ''}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(StandardRcf);
