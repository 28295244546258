import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  seriesProfileId: string;
}

const SeriesTitle = ({ seriesProfileId }: Props) => {
  const {
    nationalCostShareStore: { addNewCostShare, addedSeriesOptions, costSharesBySeriesProfile, setHasUnsavedChanges },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const seriesTitle = addedSeriesOptions.find(o => o.value === seriesProfileId)?.label;

  const costShareSeriesProfile = costSharesBySeriesProfile.get(seriesProfileId);
  const isDisabled = costShareSeriesProfile?.costShares.some(c => c.hasFieldsError) || isPublished;
  const disabledStyle = isDisabled ? styles.btnDisabled : '';

  const handleAddNewCostShare = () => {
    addNewCostShare(seriesProfileId);
    setHasUnsavedChanges(true);
  };

  return (
    <div className={styles.seriesCostShareTitle}>
      <span className={styles.seriesTitle}>{seriesTitle}</span>
      <Button variant="text" id={`add-cost-share-btn-${seriesProfileId}`} onClick={() => handleAddNewCostShare()} disabled={isDisabled} className={styles.addCostShareBtn}>
        <OATIcon icon="plus" size={16} className={disabledStyle} />
        <span className={clsx(styles.btnText, disabledStyle)}>Cost Share</span>
      </Button>
    </div>
  );
};

export default observer(SeriesTitle);
