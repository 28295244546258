import { AdminConstants, getCompatibilityForOfferType } from 'oat-admin-common';
import AccordionItemModel from '../models/AccordionItemModel';

const sortOrderList = AdminConstants.COMPATIBILITY_MODAL_OPTION_TYPES;
const { OPTION_TYPE_NAMES } = AdminConstants;

const createAccordionFromCompatibility = (compatibility: string[], optionType: AdminConstants.OPTION_TYPE_NAMES) => {
  const compatibilityByType = getCompatibilityForOfferType(optionType) || {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };

  const items: AccordionItemModel[] = [];
  const { defaultTypes, incompatibleTypes, optionalTypes } = compatibilityByType;
  [...defaultTypes, ...optionalTypes].forEach(type => {
    const mdl = new AccordionItemModel(type, type);
    mdl.checked = !!compatibility.find(c => c === `${type}`);
    items.push(mdl);
  });

  incompatibleTypes.forEach(type => {
    const mdl = new AccordionItemModel(type, type);
    mdl.disabled = true;
    items.push(mdl);
  });

  const filteredItems = optionType === OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE ? items.filter(item => item.value !== OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE) : items;

  return filteredItems
    .filter(item => !item.value.includes('RYO')) // regarding comment on OR-2019
    .sort((itemA, itemB) => (sortOrderList.findIndex(item => itemA.value === item) > sortOrderList.findIndex(item => itemB.value === item) ? 1 : -1));
};

export default createAccordionFromCompatibility;
