import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, Input, InputContainer, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import {
  ModelCodeMappingResponse,
  SaveSeriesProfileVehiclesResult,
  VehicleInput,
  useGetModelCodeMappingsQuery,
  useSaveSeriesProfileVehiclesMutation,
} from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import MultiSeriesProfileModel from '../../SeriesModelsTab/models/MultiSeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  selectedProfile: MultiSeriesProfileModel;
  onClose: () => void;
}

const MultiSeriesProfileModal = ({ selectedProfile, onClose }: Props) => {
  const [saveSeriesProfileVehicles] = useSaveSeriesProfileVehiclesMutation();
  const { offeringId } = useUrlParams();
  const { data, loading: modelCodeMappingLoading, error: modelCodeMappingError } = useGetModelCodeMappingsQuery({ variables: { offeringId } });
  const {
    seriesAndModelsStore: { seriesProfiles, updateSeriesProfile, modelCodeMappings, seriesMapping },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();
  const selected = seriesProfiles.find(item => item.id === selectedProfile.id);
  const state = useLocalObservable(() => new MultiSeriesProfileModel(selected, modelCodeMappings.mappings, selectedProfile.vehicles));
  const isLoading = modelCodeMappingLoading;
  const hasError = modelCodeMappingError;

  const { error: toastError } = useToast();

  const handleSearchInputChange = (value: string) => {
    state.setSearchInput(value, seriesMapping);
    state.setResetInput(Boolean(value));
  };

  const handleCancelSearch = () => {
    state.setSearchInput('', seriesMapping);
    state.setResetInput(false);
  };

  const handleSubmit = async () => {
    try {
      const models: VehicleInput[] = [];
      models.push(
        ...state.accordion.items
          .flatMap(item => item.items)
          .flatMap(acItem => acItem.items)
          .filter(ac => ac.checked)
          .map(item => ({ id: item.id, isInclusion: true })),
      );

      const payload = {
        seriesProfileId: selectedProfile.id,
        rev: selectedProfile.rev,
        vehicles: models,
        skipTrickleDown: true,
      };

      const resp = await trackPromise(saveSeriesProfileVehicles({ variables: { input: payload } }));
      updateSeriesProfile(resp.data?.saveSeriesProfileVehicles as SaveSeriesProfileVehiclesResult);
      onClose();
    } catch (e) {
      toastError((e as Error).message);
    } finally {
      onClose();
    }
  };

  if (!state.loaded && data?.modelCodeMappingData && data?.seriesProfiles) {
    state.setAccordion(data?.modelCodeMappingData as ModelCodeMappingResponse[], seriesMapping);
    selectedProfile.rev = data.seriesProfiles.find(sp => sp?.id === selectedProfile.id)?.rev ?? selectedProfile.rev;
  }

  return (
    <Modal isOpen onClose={onClose} className={styles.modalWrapper}>
      <ModalHeader onClose={onClose}>Edit Multi-Series Profile</ModalHeader>
      <ModalBody className={styles.body}>
        {isLoading && 'Loading...'}
        {!isLoading && data?.modelCodeMappingData && !hasError && (
          <div>
            <InputContainer label="Series Profile Name" className={styles.inputContainer}>
              <Input readOnly id="edit-multi-series-profile" className={styles.seriesProfileNameInput} value={selectedProfile.name} />
            </InputContainer>
            <div className={styles.searchSeriesProfileBox}>
              <Input
                id="edit-series-profile-search"
                className={styles.seriesProfileNameInput}
                placeholder="Search Models"
                onChange={e => {
                  handleSearchInputChange(e.currentTarget.value);
                }}
                value={state.modalSearchInput}
              />
              <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
              {state.resetInput && (
                <Button onClick={handleCancelSearch} className={styles.resetInput}>
                  <OATIcon icon="cancel-circle" colorTheme="blue" />
                </Button>
              )}
            </div>
            <AccordionComponent accordion={state.accordion} isDisabled={isPublished} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            id="edit-series-profile-select"
            className={isPublished ? styles.disabledBtn : ''}
            variant="text"
            onClick={() => state.accordion.toggleAll(true)}
            disabled={isPublished}
          >
            Select All
          </Button>
          <Button
            id="edit-series-profile-deselect"
            className={isPublished ? styles.disabledBtn : ''}
            variant="text"
            onClick={() => state.accordion.toggleAll(false)}
            disabled={isPublished}
          >
            Deselect All
          </Button>
          <Button id="edit-series-profile-save" variant="primary" onClick={handleSubmit} disabled={isPublished}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(MultiSeriesProfileModal);
