import { useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useCreateFinalPayOfferMutation, useUpdateFinalPayOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import FinalPayModel from '../../models/offers/FinalPayModel';
import { getCreateFinalPayOfferPayload, getUpdateFinalPayOfferPayload } from './createFinalPayPayload';

const useSaveFinalPay = (seriesProfile: SeriesProfileModel, rgnlAlt: RgnlAltModel, finalPay: FinalPayModel) => {
  const {
    nationalProgramsStore: { offering },
    globalStore: { setShowProgressBar },
    userInfoStore: {
      userInfo: { brand },
    },
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();
  const { setDelay } = useInputDelay();
  const [createFinalPayOffer] = useCreateFinalPayOfferMutation();
  const [updateFinalPayOfferBudget] = useUpdateFinalPayOfferMutation();
  const { error } = useToast();

  const { offerCostFields, offerFields, postSave } = finalPay;

  const saveFinalPayOffer = () => {
    if (!offerFields.name || !offerCostFields.amount || !offerFields.penetrationRate) {
      return;
    }

    setDelay(async () => {
      try {
        setShowProgressBar(true);
        if (!offerFields.id) {
          const res = await trackPromise(
            createFinalPayOffer({ variables: { input: { ...getCreateFinalPayOfferPayload(brand, offering.id, finalPay, rgnlAlt.getSortOrderByOfferUid(finalPay.uid)) } } }),
          );
          if (res.data?.createFinalPayOffer.success) {
            postSave(res.data?.createFinalPayOffer.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.createFinalPayOffer.offeringCosts as OfferingCosts);
            seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.createFinalPayOffer.ryoOffers as Offer[]);

            if (res.data?.createFinalPayOffer.rgnlAltId && res.data?.createFinalPayOffer.rgnlAltRev) {
              rgnlAlt.updateRev(res.data?.createFinalPayOffer.rgnlAltId, res.data?.createFinalPayOffer.rgnlAltRev);
            }

            const newOfferSortOrder = { offerId: res.data?.createFinalPayOffer.offer.id, sortOrder: rgnlAlt.getSortOrderByOfferUid(finalPay.uid) };
            rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
          }
        } else {
          const res = await trackPromise(updateFinalPayOfferBudget({ variables: { input: { ...getUpdateFinalPayOfferPayload(finalPay) } } }));
          if (res.data?.updateFinalPayOfferBudget.success) {
            postSave(res.data?.updateFinalPayOfferBudget.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.updateFinalPayOfferBudget.offeringCosts as OfferingCosts);
            seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.updateFinalPayOfferBudget.ryoOffers as Offer[]);
          }
        }
      } catch (e) {
        error((e as Error).message);
      } finally {
        setShowProgressBar(false);
      }
    });
  };

  return {
    saveFinalPayOffer,
  };
};

export default useSaveFinalPay;
