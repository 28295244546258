import clsx from 'clsx';
import AssumptionsMonth from './AssumptionsMonth';

import { observer } from 'mobx-react-lite';
import useStores from '../../../../stores/useStores';
import styles from '../styles.module.scss';
import { getAssumptionMonths } from '../utils';

const AssumptionsGridItems = () => {
  const {
    assumptionsStore: { fiscalYear },
  } = useStores();
  const assumptionMonths = getAssumptionMonths(fiscalYear);

  return (
    <>
      {assumptionMonths.map(assumption => {
        return (
          <div className={clsx(styles.fyBox)} key={`${assumption.month}-${assumption.year}`}>
            <ul className={styles.fyBoxList}>
              <li>
                <AssumptionsMonth month={assumption.month} year={assumption.year} />
              </li>
            </ul>
          </div>
        );
      })}
    </>
  );
};

export default observer(AssumptionsGridItems);
