import { observer } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import useStores from '../../../../stores/useStores';
import SeriesListModel from '../../models/SeriesListModel';
import styles from '../../styles.module.scss';
import FYSeriesDetails from './FYSeriesDetails';
import GridItemRowDetail from './GridItemRowDetail';
import GridItemRowTotalDetail from './GridItemRowTotalDetail';
import GridItemSeriesName from './GridItemSeriesName';
import GridWrapper from './GridWrapper';
import Other from './Other';

interface Props {
  fiscalMonths: AdminModels.FiscalMonth[];
  seriesList: SeriesListModel[];
}

const SeriesList = ({ fiscalMonths, seriesList }: Props) => {
  const {
    fyRollupStore: { fiscalYear, fyMultiSeries },
  } = useStores();

  return (
    <div className={styles.fySeriesContainer}>
      <div className={styles.fySeries}>
        {seriesList.map(item => {
          const { series, seriesMonths, seriesFYTotals } = item;

          return (
            <div key={`${series}`} id={series.toLowerCase()}>
              <GridWrapper>
                <GridItemSeriesName seriesItem={item} />
                {fiscalMonths.map((fiscalMonth, i) => {
                  const seriesMonthItem = seriesMonths[fiscalMonth.month] ?? undefined;
                  return <GridItemRowDetail key={`${series}-${seriesMonthItem?.month}-${i}`} fiscalMonth={fiscalMonth} series={series} seriesMonthModel={seriesMonthItem} />;
                })}
                <GridItemRowTotalDetail series={series} fiscalYear={fiscalYear} total={seriesFYTotals} />
              </GridWrapper>
              {item.showDetails && <FYSeriesDetails fiscalMonths={fiscalMonths} seriesItem={item} />}
            </div>
          );
        })}
        <Other fiscalMonths={fiscalMonths} multiSeriesCosts={fyMultiSeries} />
      </div>
    </div>
  );
};

export default observer(SeriesList);
