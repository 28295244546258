import { observer } from 'mobx-react-lite';
import { assignNumberValue, BlockInputLabel, Textarea, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Scenario, useSaveScenarioNoteMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel, appendTmsLabel } from '../../../../../utils/appendTfsLabel';
import NumberStack from '../../../components/NumberStack';
import ScenarioModel from '../../../models/ScenarioModel';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  scenario: ScenarioModel;
  seriesProfile: SeriesProfileModel;
}

const cellWidth = 85;
const textAlign = 'left';

const ScenarioBudgetInfo = ({ scenario, seriesProfile }: Props) => {
  const {
    offeringCostsStore: { scenarioCosts, showTfsEnhanced },
    userInfoStore: { isLexus },
  } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const { data } = seriesProfile;

  const [saveScenarioNote] = useSaveScenarioNoteMutation();

  const costs = scenarioCosts[scenario.id];

  const handleNoteChange = (note: string) => {
    scenario.updateNote(note);

    setDelay(async () => {
      try {
        const res = await trackPromise(
          saveScenarioNote({
            variables: {
              input: {
                note: scenario.note,
                rev: scenario.rev,
                scenarioId: scenario.id,
              },
            },
          }),
        );

        if (res.data?.saveScenarioNote.data) {
          scenario.setData(res.data.saveScenarioNote.data as Scenario);
        }
      } catch (e) {
        error((e as Error).message);
      }
    }, 1000);
  };

  const tfsCost = showTfsEnhanced ? costs?.enhancedTfsCost : costs?.tfsCost;
  const tfsLabel = `${showTfsEnhanced ? 'Enh. ' : ''}${appendTfsLabel(isLexus(), 'Cost')}`;

  return (
    <>
      <div className={styles.scenarioInfo}>
        <NumberStack
          value={assignNumberValue(costs?.sales)}
          label="Sales"
          dollarSign={false}
          smallFormat
          width={cellWidth}
          textAlign={textAlign}
          classNameContainer={styles.margin0}
        />
        <NumberStack value={assignNumberValue(costs?.stock)} label="Stock" dollarSign={false} smallFormat width={cellWidth} textAlign={textAlign} rightBorder />
        <NumberStack value={assignNumberValue(costs?.budgetedPnvs)} label="Budgeted" smallFormat width={cellWidth} textAlign={textAlign} />
        <NumberStack value={assignNumberValue(costs?.pnvs)} label="PNVS" smallFormat width={cellWidth} textAlign={textAlign} />
        <NumberStack value={assignNumberValue(costs?.pnvsDifference)} label="Difference" smallFormat useColors width={cellWidth} textAlign={textAlign} rightBorder />
        <NumberStack
          value={assignNumberValue(costs?.cost)}
          label={appendTmsLabel(isLexus(), 'Cost')}
          smallFormat
          useMillions
          roundMillions
          millionsThreshold={100_000}
          width={cellWidth}
          textAlign={textAlign}
        />
        <NumberStack
          value={assignNumberValue(tfsCost)}
          label={tfsLabel}
          smallFormat
          useMillions
          roundMillions
          millionsThreshold={100_000}
          width={110}
          textAlign={textAlign}
          rightBorder
        />
        <BlockInputLabel label="Notes" className={styles.notesWrapper} labelClass={styles.notesLabel}>
          <Textarea id="scenario-notes-input" isNational value={scenario.note} onChange={e => handleNoteChange(e.target.value)} disabled={data.isBudgetConfirmed} />
        </BlockInputLabel>
      </div>
    </>
  );
};

export default observer(ScenarioBudgetInfo);
