import { AdminConstants, AdminModels, transformBudgetToFYRollup } from 'oat-admin-common';
import { sortFieldsHelper } from 'oat-common-ui';
import { SeriesTitle } from '../../constants/global';
import { SeriesMapping } from '../../gql/generated';
import { SeriesListSortItem } from '../../stores/FYRollupStore';
import ModelYearDetailModel from './models/ModelYearDetailModel';

export const processTotalCost = (total: number) => {
  if (total >= 1e9) {
    return `${(total / 1e9).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}B`;
  }

  if (total >= 1e5) {
    return `${(total / 1e6).toFixed(2)}M`;
  }

  if (total >= 1e3) {
    return total.toLocaleString('en-US');
  }

  return total;
};

export const seriesItemToRender = <T extends AdminModels.Total>(title: string, item?: T): number => {
  if (!item) {
    return 0;
  }

  let itemToRender;

  switch (title) {
    case SeriesTitle.STOCK:
      itemToRender = item.stock;
      break;
    case SeriesTitle.SALES:
      itemToRender = item.sales;
      break;
    case SeriesTitle.PNVS:
      itemToRender = item.pnvs;
      break;
    case SeriesTitle.TOTAL:
      itemToRender = item.totalCost;
      break;
    default:
      itemToRender = 0;
      break;
  }

  return itemToRender;
};

export const getUpdateFunctions = (title: string, model?: ModelYearDetailModel) => {
  let updateFn;

  switch (title) {
    case SeriesTitle.STOCK:
      updateFn = model?.updateDealerStockValue;
      break;
    case SeriesTitle.SALES:
      updateFn = model?.updateSalesVolumeValue;
      break;
    case SeriesTitle.PNVS:
      updateFn = model?.updatePnvsValue;
      break;
    default:
      updateFn = undefined;
      break;
  }

  return {
    updateValue: updateFn,
    updateParentValues: model?.updateParentValues,
  };
};

export const processModelValue = (val: number) => {
  return val.toString() === '--' ? 0 : val.toString();
};

export const formatBudgetData = (data: AdminModels.BudgetResponse, removeZeroSalesSeries = true): AdminModels.FYRollup => {
  return transformBudgetToFYRollup(data, removeZeroSalesSeries);
};

// Series list should be alphabetical by cars, then by SUVs/trucks
export const sortByVehicleGroup = (seriesList: { [series: string]: AdminModels.FYSeriesItem }, seriesMapping: SeriesMapping[]) => {
  const { VEHICLE_GROUPS } = AdminConstants;
  const seriesListArray = Object.values(seriesList) as SeriesListSortItem[];
  const seriesListMap = new Map<string, SeriesListSortItem>(seriesListArray.map(item => [item.series, item]));

  const cars: SeriesListSortItem[] = [];
  const suvsAndTrucks: SeriesListSortItem[] = [];

  for (const seriesMappingItem of seriesMapping) {
    const { vehicleGroups, displayName, bosuSeries } = seriesMappingItem;
    const vehicleGroupOne = vehicleGroups[0];
    const vehicleGroupTwo = vehicleGroups[1];
    const seriesListItem = seriesListMap.get(bosuSeries);

    if (seriesListItem) {
      seriesListItem.sortSeriesName = seriesListItem.series;
      seriesListItem.series = displayName;

      if (vehicleGroupOne) {
        const groupOne = VEHICLE_GROUPS.get(vehicleGroupOne);

        if (groupOne === VEHICLE_GROUPS.get(1)) {
          cars.push(seriesListItem);
          continue;
        }

        if (groupOne === VEHICLE_GROUPS.get(2) || groupOne === VEHICLE_GROUPS.get(3)) {
          suvsAndTrucks.push(seriesListItem);
          continue;
        }

        if (vehicleGroupTwo) {
          const groupTwo = VEHICLE_GROUPS.get(vehicleGroupTwo);

          if (groupTwo === VEHICLE_GROUPS.get(1)) {
            cars.push(seriesListItem);
            continue;
          }

          if (groupTwo === VEHICLE_GROUPS.get(2) || groupTwo === VEHICLE_GROUPS.get(3)) {
            suvsAndTrucks.push(seriesListItem);
          }
        }
      }
    }
  }

  const sortedCars = cars.slice().sort(sortFieldsHelper('sortSeriesName', true));
  const sortedSuvsTrucks = suvsAndTrucks.slice().sort(sortFieldsHelper('sortSeriesName', true));

  return [...sortedCars, ...sortedSuvsTrucks];
};
