import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from '../../../styles.module.scss';

interface Props {
  children: ReactNode;
}

const OtherItemWrapper = ({ children }: Props) => {
  return (
    <div className={clsx(styles.fySeriesBox, styles.fySeriesBoxShadowRight)}>
      <ul className={styles.fySeriesBoxList}>
        <li className={styles.seriesBoxListItem}>{children}</li>
      </ul>
    </div>
  );
};

export default OtherItemWrapper;
