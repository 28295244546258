import { MonthShortNamesConstant } from 'oat-common-ui';
import styles from '../styles.module.scss';

interface Props {
  month: number;
  year: number;
}

const AssumptionsMonth = ({ month, year }: Props) => {
  return (
    <>
      <div className={styles.boxListItem}>
        <span className={styles.monthTextColor}>{MonthShortNamesConstant[Number(month)]} </span>
        <span className={styles.yearTextColor}>{year.toString().slice(2)}</span>
      </div>
    </>
  );
};

export default AssumptionsMonth;
