import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LeaseModel from '../../../models/offers/lease/LeaseModel';
import RowProps from './RowProps';
import { LeaseNglRowItem, LeaseRowItem } from './leaseRowItems';

const LeaseRow = ({ seriesProfile, rgnlAlt, offer }: RowProps<LeaseModel>) => {
  const master = offer.getMaster();
  const examples = offer.getExamplesWithDifferentTerm();
  const sortedLeases = [master, ...examples];
  const [onFocus, setOnFocus] = useState(false);

  const nglOfferCost = sortedLeases.reduce((acc, curr) => acc + Number(curr.leaseFields.rvSupportPnvs), 0);

  return (
    <>
      {master && (
        <>
          {sortedLeases.map(item => (
            <React.Fragment key={item.uid}>
              {item && (
                <LeaseRowItem
                  seriesProfile={seriesProfile}
                  rgnlAlt={rgnlAlt}
                  offer={offer}
                  leaseForm={item}
                  disablePenRate={item.isExample && item.leaseFields.term === master.leaseFields.term}
                />
              )}
            </React.Fragment>
          ))}

          {/* NGL Row only shows in Budget View */}
          {(master.isNgl || onFocus) && (
            <LeaseNglRowItem setOnFocus={setOnFocus} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} offer={offer} leaseForm={master} nglOfferCost={nglOfferCost} />
          )}
        </>
      )}
    </>
  );
};

export default observer(LeaseRow);
