import { makeAutoObservable } from 'mobx';
import { AdminModels, aggregateBudgetTotals, calculateBudgetPnvs } from 'oat-admin-common';
import FYRollupStore from '../../../stores/FYRollupStore';
import SeriesMonthModel from './SeriesMonthModel';

class SeriesListModel implements AdminModels.FYSeriesItem {
  showDetails = false;
  series = '';
  displayName = '';
  seriesFYTotals: AdminModels.Total = {
    stock: 0,
    sales: 0,
    pnvs: 0,
    totalCost: 0,
  };
  modelYears: number[] = [];
  seriesFYMYTotals: { [modelYear: number]: AdminModels.SeriesFYMYTotal } = {};
  seriesMonths: { [month: number]: SeriesMonthModel } = {};
  fyRollupStore;

  constructor(data: AdminModels.FYSeriesItem, displayName: string, payloadName: string, fyRollupStore: FYRollupStore) {
    makeAutoObservable(this);

    const { seriesFYTotals, seriesFYMYTotals, seriesMonths } = data;
    this.series = payloadName; // name that is sent when saving budget
    this.displayName = displayName;
    this.seriesFYTotals = seriesFYTotals;
    this.seriesMonths = {};
    this.fyRollupStore = fyRollupStore;
    Object.values(seriesMonths).forEach(seriesMonthItem => {
      this.seriesMonths[seriesMonthItem.month] = new SeriesMonthModel(seriesMonthItem, this);
    });
    this.seriesFYMYTotals = seriesFYMYTotals;
    this.modelYears = Object.keys(seriesFYMYTotals)
      .map(year => Number(year))
      .sort((a, b) => a - b);
  }

  toggleShowDetails = () => {
    this.showDetails = !this.showDetails;
  };

  updateTotals = () => {
    this.updateSeriesFYMYTotals();
    this.updateSeriesFYTotals();
    // update parent FY totals
    this.fyRollupStore.updateTotals();
  };

  updateSeriesFYMYTotals = () => {
    const fyMYTotals: { [modelYear: number]: AdminModels.SeriesFYMYTotal } = {};
    for (const seriesMonthItem of Object.values(this.seriesMonths)) {
      for (const modelYearDetail of Object.values(seriesMonthItem.modelYearDetails)) {
        if (!fyMYTotals[modelYearDetail.modelYear]) {
          fyMYTotals[modelYearDetail.modelYear] = { modelYear: modelYearDetail.modelYear, sales: 0, stock: 0, pnvs: 0, totalCost: 0 };
        }
        aggregateBudgetTotals(fyMYTotals[modelYearDetail.modelYear], modelYearDetail);
      }
    }

    this.seriesFYMYTotals = fyMYTotals;
  };

  updateSeriesFYTotals = () => {
    this.seriesFYTotals.stock = Object.values(this.seriesFYMYTotals).reduce((acc, curr) => acc + curr.stock, 0);
    this.seriesFYTotals.sales = Object.values(this.seriesFYMYTotals).reduce((acc, curr) => acc + curr.sales, 0);
    this.seriesFYTotals.totalCost = Object.values(this.seriesFYMYTotals).reduce((acc, curr) => acc + curr.totalCost, 0);
    this.seriesFYTotals.pnvs = calculateBudgetPnvs(this.seriesFYTotals.totalCost, this.seriesFYTotals.sales);
  };
}

export default SeriesListModel;
