import { assignNumberValue, assignStringValue } from 'oat-common-ui';
import LeaseFormModel from '../../models/offers/lease/LeaseFormModel';
import LeaseModel from '../../models/offers/lease/LeaseModel';

const baseLeasePayload = (leaseForm: LeaseFormModel, isLexus: boolean) => {
  const { offerFields, leaseFields } = leaseForm;

  return {
    // offerFields
    isForRegions: offerFields.isForRegions,
    isEnhTfsCostShareForRegions: isLexus ? true : offerFields.isEnhTfsCostShareForRegions,
    isEnhSubCashTfsCostShareForRegions: isLexus ? true : offerFields.isEnhSubCashTfsCostShareForRegions,
    penetrationRate: assignNumberValue(offerFields.penetrationRate),
    name: assignStringValue(offerFields.name),

    // leaseFields
    acquisitionFee: leaseFields.acquisitionFee,
    adjustmentFactor: assignNumberValue(leaseFields.adjustmentFactor),
    baseMsrp: assignNumberValue(leaseFields.baseMsrp), // need edit this
    dealerCostPerInvoice: assignNumberValue(leaseFields.dealerCostPerInvoice),
    dealerGross: assignNumberValue(leaseFields.dealerGross),
    downPayment: leaseFields.downPayment,
    dueAtSigning: assignNumberValue(leaseFields.dueAtSigning),
    grossCapCost: leaseFields.grossCapCost,
    financialReserve: leaseFields.financialReserve,
    holdback: leaseFields.holdback,
    isNcsApplied: leaseForm.ncsRcf > 0,
    mileage: assignNumberValue(leaseFields.mileage),
    modelCode: leaseFields.modelCode,
    ncsEnhancedTfsPnvs: leaseFields.ncsEnhancedTfsPnvs,
    ncsTfsPnvs: leaseFields.ncsTfsPnvs,
    netCapCost: leaseFields.netCapCost,
    nglResidualAmount: leaseFields.nglResidualAmount,
    nglResidualRate: leaseFields.nglResidualRate,
    offerEnhancedTfsPnvs: leaseFields.offerEnhancedTfsPnvs,
    offerPnvs: leaseFields.offerPnvs,
    offerTfsPnvs: leaseFields.offerTfsPnvs,
    rcf: assignNumberValue(leaseFields.rcf),
    rcfEnhancedTfsPnvs: leaseFields.rcfEnhancedTfsPnvs,
    rcfPnvs: leaseFields.rcfPnvs,
    rcfTfsPnvs: leaseFields.rcfTfsPnvs,
    residualAmount: leaseFields.residualAmount,
    residualRate: assignNumberValue(leaseFields.residualRate),
    rvSupport: assignNumberValue(leaseFields.rvSupport),
    rvSupportAdjustmentFactor: assignNumberValue(leaseFields.rvSupportAdjustmentFactor),
    rvSupportCostShare: assignNumberValue(leaseFields.rvSupportCostShare),
    rvSupportPenRate: assignNumberValue(leaseFields.rvSupportPenRate),
    rvSupportPnvs: assignNumberValue(leaseFields.rvSupportPnvs), //fix type
    rvSupportTfsPnvs: assignNumberValue(leaseFields.rvSupportTfsPnvs), //fix type
    series: leaseFields.series,
    seriesYear: leaseFields.seriesYear,
    standardRcf: leaseFields.standardRcf,
    subCashAmount: assignNumberValue(leaseFields.subCashAmount),
    subCashCostShare: assignNumberValue(leaseFields.subCashCostShare),
    subCashCostShareCap: assignNumberValue(leaseFields.subCashCostShareCap),
    subCashEnhancedTfsPnvs: leaseFields.subCashEnhancedTfsPnvs,
    subCashPnvs: leaseFields.subCashPnvs,
    subCashTfsPnvs: leaseFields.subCashTfsPnvs,
    targetPayment: assignNumberValue(leaseFields.targetPayment),
    term: assignNumberValue(leaseFields.term),
    tfsShare: assignNumberValue(leaseFields.tfsShare),
    tier: leaseFields.tier,
    totalDealerGross: assignNumberValue(leaseFields.totalDealerGross),
    totalMsrp: assignNumberValue(leaseFields.totalMsrp),
    vin: leaseFields.vin,
    configuration: assignStringValue(leaseFields.configuration),
    vehicleDescription: assignStringValue(leaseFields.vehicleDescription),
    costShareId: leaseFields.costShareId,
  };
};

export const getCreateLeasePayload = (leaseForm: LeaseFormModel, sortOrder: number, isLexus: boolean) => {
  return {
    rgnlAltId: leaseForm.offerFields.rgnlAltId,
    sortOrder,
    ...baseLeasePayload(leaseForm, isLexus),
  };
};

export const getUpdateLeasePayload = (lease: LeaseModel, isLexus: boolean) => {
  return lease.forms.map(form => {
    return {
      id: form.offerFields.id,
      rev: form.offerFields.rev,
      masterOfferId: form.offerFields.masterOfferId ?? undefined,
      isExample: form.offerFields.isExample ?? false,
      isIncludedInCosts: form.offerFields.isIncludedInCosts ?? false,
      isCostShareUpdated: form.offerFields.isCostShareUpdated,
      ...baseLeasePayload(form, isLexus),
    };
  });
};
