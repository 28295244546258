import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ButtonGroup, Checkbox, InlineInputLabel, Input, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import Button from '../../../../components/Button';
import { LocalTfsContract } from '../../models/offers/OfferFields';
import MiscSetupModel from '../../models/offersSetup/MiscSetupModel';
import { getTiersPerTerm, tfsUtils } from './utils';

import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import styles from './styles.module.scss';

type Props = {
  misc: MiscSetupModel;
  isDisabled?: boolean;
  onSave: () => void;
};

const TfsContracts = ({ misc, onSave, isDisabled }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const {
    id,
    fields: { tfsContracts, isTfsContractRequired },
    updateField,
  } = misc;

  const [localTfs, setLocalTfs] = useState<LocalTfsContract[]>(JSON.parse(JSON.stringify(tfsContracts)));
  const [activeTab, setActiveTab] = useState<LocalTfsContract>(localTfs[0]);
  const tiersPerTerm = getTiersPerTerm(activeTab.termsList, activeTab.tiersList);
  const { handleSelectAll, updateSingleLocalTierTerm, addTierOrTerm, uncheckRow, handleRightArrowClick, handleDownArrowClick } = tfsUtils(localTfs, activeTab, setLocalTfs);

  const isDisabledTierCta = activeTab.tiersList.includes('7') || isDisabled;
  const isDisabledTermCta = activeTab.termsList.includes(84) || isDisabled;

  const handleSave = () => {
    updateField('tfsContracts', localTfs);
    onSave();
  };

  return (
    <>
      <fieldset disabled={isDisabled} className={clsx(styles.tfsContracts, styles.fieldset)}>
        <p className={styles.tfsContractsText}>{appendTfsLabel(isLexus(), ' Contract Required?')}</p>
        <InlineInputLabel label="Yes" inputWrapperClass={styles.tfsContractsLabel} contentGrow={false}>
          <Input
            id={`tfs-input-true-${misc.uid}`}
            isNational
            type="radio"
            checked={isTfsContractRequired}
            onChange={() => {
              updateField('isTfsContractRequired', true);
              handleSave();
            }}
            className={styles.radioInput}
          />
        </InlineInputLabel>
        <InlineInputLabel label="No" inputWrapperClass={styles.tfsContractsLabel} contentGrow={false}>
          <Input
            id={`tfs-input-false-${misc.uid}`}
            isNational
            type="radio"
            checked={!isTfsContractRequired}
            onChange={() => {
              updateField('isTfsContractRequired', false);
              handleSave();
            }}
            className={styles.radioInput}
          />
        </InlineInputLabel>
      </fieldset>
      {isTfsContractRequired && (
        <fieldset disabled={isDisabled} className={styles.fieldset}>
          <ul className={styles.tfsContractTypesList}>
            {localTfs.map((tfsContract, index) => {
              return (
                <li key={index} className={styles.tfsContractTypesTab}>
                  <details
                    className={clsx(styles.tfsContractTypesTab, tfsContract === activeTab && styles.active)}
                    onClick={() => setActiveTab(tfsContract)}
                    id={`${tfsContract.contractType}-${index}-${misc.uid}`}
                  >
                    <summary>{`${tfsContract.contractType} ${tfsContract.isSubvented ? 'Subvented' : 'Non-Subvented'}`}</summary>
                  </details>
                </li>
              );
            })}
          </ul>
          <div className={styles.tfsHeaderTiers}>
            <div className={styles.tiersHeaderCtas}>
              <Button id={`select-all-tier-terms-${misc.uid}`} className={styles.selectAllCta} onClick={handleSelectAll} variant="blue" disabled={isDisabled}>
                Select All
              </Button>
            </div>
            <ul className={styles.tiersList}>
              {activeTab.tiersList.map(tier => (
                <li key={tier} className={styles.tier}>
                  Tier {tier}
                  <Button variant="text" id={`${tier}-arrow-btn-${misc.uid}`} className={styles.arrowBtn} onClick={() => handleDownArrowClick(tier)}>
                    <OATIcon className={styles.arrowIcon} icon="arrow-down2" size={10} />
                  </Button>
                </li>
              ))}
            </ul>
            <div className={styles.tiersHeaderCtas}>
              <Button
                id={`add-tier-${misc.uid}`}
                variant="transparent-blue"
                className={clsx(styles.addTierCta, isDisabledTierCta && styles.disabledCta)}
                disabled={isDisabledTierCta}
                onClick={() => addTierOrTerm('tier')}
                icon={<OATIcon icon="plus" size={12} />}
              >
                Tier
              </Button>
            </div>
          </div>
          <ul className={clsx(styles.tfsTierTermList, styles.tfsTermList)}>
            {tiersPerTerm.map((term, termIndex) => {
              const lastIndex = tiersPerTerm.length - 1;
              const isLastTerm = termIndex === lastIndex;

              return (
                <li className={styles.termsList} key={termIndex}>
                  <p className={styles.termNumber}>
                    <ButtonGroup>
                      {term.term}
                      <Button variant="text" id={`${term.term}-arrow-btn-${misc.uid}`} className={styles.arrowBtn} onClick={() => handleRightArrowClick(term.term)}>
                        <OATIcon icon="arrow-down2" size={10} className={styles.arrowTermIcon} />
                      </Button>
                    </ButtonGroup>
                    {isLastTerm && (
                      <Button
                        id={`add-term-${misc.uid}`}
                        className={clsx(styles.addTermCta, isDisabledTermCta && styles.disabledCta)}
                        disabled={isDisabledTermCta}
                        onClick={() => addTierOrTerm('term', term.term + 12)}
                        icon={<OATIcon icon="plus" size={12} />}
                      >
                        Term
                      </Button>
                    )}
                  </p>
                  <ul className={clsx(styles.tfsTierTermList, styles.tfsTierList)}>
                    {term.tiers.map((tier, tierIndex) => {
                      const isCheckedTfs = activeTab.tierTerms.some(tierTerm => tierTerm.tier === tier && tierTerm.term === term.term);

                      return (
                        <li key={tierIndex} className={styles.term}>
                          <Checkbox
                            checkmarkClass={styles.checkmark}
                            className={styles.checkbox}
                            id={`${id}-${tierIndex}-${termIndex}-${misc.uid}`}
                            isChecked={isCheckedTfs}
                            isDisabled={isDisabled}
                            onChange={() => updateSingleLocalTierTerm(tier, term.term, isCheckedTfs)}
                          />

                          {isLastTerm && (
                            <div className={styles.cancelIconWrapper}>
                              <Button
                                className={styles.cancelCircleIcon}
                                disabled={isDisabled}
                                icon={<OATIcon icon="cancel-circle" />}
                                id={`cancel-tier-cta-${tierIndex}-${misc.uid}`}
                                onClick={() => uncheckRow(tier)}
                              />
                            </div>
                          )}
                        </li>
                      );
                    })}
                    <li className={styles.term}>
                      <Button
                        className={styles.cancelTierCircleIcon}
                        icon={<OATIcon icon="cancel-circle" />}
                        id={`cancel-term-cta-${termIndex}-${misc.uid}`}
                        onClick={() => uncheckRow(term.term)}
                        disabled={isDisabled}
                      />
                      {isLastTerm && (
                        <div className={styles.saveIconWrapper}>
                          <Button id={`save-tier-term-${misc.uid}`} className={styles.saveCta} variant="blue" onClick={handleSave} disabled={isDisabled}>
                            Save
                          </Button>
                        </div>
                      )}
                    </li>
                  </ul>
                </li>
              );
            })}
          </ul>
          {localTfs.every(t => t.tierTerms.length === 0) && (
            <p className={styles.warningTxt}>
              <OATIcon className={styles.warningIcon} icon="warning" />
              Please select contract type and tiers & terms.
            </p>
          )}
        </fieldset>
      )}
    </>
  );
};

export default observer(TfsContracts);
