import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import InputsWrapper from '../components/InputsWrapper';
import SectionLabel from '../components/SectionLabel';
import ShowAdvancedSettingsButton from '../components/ShowAdvancedSettingsButton';
import StandardRcfValue from '../components/StandardRcfValue';
import TFSChangeInRcfInput from './components/TFSChangeInRcfInput';
import TFSStandardRcfTable from './components/TFSStandardRcfTable';

const TFSStandardRcf = () => {
  const {
    ratesTabTFSLeaseRCFStore: {
      tfsStandardRcf,
      setShowHiddenTfsOne,
      setShowHiddenTfsTwo,
      setShowHiddenTfsThree,
      setTfsLeaseRatesSectionOne,
      setTfsLeaseRatesSectionTwo,
      setTfsLeaseRatesSectionThree,
      tfsLeaseRatesSectionOneMaster,
      tfsLeaseRatesSectionTwoMaster,
      tfsLeaseRatesSectionThreeMaster,
    },
    userInfoStore: { isLexus },
  } = useStores();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const handleOnClick = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
    setShowHiddenTfsOne(false);
    setShowHiddenTfsTwo(false);
    setShowHiddenTfsThree(false);
    setTfsLeaseRatesSectionOne(tfsLeaseRatesSectionOneMaster);
    setTfsLeaseRatesSectionTwo(tfsLeaseRatesSectionTwoMaster);
    setTfsLeaseRatesSectionThree(tfsLeaseRatesSectionThreeMaster);
  };

  return (
    <>
      <SectionLabel label={appendTfsLabel(isLexus(), 'Standard RCF')} />
      <InputsWrapper>
        <TFSChangeInRcfInput />
        <StandardRcfValue label="24 mo Tier 1+ Standard RCF" rcf={tfsStandardRcf} />
        <ShowAdvancedSettingsButton id="tfs-std-rcf-show-advanced-settings-btn" showAdvancedSettings={showAdvancedSettings} onClickHandler={() => handleOnClick()} />
      </InputsWrapper>
      <InputsWrapper> {showAdvancedSettings && <TFSStandardRcfTable />}</InputsWrapper>
    </>
  );
};

export default observer(TFSStandardRcf);
