import { observer } from 'mobx-react-lite';
import { DragDrop, Draggable, DraggableProvided, DropResult, OATLoadingBar, useToast } from 'oat-common-ui';
import { useEffect } from 'react';
import NGCHeader from '../../../components/NGCHeader';
import { MultiSeriesOffersBudget, OfferingYearMonth, useGetMultiSeriesOffersBudgetLazyQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import GridRowsParent from './components/Grid/GridRowsParent';
import MultiSeriesOffer from './components/MultiSeriesOffer/';
import MultiSeriesOfferTotals from './components/MultiSeriesOffer/MultiSeriesOfferTotals';
import MultiSeriesOffersFooter from './components/MultiSeriesOffer/MultiSeriesOffersFooter';
import MultiSeriesOffersHeader from './components/MultiSeriesOffer/MultiSeriesOffersHeader';
import MultiSeriesOfferModel from './models/MultiSeriesOfferModel';

const MultiSeriesOffers = () => {
  const {
    multiSeriesOffersBudgetStore: {
      fiscalYear,
      headerDetails,
      initData,
      isFetched,
      loading,
      offers,
      setIsFetched,
      setIsLoading,
      setOffers,
      setTotalCosts,
      totalBudget,
      totalCosts,
    },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { error } = useToast();

  const [getMultiSeriesOfferseBudgetForYear] = useGetMultiSeriesOffersBudgetLazyQuery();

  useEffect(() => {
    const setMultiOffersBudget = async () => {
      try {
        setIsLoading(true);
        setIsFetched(true);

        const response = await getMultiSeriesOfferseBudgetForYear({ variables: { brand, fiscalYear } });

        initData(
          brand,
          response.data?.fetchMultiSeriesOffersBudget.multiSeriesOffersBudget as MultiSeriesOffersBudget,
          response.data?.fetchMultiSeriesOffersBudget.draftOfferingMonths as OfferingYearMonth[],
        );
      } catch (e) {
        error((e as Error)?.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isFetched) {
      setMultiOffersBudget();
    }
  }, [brand, error, getMultiSeriesOfferseBudgetForYear, initData, isFetched, setIsFetched, setIsLoading, fiscalYear]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const multiSeriesCopy: MultiSeriesOfferModel[] = [...offers];
    const [itemToMove] = multiSeriesCopy.splice(result.source.index, 1);
    multiSeriesCopy.splice(result.destination.index, 0, itemToMove);

    setOffers(multiSeriesCopy.map((series, i) => ({ ...series, totalCost: series.totalCost, sortOrder: i })));
  };

  return (
    <>
      {loading && <OATLoadingBar />}
      <NGCHeader title="Multi-Series Offers" />
      <GridRowsParent>
        <MultiSeriesOffersHeader headerDetails={headerDetails} fetchMultiSeriesOfferBudget={getMultiSeriesOfferseBudgetForYear} />
        <DragDrop droppableId="multiSeriesOffers" handleOnDragEnd={handleOnDragEnd}>
          {offers.map((offer, i) => {
            return (
              <Draggable key={`${offer.id}_${offer.offerName}`} draggableId={`${offer.id}_${offer.offerName}`} index={i}>
                {(draggableProvided: DraggableProvided) => {
                  return (
                    <MultiSeriesOffer
                      id={`${offer.id}_${offer.offerName}`}
                      index={i}
                      innerRef={draggableProvided.innerRef}
                      key={`${offer.id}_${offer.offerName}`}
                      multiSeriesOffer={offer}
                      setTotalCosts={setTotalCosts}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                    />
                  );
                }}
              </Draggable>
            );
          })}
        </DragDrop>

        {/* Totals By Month */}
        {offers.length > 0 && <MultiSeriesOfferTotals totalValues={totalCosts} totalBudget={totalBudget} />}

        {/* Add and Save offers CTAs */}
        <MultiSeriesOffersFooter />
      </GridRowsParent>
    </>
  );
};

export default observer(MultiSeriesOffers);
