import { makeAutoObservable } from 'mobx';
import { DropdownItem } from 'oat-common-ui';
import { SeriesProfile } from '../gql/generated';
import CostShareModel from '../pages/IpSettings/CostShareTab/models/CostShareModel';

class CostShareTabStore {
  series: SeriesProfile[] = [];
  seriesOptions: DropdownItem[] = [];

  costShares: CostShareModel[] = [];
  deletedCostShares: CostShareModel[] = [];
  savedCostShares: CostShareModel[] = [];

  hasUnsavedChanges = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSeriesProfiles = (profiles: SeriesProfile[]) => {
    this.deletedCostShares = [];
    const processedProfiles = profiles.map(p => ({ ...p, series: p.multiSeriesOfferId ? p.multiSeriesOfferName ?? p.multiSeriesProfileName ?? '' : p.series }));

    this.series = processedProfiles;

    this.seriesOptions = processedProfiles
      .filter(profile => !profile.ncsRate && !profile.ncsRcf && !profile.multiSeriesOfferId)
      .map(item => ({
        value: item.multiSeriesOfferId ?? item.id,
        label: `${!item.multiSeriesOfferId ? item.modelYear + ' ' : ''}${item.series}`,
      }));

    this.costShares = processedProfiles
      .filter(profile => !profile.multiSeriesOfferId && (profile.ncsRate || profile.ncsRcf))
      .map(profile => {
        const newCostShare = new CostShareModel(profile);
        return newCostShare;
      });

    this.savedCostShares = this.costShares;
  };

  selectSeries = (item: DropdownItem, costShare: CostShareModel) => {
    const selectedSP = this.series.find(s => s.id === item.value);
    const unselectedSPId = costShare.seriesProfileId;

    if (selectedSP) {
      costShare.setSeriesProfileData(selectedSP);
      this.removeSeriesProfileOption(selectedSP);
      this.deletedCostShares = this.deletedCostShares.filter(cs => cs.seriesProfileId !== selectedSP.id);

      if (Boolean(unselectedSPId)) {
        this.addSeriesProfileOption(unselectedSPId);
      } else {
        // first time selecting SP
        costShare.setCostsFromSeriesProfile(selectedSP);
      }
    }

    this.setHasUnsavedChanges(true);
  };

  addNewCostShare = () => {
    const newCostShare = new CostShareModel();
    this.costShares = [...this.costShares, newCostShare];

    this.setHasUnsavedChanges(true);
  };

  removeCostShare = (uid: string) => {
    const costShare = this.costShares.find(cs => cs.uid === uid);

    if (costShare?.seriesProfileId) {
      this.addSeriesProfileOption(costShare.seriesProfileId);
      costShare.updateNcsRate('0');
      costShare.updateNcsRcf('0');

      const deleteCostIndex = this.deletedCostShares.findIndex(cs => cs.seriesProfileId === costShare.seriesProfileId);

      if (deleteCostIndex === -1) {
        this.deletedCostShares = [...this.deletedCostShares, costShare];
      }
    }

    this.costShares = this.costShares.filter(item => item.uid !== uid);
    this.setHasUnsavedChanges(true);
  };

  updateCostSharesRev = (responseSeries: SeriesProfile[]) => {
    responseSeries.forEach(sp => {
      const seriesIndex = this.costShares.findIndex(costShare => costShare.seriesProfileId === sp.id);

      if (seriesIndex !== -1) {
        this.costShares[seriesIndex].rev = sp.rev;
      }
    });
  };

  hasProfileSeriesError = () => {
    return this.costShares.find(costShare => costShare.hasRateError || costShare.hasRcfError || !costShare.rev || !costShare.series);
  };

  // Removes series from list to disable picking series again
  removeSeriesProfileOption = (seriesProfile: SeriesProfile) => {
    this.seriesOptions = this.seriesOptions.filter(sp => sp.value !== seriesProfile.id);
  };

  addSeriesProfileOption = (seriesProfileId: string) => {
    const item = this.series.find(sp => sp.id === seriesProfileId);

    if (item) {
      this.seriesOptions.push({
        value: item.id,
        label: `${!item.multiSeriesOfferId ? item.modelYear + ' ' : ''}${item.series}`,
      });
    }
  };

  setHasUnsavedChanges = (val: boolean) => {
    this.hasUnsavedChanges = val;
  };

  isCostSharesSaved = (costShare: CostShareModel) => {
    return this.savedCostShares.findIndex(item => item.uid === costShare.uid) !== -1;
  };
}

export default CostShareTabStore;
