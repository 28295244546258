import { observer } from 'mobx-react-lite';

import RgnlAltModel from '../../models/RgnlAltModel';
import ScenarioModel from '../../models/ScenarioModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import OfferModel from '../../models/offers/OfferModel';
import AprSection from '../../offers/AprSection';
import CashSection from '../../offers/CashSection';
import FinalPaySection from '../../offers/FinalPaySection';
import LeaseGroup from '../../offers/LeaseSection/LeaseGroup';
import MiscSection from '../../offers/MiscSection';
import NationalRyoSection from '../../offers/NationalRyoSection';

import styles from './styles.module.scss';

type Props = {
  item: OfferModel;
  rgnlAlt: RgnlAltModel;
  scenario: ScenarioModel;
  seriesProfile: SeriesProfileModel;
};

const OfferBudget = ({ item, rgnlAlt, seriesProfile, scenario }: Props) => {
  return (
    <div className={styles.offerBudgetContainer}>
      {item.cashDetails && <CashSection cash={item.cashDetails} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />}
      {item.leaseDetails && <LeaseGroup group={item.leaseDetails} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />}
      {item.aprDetails && <AprSection apr={item.aprDetails} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />}
      {item.miscDetails && <MiscSection misc={item.miscDetails} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />}
      {item.finalPayDetails && <FinalPaySection finalPay={item.finalPayDetails} seriesProfile={seriesProfile} rgnlAlt={rgnlAlt} />}
      {item.nationalRyoDetails && <NationalRyoSection seriesProfile={seriesProfile} nationalRyo={item.nationalRyoDetails} rgnlAlt={rgnlAlt} rgnlAlts={scenario.rgnlAlts} />}
    </div>
  );
};

export default observer(OfferBudget);
