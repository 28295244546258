import { makeAutoObservable } from 'mobx';
import { OfferingStatus } from '../constants/global';
import defaultOffering from '../defaults/defaultOffering';
import { ModelCodeMappingResponse, Offering, OfferingStandardRates, PreNglResidualSeries, PreNglResidualValues, SeriesMapping, SeriesProfile } from '../gql/generated';
import SeriesProfileModel from '../pages/NationalPrograms/models/SeriesProfileModel';

class NationalProgramsStore {
  seriesProfiles: SeriesProfileModel[] = [];
  offering: Offering = defaultOffering();
  standardRates: OfferingStandardRates = {
    aprDifferential: 0,
    aprRates: [],
    created: '',
    createdBy: '',
    id: '',
    leaseDifferential: 0,
    leaseRates: [],
    offeringId: '',
    rev: '',
    rvDifferential: 0,
    tfsLeaseDifferential: 0,
    tfsLeaseRates: [],
    updated: '',
    updatedBy: '',
  };
  residualValues: PreNglResidualSeries[] = [];
  modelCodeMappings: ModelCodeMappingResponse[] = [];
  seriesMapping: SeriesMapping[] = [];
  hasBudgetChanges = false;
  hasSetupChanges = false;
  hasEnhancedRcfChanged = false;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (
    offering: Omit<Offering, 'offeringCosts'>,
    seriesProfiles: SeriesProfile[],
    standardRates: OfferingStandardRates,
    prenglResidualValues: PreNglResidualValues,
    modelCodeMappings: ModelCodeMappingResponse[],
    seriesMapping: SeriesMapping[],
  ) => {
    this.offering = offering;
    this.standardRates = standardRates;
    this.residualValues = prenglResidualValues.residualValues ?? [];
    this.modelCodeMappings = modelCodeMappings ?? [];
    this.seriesMapping = seriesMapping ?? [];

    if (seriesProfiles.length) {
      this.seriesProfiles = seriesProfiles.map(sp => {
        const seriesProfile = new SeriesProfileModel();
        seriesProfile.setData(sp, offering.brand, this.seriesMapping);

        return seriesProfile;
      });
    } else {
      this.seriesProfiles = [];
    }
  };

  setChanges = (hasBudgetChanges: boolean, hasSetupChanges: boolean, hasEnhancedRcfChanged: boolean) => {
    this.hasBudgetChanges = hasBudgetChanges;
    this.hasSetupChanges = hasSetupChanges;
    this.hasEnhancedRcfChanged = hasEnhancedRcfChanged;
  };

  setEnhancedRcfChanged = () => {
    this.hasEnhancedRcfChanged = true;
  };

  isBudgetAndSetupConfirmed = () => {
    return this.seriesProfiles.length > 0 && this.seriesProfiles.every(sp => sp.data.isBudgetConfirmed && sp.data.isSetupConfirmed);
  };

  updateOfferingRev = (rev: string) => {
    this.offering.rev = rev;
  };

  get offeringDisplayName() {
    return `${this.offering.name} ${this.offering.isAtc ? '- ATC' : ''}`;
  }

  get showPublishCta() {
    if (this.isOfferingPublished) {
      return false;
    }

    return this.offering.isAtc ? this.isBudgetAndSetupConfirmed() && this.hasBudgetChanges && this.hasSetupChanges : this.isBudgetAndSetupConfirmed();
  }

  get showAtcPublishToBudgetCta() {
    if (this.isOfferingPublished || (!this.hasBudgetChanges && !this.hasSetupChanges)) {
      return false;
    }

    return this.offering.isAtc && this.isBudgetAndSetupConfirmed() && this.hasBudgetChanges && !this.hasSetupChanges;
  }

  get showPublishToRegionsCta() {
    if (this.isOfferingPublished || (!this.hasBudgetChanges && !this.hasSetupChanges && !this.hasEnhancedRcfChanged)) {
      return false;
    }
    return this.offering.isAtc && this.isBudgetAndSetupConfirmed() && ((this.hasSetupChanges && !this.hasBudgetChanges) || this.hasEnhancedRcfChanged);
  }

  get isOfferingPublished() {
    return this.offering.status !== OfferingStatus.DRAFT;
  }
}

export default NationalProgramsStore;
