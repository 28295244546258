import { observer } from 'mobx-react-lite';
import { FEATURE_OR_4154, VIEW_ALL_RATES } from '../../../../constants/global';
import CommonFields from '../../components/CommonFields';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import OfferTitle from '../../components/OfferTitle';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import AprModel from '../../models/offers/apr/AprModel';
import APRPnvs from './components/APRPnvs';
import StdRatesButton from './components/StdRatesButton';
import TFSData from './components/TFSData';
import TierPenPercentage from './components/TierPenPercentage';
import TiersList from './components/TiersList';
import TermRateTable from './tables/TermRateTable';
import TermTable from './tables/TermTable';
import TermTableWrapper from './tables/TermTable/TermTableWrapper';
import useSaveApr from './useSaveApr';
import TfsCostSharesSelector from '../../components/TfsCostSharesSelector';
import useStores from '../../../../stores/useStores';

interface Props {
  apr: AprModel;
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;
}

const AprSection = ({ apr, rgnlAlt, seriesProfile }: Props) => {
  const {
    nationalProgramsStore: {
      standardRates: { aprRates },
    },
  } = useStores();

  const {
    uid,
    offerFields: { name, penetrationRate, optionType, isForRegions, isEnhTfsCostShareForRegions, isEnhSubCashTfsCostShareForRegions, costShareId },
    tiersList,
    selectTier,
    selectedTier,
    updateOfferField,
    updateCostShareId,
    penetrationRateError,
  } = apr;

  const { saveApr } = useSaveApr(seriesProfile, rgnlAlt, apr);

  const saveAndClearErorrs = () => {
    seriesProfile.clearErrors();
    saveApr();
  };

  return (
    <div>
      <OfferHeader>
        <OfferTitle offerType="APR" title="APR" />
        <APRPnvs aprModel={apr} />
      </OfferHeader>
      <fieldset disabled={seriesProfile?.data.isBudgetConfirmed}>
        <CommonFields
          id={apr.uid}
          name={name}
          type={optionType}
          penetrationRate={penetrationRate || ''}
          onNameChange={val => {
            updateOfferField('name', val);
            saveAndClearErorrs();
          }}
          onTotalPenChange={val => {
            updateOfferField('penetrationRate', val);
            saveAndClearErorrs();
          }}
          penetrationRateError={penetrationRateError}
          nameError={!name}
        />
        {FEATURE_OR_4154 && (
          <TfsCostSharesSelector
            isUpdated={Boolean(apr.offerFields.isCostShareUpdated && seriesProfile.data.isCostShareUpdated)}
            id={apr.uid}
            offerType="APR"
            onSelect={(id, value) => {
              updateCostShareId(id, value, aprRates);
              saveAndClearErorrs();
            }}
            selectedTfsCost={costShareId}
            seriesProfileId={seriesProfile.id}
          />
        )}
        <TierPenPercentage apr={apr} onSave={saveAndClearErorrs} />
      </fieldset>
      <TiersList uid={uid} tiersList={tiersList} selectTier={selectTier} selectedTier={selectedTier} />
      <fieldset disabled={seriesProfile?.data.isBudgetConfirmed}>
        <TermTableWrapper>
          {selectedTier === VIEW_ALL_RATES ? <TermRateTable apr={apr} onSave={saveAndClearErorrs} /> : <TermTable apr={apr} onSave={saveAndClearErorrs} />}
          <TFSData apr={apr} onSave={saveAndClearErorrs} />
        </TermTableWrapper>
        <StdRatesButton apr={apr} onSave={saveAndClearErorrs} />
      </fieldset>

      <OfferFooter
        uid={uid}
        seriesProfile={seriesProfile}
        offerFields={apr.offerFields}
        rgnlAlt={rgnlAlt}
        isEnhSubCashTfsCostShareForRegions={isEnhSubCashTfsCostShareForRegions}
        isEnhSubCashTfsCostShareForRegionsDisabled={!apr.hasEnhancedSubCashTfsCostShareTier}
        isEnhTfsCostShareForRegions={isEnhTfsCostShareForRegions}
        isEnhTfsCostShareForRegionsDisabled={!apr.hasEnhancedTfsCostShareTier}
        isForRegions={isForRegions}
        isForRegionsDisabled={false}
        updateOfferField={(field, val) => {
          updateOfferField(field, !val);
          saveAndClearErorrs();
        }}
      />
    </div>
  );
};

export default observer(AprSection);
