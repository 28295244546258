import { observer } from 'mobx-react-lite';
import useStores from '../../../stores/useStores';
import IpSettingsContentWrapper from '../components/IpSettingsContentWrapper';
import IpSettingsHeader from '../components/IpSettingsHeader';
import IpSettingsNav from '../components/IpSettingsNav';
import APRStandardRate from './APRStandardRate';
import NationalLeaseStandardRcf from './NationalLeaseStandardRcf';
import RatesTabFooter from './RatesTabFooter';
import ResidualValueRate from './ResidualValueRate';
import TFSStandardRcf from './TFSStandardRcf';
import RatesWrapper from './components/RatesWrapper';

const RatesTabComponent = () => {
  const {
    ipSettingsStore: { offeringDisplayName },
  } = useStores();

  const title = `Edit Incentive Period - ${offeringDisplayName}`;

  return (
    <IpSettingsContentWrapper>
      <IpSettingsHeader title={title} />
      <IpSettingsNav />
      <RatesWrapper>
        <ResidualValueRate />
        <APRStandardRate />
        <NationalLeaseStandardRcf />
        <TFSStandardRcf />
      </RatesWrapper>
      <RatesTabFooter />
    </IpSettingsContentWrapper>
  );
};

export default observer(RatesTabComponent);
