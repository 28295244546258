import styles from './styles.module.scss';

interface Props {
  errors: string[];
}

const SetupConfirmErrors = ({ errors }: Props) => {
  return (
    <>
      {errors.map(error => (
        <span className={styles.error}>{error}</span>
      ))}
    </>
  );
};

export default SetupConfirmErrors;
