import { makeAutoObservable } from 'mobx';
import { assignStringValue } from 'oat-common-ui';
import { OfferCosts, OfferingCosts, RgnlAltCosts, ScenarioCosts } from '../gql/generated';

const defaultOfferingCosts = (): OfferingCosts => ({
  budgetedPnvs: 0,
  pnvsDifference: 0,
  cost: 0,
  enhancedTfsCost: 0,
  enhancedTfsPnvs: 0,
  id: '',
  offeringId: '',
  pnvs: 0,
  rev: '',
  sales: 0,
  tfsCost: 0,
  tfsPnvs: 0,
  variance: 0,
});

const defaultScenarioCosts = (): ScenarioCosts => ({
  cost: 0,
  budgetedCost: 0,
  costDifference: 0,
  enhancedTfsCost: 0,
  enhancedTfsPnvs: 0,
  isSelected: false,
  modelYear: 0,
  multiSeriesOfferId: '',

  pnvs: 0,
  pnvsDifference: 0,
  budgetedPnvs: 0,

  rgnlAlts: [],
  sales: 0,
  scenarioId: '',
  series: '',
  seriesProfileId: '',
  tfsCost: 0,
  tfsPnvs: 0,
  stock: 0,
});

class OfferingCostsStore {
  offeringCosts: OfferingCosts = defaultOfferingCosts();
  scenarioCosts: Record<string, ScenarioCosts> = {};
  rgnlAltCosts: Record<string, RgnlAltCosts> = {};
  showTfsEnhanced = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Used for post mutation processing
   * @param offeringCosts
   */
  updateOfferingCostsFromResponse = (offeringCosts: OfferingCosts) => {
    // copy offering costs
    const offCosts: Omit<OfferingCosts, 'scenarios'> = offeringCosts;
    this.offeringCosts = { ...offCosts };

    // start looping to fill rest of costs
    if (offeringCosts.scenarios) {
      offeringCosts.scenarios.forEach(scenario => {
        if (scenario) {
          const sceCosts: Omit<ScenarioCosts, 'rgnlAlts'> = scenario;
          this.scenarioCosts[assignStringValue(scenario?.scenarioId)] = { ...sceCosts };

          if (scenario.rgnlAlts) {
            scenario.rgnlAlts.forEach(rgnlAlt => {
              if (rgnlAlt) {
                const raCosts: Omit<RgnlAltCosts, 'offers'> = rgnlAlt;
                this.rgnlAltCosts[assignStringValue(rgnlAlt.rgnlAltId)] = { ...raCosts };
              }
            });
          }
        }
      });
    }
  };

  getOfferCost = (raId: string, id: string): OfferCosts | undefined => {
    return this.rgnlAltCosts[raId]?.offerCosts?.find(cost => cost?.offerId === id) || undefined;
  };

  toggleShowTfsEnhanced = () => {
    this.showTfsEnhanced = !this.showTfsEnhanced;
  };

  resetCosts = () => {
    this.offeringCosts = defaultOfferingCosts();
    this.scenarioCosts = {};
    this.rgnlAltCosts = {};
  };

  updateSaleByOne = (record: string, add = true) => {
    if (!this.scenarioCosts[record]) {
      this.scenarioCosts[record] = defaultScenarioCosts();
    }

    this.scenarioCosts[record].sales = this.scenarioCosts[record].sales + (add ? 1 : -1);
  };

  updateScenarioField = <T extends keyof ScenarioCosts, V extends ScenarioCosts[T]>(record: string, field: T, value: V) => {
    if (!this.scenarioCosts[record]) {
      this.scenarioCosts[record] = defaultScenarioCosts();
      this.scenarioCosts[record][field] = value;
      return;
    }

    this.scenarioCosts[record][field] = value;
  };

  calcProposedTmsCost = () => {
    // called when sales value is updated
    // proposed tms/lms cost is recalculated
    // RA sales is updated
  };

  updateCostByOne = (add = true, record: string) => {
    if (!this.scenarioCosts[record]) {
      this.scenarioCosts[record] = defaultScenarioCosts();
    }

    this.scenarioCosts[record].cost = this.scenarioCosts[record].cost + (add ? 1 : -1);
  };
}

export default OfferingCostsStore;
