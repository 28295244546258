import clsx from 'clsx';
import { Input, OATIcon } from 'oat-common-ui';
import { useEffect, useRef } from 'react';
import { Highlighter, Menu, MenuItem, Typeahead as TypeaheadCmp } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import SeriesListModel from '../../models/SeriesListModel';
import styles from '../header/Navbar/styles.module.scss';
import { handleScrollOnClick } from '../header/Navbar/utils';

interface Props {
  searchValue: string;
  setSearchValue: (param: string) => void;
  options: SeriesListModel[];
  labelKey: string;
  fySection: string;
  fiscalYear: number;
}

const TypeaheadSearch = ({ searchValue, setSearchValue, options, labelKey, fySection, fiscalYear }: Props) => {
  const typeaheadRef = useRef<Typeahead>(null);

  useEffect(() => {
    if (typeaheadRef && typeaheadRef.current) {
      typeaheadRef.current.clear();
    }
  }, [fiscalYear]);

  const onChangeHandler = (options: Option[]) => {
    return options.length ? handleScrollOnClick((options[0] as SeriesListModel).series.toString().toLowerCase()) : undefined;
  };

  return (
    <div className={styles.searchContainer}>
      <TypeaheadCmp
        id={`${fySection}-${fiscalYear}-search-by-series`}
        options={options || []}
        ref={typeaheadRef}
        onChange={onChangeHandler}
        selectHint={(shouldSelect, e) => e.key === 'Enter' || shouldSelect}
        placeholder="Search by series"
        labelKey={labelKey}
        emptyLabel=""
        onInputChange={query => setSearchValue(query)}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }, text) => {
          return (
            <Input
              id={`${fySection}-${fiscalYear}-input`}
              {...inputProps}
              ref={input => {
                inputRef(input);
                referenceElementRef(input);
              }}
              className={clsx(
                styles.searchInput,
                options?.some(i => i.series.toLowerCase() !== text.text.toLowerCase() && i.series.toLowerCase().includes(text.text.toLowerCase())) && text.text && styles.active,
              )}
            />
          );
        }}
        renderMenu={(results, menuProps, text) => (
          <ul>
            <Menu {...menuProps} className={styles.menuItem}>
              {searchValue &&
                results.map((result, i) => (
                  <li key={(result as SeriesListModel).series}>
                    <MenuItem option={result} position={i}>
                      <Highlighter search={text.text}>{(result as SeriesListModel).series}</Highlighter>
                    </MenuItem>
                  </li>
                ))}
            </Menu>
          </ul>
        )}
      />
      <OATIcon icon="search" size={14} className={styles.searchIcon} />
      <div className={styles.spacer} />
    </div>
  );
};

export default TypeaheadSearch;
