import { defaultStringToUndefined, isRcfValid, sortFieldsHelper, uuidv4 } from 'oat-common-ui';
import { AssumptionMonth, IAssumptionMonth } from '../../../../../models/Assumptions';

export const isAssumptionValid = (field: 'standardRate' | 'standardRcf' | 'residualValue', value?: string | number) => {
  if (field === 'standardRcf') {
    return isRcfValid(value?.toString());
  } else if (field === 'standardRate' && Math.abs(Number(value)) > 100 && Math.abs(Number(value)) < -100) {
    return false;
  } else if (field === 'residualValue' && Math.abs(Number(value)) > 99 && Math.abs(Number(value)) < -99) {
    return false;
  }
  return true;
};

export const parseAssumptionMonths = (months: AssumptionMonth[]): IAssumptionMonth[] => {
  return months
    .map(a => {
      return {
        id: uuidv4(),
        month: a.month,
        year: a.year,
        residualValue: {
          isValid: isAssumptionValid('residualValue', a.residualValue),
          value: defaultStringToUndefined(a.residualValue),
        },
        standardRate: {
          isValid: isAssumptionValid('standardRate', a.standardRate),
          value: defaultStringToUndefined(a.standardRate),
        },
        standardRcf: {
          isValid: isAssumptionValid('standardRcf', a.standardRcf),
          value: defaultStringToUndefined(a.standardRcf),
        },
      };
    })
    .sort(sortFieldsHelper('month', true))
    .sort(sortFieldsHelper('year', true));
};
