import clsx from 'clsx';
import { NumberInput, NumberInputProps } from 'oat-common-ui';
import styles from './styles.module.scss';

interface InputProps extends NumberInputProps {
  className?: string;
}

const RgnlAltInput = ({ className, ...rest }: InputProps) => {
  const getColorCss = () => {
    if (rest.error) {
      return styles.error;
    } else {
      return styles.default;
    }
  };

  return <NumberInput className={clsx(styles.raInput, rest.dollarSign && styles.raDollarInput, className, getColorCss())} {...rest} />;
};

export default RgnlAltInput;
