import { ButtonGroup } from 'oat-common-ui';
import Button from '../../../../components/Button';
import AnchorNav from '../../../../components/MainSideNav/AnchorNav';
import styles from './styles.module.scss';

interface Props {
  onSave: () => void;
  disabled?: boolean;
}

const FooterCtas = ({ onSave, disabled = false }: Props) => {
  return (
    <ButtonGroup className={styles.footer}>
      <AnchorNav to="/dashboard" className={styles.cancelBtn}>
        Cancel
      </AnchorNav>
      <Button id="rates-tab-save-btn" variant="blue" onClick={onSave} disabled={disabled}>
        Save
      </Button>
    </ButtonGroup>
  );
};

export default FooterCtas;
