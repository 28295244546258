import { makeAutoObservable } from 'mobx';
import { assignStringValue, sortFieldsHelper } from 'oat-common-ui';
import { Maybe, Offer, RegionalAlternative, RgnlAltSummary, Scenario } from '../../../gql/generated';
import RgnlAltModel from './RgnlAltModel';
import SeriesProfileModel from './SeriesProfileModel';
import OfferModel from './offers/OfferModel';

class ScenarioModel {
  id = '';
  name = '';
  brand = '';
  isSelected = false;
  isConfirmed = false;
  isPublished = false;
  exportSelected = false;
  compareSelected = false;
  activated = false;
  expanded = false;
  rev = '';
  note = '';
  rgnlAlts: RgnlAltModel[] = [];
  pnvs = 0;
  sortOrder = 0;

  constructor(brand: string, scenario?: Scenario) {
    makeAutoObservable(this);
    this.brand = brand;
    if (scenario) {
      this.setData(scenario);
    }
  }

  setData = (scenario: Scenario) => {
    this.id = scenario.id;
    this.isPublished = Boolean(scenario.isPublished);
    this.isSelected = scenario.isSelected;
    this.name = scenario.name;
    this.note = assignStringValue(scenario.note);
    this.rev = scenario.rev;
  };

  setRgnlAlts = (ra: RgnlAltSummary[], seriesProfile: SeriesProfileModel) => {
    // On load, we need to gather all available national offers,
    // and then map the uid correctly after offers and rgnl data are setup
    let nationalRyoOffers: OfferModel[] = [];

    this.rgnlAlts = ra.map(item => {
      const r = new RgnlAltModel(this.brand);
      r.setData(item, seriesProfile);
      nationalRyoOffers = [...nationalRyoOffers, ...r.getNationalRyoOffers()];
      return r;
    });

    // map uid for setting up national ryo included checkboxes
    nationalRyoOffers.forEach(offer => {
      offer.nationalRyoDetails?.mapOfferSelectionUids(this.rgnlAlts);
    });

    this.sortRgnlAlts();
  };

  addRgnlAlt = () => {
    const newRa = new RgnlAltModel(this.brand);
    newRa.toggleExpanded(); // default to expanded
    this.rgnlAlts = [...this.rgnlAlts, newRa];
  };

  updateName = (name: string) => {
    this.name = name;
  };

  updateNote = (note: string) => {
    this.note = note;
  };

  updateRev = (rev: string) => {
    this.rev = rev;
  };

  toggleCompareSelected = () => {
    this.compareSelected = !this.compareSelected;
  };

  toggleExportSelected = () => {
    this.exportSelected = !this.exportSelected;
  };

  /**
   *
   * @param uid
   * @param rgnlAlts  update revs of these affected rgnlAlts
   * @param ryoOffers update amount of ryoOffers
   */
  deleteRgnlAlt = (uid: string, rgnlAlts: Maybe<RegionalAlternative>[] = [], ryoOffers: Maybe<Offer>[] = []) => {
    let deletedRgnlAltId = '';
    this.rgnlAlts = this.rgnlAlts.filter(ra => {
      if (ra.uid === uid) {
        deletedRgnlAltId = ra.data.id;
      }
      return ra.uid !== uid;
    });

    // create maps of affect rgnlAlts and ryoOffers for faster accessing
    const rgnlAltMap = new Map(rgnlAlts.map(ra => [assignStringValue(ra?.id), ra]));
    const ryoMap = new Map(ryoOffers.map(offer => [assignStringValue(offer?.id), offer]));

    // update revs/number of rgnlAlts and amount of ryoOffers
    this.rgnlAlts.forEach(ra => {
      const foundRa = rgnlAltMap.get(ra.data.id);
      if (foundRa) {
        ra.updateRev(foundRa.id, foundRa.rev);
        ra.data.number = foundRa.number;
      }

      ra.offers.forEach(offer => {
        const foundRyo = ryoMap.get(offer.getOfferId());
        if (offer.nationalRyoDetails && foundRyo) {
          if (deletedRgnlAltId) {
            const filteredOffersSelections = offer.nationalRyoDetails.offerCostFields.offerSelections.filter(item => item.rgnlAltId !== deletedRgnlAltId);
            offer.nationalRyoDetails.updateOfferCostFields('offerSelections', filteredOffersSelections);
          }
          offer.nationalRyoDetails.updateOfferCostFields('amount', assignStringValue(foundRyo.nationalRyoDetails?.amount));
          offer.nationalRyoDetails.setRevAndId(foundRyo);
        }
      });
    });

    this.sortRgnlAlts();
  };

  updateRyoOffers = (ryoOffers: Offer[]) => {
    const rgnlAlts = this.rgnlAlts.slice();
    if (ryoOffers) {
      ryoOffers.forEach(offer => {
        const rgnlAlt = rgnlAlts.find(ra => ra.data.id === offer.rgnlAltId);
        if (rgnlAlt) {
          rgnlAlt.offers.forEach(ofr => {
            // only update national ryo
            if (ofr.getOfferId() === offer.id) {
              ofr.setNationalRyoOffer(rgnlAlt.data.id, offer, offer.optionType, rgnlAlts);
            }
          });
        }
      });
    }
    this.rgnlAlts = rgnlAlts;
  };

  sortRgnlAlts = () => {
    this.rgnlAlts = this.rgnlAlts.slice().sort((a, b) => {
      const raNumberSort = sortFieldsHelper('number', true)(a.data, b.data);
      if (raNumberSort === 0) {
        return sortFieldsHelper('created', true)(a.data, b.data);
      }

      return raNumberSort;
    });
  };
}

export default ScenarioModel;
