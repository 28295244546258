import { observer } from 'mobx-react-lite';
import { Input, NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import BlockInputLabel from '../../../../components/BlockInputLabel';
import { NumberField } from '../../models/offers/OfferFields';
import styles from './styles.module.scss';

interface Props {
  id: string;
  type?: string;
  name: string;
  nameError?: boolean;
  penetrationRate: NumberField;
  penetrationRateError?: boolean;
  penetrationRateDisable?: boolean;
  onNameChange: (val: string) => void;
  onTotalPenChange: (val: number | string) => void;
}

const CommonFields = ({ id, name, nameError, penetrationRate, penetrationRateError, penetrationRateDisable, type = 'Cash', onNameChange, onTotalPenChange }: Props) => {
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    onNameChange(e.currentTarget.value);
  };

  const handleTotalPenChange = (value: string) => {
    onTotalPenChange(value);
  };

  return (
    <div className={styles.container}>
      <BlockInputLabel label="Name" className={styles.name} smallLabel>
        <Input id={`name-input-${id}`} isNational value={name} onChange={handleNameChange} error={nameError} />
      </BlockInputLabel>
      <BlockInputLabel label={`Total ${type} Pen %`} smallLabel>
        <NumberInput
          id={`pen-input-${id}`}
          value={penetrationRate}
          disabled={penetrationRateDisable}
          isNational
          wholeNumber
          percentageSign
          onValueChange={val => handleTotalPenChange(val.value)}
          error={penetrationRateError}
          min={0}
          max={100}
          className={styles.rateInput}
        />
      </BlockInputLabel>
    </div>
  );
};

export default observer(CommonFields);
