import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { OfferingRate } from '../../../../gql/generated';

class LeaseRateModel {
  uid = uuidv4();
  differential = 0;
  tier = '';
  term = 0;
  rate = 0;
  previousRate = 0;

  constructor(data: OfferingRate) {
    makeAutoObservable(this);
    this.differential = data.differential;
    this.tier = data.tier;
    this.term = data.term;
    this.previousRate = data.previousRate;
    this.rate = data.rate;
  }
}

export default LeaseRateModel;
