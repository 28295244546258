import clsx from 'clsx';
import { MonthShortNamesConstant, formatNumber } from 'oat-common-ui';
import { processYear } from '../../../../utils/processYear';
import styles from '../../styles.module.scss';
import { processTotalCost } from '../../utils';

interface Props {
  month?: number;
  year: number;
  sales: number;
  pnvs: number;
  totalCost: number;
}

const TotalDetails = ({ month, year, sales, pnvs, totalCost }: Props) => {
  const processVal = (val: number, isTotalCost = false) => {
    return !!val ? (!isTotalCost ? formatNumber(val, true) : processTotalCost(val)) : '--';
  };

  return (
    <>
      <div className={styles.seriesBoxListItem}>
        <span className={styles.totalTextColor}>{month ? MonthShortNamesConstant[Number(month)] : 'FY'} </span>
        <span className={styles.valTextColor}>{processYear(year)}</span>
      </div>
      <div className={clsx(styles.seriesBoxListItem, styles.valTextColor)}>{processVal(sales)}</div>
      <div className={clsx(styles.seriesBoxListItem, styles.valTextColor)}>{processVal(pnvs)}</div>
      <div className={clsx(styles.seriesBoxListItem, styles.valTextColor)}>{processVal(totalCost, true)}</div>
    </>
  );
};

export default TotalDetails;
