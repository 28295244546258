import { observer } from 'mobx-react-lite';
import SeriesProfileModel from '../../../../models/SeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  seriesProfileModel: SeriesProfileModel;
}

const SeriesTitle = ({ seriesProfileModel }: Props) => {
  const {
    toggleScenario,
    data: { modelYear, isBudgetConfirmed, multiSeriesOfferId, multiSeriesOfferName },
    displayName,
  } = seriesProfileModel;

  const handleToggleScenario = async () => {
    toggleScenario();
  };

  return (
    <div className={styles.seriesTitle} onClick={handleToggleScenario}>
      {isBudgetConfirmed && <span className={styles.confirmed}></span>}
      {multiSeriesOfferId ? (
        <h2>{multiSeriesOfferName}</h2>
      ) : (
        <>
          <h2>{displayName}</h2>
          <h3>{modelYear}</h3>
        </>
      )}
    </div>
  );
};

export default observer(SeriesTitle);
