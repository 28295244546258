import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels } from 'oat-admin-common';
import { uuidv4, validator } from 'oat-common-ui';
import { Maybe, Offer, OfferSortOrder, OfferSortOrderResult, OfferingStandardRates, RgnlAltRegion, RgnlAltRegionInput, RgnlAltSummary } from '../../../gql/generated';
import sortOffers from '../utils/sortOffers';
import SeriesProfileModel from './SeriesProfileModel';
import { NumberField } from './offers/OfferFields';
import OfferModel from './offers/OfferModel';
import OfferSetupModel from './offersSetup/OfferSetupModel';
import { ICostShareSeriesProfile } from '../../../stores/NationalCostShareStore';

const { OPTION_TYPE_NAMES, REGIONS } = AdminConstants;

export interface RgnlAltRegionField extends AdminModels.Region {
  selected: boolean;
  states: Array<{
    state: string;
    selected: boolean;
    applied: boolean;
  }>;
}

interface RgnlAltFields {
  id: string;
  rev: string;
  scenarioId: string;
  name: Maybe<string>;
  number: NumberField;
  note?: Maybe<string>;
  penetrationRate?: Maybe<NumberField>;
  brand?: Maybe<string>;
  created?: Maybe<string>;
  createdBy?: Maybe<string>;
  isNationalRyoRgnlAlt?: Maybe<boolean>;
  updated?: Maybe<string>;
  updatedBy?: Maybe<string>;
  offerSortOrder: OfferSortOrderResult[];
  regions: RgnlAltRegionField[];
}

class RgnlAltModel {
  uid = uuidv4();
  data: RgnlAltFields = {
    brand: '',
    created: '',
    createdBy: '',
    id: '',
    isNationalRyoRgnlAlt: false,
    name: '',
    note: '',
    number: '',
    penetrationRate: '',
    rev: '',
    scenarioId: '',
    updated: '',
    updatedBy: '',
    offerSortOrder: [],
    regions: [],
  };
  offers: OfferModel[] = [];
  setupOffers: OfferSetupModel[] = [];
  setupConfirmErrors: string[] = [];
  initialRegions: RgnlAltRegion[] = [];

  expanded = false;

  constructor(brand: string) {
    makeAutoObservable(this);
    this.data.brand = brand;
  }

  setData = (data: RgnlAltSummary, seriesProfile: SeriesProfileModel) => {
    this.data.brand = data.brand;
    this.data.created = data.created;
    this.data.createdBy = data.createdBy;
    this.data.id = data.id;
    this.data.isNationalRyoRgnlAlt = data.isNationalRyoRgnlAlt;
    this.data.name = data.name;
    this.data.note = data.note;
    this.data.number = data.number;
    this.data.penetrationRate = data.penetrationRate;
    this.data.rev = data.rev;
    this.data.scenarioId = data.scenarioId;
    this.data.updated = data.updated;
    this.data.updatedBy = data.updatedBy;
    this.data.offerSortOrder = data.offerSortOrder ?? [];
    this.data.regions = this.parseRegions(data.regions);
    this.initialRegions = data.regions as RgnlAltRegion[];

    if (data.offers) {
      const sortedOffers = sortOffers(data.offers ?? [], data.offerSortOrder ?? []);
      this.setSetupOffers(sortedOffers);

      const leaseMap: Map<string, Offer[]> = new Map<string, Offer[]>();
      const restOffers: Offer[] = [];

      // filter out lease offers
      data.offers.forEach(offer => {
        if (offer) {
          if (offer.optionType === OPTION_TYPE_NAMES.LEASE) {
            const id = offer.masterOfferId || offer.id;

            if (!leaseMap.has(id)) {
              leaseMap.set(id, []);
            }
            const leaseOffers = leaseMap.get(id);
            if (leaseOffers) {
              leaseOffers.push(offer);
            }
          } else {
            restOffers.push(offer);
          }
        }
      });

      const offerModels: OfferModel[] = [];

      // process lease mapping
      Array.from(leaseMap).forEach(([, offers]) => {
        const offerModel = new OfferModel();
        offerModel.setLeaseOffer(this.data.brand ?? '', this.data.id, seriesProfile, offers);
        offerModels.push(offerModel);
      });

      // process rest offers
      restOffers.forEach(offer => {
        const offerModel = new OfferModel();

        if (offer) {
          switch (offer.optionType) {
            case OPTION_TYPE_NAMES.CUSTOMER_CASH:
            case OPTION_TYPE_NAMES.ADDITIONAL_RYO:
              offerModel.setCashOffer({ rgnlAltId: this.data.id, offer });
              break;
            case OPTION_TYPE_NAMES.APR:
              offerModel.setAprOffer(this.data.id, offer, seriesProfile.data.ncsRate);
              break;

            case OPTION_TYPE_NAMES.NATIONAL_RYO:
              offerModel.setNationalRyoOffer(this.data.id, offer);
              break;

            case OPTION_TYPE_NAMES.FINAL_PAY:
              offerModel.setFinalPayOffer(this.data.id, offer);
              break;

            default:
              offerModel.setMiscOffer(this.data.id, offer);
          }
        }
        offerModels.push(offerModel);
      });
      // end adding offers

      // sort offers if applicable
      if (this.data.offerSortOrder.length) {
        offerModels.sort((a: OfferModel, b: OfferModel) => {
          const aIndex = this.data.offerSortOrder.find(item => item.offerId === a.getOfferId())?.sortOrder ?? 0;
          const bIndex = this.data.offerSortOrder.find(item => item.offerId === b.getOfferId())?.sortOrder ?? 0;
          return aIndex - bIndex;
        });
      }

      this.offers = offerModels;
    }
  };

  updateOfferSortOrder = (newOffer: OfferSortOrder) => {
    this.data.offerSortOrder = [...this.data.offerSortOrder, newOffer];
  };

  deleteOfferSortOrder = (offerId: string) => {
    this.data.offerSortOrder = this.data.offerSortOrder.filter(offer => offer.offerId !== offerId);
  };

  setSetupOffers = (offers: Maybe<Offer>[]) => {
    this.setupOffers = offers
      .filter(offer => {
        if (offer) {
          let allowOffer = offer.isForRegions;

          // if example and term is same as master, don't allow
          if (offer.leaseDetails && offer.isExample) {
            const master = offers.find(item => item?.id === offer.masterOfferId);
            if (master && master.leaseDetails && master.leaseDetails.term === offer.leaseDetails.term) {
              allowOffer = false;
            }
          }

          return allowOffer;
        }
        return false;
      })
      .map(offer => new OfferSetupModel(offer));
  };

  updateRev = (id: string, rev: string, created?: Maybe<string>) => {
    this.data.id = id;
    this.data.rev = rev;

    if (created) {
      this.data.created = created;
    }
  };

  toggleAllRegions = (isSelected: boolean) => {
    this.data.regions.forEach(region => {
      region.selected = isSelected;
      region.states.forEach(state => {
        state.selected = isSelected;
        state.applied = isSelected;
      });
    });
  };

  toggleRegionCode = (code: string) => {
    this.data.regions.forEach(region => {
      if (region.code === code) {
        region.selected = !region.selected;
        region.states.forEach(state => {
          state.selected = region.selected;
          state.applied = region.selected;
        });
      }
    });
  };

  toggleStateSelection = (regionCode: string, stateCode: string) => {
    this.data.regions.forEach(region => {
      if (region.code === regionCode) {
        region.states.forEach(state => {
          if (state.state === stateCode) {
            state.selected = !state.selected;
          }
        });
      }
    });
  };

  applyStates = (regionCode: string) => {
    this.data.regions.forEach(region => {
      if (region.code === regionCode) {
        region.states.forEach(state => {
          state.applied = state.selected;
        });

        region.selected = region.states.some(state => state.selected);
      }
    });
  };

  getAppliedStates = (regionCode: string) => {
    const region = this.data.regions.find(region => region.code === regionCode);

    return region?.states.filter(state => state.applied).map(state => state.state);
  };

  parseRegions = (regions: Maybe<Maybe<RgnlAltRegion>[]> | undefined | null): RgnlAltRegionField[] => {
    const filteredRegions = REGIONS.filter(region => region.brand === this.data.brand && region.code !== '190' && region.code !== '390');

    return filteredRegions.map(region => {
      return {
        ...region,
        selected: Boolean(regions?.find(r => r?.regionCode === region?.code)?.states?.length),
        states: region.statesList.split(',').map(state => {
          return {
            state,
            selected: Boolean(regions?.find(r => r?.regionCode === region?.code)?.states?.includes(state)),
            applied: Boolean(regions?.find(r => r?.regionCode === region?.code)?.states?.includes(state)),
          };
        }),
      };
    });
  };

  getRegionsPayload = () => {
    const regions: RgnlAltRegionInput[] = [];

    this.data.regions.forEach(region => {
      regions.push({
        regionCode: region.code,
        states: region.states.filter(state => state.applied).map(state => state.state),
      });
    });

    return regions;
  };

  updateDataField = <T extends keyof RgnlAltFields, V extends RgnlAltFields[T]>(field: T, value: V) => {
    this.data[field] = value;
  };

  resetRegionsToInitialValue = () => {
    this.data.regions = this.parseRegions(this.initialRegions);
  };

  setInitialRegions = (rgns: RgnlAltRegion[]) => {
    this.initialRegions = rgns;
  };

  updateOfferRegions = (updatedOffers: Offer[]) => {
    this.setupOffers.forEach(setupOffer => {
      const foundOffer = updatedOffers.find(updatedOffer => updatedOffer.id === setupOffer.id);

      if (foundOffer) {
        setupOffer.setData(foundOffer);
        setupOffer.setRevAndId(foundOffer);
      }
    });
  };

  toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  addOffers = (optionTypes: string[], seriesProfile: SeriesProfileModel, standards: OfferingStandardRates, isLexus: boolean, csSeriesProfile?: ICostShareSeriesProfile) => {
    optionTypes.forEach(optionType => {
      const offerModel = new OfferModel();

      switch (optionType) {
        case OPTION_TYPE_NAMES.CUSTOMER_CASH:
        case OPTION_TYPE_NAMES.ADDITIONAL_RYO:
          offerModel.setCashOffer({ rgnlAltId: this.data.id, optionType });
          break;
        case OPTION_TYPE_NAMES.APR: {
          const rateAdjustment = Number(csSeriesProfile?.costShares.find(cs => cs.id === csSeriesProfile.fullMonthAprCostShareId)?.fields.value || 0);
          offerModel.initAprOffer(this.data.id, standards.aprRates, isLexus, seriesProfile.data.ncsRate ? seriesProfile.data.ncsRate - rateAdjustment : rateAdjustment);
          break;
        }
        case OPTION_TYPE_NAMES.LEASE: {
          const rcfAdjustment = Number(csSeriesProfile?.costShares.find(cs => cs.id === csSeriesProfile.fullMonthLeaseCostShareId)?.fields.value || 0);
          offerModel.initLeaseOffer(this.data.brand ?? '', this.data.id, seriesProfile, standards, rcfAdjustment);
          break;
        }
        case OPTION_TYPE_NAMES.NATIONAL_RYO:
          offerModel.setNationalRyoOffer(this.data.id, undefined, optionType, seriesProfile.selectedScenario?.rgnlAlts ?? []);
          break;
        case OPTION_TYPE_NAMES.FINAL_PAY:
          offerModel.setFinalPayOffer(this.data.id, undefined, optionType);
          break;
        default:
          offerModel.setMiscOffer(this.data.id, undefined, optionType);
      }

      this.offers.push(offerModel);
    });
  };

  /**
   * Used for copy offer
   * @param offers
   * @param optionType
   * @param rgnlAltId
   * @param parentId
   */
  copyOffer = (offers: Offer[], optionType: string, rgnlAltId: string, seriesProfile: SeriesProfileModel, parentId: string) => {
    if (optionType === OPTION_TYPE_NAMES.LEASE) {
      if (seriesProfile) {
        const newOffer = new OfferModel();
        newOffer.setLeaseOffer(this.data.brand ?? '', rgnlAltId, seriesProfile, offers);
        const parentOfferIndex = this.offers.findIndex(off => off.leaseDetails?.selectedLease?.offerFields.id === parentId);
        this.offers.splice(parentOfferIndex + 1, 0, newOffer);
      }
    } else {
      offers.forEach(offer => {
        const newOffer = new OfferModel();

        switch (offer.optionType) {
          case OPTION_TYPE_NAMES.CUSTOMER_CASH:
          case OPTION_TYPE_NAMES.ADDITIONAL_RYO:
            newOffer.setCashOffer({ rgnlAltId, offer });
            break;
          case OPTION_TYPE_NAMES.APR:
            newOffer.setAprOffer(rgnlAltId, offer, seriesProfile.data.ncsRate);
            break;
          case OPTION_TYPE_NAMES.NATIONAL_RYO:
            newOffer.setNationalRyoOffer(rgnlAltId, offer);
            break;
          case OPTION_TYPE_NAMES.FINAL_PAY:
            newOffer.setFinalPayOffer(rgnlAltId, offer);
            break;
          default:
            newOffer.setMiscOffer(rgnlAltId, offer);
        }

        let parentOfferIndex = this.offers.findIndex(off => off.getOfferId() === parentId);
        this.offers.splice(parentOfferIndex + 1, 0, newOffer);
      });
    }

    this.refreshSortOrder();
  };

  deleteOffer = (offerUid: string) => {
    this.offers = this.offers.filter(item => item.offerUid !== offerUid);
  };

  hasError = () => {
    return this.numberError || this.nameError || this.penRateError;
  };

  setSetupConfirmErrors = (errors: string[]) => {
    this.setupConfirmErrors = errors;
  };

  getSortOrderByOfferUid = (offerUid: string) => {
    return this.offers.findIndex(item => item.offerUid === offerUid);
  };

  sortOffers = (offerUid: string, up: boolean) => {
    let changed = false; // decides whether to save or not
    const offers = this.offers.slice();
    const index = offers.findIndex(item => item.offerUid === offerUid);

    // if moving up, that means if index is 0, it can't go any lower
    const limit = up ? 0 : offers.length - 1;

    if (index !== -1 && index !== limit) {
      changed = true;
      const newIndex = up ? index - 1 : index + 1;

      // swap index and assign sort order
      [offers[index], offers[newIndex]] = [offers[newIndex], offers[index]];

      // create sort order array
      // there could be gaps with non persisted offers
      // Input: [persisted, not persisted, persisted]
      // Output sort order: [1, 3]
      const offerSortOrder: OfferSortOrderResult[] = offers
        .map((offer, sortOrder) => {
          const offerId = offer.getOfferId();

          return {
            offerId,
            sortOrder,
          };
        })
        .filter(item => Boolean(item.offerId));

      this.data.offerSortOrder = offerSortOrder;
      this.offers = offers;
    }

    return changed;
  };

  refreshSortOrder = () => {
    this.data.offerSortOrder = this.offers.map((item, i) => {
      return {
        offerId: item.getOfferId(),
        sortOrder: i,
      };
    });
  };

  getOfferIndexByUid = (uid: string) => {
    return this.offers.findIndex(offer => offer.offerUid === uid);
  };

  getNationalRyoOffers = () => {
    return this.offers.filter(offer => Boolean(offer.nationalRyoDetails));
  };

  getSetupError = () => {
    return this.nameError || this.regionAreaError;
  };

  get numberError() {
    return !!validator(this.data.number, { required: true, min: 0 });
  }

  get nameError() {
    return !!validator(this.data.name, { required: true });
  }

  get penRateError() {
    return !!validator(this.data.penetrationRate, { required: true, min: 0, max: 100 });
  }

  get regionAreaError() {
    return this.data.regions.every(region => !region.selected);
  }

  get budgetErrorMsg() {
    if (!!this.numberError) {
      return 'Please enter a valid RA Number.';
    } else if (!!this.nameError) {
      return 'Please enter a valid RA Name.';
    } else if (!!this.penRateError) {
      return 'Please enter a valid RA Pen Rate.';
    }
    return '';
  }
}

export default RgnlAltModel;
