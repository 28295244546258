import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import useStores from '../../../../../stores/useStores';
import TFSLeaseRateModel from '../../models/TFSLeaseRateModel';
import { processRcf } from '../../utils';
import styles from './styles.module.scss';

interface Props {
  model: TFSLeaseRateModel;
  showHidden: boolean;
}

const TFSTableInput = ({ model, showHidden }: Props) => {
  const {
    ratesTabTFSLeaseRCFStore: { updateTfsLeaseRate, updateTfsLeaseStandardRcf },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { uid, tier, term } = model;
    const newValue = Number(e.target.value);
    updateTfsLeaseRate(uid, tier, term, newValue);

    if (tier === '1+' && term === 24) {
      updateTfsLeaseStandardRcf(newValue);
    }
  };

  return (
    <div className={styles.tableItem}>
      {showHidden && model.tier === '1+' && model.term % 12 !== 0 && <span className={clsx(styles.term, styles.labelText)}>{model.term}</span>}
      <BlockInputLabel bottomContent={<div className={styles.rcfBottomVal}>{processRcf(model.rate)}</div>}>
        <NumberInput
          isNational
          id={`${model.uid}-${model.tier}`}
          className={styles.tableInput}
          rcf
          max={100}
          value={model.differential}
          onChange={onChangeHandler}
          error={!!processRcf(model.rate) && model.rate < 0}
          disabled={isPublished}
        />
      </BlockInputLabel>
    </div>
  );
};

export default observer(TFSTableInput);
