import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { InlineInputLabel, Input } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import styles from './styles.module.scss';

interface Props {
  id: string;
  label: string;
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isDisabled: boolean;
}

const RegionsCheckbox = ({ id, onChangeHandler, label, isChecked, isDisabled }: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(e);
  };

  return (
    <div className={styles.margins}>
      <InlineInputLabel
        className={clsx(styles.checkboxPadding, isDisabled ? styles.labelDisabled : styles.labelEnabled)}
        labelComponent={<Input isNational id={`${label}-checkbox-${id}`} type="checkbox" onChange={e => handleOnChange(e)} checked={isChecked} disabled={isDisabled} />}
      >
        <label className={isDisabled ? styles.labelDisabled : styles.labelEnabled} htmlFor={`${label}-checkbox-${id}`}>
          {label}
        </label>
      </InlineInputLabel>
    </div>
  );
};

export default observer(RegionsCheckbox);
