import { LazyQueryExecFunction } from '@apollo/client';
import clsx from 'clsx';
import { MonthShortNamesConstant, useToast } from 'oat-common-ui';
import { Exact, GetMultiSeriesOffersBudgetQuery, MultiSeriesOffersBudget, OfferingYearMonth } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { processYear } from '../../../../../utils/processYear';
import FiscalYearNav from '../../../../FYRollup/components/header/Navbar/components/FiscalYearNav';
import GridRow from '../Grid/GridRow';
import GridRowHead from '../Grid/GridRowHead';
import styles from '../styles.module.scss';

interface Props {
  headerDetails: Array<{ month: number; year: number }>;
  fetchMultiSeriesOfferBudget: LazyQueryExecFunction<GetMultiSeriesOffersBudgetQuery, Exact<{ brand: string; fiscalYear: number }>>;
}

const MultiSeriesOffersHeader = ({ headerDetails, fetchMultiSeriesOfferBudget }: Props) => {
  const { error } = useToast();

  const {
    multiSeriesOffersBudgetStore: { setIsLoading, setFiscalYear, initData, fiscalYear },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const changeFiscalYearHandler = async (year: number) => {
    setIsLoading(true);

    try {
      const res = await fetchMultiSeriesOfferBudget({ variables: { brand, fiscalYear: year } });

      setFiscalYear(year);
      initData(
        brand,
        res.data?.fetchMultiSeriesOffersBudget.multiSeriesOffersBudget as MultiSeriesOffersBudget,
        res.data?.fetchMultiSeriesOffersBudget.draftOfferingMonths as OfferingYearMonth[],
      );
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GridRow className={styles.borderTop}>
      <GridRowHead>
        <FiscalYearNav
          label="Fiscal Year"
          toNextFiscalYear={() => {
            changeFiscalYearHandler(fiscalYear + 1);
          }}
          toPrevFiscalYear={() => {
            changeFiscalYearHandler(fiscalYear - 1);
          }}
          buttonStyle={styles.fiscalBtn}
        />
      </GridRowHead>
      {headerDetails.map((item, i) => {
        return (
          <div className={styles.gridItem} key={`${item}-${i}`}>
            <span className={clsx(styles.fiscalText, styles.fiscalMonth)}>{MonthShortNamesConstant[item.month]}</span>
            <span className={styles.fiscalText}>{processYear(item.year)}</span>
          </div>
        );
      })}
      <div className={styles.gridItem}>
        <span className={styles.fiscalText}>Total</span>
      </div>
    </GridRow>
  );
};

export default MultiSeriesOffersHeader;
