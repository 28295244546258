import { useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useCreateMiscOfferMutation, useUpdateMiscOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import MiscModel from '../../models/offers/MiscModel';
import { getCreateMiscOfferPayload, getUpdateMiscOfferPayload } from './createMiscPayload';

const useSaveMisc = (seriesProfile: SeriesProfileModel, rgnlAlt: RgnlAltModel, misc: MiscModel) => {
  const {
    nationalProgramsStore: { offering },
    userInfoStore: {
      userInfo: { brand },
      isLexus,
    },
    offeringCostsStore: { updateOfferingCostsFromResponse },
  } = useStores();
  const { setDelay } = useInputDelay();
  const [createMiscOffer] = useCreateMiscOfferMutation();
  const [updateMiscOfferBudget] = useUpdateMiscOfferMutation();
  const { error } = useToast();

  const { offerCostFields, offerFields, postSave } = misc;

  const saveMiscOffer = () => {
    if (!offerFields.name || !offerCostFields.amount) {
      return;
    }

    setDelay(async () => {
      if (!offerFields.id) {
        try {
          const res = await trackPromise(
            createMiscOffer({ variables: { input: { ...getCreateMiscOfferPayload(brand, offering.id, misc, rgnlAlt.getSortOrderByOfferUid(misc.uid), isLexus()) } } }),
          );
          if (res.data?.createMiscOffer.success) {
            postSave(res.data?.createMiscOffer.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.createMiscOffer.offeringCosts as OfferingCosts);
            seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.createMiscOffer.ryoOffers as Offer[]);

            if (res.data?.createMiscOffer.rgnlAltId && res.data?.createMiscOffer.rgnlAltRev) {
              rgnlAlt.updateRev(res.data?.createMiscOffer.rgnlAltId, res.data?.createMiscOffer.rgnlAltRev);
            }

            const newOfferSortOrder = { offerId: res.data?.createMiscOffer.offer.id, sortOrder: rgnlAlt.getSortOrderByOfferUid(misc.uid) };
            rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
          }
        } catch (e) {
          error((e as Error).message);
        }
      } else {
        try {
          const res = await trackPromise(updateMiscOfferBudget({ variables: { input: { ...getUpdateMiscOfferPayload(misc, isLexus()) } } }));
          if (res.data?.updateMiscOfferBudget.success) {
            postSave(res.data?.updateMiscOfferBudget.offer as Offer);
            updateOfferingCostsFromResponse(res.data?.updateMiscOfferBudget.offeringCosts as OfferingCosts);
            seriesProfile?.selectedScenario?.updateRyoOffers(res.data?.updateMiscOfferBudget.ryoOffers as Offer[]);
          }
        } catch (e) {
          error((e as Error).message);
        }
      }
    });
  };

  return {
    saveMiscOffer,
  };
};

export default useSaveMisc;
