import { OATIcon } from 'oat-common-ui';
import { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

const EditButton = ({ disabled, children, ...props }: Props) => {
  return (
    <button className={styles.editBtn} {...props} disabled={disabled}>
      <OATIcon icon="edit" size={12} />
      {children}
    </button>
  );
};

export default EditButton;
