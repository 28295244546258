import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { assignNumberValue, BlockInputLabel, Input, NumberInput } from 'oat-common-ui';
import BasicPNVS from '../../../../components/BasicPNVS';
import { FEATURE_OR_2477 } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import OfferFooter from '../../components/OfferFooter';
import OfferHeader from '../../components/OfferHeader';
import OfferTitle from '../../components/OfferTitle';
import MiscModel, { MiscCostFields } from '../../models/offers/MiscModel';
import { OfferFields } from '../../models/offers/OfferFields';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import styles from './styles.module.scss';
import useSaveMisc from './useSaveMisc';

const { OPTION_TYPE_NAMES } = AdminConstants;

interface Props {
  misc: MiscModel;
  rgnlAlt: RgnlAltModel;
  seriesProfile: SeriesProfileModel;
}

const MiscSection = ({ misc, rgnlAlt, seriesProfile }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const { uid, offerFields, offerCostFields, updateOfferField, updateOfferCostFields, nameError, amountError, penetrationRateError, costShareError, isEnhancedTfs, isEnhancedCap } =
    misc;
  const hasAmount = assignNumberValue(offerCostFields.amount) >= 0;
  const { saveMiscOffer } = useSaveMisc(seriesProfile, rgnlAlt, misc);

  const handleUpdateMiscField = (fieldName: keyof MiscCostFields, value: string | number) => {
    seriesProfile.clearErrors();
    updateOfferCostFields(fieldName, value);
    saveMiscOffer();
  };

  const handleUpdateOfferField = (fieldName: keyof OfferFields, value: string | number | boolean) => {
    seriesProfile.clearErrors();
    updateOfferField(fieldName, value);
    saveMiscOffer();
  };

  return (
    <>
      <OfferHeader>
        <OfferTitle offerType="misc" title={offerFields.optionType} />
        <BasicPNVS offerPnvs={offerCostFields.offerPnvs} />
      </OfferHeader>
      <fieldset className={styles.miscForm} disabled={seriesProfile?.data.isBudgetConfirmed}>
        <div className={styles.miscFormName}>
          <BlockInputLabel label="Name" className={clsx(styles.inputWrapper, styles.textInput)}>
            <Input id={`name-${uid}`} isNational value={offerFields.name} onChange={val => handleUpdateOfferField('name', val.currentTarget.value)} error={nameError} />
          </BlockInputLabel>
        </div>
        <div className={styles.miscFormFields}>
          <BlockInputLabel label={offerFields.optionType === OPTION_TYPE_NAMES.DEFERRED_PAYMENT ? 'PNV' : 'Amount'} className={clsx(styles.inputWrapper, styles.textInput)}>
            <NumberInput
              id={`${offerFields.optionType === OPTION_TYPE_NAMES.DEFERRED_PAYMENT ? 'PNV' : 'Amount'}-${uid}`}
              dollarSign={hasAmount}
              error={amountError}
              units
              isNational
              onChange={e => handleUpdateMiscField('amount', e.currentTarget.value)}
              required
              value={offerCostFields.amount}
              wholeNumber
            />
          </BlockInputLabel>
          <BlockInputLabel label="Total Cash Pen %" className={clsx(styles.inputWrapper, styles.textInput)}>
            <NumberInput
              id={`cash-pen-${uid}`}
              error={penetrationRateError}
              isNational
              max={100}
              min={0}
              onChange={val => handleUpdateOfferField('penetrationRate', val.currentTarget.value)}
              percentageSign
              required
              value={offerFields.penetrationRate}
              wholeNumber
            />
          </BlockInputLabel>
          {(!FEATURE_OR_2477 ||
            (FEATURE_OR_2477 && misc.offerFields.optionType !== OPTION_TYPE_NAMES.RYO_EARNINGS && misc.offerFields.optionType !== OPTION_TYPE_NAMES.LUMPSUM_RYO)) && (
            <>
              <BlockInputLabel label={appendTfsLabel(isLexus(), 'Cost Share %')} className={clsx(styles.inputWrapper, styles.textInput)}>
                <NumberInput
                  id={`cost-share-${uid}`}
                  error={costShareError}
                  isNational
                  max={100}
                  min={0}
                  onChange={val => handleUpdateMiscField('tfsCostShare', assignNumberValue(val.currentTarget.value))}
                  percentageSign
                  required
                  value={offerCostFields.tfsCostShare}
                  wholeNumber
                  className={FEATURE_OR_2477 && isEnhancedTfs ? styles.costShareEnh : ''}
                />
              </BlockInputLabel>
              <BlockInputLabel label={appendTfsLabel(isLexus(), 'Cap')} className={clsx(styles.inputWrapper, styles.textInput)}>
                <NumberInput
                  id={`tfs-cap-${uid}`}
                  dollarSign
                  isNational
                  min={0}
                  onValueChange={val => handleUpdateMiscField('tfsCapAmount', assignNumberValue(val.value))}
                  value={offerCostFields.tfsCapAmount}
                  wholeNumber
                  className={FEATURE_OR_2477 && isEnhancedCap ? styles.costShareEnh : ''}
                  units
                />
              </BlockInputLabel>
            </>
          )}
        </div>
      </fieldset>
      <OfferFooter
        uid={misc.uid}
        seriesProfile={seriesProfile}
        offerFields={misc.offerFields}
        rgnlAlt={rgnlAlt}
        isEnhTfsCostShareForRegions={misc.offerFields.isEnhTfsCostShareForRegions}
        isForRegions={misc.offerFields.isForRegions}
        isForRegionsDisabled={false}
        isEnhTfsCostShareForRegionsDisabled={misc.enhCostShareCheckboxIsDisabled}
        updateOfferField={(field, val) => {
          handleUpdateOfferField(field, !val);
        }}
      />
    </>
  );
};

export default observer(MiscSection);
