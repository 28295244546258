import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import NationalRyoModel from '../../../models/offers/NationalRyoModel';
import RgnlAltInput from '../RgnlAltInput';
import styles from '../styles.module.scss';
import OfferName from './OfferName';
import { useSummaryCosts } from '../useSummaryCosts';
import RowProps from './RowProps';
import { FEATURE_OR_4154 } from '../../../../../constants/global';

const NationalRyoRow = ({ offer, rgnlAlt }: RowProps<NationalRyoModel>) => {
  const { tmsCost } = useSummaryCosts(rgnlAlt, offer);

  return (
    <tr>
      <td className={styles.label}>
        <OfferName>{offer.offerFields.name}</OfferName>
      </td>
      <td>
        <span className={styles.nryoAmt}>${formatDollars(offer.offerCostFields.amount)}</span>
      </td>
      <td>${formatDollars(offer.offerCostFields.offerPnvs)}</td>
      <td>
        <RgnlAltInput
          id={`misc-summary-penrate-${offer.uid}`}
          isNational
          wholeNumber
          percentageSign
          value={offer.offerFields.penetrationRate}
          disabled
          className={styles.penRateInput}
        />
      </td>
      {FEATURE_OR_4154 && (
        <>
          <td>${tmsCost}</td>
          <td>$0</td>
        </>
      )}
    </tr>
  );
};

export default observer(NationalRyoRow);
