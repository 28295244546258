import { getFiscalYearMonths } from 'oat-admin-common';
import { AssumptionMonth, AssumptionsModel } from '../../../models/Assumptions';

export const getAssumptionMonths = (fiscalYear: number) => {
  return getFiscalYearMonths(fiscalYear);
};

export const setMonthsToParse = (fiscalYear: number, data: AssumptionsModel): AssumptionMonth[] => {
  return getAssumptionMonths(fiscalYear).map(m => {
    const { month, year } = m;
    const foundData = data.months.find(dataMonth => dataMonth.month === month && dataMonth.year === year);

    return {
      month,
      year,
      residualValue: foundData?.residualValue ?? undefined,
      standardRate: foundData?.standardRate ?? undefined,
      standardRcf: foundData?.standardRcf ?? undefined,
    };
  });
};

export const setEmptyAssumptionMonths = (fiscalYear: number) => {
  return getFiscalYearMonths(fiscalYear).map(m => ({ ...m, residualValue: undefined, standardRate: undefined, standardRcf: undefined }));
};
