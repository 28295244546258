import Button from '../../../components/Button';
import styles from './styles.module.scss';

interface Props {
  errorMsg: string;
  disableOverrideCta?: boolean;
  onOverride?: (skipError: boolean) => void;
  onCancelOverride?: () => void;
}

const ModalErrorMsg = ({ errorMsg = '', disableOverrideCta = false, onOverride, onCancelOverride }: Props) => {
  return (
    <>
      {errorMsg && (
        <p className={styles.errorText}>
          {errorMsg}
          {onOverride && (
            <>
              <Button
                disabled={disableOverrideCta}
                id="cancel-date-error-btn"
                onClick={() => {
                  if (onCancelOverride) {
                    onCancelOverride();
                  }
                }}
                variant="override"
              >
                Cancel
              </Button>
              <Button
                disabled={disableOverrideCta}
                id="ignore-date-error-btn"
                onClick={() => {
                  onOverride(true);
                }}
                variant="override"
              >
                Yes
              </Button>
            </>
          )}
        </p>
      )}
    </>
  );
};

export default ModalErrorMsg;
