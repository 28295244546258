import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Dropdown, InlineInputLabel, Input, NumberInput, Textarea, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useUpdateMiscOfferSetupMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { getDropdownItemFromEnum } from '../../../../../utils/getDropdownItemFromEnum';
import AddVehicle from '../../../components/AddVehicle';
import TfsContracts from '../../../components/TfsContracts';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import MiscSetupModel from '../../../models/offersSetup/MiscSetupModel';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';
import styles from './styles.module.scss';
import { getPayloadForUpdateMiscOfferByType } from './utils';

const { OPTION_TYPE_NAMES, GIFT_DELIVERY } = AdminConstants;

type Props = {
  item: OfferSetupModel;
  optionType: string;
  seriesProfile: SeriesProfileModel;
  miscSetupDetails: MiscSetupModel;
};

const MiscOfferSetup = ({ item, optionType, miscSetupDetails, seriesProfile }: Props) => {
  const {
    fields: { giftDeliveryOption, targetedAudience, isTransferable, maxDaysNo },
    updateField,
    showCompetitorDetails,
    hasTargetedAndTransferable,
  } = miscSetupDetails;

  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const [updateMiscOfferSetup] = useUpdateMiscOfferSetupMutation();

  const handleSaveMisc = async () => {
    setDelay(async () => {
      try {
        if (!miscSetupDetails.hasErrors()) {
          const res = await trackPromise(
            updateMiscOfferSetup({
              variables: {
                input: getPayloadForUpdateMiscOfferByType(optionType, miscSetupDetails, item),
              },
            }),
          );
          if (res.data?.updateMiscOfferSetup.success) {
            item.setRevAndId(res.data.updateMiscOfferSetup.offer);
          }
        }
      } catch (e) {
        error((e as Error).message);
      }
    });
  };

  const isDisabled = seriesProfile.isConfirmed || isOfferingPublished;

  return (
    <fieldset className={styles.miscSetupDetails} disabled={isDisabled}>
      {optionType === OPTION_TYPE_NAMES.DEFERRED_PAYMENT && (
        <BlockInputLabel label="Maximum No. of Days" labelClass={styles.label}>
          <NumberInput
            className={styles.maxNoOfDaysInput}
            id="max-no-of-days"
            isNational
            value={maxDaysNo}
            onChange={val => {
              updateField('maxDaysNo', Number(val.currentTarget.value));
              handleSaveMisc();
            }}
            units
          />
        </BlockInputLabel>
      )}
      <TfsContracts misc={miscSetupDetails} onSave={handleSaveMisc} isDisabled={isDisabled} />
      <div className={styles.miscSetupFields}>
        {hasTargetedAndTransferable && (
          <>
            <BlockInputLabel label="Targeted Audience" labelClass={styles.label}>
              <Textarea
                id="targeted-audience-setup"
                isNational
                value={targetedAudience}
                onChange={val => {
                  updateField('targetedAudience', val.currentTarget.value);
                  handleSaveMisc();
                }}
                required={miscSetupDetails.isTargetAudienceRequired}
                error={miscSetupDetails.targetAudienceError}
              />
            </BlockInputLabel>
            <div className={styles.isTransferable}>
              <p className={styles.isTransferableText}>Transferable</p>
              <InlineInputLabel label="Yes" inputWrapperClass={styles.isTransferableLabel} contentGrow={false}>
                <Input
                  isNational
                  id="is-transferable-setup-1"
                  type="radio"
                  checked={isTransferable}
                  onChange={() => {
                    updateField('isTransferable', true);
                    handleSaveMisc();
                  }}
                  className={styles.radioInput}
                  required
                />
              </InlineInputLabel>
              <InlineInputLabel label="No" inputWrapperClass={styles.isTransferableLabel} contentGrow={false}>
                <Input
                  isNational
                  id="is-transferable-setup-2"
                  type="radio"
                  checked={!isTransferable}
                  onChange={() => {
                    updateField('isTransferable', false);
                    handleSaveMisc();
                  }}
                  className={styles.radioInput}
                  required
                />
              </InlineInputLabel>
            </div>
          </>
        )}
        {optionType === OPTION_TYPE_NAMES.NON_CASH_CERTIFICATE && (
          <BlockInputLabel label="Gift Delivery" labelClass={styles.label}>
            <Dropdown
              id="gift-delivery"
              value={giftDeliveryOption}
              options={getDropdownItemFromEnum(GIFT_DELIVERY)}
              onChange={val => {
                updateField('giftDeliveryOption', val.value);
                handleSaveMisc();
              }}
              disabled={isDisabled}
            />
          </BlockInputLabel>
        )}
      </div>
      {/* Competitor Details aka AddVehicle */}
      {showCompetitorDetails && <AddVehicle misc={miscSetupDetails} optionType={optionType} onSave={handleSaveMisc} />}
    </fieldset>
  );
};

export default observer(MiscOfferSetup);
