import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import useStores from '../../../../../stores/useStores';
import styles from '../../../styles.module.scss';

interface Props {
  year: number;
}

const FYSeriesYearsListItem = ({ year }: Props) => {
  const {
    fyRollupStore: { isEdit },
  } = useStores();

  return (
    <li>
      <div className={clsx(isEdit && styles.seriesBoxListItemEdit, styles.seriesBoxListItem, styles.valTextColor)}>{year}</div>
    </li>
  );
};

export default observer(FYSeriesYearsListItem);
