import { observer } from 'mobx-react-lite';
import { assignStringValue, BlockUi, ButtonGroup, dateStringToDate, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../components/Button';
import GeneralIpFields from '../../components/GeneralIpFields';
import { Offering, useCopyOfferingMutation, useGetIncentiveCalendarLazyQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import ModalErrorMsg from './ModalErrorMsg';
import styles from './styles.module.scss';

interface Props {
  offering: Offering;
  showCopyModal: boolean;
  handleCloseCopyModal: () => void;
  setShowCopyModal: (val: boolean) => void;
}

const CopyProgramModal = ({ offering, showCopyModal, setShowCopyModal, handleCloseCopyModal }: Props) => {
  const {
    dashboardStore: { addCopiedOffering, setUnsavedChanges },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { error } = useToast();
  const [fetchIncentiveCalendar] = useGetIncentiveCalendarLazyQuery();
  const [copyOffering] = useCopyOfferingMutation();

  const [copiedOffering, setCopiedOffering] = useState(offering);
  const [showNote, setShowNote] = useState(Boolean(offering?.note?.length));
  const [errorMsg, setErrorMsg] = useState('');
  const [invalidDatesErrorMsg, setInvalidDatesErrorMsg] = useState('');
  const [blockingUI, setBlockingUI] = useState(false);

  const handleTrashIcon = () => {
    setCopiedOffering(prevState => {
      return {
        ...prevState,
        note: '',
      };
    });
    setShowNote(false);
    setUnsavedChanges(true);
  };

  const handleFieldChange = (fieldName: 'name' | 'startDate' | 'endDate' | 'note', value: string) => {
    if ((fieldName === 'startDate' || fieldName === 'endDate') && !value) {
      return;
    }

    if (['startDate', 'endDate'].includes(fieldName)) {
      setErrorMsg('');
    }
    setCopiedOffering(prevState => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
    setUnsavedChanges(true);
  };

  const handleCancelOverride = () => {
    setErrorMsg('');
    setCopiedOffering(prevState => {
      return {
        ...prevState,
        startDate: offering.startDate,
        endDate: offering.endDate,
      };
    });
  };

  const handleCreateCopyIp = async (skipError = false) => {
    if (!skipError) {
      try {
        const res = await fetchIncentiveCalendar({ variables: { brand, fiscalYear: dateStringToDate(copiedOffering.startDate).getFullYear() } });

        const isMatch = res.data?.fetchIncentiveCalendar?.data?.months?.some(
          month =>
            dateStringToDate(copiedOffering.startDate).getTime() === dateStringToDate(month.startDate).getTime() &&
            dateStringToDate(copiedOffering.endDate).getTime() === dateStringToDate(month.endDate).getTime(),
        );

        if (!isMatch) {
          setErrorMsg('Incentive period dates do not align with the OAT Incentive Calendar. Do you wish to overwrite the OAT Incentive Calendar?');

          return;
        }
      } catch (e) {
        error((e as Error).message);
      }
    }

    try {
      setBlockingUI(true);

      const res = await trackPromise(
        copyOffering({
          variables: {
            input: { offeringId: copiedOffering.id, name: copiedOffering.name, startDate: copiedOffering.startDate, endDate: copiedOffering.endDate, note: copiedOffering.note },
          },
        }),
      );
      if (res.data?.copyOffering.success) {
        addCopiedOffering(res.data.copyOffering.offering);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setBlockingUI(false);
      setShowCopyModal(false);
      setUnsavedChanges(false);
    }
  };

  const dateOrderError = Boolean(copiedOffering.startDate && copiedOffering.endDate && copiedOffering.startDate >= copiedOffering.endDate);
  const disableCreateCta = !copiedOffering.startDate.length || !copiedOffering.endDate.length || !copiedOffering.name || dateOrderError || Boolean(errorMsg.length);

  useEffect(() => {
    if (dateOrderError) {
      setInvalidDatesErrorMsg('Invalid Start and End Dates');
    } else {
      setInvalidDatesErrorMsg('');
    }
  }, [dateOrderError]);

  return (
    <Modal isOpen={showCopyModal} onClose={handleCloseCopyModal}>
      <BlockUi blocking={blockingUI} title="Copying Incentive Period ..." />
      <ModalHeader title="Copy Incentive Program" onClose={handleCloseCopyModal} />
      <ModalBody>
        <GeneralIpFields
          endDate={assignStringValue(copiedOffering?.endDate)}
          endDateError={!copiedOffering.endDate.length || dateOrderError}
          handleFieldChange={handleFieldChange}
          handleTrashIcon={handleTrashIcon}
          name={assignStringValue(copiedOffering?.name)}
          note={assignStringValue(copiedOffering?.note)}
          noteId={assignStringValue(copiedOffering?.id)}
          offeringNameError={false}
          setShowNote={setShowNote}
          showNote={showNote}
          startDate={assignStringValue(copiedOffering?.startDate)}
          startDateError={!copiedOffering.startDate.length || dateOrderError}
        />
      </ModalBody>
      <ModalFooter>
        <div className={styles.errorsWrapper}>
          <ModalErrorMsg errorMsg={invalidDatesErrorMsg} />
          <ModalErrorMsg errorMsg={errorMsg} disableOverrideCta={!copiedOffering.name} onOverride={handleCreateCopyIp} onCancelOverride={handleCancelOverride} />
        </div>
        <ButtonGroup className={styles.footerBtns}>
          <Button id="cancel-ip-copy-btn" onClick={handleCloseCopyModal} className={styles.cancelBtn}>
            Cancel
          </Button>
          <Button id="create-ip-copy-btn" variant="blue" onClick={() => handleCreateCopyIp()} disabled={disableCreateCta}>
            Create
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(CopyProgramModal);
