import { assignStringValue, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useCreateAprOfferMutation, useUpdateAprOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import AprModel from '../../models/offers/apr/AprModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import { createNewAprParams, createUpdateAprParams } from './utils/createParams';

const useSaveApr = (seriesProfile: SeriesProfileModel, rgnlAlt: RgnlAltModel, apr: AprModel) => {
  const {
    offeringCostsStore: { updateOfferingCostsFromResponse },
    userInfoStore: { isLexus },
  } = useStores();
  const { setDelay } = useInputDelay();
  const [createApr] = useCreateAprOfferMutation();
  const [updateApr] = useUpdateAprOfferMutation();
  const { error } = useToast();

  const { offerFields, setRevAndId, hasNoErrors } = apr;

  const saveApr = () => {
    if (hasNoErrors()) {
      setDelay(async () => {
        try {
          if (!offerFields.rev) {
            const createInput = createNewAprParams(apr, rgnlAlt.getSortOrderByOfferUid(apr.uid), isLexus());
            const res = await trackPromise(createApr({ variables: { input: createInput } }));
            const resData = res.data;

            if (resData) {
              setRevAndId(resData.createAprOffer.offer as Offer);
              updateOfferingCostsFromResponse(resData.createAprOffer.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(resData.createAprOffer.ryoOffers as Offer[]);
              if (resData.createAprOffer.rgnlAltId && resData.createAprOffer.rgnlAltRev) {
                rgnlAlt.updateRev(resData.createAprOffer.rgnlAltId, resData.createAprOffer.rgnlAltRev);
              }

              const newOfferSortOrder = { offerId: assignStringValue(resData.createAprOffer.offer.id), sortOrder: rgnlAlt.getSortOrderByOfferUid(apr.uid) };
              rgnlAlt.updateOfferSortOrder(newOfferSortOrder);
            }
          } else {
            const updateInput = createUpdateAprParams(apr, isLexus());
            const res = await trackPromise(updateApr({ variables: { input: updateInput } }));
            const resData = res.data;

            if (resData) {
              setRevAndId(resData.updateAprOfferBudget.offer as Offer);
              updateOfferingCostsFromResponse(resData.updateAprOfferBudget.offeringCosts as OfferingCosts);
              seriesProfile?.selectedScenario?.updateRyoOffers(resData.updateAprOfferBudget.ryoOffers as Offer[]);
            }
          }
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return {
    saveApr,
  };
};

export default useSaveApr;
