import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_5454_PHASE_2 } from '../../../../../constants/global';
import { TfsContractInput, UpdateMiscOfferSetupInput } from '../../../../../gql/generated';
import MiscSetupModel from '../../../models/offersSetup/MiscSetupModel';
import OfferSetupModel from '../../../models/offersSetup/OfferSetupModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const getPayloadForUpdateMiscOfferByType = (optionType: string, miscDetails: MiscSetupModel, item: OfferSetupModel): UpdateMiscOfferSetupInput => {
  let input: UpdateMiscOfferSetupInput = { id: miscDetails.id, optionType, rev: item.rev, isTfsContractRequired: miscDetails.fields.isTfsContractRequired };

  const inputTfs: TfsContractInput[] = [];
  miscDetails.fields.tfsContracts.forEach(tfsContract => {
    if (tfsContract.tierTerms.length > 0) {
      inputTfs.push({ isSubvented: tfsContract.isSubvented, tierTerms: tfsContract.tierTerms, contractType: tfsContract.contractType });
    }
  });

  input = { ...input, tfsContracts: inputTfs };

  if (optionType === OPTION_TYPE_NAMES.DEFERRED_PAYMENT) {
    input = { ...input, maxDaysNo: miscDetails.fields.maxDaysNo };
  }

  if (optionType === OPTION_TYPE_NAMES.NON_CASH_CERTIFICATE) {
    input = { ...input, giftDeliveryOption: miscDetails.fields.giftDeliveryOption };
  }

  if (optionType === OPTION_TYPE_NAMES.CONQUEST || optionType === OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE) {
    input = { ...input, competitorDetails: miscDetails.fields.competitorDetails };
  }

  if (
    optionType === (FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE) ||
    optionType === OPTION_TYPE_NAMES.MILITARY ||
    optionType === OPTION_TYPE_NAMES.LEASE_LOYALTY ||
    optionType === OPTION_TYPE_NAMES.TARGETED_CERTIFICATE ||
    optionType === OPTION_TYPE_NAMES.CAMPAIGN ||
    optionType === OPTION_TYPE_NAMES.LOYALTY ||
    optionType === OPTION_TYPE_NAMES.CONQUEST
  ) {
    input = { ...input, targetedAudience: miscDetails.fields.targetedAudience, isTransferable: miscDetails.fields.isTransferable };
  }

  return input;
};
