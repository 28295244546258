import { OATLoadingBar } from 'oat-common-ui';
import { useEffect } from 'react';
import { useGetIncentiveCalendarQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import IncentiveCalendarComponent from './IncentiveCalendarComponent';

const IncentiveCalendar = () => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    incentiveCalendarStore: { incentiveCalendar, setIncentiveCalendar, setBrand },
  } = useStores();

  const { data, loading } = useGetIncentiveCalendarQuery({ variables: { brand, fiscalYear: incentiveCalendar.fiscalYear } });

  useEffect(() => {
    setBrand(brand);
  }, [brand, setBrand]);

  if (loading) {
    return <OATLoadingBar />;
  }

  if (data?.fetchIncentiveCalendar?.success && data.fetchIncentiveCalendar.data) {
    setIncentiveCalendar(data.fetchIncentiveCalendar.data);
  }

  return <IncentiveCalendarComponent />;
};

export default IncentiveCalendar;
