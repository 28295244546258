import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';

interface Props {
  exampleName: string;
  onClose: () => void;
  onSave: () => void;
}

const RemoveExampleModal = ({ exampleName, onClose, onSave }: Props) => {
  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Remove Lease Example" onClose={onClose} />
      <ModalBody>{`Are you sure you want to remove ${exampleName}?`}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-no-cta" variant="primary" onClick={onClose}>
            No
          </Button>
          <Button id="modal-yes-cta" variant="primary" onClick={onSave}>
            Yes
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveExampleModal;
