import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useInputDelay } from 'oat-common-ui';
import { FYROLLUP, SeriesTitle } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import styles from '../../styles.module.scss';
import { processModelValue, processTotalCost } from '../../utils';
import UpdateValueInput from './UpdateValueInput';

interface Props {
  hasValue: boolean;
  itemValue: number;
  isFyTotal?: boolean;
  title?: string;
  updateValue?: (val: number) => void;
  updateParentValues?: () => void;
}

const FYSeriesBoxListItem = ({ hasValue, itemValue, isFyTotal, title, updateValue, updateParentValues }: Props) => {
  const { setDelay } = useInputDelay();
  const {
    fyRollupStore: { isEdit, fySection },
  } = useStores();

  // if there is no value or item, return --
  if (!hasValue) {
    return <div className={clsx(isEdit && styles.seriesBoxListItemEdit, styles.seriesBoxListItem, isFyTotal ? styles.totalTextColor : styles.valTextColor)}>{'--'}</div>;
  }

  if (isEdit && !isFyTotal && updateValue && updateParentValues) {
    const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      const value = Number(e.target.value.split(',').join(''));

      value !== itemValue && onChangeHandler(value, 0);
    };

    const onChangeHandler = (val: number, delay = 1500) => {
      setDelay(() => {
        updateValue(val);
        updateParentValues();
      }, delay);
    };

    if ((fySection === FYROLLUP.FORECASTED && title === SeriesTitle.PNVS) || (fySection === FYROLLUP.WORKING && (title === SeriesTitle.STOCK || title === SeriesTitle.SALES))) {
      return <UpdateValueInput value={processModelValue(itemValue)} onChangeHandler={onChangeHandler} onBlurHandler={onBlurHandler} />;
    }
  }

  return (
    <div className={clsx(isEdit && styles.seriesBoxListItemEdit, styles.seriesBoxListItem, isFyTotal ? styles.totalTextColor : styles.valTextColor)}>
      {itemValue > 0 ? processTotalCost(itemValue) : '--'}
    </div>
  );
};

export default observer(FYSeriesBoxListItem);
