import { AdminModels } from 'oat-admin-common';
import { dateStringToDate, OATLoadingBar } from 'oat-common-ui';
import { FYROLLUP } from '../../../constants/global';
import { useGetBudgetDataQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import Error from '../../ErrorPage';
import { formatBudgetData } from '../utils';
import WorkingComponent from './WorkingComponent';

const Working = () => {
  const {
    fyRollupStore,
    userInfoStore: {
      userInfo: { brand },
    },
    seriesSettingsStore: { seriesMapping },
  } = useStores();

  const fiscalYear = `${dateStringToDate(new Date()).getFullYear() + 1}`;

  const { data, loading, error } = useGetBudgetDataQuery({
    variables: {
      brand,
      type: FYROLLUP.WORKING,
      fiscalYear,
    },
  });

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    fyRollupStore.initEnterFYSection();
    fyRollupStore.setFYSection(FYROLLUP.WORKING);
    fyRollupStore.setData(formatBudgetData(data.budgetData as AdminModels.BudgetResponse), seriesMapping, !!data.budgetData?.recalculateWorkingBudget);
  }

  return <WorkingComponent />;
};

export default Working;
