import { validator } from 'oat-common-ui';
import TierTermModel from '../models/TierTermModel';

export const isComplete = (tierTerms: TierTermModel[]) => {
  return tierTerms.every(
    t =>
      !validator(t.rate, { required: true, min: 0, max: t.standardRate }) &&
      !validator(t.tfsShare, { required: true, min: 0 }) &&
      !validator(t.penetrationRate, { required: true }) &&
      !validator(t.averageAmountFinanced, { required: true, min: 0 }) &&
      !validator(t.monthlyPayment, { required: true, min: 0 }) && !t.rateError
  );
};

export const tierTermIsComplete = (tierTerms: TierTermModel[]) => {
  return isComplete(tierTerms) && tierTerms.reduce((acc, curr) => acc + Number(curr.penetrationRate), 0) === 100;
};
