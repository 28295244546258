import { makeAutoObservable } from 'mobx';
import { assignNumberValue, uuidv4 } from 'oat-common-ui';
import { MultiSeriesOffer, MultiSeriesOfferMonth } from '../../../../models/MultiSeriesOffer';
import { sortForFiscalYear } from '../../../../utils/sortForFiscalYear';

class MultiSeriesOfferModel {
  id = uuidv4();
  sortOrder: number;

  offerName: string;
  months: MultiSeriesOfferMonth[] = [];

  constructor(data: MultiSeriesOffer) {
    makeAutoObservable(this);

    this.offerName = data.offerName;
    this.months = sortForFiscalYear(data.months);
    this.sortOrder = assignNumberValue(data.sortOrder);
    this.id = data.id;
  }

  get totalCost() {
    return this.months.reduce((acc, curr) => acc + Number(curr.amount), 0);
  }

  setOfferMonthCost = (month: number, value: string) => {
    this.months = this.months.map(m => {
      return {
        ...m,
        amount: m.month === month ? value : m.amount,
      };
    });
  };

  setOfferName = (offerName: string) => {
    this.offerName = offerName;
  };

  hasPublished = () => {
    return this.months.some(month => month.isPublished);
  };
}

export default MultiSeriesOfferModel;
