import { ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import Button from '../../../../components/Button';
import buildOfferTypes from './buildOfferTypes';
import styles from './styles.module.scss';
import { sortAddOffers } from './utils';

interface Props {
  onClose: () => void;
  onAddOffer: (offers: string[]) => void;
}

const renderChboxes = (items: string[], selected: string[], onChange: (item: string) => void) => {
  return (
    <>
      {items.map(item => (
        <label key={item}>
          <input id={item} type="checkbox" checked={selected.includes(item)} onChange={() => onChange(item)} />
          {item}
        </label>
      ))}
    </>
  );
};

const AddOfferModal = ({ onClose, onAddOffer }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const { frequent, other } = buildOfferTypes();

  const handleChange = (type: string) => {
    setSelected(sortAddOffers(selected.includes(type) ? selected.filter(item => item !== type) : [...selected, type]));
  };

  const handleAddOffer = () => {
    onAddOffer(selected);
  };

  return (
    <Modal isOpen onClose={onClose} data-testid="add-offer-modal">
      <ModalHeader title="Select Offer Type" onClose={onClose} />
      <ModalBody className={styles.modalBody}>
        <div className={styles.container}>
          <div className={styles.firstCol}>
            <div className={styles.colHeader}>Frequent Offers</div>
            {renderChboxes(frequent, selected, handleChange)}
          </div>
          <div>
            <div className={styles.colHeader}>Other Offers</div>
            {renderChboxes(other, selected, handleChange)}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <ButtonGroup>
          <Button id="mdl-cancel-cta" variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button id="mdl-add-cta" variant="blue" icon={<OATIcon icon="plus" />} onClick={handleAddOffer}>
            Add Offer
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default AddOfferModal;
