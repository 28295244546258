import { TFS_LABEL, TMS_LABEL } from '../constants/global';

const appendLabel = (prefix: string, label: string, frontLabel = '') => {
  return `${frontLabel ? `${frontLabel} ` : ''}${prefix} ${label}`;
};

export const appendTfsLabel = (isLexus: boolean, label: string, frontLabel = '') => {
  return appendLabel(isLexus ? TFS_LABEL.LEXUS : TFS_LABEL.TOYOTA, label, frontLabel);
};

export const appendTmsLabel = (isLexus: boolean, label: string, frontLabel = '') => {
  return appendLabel(isLexus ? TMS_LABEL.LEXUS : TMS_LABEL.TOYOTA, label, frontLabel);
};
