import { AdminConstants } from 'oat-admin-common';
import { assignStringValue } from 'oat-common-ui';
import RgnlAltModel from '../../../models/RgnlAltModel';
import OfferModel from '../../../models/offers/OfferModel';
import { CompareOffer } from './useCompareData';

const { OPTION_TYPE_NAMES } = AdminConstants;

const regularCompareOfferTypes = [OPTION_TYPE_NAMES.NATIONAL_RYO, OPTION_TYPE_NAMES.CUSTOMER_CASH, OPTION_TYPE_NAMES.APR, OPTION_TYPE_NAMES.LEASE, OPTION_TYPE_NAMES.FINAL_PAY];

const otherCompareOfferTypes = [Object.values(AdminConstants.OPTION_TYPE_NAMES).filter(optionType => !regularCompareOfferTypes.includes(optionType))];

export const compareOfferTypesSortOrder = regularCompareOfferTypes.concat(...otherCompareOfferTypes);

export const sortOffersForCompare = (offers?: OfferModel[]) => {
  return (
    offers?.sort((offerA, offerB) =>
      compareOfferTypesSortOrder.findIndex(o => o === offerA.getOptionType()) < compareOfferTypesSortOrder.findIndex(o => o === offerB.getOptionType()) ? 1 : -1,
    ) || []
  );
};

export const groupOfferByType = (offers?: OfferModel[]) => {
  if (!offers) {
    return;
  }

  const map = new Map();

  offers.forEach(offer => {
    const collection = map.get(offer.getOptionType());

    if (!collection) {
      map.set(offer.getOptionType(), [offer]);
    } else {
      collection.push(offer);
    }
  });

  return map;
};

type GroupedRAs = { [id: number]: RgnlAltModel[] };

const groupRAsByNumber = (rgnAlts: RgnlAltModel[]): GroupedRAs => {
  const result: {
    [id: number]: RgnlAltModel[];
  } = {};

  rgnAlts.forEach(ra => {
    const collection = result[Number(ra.data.number)];

    if (!collection) {
      result[Number(ra.data.number)] = [ra];
    } else {
      collection.push(ra);
    }
  });

  return result;
};

type RACompareDataResult = Array<{
  raNumber: number;
  originalRA?: RgnlAltModel;
  compareRA?: RgnlAltModel;
}>;

export const getRaCompareData = (originalRAs: RgnlAltModel[], compareRAs: RgnlAltModel[]): RACompareDataResult => {
  const result: RACompareDataResult = [];

  const originalRgnAlts = groupRAsByNumber(originalRAs);
  const compareRgnAlts = groupRAsByNumber(compareRAs);

  const highestRANumber = Number(
    Object.keys(originalRgnAlts).reverse()[0] > Object.keys(compareRgnAlts).reverse()[0] ? Object.keys(originalRgnAlts).reverse()[0] : Object.keys(compareRgnAlts).reverse()[0],
  );

  for (let index = 1; index < highestRANumber + 1; index++) {
    originalRgnAlts[index]?.forEach(ra => {
      result.push({
        raNumber: index,
        originalRA: ra,
        compareRA: compareRgnAlts[index]?.shift(),
      });
    });

    compareRgnAlts[index]?.forEach(ra => {
      result.push({
        raNumber: index,
        originalRA: undefined,
        compareRA: ra,
      });
    });
  }

  return result;
};

export const getOferTypeCompareLabel = (offer: CompareOffer) => {
  return assignStringValue(offer.originalOffer?.getOfferFields()?.name || offer.compareOffer?.getOfferFields()?.name);
};

export const addSign = (dollarSign = false, percentageSign = false, stringValue: string) => {
  return !!stringValue ? `${dollarSign ? '$' : ''}${stringValue}${percentageSign ? '%' : ''}` : '';
};
