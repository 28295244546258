import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import Button from '../../../../../../../components/Button';
import styles from '../styles.module.scss';

interface Props {
  handleDeleteByTier: (tier: string) => void;
  disabled: boolean;
}

const DeleteRow = ({ handleDeleteByTier, disabled }: Props) => {
  return (
    <tr className={styles.tRow}>
      <td className={styles.deleteTD} />
      <td className={styles.deleteTD}>
        <Button disabled={disabled} variant="text" id="tier-1+-delete-btn" className={styles.tierDeleteBtn} onClick={() => handleDeleteByTier('1+')}>
          <OATIcon icon="minus" size={20} />
        </Button>
      </td>
      <td className={styles.deleteTD}>
        <Button disabled={disabled} variant="text" id="tier-1-delete-btn" className={styles.tierDeleteBtn} onClick={() => handleDeleteByTier('1')}>
          <OATIcon icon="minus" size={20} />
        </Button>
      </td>
      <td className={styles.deleteTD}>
        <Button disabled={disabled} variant="text" id="tier-2-delete-btn" className={styles.tierDeleteBtn} onClick={() => handleDeleteByTier('2')}>
          <OATIcon icon="minus" size={20} />
        </Button>
      </td>
      <td className={styles.deleteTD}>
        <Button disabled={disabled} variant="text" id="tier-3-delete-btn" className={styles.tierDeleteBtn} onClick={() => handleDeleteByTier('3')}>
          <OATIcon icon="minus" size={20} />
        </Button>
      </td>
    </tr>
  );
};

export default observer(DeleteRow);
