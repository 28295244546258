import { observer } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import { SeriesTitle } from '../../../../constants/global';
import FYSeriesBoxListItem from './FYSeriesBoxListItem';
import GridItemWrapper from './GridItemWrapper';

interface Props {
  series: string;
  fiscalYear: number;
  total?: AdminModels.Total;
}

const GridItemRowTotalDetail = ({ series, fiscalYear, total }: Props) => {
  return (
    <GridItemWrapper>
      <li>
        <FYSeriesBoxListItem title={SeriesTitle.SALES} hasValue={!!total} itemValue={total?.sales ?? 0} isFyTotal={true} />
      </li>
      <li>
        <FYSeriesBoxListItem title={SeriesTitle.PNVS} hasValue={!!total} itemValue={total?.pnvs ?? 0} isFyTotal={true} />
      </li>
      <li>
        <FYSeriesBoxListItem title={SeriesTitle.TOTAL} hasValue={!!total} itemValue={total?.totalCost ?? 0} isFyTotal={true} />
      </li>
    </GridItemWrapper>
  );
};

export default observer(GridItemRowTotalDetail);
