import { observer } from 'mobx-react-lite';
import { BlockUi, ButtonGroup, dateStringToDate, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../../components/Button';
import GeneralIpFields from '../../../components/GeneralIpFields';
import defaultOffering from '../../../defaults/defaultOffering';
import { Offering, useGetIncentiveCalendarLazyQuery, useSaveOfferingMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import ModalErrorMsg from '../ModalErrorMsg';
import styles from '../styles.module.scss';

interface Props {
  onClose: () => void;
}

const CreateProgramModal = ({ onClose }: Props) => {
  const {
    dashboardStore: { setUnsavedChanges, addActiveOffering },
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const { error } = useToast();
  const [fetchIncentiveCalendar] = useGetIncentiveCalendarLazyQuery();
  const [saveOffering] = useSaveOfferingMutation();

  const [newOffering, setNewOffering] = useState<Offering>(defaultOffering());
  const [showNote, setShowNote] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [invalidDatesErrorMsg, setInvalidDatesErrorMsg] = useState('');
  const [blockingUI, setBlockingUI] = useState(false);

  const handleFieldChange = <T extends keyof Offering, V extends Offering[T]>(field: T, value: V) => {
    if (['startDate', 'endDate'].includes(field)) {
      setErrorMsg('');
    }
    setNewOffering({ ...newOffering, [field]: value });
    setUnsavedChanges(true);
  };

  const handleClearNote = () => {
    setNewOffering(prevState => {
      return {
        ...prevState,
        note: '',
      };
    });
    setShowNote(false);
    setUnsavedChanges(true);
  };

  const handleCreateIp = async (skipError = false) => {
    if (!skipError) {
      try {
        const res = await trackPromise(fetchIncentiveCalendar({ variables: { brand, fiscalYear: dateStringToDate(newOffering.startDate).getFullYear() } }));

        const isMatch = res.data?.fetchIncentiveCalendar?.data?.months?.some(
          month =>
            dateStringToDate(newOffering.startDate).getTime() === dateStringToDate(month.startDate).getTime() &&
            dateStringToDate(newOffering.endDate).getTime() === dateStringToDate(month.endDate).getTime(),
        );

        if (!isMatch) {
          setErrorMsg('Incentive period dates do not align with the OAT Incentive Calendar. Do you wish to overwrite the OAT Incentive Calendar?');
          return;
        }
      } catch (e) {
        error((e as Error).message);
      }
    }

    try {
      setBlockingUI(true);

      const res = await trackPromise(
        saveOffering({
          variables: {
            input: { name: newOffering.name, brand, startDate: newOffering.startDate, endDate: newOffering.endDate, note: newOffering.note },
          },
        }),
      );
      if (res.data?.saveOfferingHandler.success) {
        addActiveOffering(res.data.saveOfferingHandler.offering);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setUnsavedChanges(false);
      setBlockingUI(false);
      onClose();
    }
  };

  const dateOrderError = Boolean(newOffering.startDate && newOffering.endDate && newOffering.startDate >= newOffering.endDate);
  const disableCreateCta = !newOffering.startDate.length || !newOffering.endDate.length || !newOffering.name || dateOrderError || Boolean(errorMsg.length);

  useEffect(() => {
    if (dateOrderError) {
      setInvalidDatesErrorMsg('Invalid Start and End Dates');
    } else {
      setInvalidDatesErrorMsg('');
    }
  }, [dateOrderError]);

  return (
    <Modal isOpen onClose={onClose}>
      <BlockUi blocking={blockingUI} title="Creating Incentive Period ..." />
      <ModalHeader title="Create Incentive Period" onClose={onClose} className={styles.createIPModalTitle} />
      <ModalBody>
        <GeneralIpFields
          endDate={newOffering.endDate}
          endDateError={!newOffering.endDate.length || dateOrderError}
          handleFieldChange={handleFieldChange}
          handleTrashIcon={handleClearNote}
          name={newOffering.name}
          note={newOffering.note ?? ''}
          noteId={newOffering?.id}
          offeringNameError={!newOffering.name.length}
          setShowNote={setShowNote}
          showNote={showNote}
          startDate={newOffering.startDate}
          startDateError={!newOffering.startDate.length || dateOrderError}
        />
      </ModalBody>
      <ModalFooter>
        <div className={styles.errorsWrapper}>
          <ModalErrorMsg errorMsg={invalidDatesErrorMsg} />
          <ModalErrorMsg errorMsg={errorMsg} disableOverrideCta={!newOffering.name} onOverride={handleCreateIp} />
        </div>
        <ButtonGroup className={styles.footerBtns}>
          <Button id="cancel-create-ip-btn" onClick={onClose} className={styles.cancelBtn}>
            Cancel
          </Button>
          <Button id="create-ip-btn" variant="blue" onClick={() => handleCreateIp()} disabled={disableCreateCta}>
            Create
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(CreateProgramModal);
