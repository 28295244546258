import { makeAutoObservable } from 'mobx';
import { countTotalSelected } from '../utils/countTotalSelected';
import recursiveAllSelected from '../utils/recursiveAllSelected';
import recursiveDisplay from '../utils/recursiveDisplay';
import recursiveExpand from '../utils/recursiveExpand';
import recursivePartialSelection from '../utils/recursivePartialSelection';
import recursiveQuery from '../utils/recursiveQuery';
import recursiveReverseSelection from '../utils/recursiveReverseSelection';
import recursiveToggle from '../utils/recursiveToggle';
import recursiveToggleAll from '../utils/recursiveToggleAll';
import recursiveToggleExpandAll from '../utils/recursiveToggleExpandAll';
import AccordionItemModel from './AccordionItemModel';

class AccordionModel {
  items: AccordionItemModel[] = [];
  showTotal = false;

  constructor(items?: AccordionItemModel[]) {
    makeAutoObservable(this);

    if (items) {
      this.setData(items);
    }
  }

  setQuery = (query: string) => {
    const items = this.items.slice();
    recursiveQuery(items, query);
    this.items = items;
  };

  setData = (items: AccordionItemModel[]) => {
    this.items = items;
    recursivePartialSelection(items);
  };

  isAllSelected = (selectionValue: boolean) => {
    const items = this.items.slice();
    return recursiveAllSelected(items, selectionValue);
  };

  toggleExpand = (uid: string) => {
    const items = this.items.slice();
    recursiveExpand(items, uid);
    this.items = items;
  };

  toggleExpandAll = (isExpand: boolean) => {
    const items = this.items.slice();
    recursiveToggleExpandAll(items, isExpand);
    this.items = items;
  };

  toggleReverse = () => {
    const items = this.items.slice();
    recursiveReverseSelection(items, null);
    this.items = items;
  };

  toggleItem = (uid: string, checked: boolean) => {
    const items = this.items.slice();
    recursiveToggle(items, uid, checked, null);
    recursivePartialSelection(items);
    if (this.showTotal) {
      countTotalSelected(items);
    }
    this.items = items;
  };

  toggleAll = (checked: boolean) => {
    const items = this.items.slice();
    recursiveToggleAll(items, checked);
    recursivePartialSelection(items);
    this.items = items;
  };

  getChecked = (items: AccordionItemModel[]) => {
    return items.filter(i => i.checked);
  };

  getDisplayValues = () => {
    const items = this.items.slice();
    const display = recursiveDisplay(items);

    return display.length > 0 ? display.join(', ') : 'None';
  };

  setShowTotal = () => {
    this.showTotal = true;
  };
}

export default AccordionModel;
