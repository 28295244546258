import { observer } from 'mobx-react-lite';
import { IAssumptionMonth } from '../../../../models/Assumptions';
import useStores from '../../../../stores/useStores';
import AssumptionInput from './Inputs/AssumptionInput';

import styles from '../styles.module.scss';

type Props = {
  assumptions: IAssumptionMonth[];
};

const StandardRates = ({ assumptions }: Props) => {
  const {
    assumptionsStore: { updateAssumptionField },
  } = useStores();

  return (
    <div className={styles.ngcRow}>
      <div className={styles.ngcShadowWrapper}>
        <div className={styles.ngcGridRow}>
          <div className={styles.ngcRowHeader}>
            <label>Standard Rates</label>
          </div>
          {assumptions.map(assumption => (
            <AssumptionInput
              assumption={assumption}
              field="standardRate"
              id={`standard-rate-${assumption.month}`}
              key={`standard-rate-${assumption.id}`}
              onChange={updateAssumptionField}
              className={styles.underlineInput}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(StandardRates);
