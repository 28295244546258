import { assignNumberValue } from 'oat-common-ui';
import { CreateNationalRyoOffer, OfferSelection, UpdateNationalRyoOfferBudgetInput } from '../../../../gql/generated';
import NationalRyoModel, { OfferUidSelection } from '../../models/offers/NationalRyoModel';

export const getCreateNationalRyoOfferPayload = (nationalRyo: NationalRyoModel): CreateNationalRyoOffer => {
  return {
    rgnlAltId: nationalRyo.offerFields.rgnlAltId,
    isForRegions: nationalRyo.offerFields.isForRegions,
    penetrationRate: assignNumberValue(nationalRyo.offerFields.penetrationRate),
    optionType: nationalRyo.offerFields.optionType,
    name: nationalRyo.offerFields.name,
    ...nationalRyo.offerCostFields,
    amount: assignNumberValue(nationalRyo.offerCostFields.amount),
    offerPnvs: assignNumberValue(nationalRyo.offerCostFields.offerPnvs),
    offerSelections: cleanOfferSelections(nationalRyo.offerCostFields.offerSelections),
    ryoAmountPercent: assignNumberValue(nationalRyo.offerCostFields.ryoAmountPercent),
    isPercentSelected: nationalRyo.offerCostFields.isPercentSelected,
  };
};

export const getUpdateNationalRyoOfferBudgetPayload = (nationalRyo: NationalRyoModel): UpdateNationalRyoOfferBudgetInput => {
  return {
    id: nationalRyo.offerFields.id,
    rev: nationalRyo.offerFields.rev,
    isForRegions: nationalRyo.offerFields.isForRegions,
    penetrationRate: assignNumberValue(nationalRyo.offerFields.penetrationRate),
    name: nationalRyo.offerFields.name,
    ...nationalRyo.offerCostFields,
    amount: assignNumberValue(nationalRyo.offerCostFields.amount),
    offerPnvs: assignNumberValue(nationalRyo.offerCostFields.offerPnvs),
    offerSelections: cleanOfferSelections(nationalRyo.offerCostFields.offerSelections),
    ryoAmountPercent: assignNumberValue(nationalRyo.offerCostFields.ryoAmountPercent),
    rgnlAltId: nationalRyo.offerFields.rgnlAltId,
  };
};

// removes non-persisted offers from offer selections
const cleanOfferSelections = (selections: OfferUidSelection[]): OfferSelection[] => {
  return selections
    .filter(item => Boolean(item.offerId))
    .map(item => ({
      offerId: item.offerId,
      rgnlAltId: item.rgnlAltId,
      isNgl: item.isNgl,
    }));
};
