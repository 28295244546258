import { observer } from 'mobx-react-lite';
import { DragDrop, Draggable, DraggableProvided, DraggableStateSnapshot, DraggingStyle, DropResult, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import DragSortingItem from '../../../../components/DragSortingItem/DragSortingItem';
import { sortDragItemsList } from '../../../../components/DragSortingItem/utils';
import { INITIAL_REV_ID } from '../../../../constants/global';
import { SeriesSortItem, useUpdateSeriesSortingMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import { getItemStyle } from '../utils';

interface Props {
  seriesSortingList: SeriesSortItem[];
}

const SeriesSortingList = ({ seriesSortingList }: Props) => {
  const [updateSeriesSorting] = useUpdateSeriesSortingMutation();
  const { seriesSettingsStore, userInfoStore } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const currSortListCopy = [...seriesSortingList];
    const updatedSortList = sortDragItemsList(seriesSortingList, result.source.index, result.destination.index) as SeriesSortItem[];
    seriesSettingsStore.setSeriesSortingList(updatedSortList.map((item, i) => ({ ...item, sortOrder: i })));

    setDelay(async () => {
      try {
        const { data } = await trackPromise(
          updateSeriesSorting({
            variables: {
              input: {
                rev: seriesSettingsStore.seriesSortingRev || INITIAL_REV_ID,
                brand: userInfoStore.userInfo.brand,
                sortList: updatedSortList.map((item, i) => ({ id: item.id, sortOrder: i, bosuSeries: item.bosuSeries, displayName: item.displayName })),
              },
            },
          }),
        );

        seriesSettingsStore.setSeriesSortingRev(data?.updateSeriesSorting?.seriesSorting?.rev || '');
      } catch (e) {
        seriesSettingsStore.setSeriesSortingList(currSortListCopy);
        error(`Modifications were not saved, ${(e as Error).message}.`);
      }
    }, 500);
    // new order is autosaved and applied to each incentive period regardless of publish status
    // the same order should also be applied to the regional review screens
  };

  return (
    <DragDrop droppableId="seriesSorting" handleOnDragEnd={handleOnDragEnd}>
      {seriesSortingList.map((item, i) => {
        return (
          <Draggable key={item.id} draggableId={item.bosuSeries} index={i}>
            {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
              return (
                <DragSortingItem
                  innerRef={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  item={item.bosuSeries}
                  key={item.id}
                  style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style) as DraggingStyle}
                  moveItemUp={() => void 0}
                />
              );
            }}
          </Draggable>
        );
      })}
    </DragDrop>
  );
};

export default observer(SeriesSortingList);
