import { observer } from 'mobx-react-lite';
import useStores from '../../../../stores/useStores';
import styles from '../styles.module.scss';
import StandardRates from './StandardRates';
import StandardRcfs from './StandardRcfs';

import ResidualValues from './ResidualValues';

type Props = {
  onSave: () => void;
};

const AssumptionsRows = ({ onSave }: Props) => {
  const {
    assumptionsStore: { assumptions },
  } = useStores();

  return (
    <>
      <StandardRates assumptions={assumptions} />
      <StandardRcfs assumptions={assumptions} />
      <ResidualValues assumptions={assumptions} />
      <div className={styles.btnRow}>
        <button className={styles.saveBtn} onClick={onSave} id="save-assumptions-btn">
          Save Assumptions
        </button>
      </div>
    </>
  );
};

export default observer(AssumptionsRows);
