import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { ReactNode, useEffect } from 'react';
import { RgnlAltSummary, useGetRgnlAltSummaryLazyQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import ScenarioModel from '../models/ScenarioModel';
import SeriesProfileModel from '../models/SeriesProfileModel';

interface Props {
  children?: ReactNode;
  isSetupView: boolean;
  scenario: ScenarioModel;
  seriesProfile: SeriesProfileModel;
}

const GetRgnlAltData = ({ children, scenario, seriesProfile, isSetupView }: Props) => {
  const { globalStore } = useStores();
  const { error } = useToast();
  const [getRgnlAltSummary] = useGetRgnlAltSummaryLazyQuery();

  // fetch offers for rgnl within scenario and set in store
  useEffect(() => {
    const getRgnlAltWithOffers = async (scenario: ScenarioModel) => {
      try {
        globalStore.setShowProgressBar(true);
        const rgnlAltRes = await getRgnlAltSummary({ variables: { scenarioId: scenario.id } });
        scenario.setRgnlAlts(rgnlAltRes.data?.getRgnlAltSummary as RgnlAltSummary[], seriesProfile);
      } catch (e) {
        error((e as Error).message);
      } finally {
        globalStore.setShowProgressBar(false);
      }
    };

    getRgnlAltWithOffers(scenario);
  }, [scenario, globalStore, error, getRgnlAltSummary, seriesProfile, isSetupView]);

  return <>{children} </>;
};

export default observer(GetRgnlAltData);
