import { Scenario, SeriesProfile } from '../../../../../../../gql/generated';
import ScenarioModel from '../../../../../models/ScenarioModel';
import SeriesProfileModel from '../../../../../models/SeriesProfileModel';

export const processSeriesProfiles = (affectedSPs: SeriesProfile[], seriesProfiles: SeriesProfileModel[]) => {
  for (const affectedSP of affectedSPs) {
    const foundSP = seriesProfiles?.find(s => s.data.id === affectedSP.id);

    if (foundSP && affectedSP.scenarios) {
      for (const scenario of affectedSP.scenarios) {
        const exportedScenario = new ScenarioModel(scenario?.brand || '');
        exportedScenario.setData(scenario as Scenario);

        foundSP.scenarios = [...foundSP.scenarios, exportedScenario];
        foundSP.tempScenarios = [...foundSP.tempScenarios, exportedScenario];
        foundSP.setRev(affectedSP.rev);
      }
    }
  }
};
