import clsx from 'clsx';
import { Button, ButtonGroup, InlineInputLabel, OATIcon } from 'oat-common-ui';

import styles from '../styles.module.scss';

interface Props {
  toPrevFiscalYear: (toNext: boolean) => void;
  toNextFiscalYear: (toNext: boolean) => void;
  label: string;
  buttonStyle?: string;
  isDisabledNext?: boolean;
  isDisabledPrev?: boolean;
}

const FiscalYearNav = ({ toPrevFiscalYear, toNextFiscalYear, label, buttonStyle, isDisabledNext, isDisabledPrev }: Props) => {
  return (
    <div className={styles.fiscalContainer}>
      <InlineInputLabel label={label}>
        <ButtonGroup className={styles.navButtonGroup}>
          <Button
            id="previous-fiscal-year-btn"
            data-testid="previous-fiscal-year-btn"
            className={clsx(styles.angleButton, buttonStyle, { [styles.disabled]: isDisabledPrev })}
            disabled={isDisabledPrev}
            onClick={() => toPrevFiscalYear(false)}
            variant="text"
          >
            <OATIcon icon="angle-left" size="16" />
          </Button>
          <Button
            id="next-fiscal-year-btn"
            data-testid="next-fiscal-year-btn"
            className={clsx(styles.angleButton, buttonStyle, { [styles.disabled]: isDisabledNext })}
            disabled={isDisabledNext}
            onClick={() => toNextFiscalYear(true)}
            variant="text"
          >
            <OATIcon icon="angle-right" size="16" />
          </Button>
        </ButtonGroup>
      </InlineInputLabel>
    </div>
  );
};

export default FiscalYearNav;
