import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, NumberInput } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import useStores from '../../../../../stores/useStores';
import AprRateModel from '../../models/AprRateModel';
import styles from './styles.module.scss';

interface Props {
  model: AprRateModel;
}

const APRRateTableInput = ({ model }: Props) => {
  const {
    ratesTabAPRRatesStore: { updateAprRate },
    ipSettingsStore: {
      offering: { isPublished },
    },
  } = useStores();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { uid, tier, term } = model;
    updateAprRate(uid, tier, term, Number(e.target.value));
  };

  return (
    <div className={clsx(styles.gridColumn, styles.gridItem)}>
      <BlockInputLabel className={styles.blockInput} bottomContent={<span className={styles.percentage}>{`${model.rate.toFixed(2)}%`}</span>}>
        <NumberInput isNational id={model.uid} rate min={-100} max={100} value={model.differential} onChange={onChangeHandler} error={model.rate < 0} disabled={isPublished} />
      </BlockInputLabel>
    </div>
  );
};

export default observer(APRRateTableInput);
