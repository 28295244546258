import { assignStringValue } from 'oat-common-ui';
import { OfferRegion } from '../../../gql/generated';
import { RgnlAltRegionField } from '../../../pages/NationalPrograms/models/RgnlAltModel';
import AccordionItemModel from '../models/AccordionItemModel';

const isStateIncluded = (offerRegions: OfferRegion[], regionCode: string, state: string) => {
  const foundRegion = offerRegions?.find(region => region.regionCode === regionCode);

  return Boolean(foundRegion?.states?.includes(state));
};

const createAccordionFromOfferRegions = (rgnAltRegions: RgnlAltRegionField[], regions?: OfferRegion[]) => {
  const regionAccordions: AccordionItemModel[] = [];

  rgnAltRegions.forEach(region => {
    let regionItem = regionAccordions?.find(accordion => accordion.value === region.code);

    if (!regionItem) {
      regionItem = new AccordionItemModel(region.code, region.code);
    }

    region.states?.forEach(state => {
      let stateItem = regionItem?.items?.find(item => item.value === state.state);

      if (!stateItem) {
        stateItem = new AccordionItemModel(state.state, state.state);
      }

      stateItem.level = 2;
      stateItem.disabled = !state.selected;
      stateItem.checked = regions ? isStateIncluded(regions, assignStringValue(region.code), stateItem.value) : state.selected;
      regionItem?.items.push(stateItem);
    });

    regionItem.checked = regionItem.items.some(item => item.checked);

    if (!region.selected) {
      regionItem.disabled = !region.selected;
      regionItem.setChecked(false);
      regionItem.items.forEach(item => item.setChecked(false));
    }

    regionItem?.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    regionAccordions.push(regionItem);
  });

  return regionAccordions.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
};

export default createAccordionFromOfferRegions;
