import { makeAutoObservable } from 'mobx';
import { CreateLeaseModels } from 'oat-common-ui';
import Fields from './models/Fields';
import TableSort from './models/TableSort';

class AddVinStore {
  tab = 1;
  step = 1;
  enterVinStep = 1;
  trimList: CreateLeaseModels.TrimItem[] = [];
  trimTable = new TableSort('code', true);
  configTable = new TableSort('optionsTotal', true);
  vinTable = new TableSort('dealerInvoice', false);
  series = '';
  seriesYear = '';
  error: Error | undefined = undefined;
  gStatusFiltered = false;
  selectTrimFields = new Fields();
  enterVinFields = new Fields();
  loading:boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTab = (tab: number) => {
    this.tab = tab;
  };

  getFlow = () => {
    return this.tab === 1 ? this.selectTrimFields : this.enterVinFields;
  };

  setTrimQuery = (query: string) => {
    this.selectTrimFields.trimQuery = query;
  };

  setConfigQuery = (query: string) => {
    this.selectTrimFields.configQuery = query;
  };

  setVinQuery = (query: string) => {
    this.enterVinFields.vinQuery = query;
  };

  setDealerCodeQuery = (query: string) => {
    this.selectTrimFields.dealerCodeQuery = query;
  };

  setTrimList = (trimList: CreateLeaseModels.TrimItem[], series: string, seriesYear: string) => {
    if (this.trimList.length && series === this.series && seriesYear === this.seriesYear) {
      return;
    }

    this.error = undefined;
    this.series = series;
    this.seriesYear = seriesYear;
    this.trimList = trimList;
  };

  getTableSort = () => {
    if (this.showConfigTable()) {
      return this.configTable;
    }

    if (this.showVinTable()) {
      return this.vinTable;
    }

    return this.trimTable;
  };

  sortTrimList = (col: string) => {
    this.trimTable.setSortField(col);
  };

  sortConfigList = (col: string) => {
    this.configTable.setSortField(col);
  };

  sortVinList = (col: string) => {
    this.vinTable.setSortField(col);
  };

  setConfigList = (configList: CreateLeaseModels.ConfigItem[]) => {
    this.error = undefined;
    this.gStatusFiltered = false;

    if (this.tab === 1) {
      this.selectTrimFields.configList = configList;
    } else {
      this.enterVinFields.configList = configList;
      this.enterVinFields.trim = this.trimList.filter(trim => trim.code === this.enterVinFields.vinList[0].modelCode)[0];
      this.enterVinFields.config = this.enterVinFields.configList[0];
    }
  };

  setVinList = (vinList: CreateLeaseModels.VinItem[]) => {
    const listCopy = vinList.slice().map(listItem => {
      return {
        ...listItem,
        dealerInvoice: Math.round(+listItem.dealerInvoice).toString(),
        msrpDiscount: listItem.msrpDiscount ?? 0,
      };
    });

    if (this.tab === 1) {
      this.selectTrimFields.vinList = listCopy;
    } else {
      this.enterVinFields.vinList = listCopy;
    }

    this.error = undefined;
    this.gStatusFiltered = false;
  };

  findConfig = (modelCode: string) => {
    return this.selectTrimFields.configList.find(config => config.modelCode === modelCode);
  };

  selectTrim = (trim: CreateLeaseModels.TrimItem) => {
    this.selectTrimFields.trim = trim;
    this.step += 1;
  };

  selectConfig = (config: CreateLeaseModels.ConfigItem) => {
    this.selectTrimFields.config = config;
    this.step += 1;
  };

  selectVin = (vin: CreateLeaseModels.VinItem) => {
    if (this.tab === 1) {
      this.selectTrimFields.vin = vin;
      this.step += 1;
    } else {
      this.enterVinFields.vin = vin;
      this.enterVinStep += 1;
    }
  };

  setGstatus = () => {
    this.gStatusFiltered = !this.gStatusFiltered;
  };

  setGstatusFilterDefault = () => {
    this.gStatusFiltered = false;
  };

  setStep = (step: number) => {
    this.step = step;
    if (step === 1) {
      this.selectTrimFields.trim = undefined;
    }

    if (step <= 2) {
      this.selectTrimFields.config = undefined;
    }

    if (step <= 3) {
      this.selectTrimFields.vin = undefined;
    }
  };

  setEnterVinStep = (step: number) => {
    this.enterVinStep = step;
  };

  backToTrimSelectionStep = () => {
    this.setTrimQuery('');
    this.setDealerCodeQuery('');
    this.setStep(1);
    this.setError();
    this.setGstatusFilterDefault();
    this.setConfigList([]);
    this.setVinList([]);
  };

  backToConfigSelectionStep = () => {
    this.setConfigQuery('');
    this.setDealerCodeQuery('');
    this.setStep(2);
    this.setError();
    this.setGstatusFilterDefault();
    this.setVinList([]);
  };

  clearDealerCode = () => {
    this.setDealerCodeQuery('');
    this.setGstatusFilterDefault();
  };

  setError = (e?: Error) => {
    if (this.error && e === undefined) {
      this.error = undefined;
      return;
    }

    this.error = e;
  };

  getDetails = () => {
    return this.tab === 1 ? this.selectTrimFields : this.enterVinFields;
  };

  resetAll = () => {
    if (this.tab === 1) {
      this.initSelectTrim();
    } else {
      this.initEnterVin();
    }
  };

  vinReset = () => {
    if (this.tab === 1) {
      this.setStep(3);
    } else {
      this.setEnterVinStep(2);
    }
  };

  vinSearchReset = () => {
    this.setVinQuery('');
    this.setVinList([]);
  };

  updateIsSpecialEdition = (val: boolean) => {
    this.getFieldObj().isSpecialEdition = val;
  };

  getFieldObj = () => {
    return this.tab === 1 ? this.selectTrimFields : this.enterVinFields;
  };

  showTrimTable = () => this.tab === 1 && this.step === 1;
  showConfigTable = () => this.tab === 1 && this.step === 2;
  showVinTable = () => this.tab === 1 && this.step === 3;

  showVinList = () => this.enterVinStep < 3;

  showSelectTrimFlow = () => this.tab === 1 || this.enterVinStep === 3;
  showEnterVinFlow = () => this.tab === 2 && this.enterVinStep < 3;
  showFinalSelectionFlow = () => (this.tab === 1 && this.step === 4) || (this.tab === 2 && this.enterVinStep === 3);

  initSelectTrim = () => {
    this.step = 1;
    this.error = undefined;
    this.selectTrimFields.trimQuery = '';
    this.selectTrimFields.configQuery = '';
    this.selectTrimFields.vinQuery = '';
    this.selectTrimFields.dealerCodeQuery = '';
    this.selectTrimFields.trim = undefined;
    this.selectTrimFields.config = undefined;
    this.selectTrimFields.configList = [];
    this.selectTrimFields.vin = undefined;
    this.selectTrimFields.vinList = [];
    this.selectTrimFields.name = 'Lease';
    this.selectTrimFields.pnv = 0;
    this.selectTrimFields.tfsCostShare = 20;
  };

  initEnterVin = () => {
    this.enterVinStep = 1;
    this.error = undefined;
    this.enterVinFields.trimQuery = '';
    this.enterVinFields.configQuery = '';
    this.enterVinFields.vinQuery = '';
    this.enterVinFields.dealerCodeQuery = '';
    this.enterVinFields.trim = undefined;
    this.enterVinFields.config = undefined;
    this.enterVinFields.configList = [];
    this.enterVinFields.vin = undefined;
    this.enterVinFields.vinList = [];
    this.enterVinFields.name = 'Lease';
    this.enterVinFields.pnv = 0;
    this.enterVinFields.tfsCostShare = 20;
  };

  get hasVinQueryError() {
    return !this.enterVinFields.vinQuery;
  }
}

export default AddVinStore;
