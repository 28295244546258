import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import styles from '../../styles.module.scss';
import TotalDetails from './TotalDetails';

interface Props {
  fiscalMonths: AdminModels.FiscalMonth[];
  fyMonthTotals: { [month: number]: AdminModels.FyMonthTotal };
}

const GridItemTotalDetail = ({ fiscalMonths, fyMonthTotals }: Props) => {
  return (
    <>
      {fiscalMonths.map((item, i) => {
        const total = fyMonthTotals[item.month]?.totals ?? undefined;
        return (
          <div className={clsx(styles.fySeriesBox)} key={i}>
            <ul className={styles.fySeriesBoxList}>
              <li>
                <TotalDetails month={item.month} year={item.year} sales={total?.sales ?? 0} pnvs={total?.pnvs ?? 0} totalCost={total?.totalCost ?? 0} />
              </li>
            </ul>
          </div>
        );
      })}
    </>
  );
};

export default observer(GridItemTotalDetail);
